import { Modal } from '../../components/common'

export default class ChangeSectionSuccessModal extends Component {
  footerActions() {
    return [
      { buttonLabel: 'Close', isDismissable: true, isPrimary: true },
    ]
  }

  render() {
    const { studentName, sectionFromName, sectionToName, modalCloseFocusElem } = this.props

    return (
      <Modal
        headerTitle="Change Section"
        modalCloseFocusElem={modalCloseFocusElem}
        footerActions={this.footerActions()}
      >
        <div>{studentName} has been moved from {sectionFromName} to {sectionToName}.</div>
      </Modal>
    )
  }
}
