const InvoiceNumbers = ({ customerNumber, invoiceNumber }) => {
  if (customerNumber || invoiceNumber) {
    return (
      <>
        {customerNumber && (
          <>
            <h3 className="h4">Customer Number</h3>
            <div>{customerNumber}</div>
          </>
        )}
        {invoiceNumber && (
          <>
            <h3 className="h4" style={{ marginTop: '1em' }}>
              Invoice Number
            </h3>
            <div>{invoiceNumber}</div>
          </>
        )}
      </>
    )
  }
  return null
}

export default InvoiceNumbers
