import * as c from '../constants/OrderConstants'

export default (state = { selectedFilter: c.ALL_APPROVAL_REQUESTS }, { type, payload }) => {
  switch(type) {
    case c.SET_APPROVAL_REQUEST_FILTER:
      return { ...state, selectedFilter: payload }

    default:
      return state
  }
}
