import NonSecureMaterialsOrderForm from './NonSecureMaterialsOrderForm'
import OrderSummary from './OrderSummary'
import CustomerServiceContactPhoneNumbers from '../../../common/CustomerServiceContactPhoneNumbers'

const mapStateToProps = (state, ownProps) => {
  const { tabLabel } = ownProps
  return {
    tabLabel,
  }
}

const OtherMaterialsTab = props => {
  const { tabLabel } = props

  return (
    <div aria-labelledby={tabLabel} role="tabpanel">
      <div className="row">
        <div className="col-md-9 col-sm-8">
          <p>
            To order additional materials for your exam administration, please contact AP Services
            for Educators at: <CustomerServiceContactPhoneNumbers />.
          </p>
          <NonSecureMaterialsOrderForm />
        </div>
        <div className="col-md-3 col-sm-4">
          <OrderSummary />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(OtherMaterialsTab)
