import { Link } from 'react-router-dom'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import PurchaseOrder from './PurchaseOrder'
import BillingContact from './BillingContact'
import { NAV_LINKS } from '../../constants/NavConstants'
import {
  STUDENT_ENROLLMENT_STATUS_YES,
  STUDENT_ENROLLMENT_STATUS_UNUSED,
} from '../../constants/StudentConstants'
import { ORDER_EXAM_FILTER } from '../../constants/FilterConstants'

const mapStateToProps = ({
  settingsEducationPeriod: { selectedEducationPeriod },
  settingsDeadlines: { data: settingsDeadlinesData },
  settingsOrg,
  user: {
    data: { selectedOrgId },
  },
}) => {
  const invoiceAvailableDate =
    settingsDeadlinesData?.[selectedEducationPeriod]?.invoiceAvailableDate
  return {
    invoiceAvailableDate: formatDate(invoiceAvailableDate, DATETIME_FORMATS.longMonthDayYear),
    isCentralBill: settingsOrg[selectedOrgId]?.data?.schoolInformation?.billing?.centralBilling,
  }
}

const InvoiceNotAvailable = ({ invoiceAvailableDate, isCentralBill }) => (
  <div className="row">
    <div className="col-md-8">
      <h2 style={{ marginBottom: '2em' }}>
        {isCentralBill
          ? `Your invoice will be ready for your district on ${invoiceAvailableDate}. You'll also be able to view the details at that time.`
          : `Your invoice will be ready on ${invoiceAvailableDate}`}
      </h2>
      <p>
        Before then, there are a few things you should do to make sure your invoice is accurate.
      </p>

      {!isCentralBill ? (
        <BillingContact
          title="Check Your School's Billing Contact Info"
          afterLinkText="if you need to update the contact info"
          linkTitle="Go to your settings"
          date={invoiceAvailableDate}
        />
      ) : null}

      <h3 className="h4">Check Your Students&#39; Fee Reduction Status</h3>
      <p>
        Make sure that you&#39;ve indicated each{' '}
        <Link
          to={`${NAV_LINKS.students}?${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_YES}`}
        >
          student eligible for an AP College Board fee reduction
        </Link>{' '}
        as &#39;Reduced&#39; under the AP Fee Status field on the Students page. This will ensure
        that any exams taken by these students are priced correctly. If you need to make a change to
        a student&#39;s fee reduction status after April 30, 2019, you will need to call AP Services
        at (877) 274-6474 (toll free in the United States and Canada) or (212) 632-1781.
      </p>

      <h3 className="h4">Mark All Unused Exams</h3>
      <p>
        If any students don&#39;t take an exam that was ordered for them, you need to{' '}
        <Link
          to={`${NAV_LINKS.students}?${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_YES}&${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_UNUSED}`}
        >
          indicate those exams as &#39;Unused&#39; on the Students page
        </Link>
        , so that the full exam fee isn&#39;t applied for those exams.
      </p>

      {!isCentralBill ? (
        <PurchaseOrder
          linkTitle="add or edit"
          content="If your school uses a Purchase Order when paying for AP Exams, you can <link> purchase order numbers from the Settings page."
        />
      ) : null}
    </div>
  </div>
)

export default connect(mapStateToProps)(InvoiceNotAvailable)
