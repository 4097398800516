import { StreetAddress } from '../common'

const GrantorAddress = ({ firstName, lastName, orgName, email, address }) => (
  <>
    <div className="contact-name">
      {firstName} {lastName}
    </div>
    <div className="contact-org">{orgName}</div>
    <StreetAddress {...address} styles={{ marginBottom: '0' }} />
    <p className="contact-email"><a href={`mailto:${email}`} title={`Email ${email}`}>{email}</a></p>
  </>
)

export default GrantorAddress
