/**
 * Accepts:
 * label : the dropdown menu's label
 * id : the id to scope the dropdown by
 * menuitems
 *   val : the value to add to the menuitem's anchor's data-val attribute
 *   clickEvent : the function to execute when a menuitem is clicked
 *   label : the label to show in the menuitem
 **/

export const Dropdown = ({
  label,
  id,
  menuItems,
  rightAligned,
  applyStyles = {},
  className = '',
  ariaDescribedBy,
  disabled,
  tag = 'div',
}) => {
  const children = (
    <>
      <a
        className={`dropdown-toggle ${disabled ? 'disabled' : ''}`}
        data-toggle="dropdown"
        href={disabled ? undefined : '#'}
        id={`dropdownMenu${id}`}
        role="button"
        aria-expanded={false}
        aria-haspopup={true}
        aria-describedby={ariaDescribedBy}
      >
        <span>{label}</span>
        <span className="sr-only">Press Enter for Dropdown.</span>
        <i className="cb-glyph cb-glyph-right cb-down" aria-hidden="true"></i>
        <i className="cb-glyph cb-glyph-right cb-up hidden" aria-hidden="true"></i>
      </a>
      <ul
        className="dropdown-menu"
        role="menu"
        aria-labelledby={`dropdownMenu${id}`}
        style={rightAligned ? { right: 0, left: 'auto' } : {}}
      >
        {menuItems.map((item, i) => (
          <li role="presentation" key={i}>
            <a
              role="menuitem"
              data-val={item.val}
              href={item.href || '#'}
              onClick={item.clickEvent}
              title={item.title}
              target={item.target}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </>
  )

  return React.createElement(
    tag,
    { className: `dropdown cb-dropdown ${className}`, style: applyStyles },
    children
  )
}

export const IconDropdown = ({
  iconClass,
  srTitle,
  id,
  menuItems,
  ariaDescribedBy,
  dropdownClass = '',
}) => (
  <div className={`dropdown cb-dropdown ${dropdownClass}`}>
    <a
      className="dropdown-toggle"
      data-toggle="dropdown"
      href="#"
      id={`dropdownMenu${id}`}
      role="button"
      aria-expanded={false}
      aria-haspopup={true}
      aria-describedby={ariaDescribedBy}
    >
      <i className={iconClass} aria-hidden="true" />
      <span className="sr-only">{srTitle} Press Enter for Dropdown.</span>
    </a>
    <ul className="dropdown-menu" role="menu" aria-labelledby={`dropdownMenu${id}`}>
      {menuItems.map((item, i) => (
        <li role="presentation" key={i}>
          <a role="menuitem" data-val={item.val} href="#" onClick={item.clickEvent}>
            {item.label}
          </a>
        </li>
      ))}
    </ul>
  </div>
)
