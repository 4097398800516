import ProgramStatistics from '../../components/orders/coordinator/ProgramStatistics'
import NoAccessPage from './NoAccessPage'
import { setNavLink, unsetNavLink } from '../../actions/nav'

const mapStateToProps = (state) => {
  return {
    user: state.user.data
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ setNavLink, unsetNavLink }, dispatch)

export class ProgramStatisticsPage  extends Component {

  componentDidMount() {
    let { setNavLink } = this.props

    setNavLink('/orders', 'Back to orders')
  }

  componentWillUnmount() {
    let { unsetNavLink } = this.props

    unsetNavLink()
  }

  render() {
    const { user } = this.props;
    
    return (
      <CSSTransition classNames="animate-fade" in={true} timeout={500} appear={true} enter={false} exit={false}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              { user.isCoordinator ? <ProgramStatistics /> : <NoAccessPage /> }
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

ProgramStatisticsPage.propTypes = {
  user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramStatisticsPage)