import { isDateSameOrAfter, isDateBefore } from '@myap/ui-library/esm/date'
import { Modal, Error } from '../../../common'
import UpdateTable from '../../../orders/coordinator/UpdateTable'
import { PAPER_EXAM } from '../../../../constants/SettingsConstants'
import { isEmpty } from '../../../../utils/common'
import LateReason from './common/LateReason'
import TestingLocation from './common/TestingLocation'
import RequestExamHasStarted from './common/RequestExamHasStarted'
import {
  getExamWindowsForCourse,
  isDigitalExamInCutoffPeriod,
  getExamDay,
} from '../../../../selectors/examWindows'
import { isAdministeringDigitalExams } from '../../../../selectors/settings'

import style from '../../../../assets/style/scss/orders.scss'

const mapStateToProps = (state, ownProps) => {
  const {
    status: {
      data: { isSubmitted },
    },
  } = state
  const {
    exam: {
      previousTestDate,
      previousTestWindow,
      previousLateReasonCode,
      previousTestLocation,
      previousExamStarted,
      examFormat,
      lateReasonCode,
      testCd,
      testWindow,
    },
  } = ownProps
  const examWindowData = getExamWindowsForCourse(state, testCd)
  const { examFormat: nextExamFormat, lateTestingFeeApplies } = examWindowData[testWindow]
  const isLateReasonChange =
    lateTestingFeeApplies && Boolean(lateReasonCode) && !Boolean(previousTestWindow)
  const isPaper = examFormat === PAPER_EXAM
  const isPastDigitalCutOff = isDigitalExamInCutoffPeriod(state, {
    testCd,
    testWindow: previousTestWindow,
  })
  const isBeforePreviousExamDay = isDateBefore(
    new Date(),
    getExamDay(state, testCd, previousTestWindow ?? testWindow)
  )

  return {
    isSubmitted,
    showHasStudentStartedExam: isPaper && isDateSameOrAfter(new Date(), previousTestDate),
    isLateTestingWindow: lateTestingFeeApplies,
    isLateReasonChange,
    examWindowData,
    isDigital: nextExamFormat !== PAPER_EXAM,
    isWithinDigitalWarningPeriod: isPastDigitalCutOff && isBeforePreviousExamDay,
    notAdministeringDigitalExams: !isAdministeringDigitalExams(state),
    previousLateReasonCode,
    previousTestLocation,
    previousExamStarted,
  }
}

const IntroText = ({
  isLateReasonChange,
  isLateTestingWindow,
  courseName,
  isWithinDigitalWarningPeriod,
}) => {
  if (isLateReasonChange)
    return (
      <p>
        You are changing the reason why the student is using the Late-Testing date. By doing so
        there may be cost implication.
      </p>
    )

  if (!isLateTestingWindow)
    return (
      <p>
        You are changing the <strong>{courseName}</strong> exam date for this student. The cost for
        this exam is noted below.
      </p>
    )

  return (
    <>
      {isWithinDigitalWarningPeriod && (
        <>
          <div
            className="alert alert-warning cb-alert-heading"
            style={{ marginBottom: 0 }}
            role="alert"
            tabIndex="0"
          >
            <h2>Cannot Undo</h2>
          </div>
          <p>
            <strong>Important:</strong> If you change this student&#39;s exam date now, they
            won&#39;t be able to test on this exam date. Click <strong>Update</strong> to proceed
            with changing the student&#39;s exam date. Click <strong>Cancel</strong> to keep the
            student&#39;s exam date.
          </p>
        </>
      )}
      <p>
        You are changing the <strong>{courseName}</strong> exam date for this student. Select the
        reason below. Some reasons may incur a late-testing fee; any additional fee will be noted.
      </p>
    </>
  )
}

const TestDate = ({
  exam: incomingExam,
  studentName,
  courseName,
  isSubmitted,
  showHasStudentStartedExam,
  isLateTestingWindow,
  isLateReasonChange,
  error,
  modalStyles,
  shouldCloseModal,
  onCloseAction,
  modalCloseFocusElem,
  getFooterActions,
  isDigital,
  isWithinDigitalWarningPeriod,
  notAdministeringDigitalExams,
  previousLateReasonCode,
  previousTestLocation,
  previousExamStarted,
  previousTestWindow,
}) => {
  const [exam, setExam] = useState(incomingExam)
  const [attested, setAttested] = useState(isDigital || notAdministeringDigitalExams)
  const [examStarted, updateExamStarted] = useState(null)
  const { lateReasonCode, testLocation, testWindow } = exam
  const hasStartedExamNotSelected = showHasStudentStartedExam && isEmpty(examStarted)
  const disableUpdateButton =
    (!lateReasonCode && isLateTestingWindow) || hasStartedExamNotSelected || !attested
  const needsSelectedLateReason = lateReasonCode || !isLateTestingWindow
  const hasSpecialDigitalFormats =
    exam.specialDigitalFormats && Object.keys(exam.specialDigitalFormats).length > 0 && isDigital

  useEffect(() => {
    setExam({ ...exam, examStarted })
  }, [examStarted])

  const getUpdatedTestDate = () => {
    // For that case where previous test window required examStarted and then
    // switched to test window that does not require examStarted,
    // reset examStarted to null
    const hasStartedExam = !isEmpty(examStarted) && !showHasStudentStartedExam ? null : examStarted
    return {
      ...exam,
      examStarted: hasStartedExam,
      lateReasonCode: isLateTestingWindow ? lateReasonCode : null,
      testLocation: isDigital ? testLocation : null,
    }
  }

  const updateLateReason = e => {
    setExam({ ...exam, lateReasonCode: e.target.value })
  }

  const updateTestLocation = e => setExam({ ...exam, testLocation: e.target.value })

  return (
    <Modal
      modalStyles={modalStyles}
      headerTitle={isSubmitted ? 'Change Submitted Order' : 'Update Order'}
      shouldCloseModal={shouldCloseModal}
      onCloseAction={onCloseAction}
      modalCloseFocusElem={modalCloseFocusElem}
      footerActions={getFooterActions(getUpdatedTestDate, {
        disabled:
          disableUpdateButton ||
          (isLateTestingWindow &&
            previousLateReasonCode === lateReasonCode &&
            previousTestLocation === testLocation &&
            previousExamStarted === examStarted &&
            previousTestWindow == testWindow),
      })}
    >
      {error ? <Error title="Error Updating Student" message={error} /> : null}

      <IntroText
        isLateReasonChange={isLateReasonChange}
        isLateTestingWindow={isLateTestingWindow}
        courseName={courseName}
        isWithinDigitalWarningPeriod={isWithinDigitalWarningPeriod}
      />
      <LateReason
        isLateTestingWindow={isLateTestingWindow}
        updateLateReason={updateLateReason}
        lateReasonCode={lateReasonCode}
      />
      <TestingLocation
        isDigital={isDigital}
        testLocation={testLocation}
        updateTestLocation={updateTestLocation}
        attestTestLocation={e => setAttested(e.target.checked)}
      />
      {needsSelectedLateReason && (
        <>
          <hr className={style['order-border-warning']} />
          <RequestExamHasStarted
            showHasStudentStartedExam={showHasStudentStartedExam}
            examStarted={examStarted}
            updateHasStartedExam={updateExamStarted}
          />
          {isSubmitted && (
            <p>You will need to review and submit your change order to apply this update.</p>
          )}
          {hasSpecialDigitalFormats && (
            <p style={{ fontWeight: 'bold' }}>
              This student has accommodations which must be confirmed for the digital exam. Go to
              the Student Details page to review and verify the accommodations and exam format
              needed for this digital exam.
            </p>
          )}
          <UpdateTable exam={exam} studentName={studentName} />
        </>
      )}
    </Modal>
  )
}

export default connect(mapStateToProps)(TestDate)
