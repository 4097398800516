import axios, { getServerErrorMessage } from '../../utils/axios'

// TBD: Stubbing EXCEPTION in to keep this class happy till we decide what
// we're doing with these instructions.
const EXCEPTION_TESTDAY_VAL = 'EXCEPTION'

const mapStateToProps = state => {
  const {
    user: {
      data: { isCoordinator, isCSR, selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
  } = state
  return {
    // No exception testing over multiple days anymore, so this instructions
    // shouldn't be needed. Disabling for now.
    canAccess: false, //isCoordinator && !isCSR,
    selectedOrgId,
    educationPeriodCd,
  }
}

class ExceptionExamInstructions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      countsPerTestDay: {},
      signedExceptionExamInstructionsUrl: '',
      error: null,
    }
  }

  componentDidMount() {
    const { canAccess, selectedOrgId, educationPeriodCd } = this.props
    const { CancelToken } = axios
    const source = CancelToken.source()

    if (canAccess) {
      axios
        .get(
          `${Config.API_URL}/enrollments/counts-per-test-day-type?orgId=${selectedOrgId}&educationPeriodCd=${educationPeriodCd}`,
          { cancelToken: source.token }
        )
        .then(res => this.setState({ countsPerTestDay: res.data }))
        .catch(err => {
          if (!axios.isCancel(err)) {
            this.setState({ error: getServerErrorMessage(err) })
          }
        })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { countsPerTestDay } = this.state
    if (
      prevState.countsPerTestDay[EXCEPTION_TESTDAY_VAL] !==
        countsPerTestDay[EXCEPTION_TESTDAY_VAL] &&
      countsPerTestDay[EXCEPTION_TESTDAY_VAL] > 0
    ) {
      this.getSignedExceptionExamInstructionsUrl()
    }
  }

  getSignedExceptionExamInstructionsUrl() {
    const { selectedOrgId, educationPeriodCd } = this.props
    axios
      .get(
        `${Config.API_URL}/v1/exam-instructions/organizations/${selectedOrgId}/education-periods/${educationPeriodCd}/signed-zip`
      )
      .then(res => this.setState({ signedExceptionExamInstructionsUrl: res.data.url }))
      .catch(err => this.setState({ error: getServerErrorMessage(err) }))
  }

  render() {
    const { canAccess } = this.props
    const {
      countsPerTestDay: { [EXCEPTION_TESTDAY_VAL]: exceptionTestCount },
      signedExceptionExamInstructionsUrl,
      error,
    } = this.state

    if (canAccess && exceptionTestCount && !error) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <h2 className="h2 roboto" style={{ marginBottom: 0, marginTop: '30px' }}>
              <a href={signedExceptionExamInstructionsUrl}>Exception Exam Instructions</a>
            </h2>
            <p>
              Download PDFs for the General Instructions and the exam subject(s) you&#39;re
              administering during exception testing.
            </p>
          </div>
        </div>
      )
    }

    return null
  }
}

export default connect(mapStateToProps)(ExceptionExamInstructions)
