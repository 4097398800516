import { Modal, Error, Loader, BasicSelect } from '../../components/common'
import { openModal } from '../../actions/app'
import { fetchAvailableSectionsForMove, resetStudentUpdate } from '../../actions/studentsCommon'
import { isExamOnly } from '../../selectors/section'
import { sortColumnByKey } from '../../utils/sort'
import { UPDATE_TYPE_CHANGE } from '../../constants/StudentConstants'

const mapStateToProps = (state, { exam, storeLocation }) => {
  const {
    update: { type, updating, error, id },
  } = state[storeLocation]
  const isActive = type === UPDATE_TYPE_CHANGE && exam.examId === id

  return {
    active: isActive,
    updating: isActive && updating,
    error: isActive && error,
    isCoordinator: state.user.data.isCoordinator,
    breakpoint: state.app.breakpoint.name,
  }
}

const reformatSections = sections => {
  const processedSections = sections.map(s => {
    const teachers = isExamOnly(s.sectionType) ? 'Exam-Only' : s.teacherNames.join(', ')
    return {
      value: s.sectionId,
      label: `${s.sectionName}${teachers ? ' - ' : ''}${teachers}`,
      name: s.sectionName,
    }
  })
  return sortColumnByKey(processedSections, 'label', 'asc')
}

class ChangeSectionModal extends Component {
  state = {
    selectedSection: null,
    availableSections: [],
    availableSectionsFetching: true,
    availableSectionsError: null,
    shouldCloseModal: false,
    active: false,
  }

  constructor(props) {
    super()
    this.handleChangeSectionChoice = this.handleChangeSectionChoice.bind(this)
    this.enrollmentWithFromSection = props.enrollment
  }

  static getDerivedStateFromProps(props, state) {
    if (state.active && !props.active) return { shouldCloseModal: true }

    return { active: props.active }
  }

  componentDidMount() {
    this.getAvailableSections()
  }

  componentWillUnmount() {
    const { resetStudentUpdate } = this.props
    resetStudentUpdate()
  }

  onCloseAction() {
    const { studentName, section, openModal } = this.props
    const { shouldCloseModal, availableSections, selectedSection } = this.state

    if (shouldCloseModal) {
      openModal('ChangeSectionSuccessModal', {
        studentName,
        sectionFromName: section.name,
        sectionToName: availableSections.find(s => s.value === selectedSection)?.name,
      })
    }
  }

  async getAvailableSections() {
    const {
      exam: { sectionId, studentId },
    } = this.props
    const { data = [], error = null } = await fetchAvailableSectionsForMove(sectionId, studentId)
    this.setState({
      availableSections: reformatSections(data),
      availableSectionsError: error,
      availableSectionsFetching: false,
    })
  }

  getSectionTeachersList() {
    const {
      section: { type, teachers },
    } = this.props

    return (
      <>
        <dt key="teachers" style={{ margin: '15px 0 0 0' }}>{`Current Teacher${
          teachers.length > 1 ? 's' : ''
        }:`}</dt>
        {isExamOnly(type) ? (
          <dd style={{ marginLeft: 0 }} key="examonly">
            <em>Exam Only</em>
          </dd>
        ) : (
          teachers.map(t => (
            <dd style={{ marginLeft: 0 }} key={t.proId}>
              {t.name}
            </dd>
          ))
        )}
      </>
    )
  }

  footerActions() {
    const { exam, action, updating } = this.props
    const { selectedSection, availableSections } = this.state

    if (availableSections.length) {
      return [
        { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
        {
          buttonLabel: 'Change Section',
          isPrimary: true,
          onClick: () => action(selectedSection, exam),
          isDisabled: !selectedSection || updating,
          busy: updating,
        },
      ]
    }

    return [{ buttonLabel: 'Close', isDismissable: true, isPrimary: true }]
  }

  handleChangeSectionChoice(e) {
    this.setState({ selectedSection: parseInt(e.target.value, 10) })
  }

  render() {
    const {
      isCoordinator,
      courseName,
      section: { name },
      studentName,
      modalCloseFocusElem,
      breakpoint,
      error,
    } = this.props
    const {
      availableSectionsFetching,
      availableSectionsError,
      availableSections,
      selectedSection,
      shouldCloseModal,
    } = this.state
    const hasAvailableSections = availableSections.length

    return (
      <Modal
        modalStyles={breakpoint !== 'mobile' ? { width: 750 } : {}}
        headerTitle="Change Section"
        shouldCloseModal={shouldCloseModal}
        modalCloseFocusElem={modalCloseFocusElem}
        onCloseAction={this.onCloseAction.bind(this)}
        footerActions={this.footerActions()}
      >
        {availableSectionsFetching ? <Loader /> : null}
        {availableSectionsError ? (
          <Error title="Error Loading Available Sections" message={availableSectionsError} />
        ) : null}
        {error ? <Error title="Error Changing Sections" message={error} /> : null}
        {hasAvailableSections ? (
          <>
            <p style={{ marginBottom: 15 }}>
              Note: Moving a student to a new section will delete any existing assignments from the
              AP Classroom for that student.
            </p>
            <div className="row">
              <div className="col-xs-6">
                <dl>
                  <dt style={{ marginLeft: 0 }}>Student:</dt>
                  <dd style={{ marginLeft: 0 }}>{studentName}</dd>
                  {isCoordinator ? this.getSectionTeachersList() : null}
                  <dt style={{ margin: '15px 0 0 0' }}>Current Section:</dt>
                  <dd style={{ marginLeft: 0 }}>{name}</dd>
                </dl>
              </div>
              <div className="col-xs-6">
                <BasicSelect
                  label="Move to:"
                  input={{
                    name: 'selectMoveToSectionField',
                    value: selectedSection,
                    onChange: this.handleChangeSectionChoice,
                  }}
                  options={availableSections}
                  truncate={34}
                />
              </div>
            </div>
          </>
        ) : null}
        {!hasAvailableSections && !availableSectionsFetching ? (
          <p>
            There are no available sections of {courseName} where {studentName} can be moved.
          </p>
        ) : null}
      </Modal>
    )
  }
}

export default connect(mapStateToProps, { openModal, resetStudentUpdate })(ChangeSectionModal)
