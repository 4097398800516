import style from '../../../assets/style/scss/filters.scss'
import FilterTag from './FilterTag'
import { getFilterTags } from '../../../selectors/filters/utils'

const FilterContainer = props => {
  const { activeFilters = [], label, children, getFilterTagData, styles = {} } = props
  const filterTags = getFilterTags(activeFilters, getFilterTagData)

  return (
    <div className={style['filter-container']} role="region" aria-label={label} style={styles}>
      <div className="container">
        <div className={style['filter-components']}>{children}</div>
        <div role="region" aria-label="Active/Applied Filters">
          {filterTags.map((tag, i) => (
            <FilterTag {...tag} key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default FilterContainer
