import { StepTracker, StepButtons, ExamAdministration } from '../../components/setup'
import { EXAM_ADMINISTRATION_FORM } from '../../constants/SetupConstants'

export default ({ backStep }) => (
  <div>
    <StepTracker />

    <h3 className="h1" style={{ marginTop: 40 }}>Provide AP Exam Administration Information</h3>
    <ExamAdministration />

    <StepButtons backStep={backStep} form={EXAM_ADMINISTRATION_FORM} />
  </div>
)
