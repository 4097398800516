import { Loader, Error } from '../common'

const mapStateToProps = state => state.user

export default connect(mapStateToProps)(({ isLoading, error, isLoaded }) => (
	<div className="container" style={{paddingTop: 30}}>
		{ isLoading ? <Loader />
			: error ? <Error title="School Unavailable" message={error} />
			: isLoaded ? <p>Select school to load coordinator view</p>
			: null }
  </div>
))