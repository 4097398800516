import { dollarFormat } from '../../../utils/numbers'
import styles from '../../../assets/style/scss/student-details.scss'


export default ({ examPrice }) => {
  const { standardTestPrice, totalPrice, lateOrderFee, cancellationFee, alternateExamFee } = examPrice || {}

  if (examPrice)
    return (
      <div className={`${styles.cost} col-lg-2 col-md-3 col-sm-4 col-xs-12`}>
        <h5>Cost</h5>
        <dl>
          { cancellationFee
            ? (
              <>
                <dt style={{ lineHeight: 'normal' }}>Unused/<br />Cancellation Fee</dt>
                <dd style={{ paddingTop: '1em' }}>{dollarFormat(cancellationFee)}</dd>
              </>
            ) : (
              <>
                <dt>Exam Fee</dt>
                <dd>{dollarFormat(standardTestPrice)}</dd>
              </>
            )}
          { lateOrderFee
            ? (
              <>
                <dt>Late Order Fee</dt>
                <dd>{dollarFormat(lateOrderFee)}</dd>
              </>
            ) : null }
          { alternateExamFee
            ? (
              <>
                <dt>Late-Testing Fee</dt>
                <dd>{dollarFormat(alternateExamFee)}</dd>
              </>
            ) : null }
          { lateOrderFee || alternateExamFee
            ? (
              <>
                <dt><strong>Total</strong></dt>
                <dd><strong>{dollarFormat(totalPrice)}</strong></dd>
              </>
            ) : null }
        </dl>
      </div>
    )

  return null
}
