import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { Loader, Error, StreetAddress } from '../common'
import InvoiceTable from './InvoiceTable'
import InvoiceTotals from './InvoiceTotals'
import ReimbursementTable from './ReimbursementTable'
import { getReimbursements, isCentralBill } from './utils'
import { formatPhoneNumber } from '../../utils/numbers'

import styles from '../../assets/style/scss/invoices.scss'

const mapStateToProps = (state, ownProps) => {
  const { type } = ownProps
  const {
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsDeadlines: { data: settingsDeadlinesData },
    invoices,
    user: {
      data: { selectedOrgId },
    },
  } = state
  const {
    fetching: fetchingOrgInfo,
    error: errorOrgInfo,
    data: orgData = {},
  } = state.settingsOrg[selectedOrgId] || {}
  const {
    schoolInformation: {
      coordinator: { contactInformation: coordinatorContactInformation },
      name: schoolName,
      aiCode,
      address: schoolAddress,
    },
  } = orgData
  const paymentDeadline = settingsDeadlinesData?.[selectedEducationPeriod]?.paymentDeadline
  return {
    type,
    paymentDeadlineDate: formatDate(paymentDeadline, DATETIME_FORMATS.longMonthDayYear),
    ...invoices,
    breakpoint: name,
    fetchingOrgInfo,
    errorOrgInfo,
    coordinatorContactInformation,
    schoolName,
    aiCode,
    schoolAddress,
  }
}

const SchoolInfo = ({ aiCode, schoolName, schoolAddress, fetching, error }) => {
  if (error)
    return (
      <div className="col-xs-4 school-info">
        <Error message={error} />
      </div>
    )

  if (fetching)
    return (
      <div className="col-xs-4 school-info">
        <Loader />
      </div>
    )

  return (
    <div className="col-xs-4 school-info">
      <h5>School</h5>
      <div className="aiCode">CB School Code: {aiCode}</div>
      <div className="name">{schoolName}</div>
      <StreetAddress {...schoolAddress} />
    </div>
  )
}

const CoordinatorInfo = ({ contactInfo, fetching, error }) => {
  if (error)
    return (
      <div className="col-xs-4 coordinator-info">
        <Error message={error} />
      </div>
    )

  if (fetching)
    return (
      <div className="col-xs-4 coordinator-info">
        <Loader />
      </div>
    )

  return (
    <div className="col-xs-4 coordinator-info">
      <h5>AP Coordinator</h5>
      <div className="full-name">
        {contactInfo.firstName} {contactInfo.middleInitial} {contactInfo.lastName}
      </div>
      {contactInfo.telephone ? (
        <div className="phone-number">{formatPhoneNumber(contactInfo.telephone)}</div>
      ) : null}
      {contactInfo.email ? <div className="email-address">{contactInfo.email}</div> : null}
    </div>
  )
}

const InvoiceAvailable = ({
  type,
  data = {},
  fetchingOrgInfo,
  errorOrgInfo,
  coordinatorContactInformation,
  schoolName,
  aiCode,
  schoolAddress,
}) => (
  <>
    <div style={{ display: 'flex', marginBottom: '2em' }}>
      <div
        className="cb-glyph-logo cb-logo"
        data-alt="CollegeBoard logo"
        style={{ fontSize: '2em' }}
      />
      <button
        type="button"
        className="btn btn-sm btn-primary no-print"
        onClick={e => {
          e.preventDefault()
          window.print()
        }}
        style={{ marginLeft: 'auto' }}
      >
        Print
      </button>
    </div>
    <h2 style={{ textTransform: 'capitalize' }}>AP Exam Invoice: {type} Copy</h2>
    <div className="row">
      <SchoolInfo aiCode={aiCode} schoolName={schoolName} schoolAddress={schoolAddress} />
      <CoordinatorInfo
        contactInfo={coordinatorContactInformation}
        fetching={fetchingOrgInfo}
        error={errorOrgInfo}
      />
    </div>
    <InvoiceTable title="Fees" data={data.fees} />
    <div className={styles['credits-and-totals-section']}>
      <InvoiceTable title="Credits" data={data.credits} />
      <InvoiceTotals {...data} />
    </div>
    <ReimbursementTable
      type={type}
      isCentralBill={isCentralBill(data)}
      data={getReimbursements(data, type)}
    />
  </>
)

export default connect(mapStateToProps)(InvoiceAvailable)
