import { Children, cloneElement, useEffect } from 'react'

/* assumes first child is the parent (accordion header) */
/* assumes children are in proper html table row format */
const AccordionTableRows = props => {
  const { defaultOpen, children, title = 'rows', rowIds } = props
  const [open, setOpen] = useState(defaultOpen)
  const [parent, ...kids] = Children.toArray(children)

  useEffect(() => {
    if (defaultOpen) setOpen(defaultOpen)
  }, [defaultOpen])

  const ActionColumn = () => (
    <td>
      <button
        type="button"
        className="btn-link"
        onClick={() => setOpen(!open)}
        aria-controls={open ? rowIds : null}
      >
        <span className="sr-only">
          {open ? 'Collapse' : 'Expand'} {title}
        </span>
        <i className={`cb-glyph ${open ? 'cb-minus' : 'cb-plus'}`} aria-hidden={true} />
      </button>
    </td>
  )

  return (
    <tbody>
      {cloneElement(parent, null, [
        ...parent.props.children,
        <ActionColumn key={`action-${parent.props.children.length}`} />,
      ])}
      {open ? (
        <CSSTransition
          classNames="animate-slide"
          in={true}
          timeout={500}
          appear={true}
          enter={false}
          exit={false}
        >
          <>
            {kids.map((k, i) =>
              cloneElement(k, null, [...k.props.children, <td key={k.props.children.length} />])
            )}
          </>
        </CSSTransition>
      ) : null}
    </tbody>
  )
}

export default AccordionTableRows
