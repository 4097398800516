import * as c from '../constants/AppConstants'

export const openModal = (name, props) => ({ type: c.MODAL_OPEN, name, props })
export const startCloseModal = name => ({ type: c.MODAL_START_CLOSE })
export const finishCloseModal = name => (dispatch, getState) => {
  const currentModal = getState().app.modal.name
  dispatch({ type: c.MODAL_FINISH_CLOSE, name: currentModal !== name ? currentModal : null })
}

export const breakpointChange = breakpointObj => ({
  type: c.BREAKPOINT_CHANGE,
  payload: breakpointObj,
})

// param action: { name: <string>, action: <fn> }
export const addFetchAction = action => (dispatch, getState) => {
  const currentActions = getState().app.fetchActionsOnChange
  if (currentActions.findIndex(a => action.name === a.name) === -1) {
    dispatch({ type: c.ADD_FETCH_ACTION, payload: action })
  }
}

export const removeFetchAction = name => ({ type: c.REMOVE_FETCH_ACTION, payload: name })
