import { gql } from '@apollo/client'

const examReadinessFragment = gql`
  fragment examReadinessFragment on StatusUpdate {
    apId
    appInstalled
    checkinStatus
    educationPeriodCd
    examStatus
    examWindow
    makeupStatus
    orgId
    practiceStatus
    sectionId
    setupStatus
    testCode
    toolsPracticeStatus
  }
`

export const COORINDATOR_EXAM_READINESS_SUBSCRIPTION = gql`
  subscription statusUpdate($orgId: Int, $educationPeriodCd: Int!) {
    statusUpdate(orgId: $orgId, educationPeriodCd: $educationPeriodCd) {
      apId
      appInstalled
      checkinStatus
      educationPeriodCd
      examStatus
      examWindow
      makeupStatus
      orgId
      practiceStatus
      sectionId
      setupStatus
      testCode
      toolsPracticeStatus
    }
  }
`
export const TEACHER_EXAM_READINESS_SUBSCRIPTION = gql`
  subscription statusUpdate($sectionId: Int, $educationPeriodCd: Int!) {
    statusUpdate(sectionId: $sectionId, educationPeriodCd: $educationPeriodCd) {
      ...examReadinessFragment
    }
  }
  ${examReadinessFragment}
`
