import { SortableTable, TableRowSelection } from '../common'
import { formatSSDMaterials, getRoom } from './utils'
import { updateSelectedStudents } from '../../actions/examRoom'

const headerColumns = [
  {
    headerText: { title: 'Select Student', srOnly: true },
  },
  {
    headerText: { title: 'Student Name' },
    key: ['lastName', 'firstName'],
    sorting: 'asc',
    defaultSort: true,
  },
  {
    headerText: { title: 'School Code' },
    key: 'aiCode',
    sorting: 'asc',
  },
  {
    headerText: { title: 'AP ID' },
    key: 'id',
    sorting: 'asc',
  },
  {
    headerText: { title: 'SSD ID' },
    key: 'ssdId',
    sorting: 'asc',
  },
  {
    headerText: { title: 'SSD Materials' },
    key: ['ssdMaterials', 'lastName', 'firstName'],
    sorting: 'asc',
  },
]

const mapStateToProps = (state, { roomId }) => {
  const { name } = getRoom(state.examRoomSchedule.data, roomId)
  const { [roomId]: students = [], saving, selectedStudents } = state.examRoomStudents
  return {
    name,
    students,
    saving,
    selectedStudents,
  }
}

const ExamRoomStudentTable = props => {
  const { name, students = [], saving, selectedStudents, updateSelectedStudents } = props

  return (
    <SortableTable
      tableAttributes={{
        id: 'studentRoster',
        summary:
          'Students are listed in ascending order by name. Additional sorting options are available by clicking on the column headers.',
        className: 'table cb-no-table-border',
      }}
      data={students.map(s => ({ ...s, ssdMaterials: formatSSDMaterials(s) }))}
      rowBuilder={students =>
        students.map(student => (
          <TableRowSelection
            key={student.id}
            rowId={`assigned-${student.id}`}
            label={`Select ${student.firstName} ${student.lastName}`}
            disabled={saving}
            ariaProps={{ 'aria-controls': 'exam-room-action-bar' }}
            isChecked={selectedStudents.find(id => student.id === id)}
            onSelection={({ checked }) => updateSelectedStudents(checked, student.id)}
          >
            <th scope="row">
              {student.lastName}, {student.firstName}
            </th>
            <td>{student.aiCode}</td>
            <td>{student.id}</td>
            <td>{student.ssdId}</td>
            <td>{student.ssdMaterials}</td>
          </TableRowSelection>
        ))
      }
      columns={headerColumns}
      tableCaption={{
        caption: `A view of all students in exam room: ${name}`,
        srOnly: true,
      }}
    />
  )
}

export default connect(
  mapStateToProps,
  { updateSelectedStudents }
)(ExamRoomStudentTable)
