import * as c from '../constants/StudentConstants'
import { UPDATE_SECTION_AFTER_STUDENT_MOVED_OR_DROPPED } from '../constants/CourseConstants'
import axios, { getServerErrorMessage } from '../utils/axios'
import { analyticsExamChangeEvent } from '../utils/analytics'

export const fetchStudentsBySection =
  ({ sectionId, testCd }) =>
  async dispatch => {
    dispatch({ type: c.FETCH_STUDENTS_BYSECTION_PENDING })
    try {
      const { data = [] } = await axios.get(`${Config.API_URL}/section/${sectionId}/enrollments`)
      dispatch({
        type: c.FETCH_STUDENTS_BYSECTION_FULFILLED,
        payload: { students: data.map(d => ({ ...d, sectionId, testCd })) },
      })
    } catch (err) {
      dispatch({ type: c.FETCH_STUDENTS_BYSECTION_REJECTED, payload: getServerErrorMessage(err) })
    }
  }

export const updateStudentSectionExamIntent = student => async (dispatch, getState) => {
  const { enrollmentId } = student
  dispatch({
    type: c.UPDATE_SECTION_STUDENT_PENDING,
    payload: { student, type: c.UPDATE_TYPE_INTENT },
  })

  try {
    const { data } = await axios.post(
      `${Config.API_URL}/v2/enrollments/${enrollmentId}/exam-intent/`,
      student
    )
    analyticsExamChangeEvent({
      exam: student,
      type: c.UPDATE_TYPE_INTENT,
      studentAfterUpdate: data,
      getState,
    })
    dispatch({
      type: c.UPDATE_SECTION_STUDENT_EXAM_FULFILLED,
      payload: { ...student, examIntent: data.examIntent },
    })
  } catch (err) {
    dispatch({ type: c.UPDATE_SECTION_STUDENT_REJECTED, payload: getServerErrorMessage(err) })
  }
}

const dropUrl = {
  [c.STUDENT_DROP_OPTION_KEEP]: 'drop-from-section',
  [c.STUDENT_DROP_OPTION_REMOVE]: 'drop-from-section-unreg-exam',
}

const updateSectionCounts = (dispatch, { toSection, fromSection }) => {
  dispatch({ type: UPDATE_SECTION_AFTER_STUDENT_MOVED_OR_DROPPED, payload: fromSection })
  dispatch({ type: UPDATE_SECTION_AFTER_STUDENT_MOVED_OR_DROPPED, payload: toSection })
}

export const dropSectionStudent = (dropOption, student) => async dispatch => {
  const url = dropUrl[dropOption]
  dispatch({
    type: c.UPDATE_SECTION_STUDENT_PENDING,
    payload: { student, type: c.UPDATE_TYPE_DROP },
  })

  try {
    const { data } = await axios.post(
      `${Config.API_URL}/enrollments/${student.enrollmentId}/${url}`
    )
    dispatch({ type: c.DROP_SECTION_STUDENT_FULFILLED, payload: student.studentId })
    updateSectionCounts(dispatch, data)
  } catch (err) {
    dispatch({ type: c.UPDATE_SECTION_STUDENT_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const moveSectionStudent = (sectionId, student) => async dispatch => {
  dispatch({
    type: c.UPDATE_SECTION_STUDENT_PENDING,
    payload: { student, type: c.UPDATE_TYPE_CHANGE },
  })
  try {
    const { data } = await axios.put(
      `${Config.API_URL}/enrollments/${student.enrollmentId}/move-to-section`,
      { sectionId }
    )
    dispatch({ type: c.MOVE_SECTION_STUDENT_FULFILLED, payload: student.studentId })
    updateSectionCounts(dispatch, data)
  } catch (err) {
    dispatch({ type: c.UPDATE_SECTION_STUDENT_REJECTED, payload: getServerErrorMessage(err) })
  }
}
