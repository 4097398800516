import { StepButtons, StepTracker } from '.'
import { changeStep, setDistrictFundingTypeData } from '../../actions/funding'
import {
  FUNDING_CENTRAL_BILL,
  FUNDING_SUBSIDY,
  TOTAL_FUNDING_STEPS,
} from '../../constants/FundingConstants'
import styles from '../../assets/style/scss/funding.scss'

const mapStateToProps = state => {
  const {
    settingsEducationPeriod: { selectedEducationPeriod, availableEducationPeriods },
    funding: {
      data: { disableDistrictFunding },
    },
  } = state

  return {
    academicYear: availableEducationPeriods[selectedEducationPeriod]?.descr,
    disableDistrictFunding,
  }
}

const FundingType = ({
  academicYear,
  disableDistrictFunding,
  changeStep,
  setDistrictFundingTypeData,
}) => {
  const alertBeforeLeaving = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    window.scroll(0, 0)
    window.addEventListener('beforeunload', alertBeforeLeaving)
    return () => {
      window.removeEventListener('beforeunload', alertBeforeLeaving)
    }
  }, [])

  return (
    <>
      <StepTracker />
      <div style={{ marginBottom: '24px' }}>
        <p>
          Select the funding arrangement your district would like to use for the {academicYear}{' '}
          school year.
        </p>
        {disableDistrictFunding ? (
          <div className={`${styles.intro} ${styles.alert}`}>
            <p>
              District Subsidy creation is not available for districts in your state at this time.
              If you&#39;d like to pay your schools&#39; AP exam invoices in full, select &#39;I
              choose to create a Central Billing Agreement arrangement&#39; below.
            </p>
          </div>
        ) : null}
        <h2 className="h3">Central Billing</h2>
        <p>
          With this selection, your district agrees to pay for all charges incurred by the schools
          you have selected. This includes late ordering fees, late-testing fees,
          unused/cancellation fees and any score reporting service charges.{' '}
          <strong>If you choose this arrangement, no additional step is required.</strong>
        </p>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={e => {
            e.preventDefault()
            setDistrictFundingTypeData(FUNDING_CENTRAL_BILL)
            changeStep(4)
          }}
        >
          I choose to create a Central Billing Agreement arrangement
        </button>
      </div>
      <div style={{ marginBottom: '24px', opacity: disableDistrictFunding ? '.7' : '1' }}>
        <h2 className="h3">District Subsidy</h2>
        <p>
          With a district subsidy arrangement, your district agrees to pay for specific charges
          incurred by the schools you&#39;ve selected. This selection typically focuses on exam
          fees, but it could also include non-exam based charges (e.g., unused/canceled fees). If
          you choose this arrangement, you&#39;ll complete additional steps to generate the
          appropriate funding selection for your district.
        </p>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={e => {
            e.preventDefault()
            setDistrictFundingTypeData(FUNDING_SUBSIDY)
            changeStep(4)
          }}
          disabled={disableDistrictFunding}
        >
          I choose to create a District Subsidy arrangement
        </button>
      </div>
      <StepButtons previousStep={2} hideNextStep={true} totalSteps={TOTAL_FUNDING_STEPS} />
    </>
  )
}

export default connect(mapStateToProps, { changeStep, setDistrictFundingTypeData })(FundingType)
