import { Error, Loader } from '@myap/ui-library/esm/components'
import { fetchIncidentReportHistory } from '../../actions/incidentReports'
import { IncidentReportHistoryCourse, IncidentReportView } from '.'
import { getSelectedEducationPeriod } from '../../selectors/settings'
import { isEmpty } from '../../utils/common'

const mapStateToProps = (state, props) => {
  const {
    user: {
      data: { selectedOrgId },
    },
    incidentReportHistory: { fetching, error, data },
  } = state
  return {
    orgId: selectedOrgId,
    educationPeriodCd: props?.educationPeriodCd || getSelectedEducationPeriod(state),
    data,
    fetching,
    error,
  }
}

const IncidentReportHistory = ({
  orgId,
  educationPeriodCd,
  data,
  fetching,
  error,
  print = false,
  courseName,
  reportId,
  fetchIncidentReportHistory,
}) => {
  const courseKeys = !isEmpty(data) ? Object.keys(data).sort() : []
  const [report, setReport] = useState({})

  useEffect(() => {
    if (!fetching && !isEmpty(orgId) && !isEmpty(educationPeriodCd)) {
      // Should fetch every time page is visited in case new IRs have been submitted
      fetchIncidentReportHistory({ orgId, educationPeriodCd })
    }
  }, [orgId, educationPeriodCd])

  useEffect(() => {
    if (print && !isEmpty(data)) {
      setReport(data[courseName]?.find(({ reportId: thisReportId }) => thisReportId === reportId))
    }
  }, [data])

  if (error)
    return (
      <Error useWrapper={true} title="Error retrieving incident report history" message={error} />
    )

  if (fetching) return <Loader />

  if (courseKeys?.length < 1) {
    return <p>No Incident Reports have been submitted online.</p>
  }

  if (print) {
    return <IncidentReportView report={report} print={print} />
  }

  return courseKeys.map(courseName => (
    <IncidentReportHistoryCourse
      key={courseName.replaceAll(' ', '_')}
      courseName={courseName}
      reports={data[courseName]}
    />
  ))
}
export default connect(mapStateToProps, { fetchIncidentReportHistory })(IncidentReportHistory)
