import { Loader, Modal, Error, BasicSelect } from '../../components/common'
import { openModal } from '../../actions/app'
import {
  STUDENT_ENROLLMENT_STATUS_NO,
  STUDENT_ENROLLMENT_STATUS_UNUSED,
  STUDENT_ENROLLMENT_STATUS_YES,
  STUDENT_DROP_OPTIONS,
  STUDENT_DROP_OPTION_KEEP,
  STUDENT_DROP_OPTION_REMOVE,
  STUDENT_DROP_OPTION_UNUSED,
  UPDATE_TYPE_DROP,
} from '../../constants/StudentConstants'
import { fetchDropStudentOptions, resetStudentUpdate } from '../../actions/studentsCommon'
import UpdateTable from '../../components/orders/coordinator/UpdateTable'
import { isEmpty } from '../../utils/common'
import style from '../../assets/style/scss/orders.scss'

const mapStateToProps = (state, { exam, storeLocation }) => {
  const { update: { type, updating, error, id }} = state[storeLocation]
  const isActive = type === UPDATE_TYPE_DROP && exam.examId === id

  return {
    active: isActive,
    updating: isActive && updating,
    error: isActive && error,
    breakpoint: state.app.breakpoint.name,
  }
}

class DropStudentModal extends Component {
  state = { selectedOption: null, options: [], optsFetching: true, optsError: null, optsData: {}, shouldCloseModal: false, active: false }

  constructor(props) {
    super(props)
    this.executeDrop = this.executeDrop.bind(this)
    this.handleChangeDropChoice = this.handleChangeDropChoice.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (state.active && !props.active)
      return { shouldCloseModal: true }
  
    return { active: props.active }
  }

  componentDidMount() {
    this.setDropOptions()
  }

  componentWillUnmount() {
    const { resetStudentUpdate } = this.props
    resetStudentUpdate()
  }

  onCloseAction() {
    const { shouldCloseModal } = this.state
    const { openModal, studentName, courseName, exam } = this.props
    
    if (shouldCloseModal)
      openModal('DropStudentSuccessModal', {
        studentName,
        courseName,
        exam,
        isKeepExam: this.isKeepExam(),
        isMarkUnused: this.isMarkUnused(),
        isRemoveExamWithCostImplications: this.hasCostImplication() && this.isRemoveExam(),
      })
  }

  async setDropOptions() {
    const { exam: { enrollmentId }, studentName, courseName } = this.props
    const { data = {}, error = null } = await fetchDropStudentOptions(enrollmentId)
    const options = Object.keys(data).reduce((arr, key) => (data[key].allow ? [...arr, { label: STUDENT_DROP_OPTIONS[key], value: key }] : arr), [])
    const numOptions = options.length

    this.setState({
      optsData: data,
      optsError: error || (numOptions === 0 ? `Error: You can not drop ${studentName} from ${courseName}.` : null),
      optsFetching: false,
      options: numOptions > 1 ? options : [], 
      selectedOption: numOptions === 1 ? options[0].value : null,
    })
  }

  getIntro() {
    const { studentName, courseName } = this.props
    const { options } = this.state

    if (options.length > 1)
      return <p>{studentName} is currently signed up to take the {courseName} exam. Please decide what you would like to do with this exam after dropping {studentName}.</p>

    if (this.isRemoveExam() && this.hasCostImplication())
      return <p>{studentName} is currently signed up to take the {courseName} exam. By dropping {studentName} you will also be removing the exam.</p>

    return <p>Are you sure you want to drop {studentName} from {courseName}?</p>
  }

  isMarkUnused() {
    const { selectedOption } = this.state
    return selectedOption === STUDENT_DROP_OPTION_UNUSED
  }

  isKeepExam() {
    const { selectedOption } = this.state
    return selectedOption === STUDENT_DROP_OPTION_KEEP
  }

  isRemoveExam() {
    const { selectedOption } = this.state
    return selectedOption === STUDENT_DROP_OPTION_REMOVE
  }

  hasCostImplication() {
    const { optsData, selectedOption } = this.state
    return optsData[selectedOption]?.hasCostImplication
  }

  handleChangeDropChoice(e) {
    this.setState({ selectedOption: e.target.value })
  }

  footerActions() {
    const { optsFetching, selectedOption } = this.state
    const { updating } = this.props

    return [
      { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
      {
        buttonLabel: 'Drop',
        isPrimary: true,
        onClick: this.executeDrop,
        isDisabled: optsFetching || !selectedOption || updating,
        busy: updating,
      },
    ]
  }

  executeDrop() {
    const { exam, action } = this.props
    const { selectedOption } = this.state
    action(selectedOption, exam)
  }

  render() {
    const { error, modalCloseFocusElem, breakpoint, exam, studentName } = this.props
    const { selectedOption, options, optsData, optsFetching, optsError, shouldCloseModal } = this.state
    const getProposedExamIntent = this.isRemoveExam() ? STUDENT_ENROLLMENT_STATUS_NO : this.isMarkUnused() ? STUDENT_ENROLLMENT_STATUS_UNUSED : STUDENT_ENROLLMENT_STATUS_YES

    return (
      <Modal
        modalStyles={breakpoint !== 'mobile' ? { width: 750 } : {}}
        headerTitle="Drop Student"
        shouldCloseModal={shouldCloseModal}
        onCloseAction={this.onCloseAction.bind(this)}
        modalCloseFocusElem={modalCloseFocusElem}
        footerActions={this.footerActions()}
      >

        { optsFetching ? <Loader /> : null }
        { optsError ? <Error title="Error Dropping Student" message={optsError} /> : null }
        { !isEmpty(optsData)
          ? <>
              { error ? <Error title="Error Dropping Student" message={error} /> : null }

              {this.getIntro()}

              {options.length > 1 ? (
                <BasicSelect
                  srOnlyLabel={true}
                  label="Select drop option"
                  input={{
                    name: 'selectDropOptionField',
                    value: selectedOption,
                    onChange: this.handleChangeDropChoice,
                  }}
                  options={options}
                />
              ) : null}

              {this.hasCostImplication() ? (
                  <>
                    <hr className={style['order-border-warning']} />
                    <UpdateTable exam={{ ...exam, examIntent: getProposedExamIntent }} studentName={studentName} />
                  </>
              ) : null}
            </>
          : null }
      </Modal>
    )
  }
}

export default connect(mapStateToProps, { openModal, resetStudentUpdate })(DropStudentModal)
