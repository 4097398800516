import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import memoize from 'memoizee'
import { DownloadToCSV } from '../../common'
import { SEE_SUBMISSION_DEADLINE_MSG } from '../../../constants/StudentConstants'
import { getAllExamWindows } from '../../../selectors/examWindows'

const transformData = memoize((details, examWindowData) =>
  details.map((det = {}) => {
    const formattedDate = det.testDate
      ? formatDate(det.testDate, DATETIME_FORMATS.shortMonthDayYear)
      : ''
    const examObj = det.noEndOfCourseExam
      ? { examDate: SEE_SUBMISSION_DEADLINE_MSG, examType: '' }
      : {
          examDate: formattedDate,
          examType: examWindowData[det.testCd]?.[det.testDayType]?.displayName || '',
        }

    return { ...det, ...examObj }
  })
)

const mainHeader = orgName => [
  { label: `Order Details for ${orgName}`, key: 'courseName' },
  { label: '', key: 'examType' },
  { label: '', key: 'examDate' },
  { label: '', key: 'numberReducedFee' },
  { label: '', key: 'numberSpecialAccomodations' },
  { label: '', key: 'numberNotTakingExams' },
  { label: '', key: 'numberExams' },
]

const RowHeaders = {
  courseName: 'Course Name',
  examType: 'Exam Type',
  examDate: 'Exam Date',
  numberReducedFee: 'Reduced Fee',
  numberSpecialAccomodations: 'SSD Materials',
  numberNotTakingExams: 'Not Taking Exam',
  numberExams: 'Total Exams',
}

const mapStateToProps = state => {
  const {
    user: {
      data: { roles, selectedRole },
    },
    ordersSummary: { courseExamSummaries },
  } = state
  const examWindowData = getAllExamWindows(state)

  return {
    details: transformData(courseExamSummaries, examWindowData),
    header: mainHeader(roles[selectedRole].orgName),
  }
}

export default connect(mapStateToProps)(({ header, details }) => (
  <DownloadToCSV
    filename="order-details"
    style={{ marginBottom: 10 }}
    header={header}
    title="Order Details"
    timestampHeaderKey="courseName"
    data={[RowHeaders, ...details]}
  />
))
