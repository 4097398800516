import { useLocation } from 'react-router-dom'
import { BasicInput } from '../../../common'
import { COURSE_FILTER } from '../../../../constants/FilterConstants'
import { selectEnrollments, unselectEnrollments } from '../../../../actions/studentsByOrg'
import { getFilters } from '../../../../actions/filters'

export default connect(null, {
  selectEnrollments,
  unselectEnrollments,
})(
  ({
    selected = [],
    visibleExams = [],
    isSelectAll = false,
    rowData = {},
    selectEnrollments,
    unselectEnrollments,
  }) => {
    const { search } = useLocation()

    return (
      <BasicInput
        type="checkbox"
        disabled={getFilters(search)[COURSE_FILTER.filter]?.length !== 1}
        srOnlyLabel={
          isSelectAll ? 'Select all enrollments' : `Select ${rowData.firstName} ${rowData.lastName}`
        }
        input={{
          checked: isSelectAll
            ? selected?.length === visibleExams?.length
            : Boolean(selected?.find(selectedId => selectedId === rowData.examId)),
          name: `studentsMultiSelect-${rowData.examId || 'all'}`,
          value: 'checked',
          onChange: e => {
            if (e.target.checked)
              selectEnrollments(
                isSelectAll ? visibleExams.map(exam => exam.examId) : [rowData.examId]
              )
            else unselectEnrollments(rowData.examId)
          },
        }}
      />
    )
  }
)
