import pickBy from 'lodash-es/pickBy'
import * as c from '../constants/ExamReadinessConstants'
import axios, { getServerErrorMessage } from '../utils/axios'
import {
  filterPassedCheckpoints,
  processExamReadinessData,
  reprocessExamReadinessData,
} from '../selectors/examReadiness'

export const fetchExamReadiness = (orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_EXAM_READINESS_PENDING })

  try {
    const { data } = await axios.get(
      `${Config.API_URL}/paapro/v1/exam-readiness/organizations/${orgId}/education-periods/${educationPeriodCd}`
    )
    const { students, ...dataMaps } = data
    dispatch({
      type: c.FETCH_EXAM_READINESS_FULFILLED,
      payload: { ...processExamReadinessData(data), ...dataMaps },
    })
  } catch (err) {
    dispatch({ type: c.FETCH_EXAM_READINESS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

// called when checkpoint time expires
export const reprocessExamReadinessStatus = (examObj, examIds, alarmId, checkpoints) => dispatch =>
  dispatch({
    type: c.REPROCESS_EXAM_STATUSES,
    payload: {
      examObj: reprocessExamReadinessData(examIds.map(id => examObj[id])),
      alarmId,
      checkpoints: filterPassedCheckpoints(checkpoints),
    },
  })

// called when new data comes via subscription
export const updateExamReadinessStatus = ({ subscriptionData }) => (dispatch, getState) => {
  const { statusUpdate = {} } = subscriptionData?.data || {}
  const { apId, testCode, educationPeriodCd, examWindow } = statusUpdate
  const updatesWithNullsRemoved = pickBy(statusUpdate, value => value !== null)
  const { examObj } = getState().examReadiness
  const objPrefix = `${apId}-${educationPeriodCd}-${testCode}`
  const current = examObj[`${objPrefix}-${examWindow}`]
  let currentExams

  if (examWindow === c.PRACTICE_WINDOW)
    currentExams = Object.values(examObj).filter(exam => exam.studentExamId.includes(objPrefix))
  else if (current) currentExams = [current]

  if (currentExams && Object.keys(updatesWithNullsRemoved).length)
    dispatch({
      type: c.UPDATE_EXAM_STATUS,
      payload: {
        examObj: reprocessExamReadinessData(
          currentExams.map(c => ({ ...c, ...updatesWithNullsRemoved }))
        ),
      },
    })
}

// this request does not return any data and it not redux
export const provisionPractice = async (testCode, orgId, educationPeriodCd) => {
  try {
    await axios.post(
      `${Config.API_URL}/paapro/v1/digital-exams/education-period-cd/${educationPeriodCd}/orgId/${orgId}/testCd/${testCode}/practice/retake`
    )
    return { success: true }
  } catch (err) {
    return { error: getServerErrorMessage(err) }
  }
}

export const updateMakeupStatus = (studentExamId, makeupStatus) => async (dispatch, getState) => {
  const [studentId, educationPeriodCd, testCd, examWindow] = studentExamId.split('-')
  const { examObj } = getState().examReadiness
  const current = examObj[studentExamId]

  dispatch({ type: c.UPDATE_MAKEUP_STATUS_PENDING, payload: studentExamId })

  try {
    await axios.post(
      `${Config.API_URL}/paapro/v1/digital-exams/education-periods/${educationPeriodCd}/students/${studentId}/courses/${testCd}/windows/${examWindow}`,
      { makeupStatus }
    )
    dispatch({
      type: c.UPDATE_MAKEUP_STATUS_FULFILLED,
      payload: {
        examObj: reprocessExamReadinessData([{ ...current, makeupStatus }]),
      },
    })
  } catch (err) {
    dispatch({
      type: c.UPDATE_MAKEUP_STATUS_REJECTED,
      payload: { studentExamId, error: getServerErrorMessage(err) },
    })
  }
}
