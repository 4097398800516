import { openModal } from '../../../actions/app'
import { CODE_TYPES } from '../../../constants/SectionConstants'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openModal }, dispatch)
}

export class ViewJoinCodeButton extends Component {
  constructor() {
    super()
    this.viewJoinCodeModal = this.viewJoinCodeModal.bind(this)
  }

 viewJoinCodeModal(id) {
    const { section, openModal } = this.props
    openModal('SectionCodeModal', {
      sectionId: section.sectionId,
      useCode: CODE_TYPES.join,
      modalCloseFocusElem: this.modalCloseFocusElem
    })
  }

  render() {
    return (
      <button type="button"
        className="btn-link cb-small-font-size"
        aria-label={`Manage Join Code ${this.props.section[CODE_TYPES.join.prop]}`}
        onClick={() => this.viewJoinCodeModal()}
        ref={(node) => this.modalCloseFocusElem = node}>
          <span aria-hidden="true" className="cb-glyph cb-share" data-alt="Manage Join Code" />
      </button>
    )
  }
}

export default connect(null, mapDispatchToProps)(ViewJoinCodeButton)
