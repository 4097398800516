import { Text, LimitedStringValidation } from '../../common'
import { SCHOOL_INFORMATION_FIELDS as FIELDS } from '../../../constants/SetupConstants'

export default () => (
  <fieldset>
    <div className="row">
      <div className="col-xs-12">
        <Text
          label="Purchase Order Number (up to 20 characters)"
          name={FIELDS.PO_NUMBER.name}
          maxlength={20}
          isRequired={FIELDS.PO_NUMBER.required}
          validate={[LimitedStringValidation]}
        />
      </div>
    </div>
  </fieldset>
)
