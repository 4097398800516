import { openModal } from '../../../actions/app'
import GrantedAccessTableRow from './GrantedAccessTableRow'
import { Error, Loader } from '../../common'
import { sortColumnByKey } from '../../../utils/sort'

const mapStateToProps = state => {
  const {
    teachersGrantedAccess: {
      allowedToGrantAccess,
      fetchingAllowedToGrant,
      aproGrantedCourses: data = [],
      fetching,
      error,
    },
  } = state

  return {
    allowedToGrantAccess,
    grants: sortColumnByKey(data, ['courseName', 'firstName', 'lastName'], ['asc', 'asc', 'asc']),
    fetching,
    fetchingAllowedToGrant,
    error,
  }
}

const GrantAccessButton = connect(mapStateToProps, { openModal })(
  ({ allowedToGrantAccess, hasGrants = false, fetchingAllowedToGrant, openModal }) => {
    const grantAccessButtonRef = useRef()
    const classes = hasGrants ? 'btn-link cb-small-font-size' : 'btn btn-secondary btn-sm'
    if (fetchingAllowedToGrant) {
      return <Loader size="small" />
    }

    if (!allowedToGrantAccess) {
      return null
    }
    return (
      <button
        type="button"
        className={classes}
        style={{
          ...(hasGrants && { float: 'right', padding: '0', borderWidth: '0' }),
          marginBottom: '10px',
        }}
        onClick={() => {
          openModal('GrantTeacherAccessModal', {
            modalCloseFocusElem: grantAccessButtonRef.current,
          })
        }}
        ref={grantAccessButtonRef}
      >
        {hasGrants && <span aria-hidden="true" className="cb-glyph cb-plus" />} Grant teacher access
      </button>
    )
  }
)

const GrantedAccessTable = ({ fetching, error, grants }) => {
  if (error) return <Error title="Error Retrieving Data" message={error} />

  if (fetching) return <Loader size="md" />

  if (grants.length)
    return (
      <>
        <GrantAccessButton hasGrants={grants?.length} />
        <table className="table" summary="Teachers are grouped in ascending order by course.">
          <caption className="sr-only">
            A list of all teachers who have been explicitly granted access to teach courses.
          </caption>
          <thead>
            <tr role="row">
              <th scope="col" role="columnheader" style={{ textAlign: 'center' }}>
                Teacher Name
              </th>
              <th scope="col" role="columnheader" style={{ textAlign: 'center' }}>
                Course
              </th>
              <th scope="col" role="columnheader" style={{ textAlign: 'center' }}>
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {grants.map((grant, index) => (
              <GrantedAccessTableRow
                key={`${grant.proId}_${grant.testCd}`}
                num={index}
                grant={grant}
              />
            ))}
          </tbody>
        </table>
      </>
    )

  return <GrantAccessButton />
}

export default connect(mapStateToProps, { openModal })(GrantedAccessTable)
