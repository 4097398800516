import { reduxForm, isDirty, isSubmitting, isValid, getFormValues } from 'redux-form'
import { StepButtons, StepTracker } from '.'
import { changeStep, setDistrictFundingContactInfoData } from '../../actions/funding'
import {
  CONTACT_INFORMATION_FORM_NAME,
  CONTACT_INFORMATION_FORM_FIELDS as FIELDS,
  TOTAL_FUNDING_STEPS
} from '../../constants/FundingConstants'
import { DOMESTIC_PHONE_LENGTH, INTERNATIONAL_PHONE_LENGTH } from '../../constants/SettingsConstants'
import {
  Text,
  Select,
  PhoneValidation,
  EmailValidation,
  PurchaseOrderValidation,
  ZipValidation,
  OpenNewWindow
} from '../common'
import { fundingDataExists } from '../../selectors/funding'
import { formatZip, convertToNumberString } from '../../utils/numbers'
import { isEmpty } from '../../utils/common'
import PhoneNumber from '../common/forms/PhoneNumber'

const TERMS_URL = 'https://apcentral.collegeboard.org/pdf/ap-district-funding-terms-2021-22.pdf'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedRole, roles },
    },
    funding: { data },
  } = state

  const dataExists = fundingDataExists(state)
  const grantor = data?.grantor ?? {}
  const purchaseOrderNumber = data?.purchaseOrderNumber ?? ''
  const { country } = getFormValues(CONTACT_INFORMATION_FORM_NAME)(state) || {}

  return {
    dataExists,
    dirty: isDirty(CONTACT_INFORMATION_FORM_NAME)(state),
    submitting: isSubmitting(CONTACT_INFORMATION_FORM_NAME)(state),
    formComplete: dataExists && !isEmpty(grantor) && isValid(CONTACT_INFORMATION_FORM_NAME)(state),
    initialValues: {
      ...grantor,
      purchaseOrderNumber,
    },
    orgName: roles[selectedRole]?.orgName,
    countryCode: country ?? 'US'
  }
}

const COUNTRY_OPTIONS = APRICOT.data.countries.map(c => ({
  label: c.name,
  value: c.iso.toUpperCase(),
}))
const STATE_OPTIONS = APRICOT.data.states.map(c => ({
  label: c.name,
  value: c.value,
}))

const ContactInformation = ({
  dataExists,
  dirty,
  submitting,
  formComplete,
  handleSubmit,
  countryCode
}) => { 
  const alertBeforeLeaving = e => {
    e.preventDefault()
    e.returnValue = ''
  }
  
  const isDomestic = countryCode === 'US'

  useEffect(() => {
    window.scroll(0, 0)
    if (dataExists || (dirty && !submitting)) {
      window.addEventListener('beforeunload', alertBeforeLeaving)
    }
    return () => {
      if (dataExists || (dirty && !submitting)) {
        window.removeEventListener('beforeunload', alertBeforeLeaving)
      }
    }
  }, [dataExists, dirty, submitting])

  return (
    <>
      <StepTracker />
      <p>
        Enter the contact information for the individual managing the district&#39;s funding plans.
        This person will also be College Board&#39;s primary contact if there are any questions
        about your funding arrangements or outstanding invoices.
      </p>
      <p>
        By completing the steps below, you represent and warrant that you are authorized to act and
        order on behalf of the district and to set up the billing arrangements selected below in
        accordance with these <OpenNewWindow url={TERMS_URL}>terms and conditions</OpenNewWindow>.
      </p>
      <form onSubmit={handleSubmit} className="row">
        <fieldset className="col-xs-6">
          <legend
            className="h2"
            style={{ marginBottom: 10, width: 'auto', position: 'relative', border: 0 }}
          >
            Contact Information
          </legend>
          <div className="row">
            <div className="col-xs-6">
              <Text
                name={FIELDS.fname.name}
                isRequired={FIELDS.fname.required}
                label="First Name"
              />
            </div>
            <div className="col-xs-6">
              <Text name={FIELDS.lname.name} isRequired={FIELDS.lname.required} label="Last Name" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <PhoneNumber
                name={FIELDS.phone.name}
                isRequired={FIELDS.phone.required}
                label="Phone Number"
                placeholder={isDomestic ? 'Enter a 10 digit number' : 'Enter telephone number'}
                validate={[PhoneValidation]}
                shouldFormat={true}
                countryCode={countryCode}
                normalize={convertToNumberString}
                maxlength={isDomestic ? DOMESTIC_PHONE_LENGTH : INTERNATIONAL_PHONE_LENGTH}
              />
            </div>
            <div className="col-xs-6">
              <Text
                name={FIELDS.email.name}
                isRequired={FIELDS.email.required}
                label="Email"
                placeholder="abc@example.com"
                validate={[EmailValidation]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Text
                name={FIELDS.ponumber.name}
                isRequired={FIELDS.ponumber.required}
                label="Purchase Order Number (if required by your district)"
                placeholder="Enter purchase order number"
                validate={[PurchaseOrderValidation]}
                maxlength={20}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="col-xs-6" style={{ clear: 'both' }}>
          <legend
            className="h2"
            style={{ marginBottom: 10, width: 'auto', position: 'relative', border: 0 }}
          >
            Billing Address
          </legend>
          <div className="row">
            <div className="col-xs-12">
              <Select
                name={FIELDS.country.name}
                isRequired={FIELDS.country.required}
                options={COUNTRY_OPTIONS}
                label="Country"
              />
              <Text
                name={FIELDS.addr1.name}
                isRequired={FIELDS.addr1.required}
                label="Address Line 1"
                maxlength={50}
              />
              <Text
                name={FIELDS.addr2.name}
                isRequired={FIELDS.addr2.required}
                label="Address Line 2"
                maxlength={50}
              />
              <Text name={FIELDS.city.name} isRequired={FIELDS.city.required} label="City" />
              <div className="row">
                <div className="col-xs-6">
                  <Select
                    name={FIELDS.state.name}
                    isRequired={FIELDS.state.required}
                    options={STATE_OPTIONS}
                    label="State"
                    showEmptyOption={false}
                  />
                </div>
                <div className="col-xs-6">
                  <Text
                    name={FIELDS.zip.name}
                    isRequired={FIELDS.zip.required}
                    label="Zip"
                    placeholder="Enter 5 or 9 digit code"
                    validate={[ZipValidation]}
                    format={formatZip}
                    normalize={convertToNumberString}
                    maxlength={9}
                  />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <StepButtons
        form={CONTACT_INFORMATION_FORM_NAME}
        previousButtonText="Back to Dashboard"
        previousStep={0}
        formComplete={formComplete}
        totalSteps={TOTAL_FUNDING_STEPS}
      />
    </>
  )
}

export default connect(mapStateToProps, { changeStep, setDistrictFundingContactInfoData })(
  reduxForm({
    form: CONTACT_INFORMATION_FORM_NAME,
    enableReinitialize: true,
    onSubmit: (vals, dispatch, { orgName, setDistrictFundingContactInfoData }) =>
      setDistrictFundingContactInfoData({
        grantor: {
          grantorName: orgName,
          [FIELDS.fname.name]: vals[FIELDS.fname.name],
          [FIELDS.lname.name]: vals[FIELDS.lname.name],
          [FIELDS.phone.name]: vals[FIELDS.phone.name],
          [FIELDS.email.name]: vals[FIELDS.email.name],
          [FIELDS.country.name]: vals[FIELDS.country.name],
          [FIELDS.addr1.name]: vals[FIELDS.addr1.name],
          [FIELDS.addr2.name]: isEmpty(vals[FIELDS.addr2.name]) ? null : vals[FIELDS.addr2.name],
          [FIELDS.city.name]: vals[FIELDS.city.name],
          [FIELDS.state.name]: vals[FIELDS.state.name],
          [FIELDS.zip.name]: vals[FIELDS.zip.name],
        },
        [FIELDS.ponumber.name]: isEmpty(vals[FIELDS.ponumber.name])
          ? null
          : vals[FIELDS.ponumber.name],
      }),
    onSubmitSuccess: (result, dispatch, { changeStep }) => changeStep(2),
  })(ContactInformation)
)
