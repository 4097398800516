import memoize from 'memoizee'
import uniqWith from 'lodash-es/uniqWith'
import isEqual from 'lodash-es/isEqual'
import FilterContainer from '../common/FilterContainer'
import { FEES_FILTER_VALUES } from '../../../constants/FilterConstants'
import { PAPER_EXAM } from '../../../constants/SettingsConstants'
import {
  CourseFilterDropdown,
  AdminFilterDropdown,
  TeacherFilterDropdown,
  CourseTypeFilterDropdown,
} from '../common/Dropdowns'
import {
  OrderExamFilterDropdown,
  FeeStatusFilterDropdown,
  AccommodationsFilterDropdown,
  StudentAccommodationsFilterDropdown,
  SSDIDFilterDropdown,
  OrderStatusFilterDropdown,
  SubsidyFilterDropdown,
  FeesFilterDropdown,
  ApprovalRequestFilterDropdown,
  TestLocationFilterDropdown,
} from './StudentFilterDropdowns'
import { getExamWindowRefData } from '../../../selectors/examWindows'
import { getStudentsFilterDataTag } from '../../../selectors/filters/studentFilters'
import { getTotalVisibleStudents, getTotalStudents } from '../../../selectors/student'
import { getDeadlineIsPast } from '../../../selectors/settings'
import { FilterNoResultsMessage, FilterResultsCount } from '../common/FilterMessages'
import { isEmpty } from '../../../utils/common'

const POSSIBLE_FEES = Object.keys(FEES_FILTER_VALUES)
const getAvailableFilterData = memoize(({ allExams: exams, examWindowRefData }) => {
  const len = exams.length
  const windows = new Set()
  const subsidies = new Set()
  const fees = new Set()
  const nonstockedApprovals = new Set()
  const exceptionApprovals = new Set()
  const orderApprovals = new Set()
  const digitalAccommodations = []
  const testingLocations = new Set()
  // Build a list of exam windows from windows that do not require unlocking
  Object.keys(examWindowRefData).forEach(windowName => {
    if (!examWindowRefData[windowName].requiresUnlocking) {
      windows.add(examWindowRefData[windowName].adminWindow)
    }
  })
  for (let i = 0; i < len; i += 1) {
    const {
      testWindow,
      subsidies: examSubsidies = [],
      examPrice = {},
      etsNonStockedApprovalStatus,
      etsExceptionApprovalStatus,
      etsOrderAfterDeadlineApprovalStatus,
      specialDigitalFormats,
      testLocation,
      examFormat = PAPER_EXAM,
    } = exams[i]
    const includedFees = POSSIBLE_FEES.filter(key => examPrice[key])

    if (!isEmpty(testWindow)) {
      windows.add(testWindow)
    }
    includedFees.forEach(f => fees.add(f))
    examSubsidies.forEach(s => subsidies.add(s))
    etsNonStockedApprovalStatus && nonstockedApprovals.add(etsNonStockedApprovalStatus)
    etsExceptionApprovalStatus && exceptionApprovals.add(etsExceptionApprovalStatus)
    etsOrderAfterDeadlineApprovalStatus && orderApprovals.add(etsOrderAfterDeadlineApprovalStatus)
    specialDigitalFormats &&
      examFormat !== PAPER_EXAM &&
      digitalAccommodations.push(specialDigitalFormats)
    testLocation && testingLocations.add(testLocation)
  }

  return {
    windows: Array.from(windows),
    subsidies: Array.from(subsidies),
    fees: Array.from(fees),
    nonstockedApprovals: Array.from(nonstockedApprovals),
    exceptionApprovals: Array.from(exceptionApprovals),
    orderApprovals: Array.from(orderApprovals),
    digitalAccommodations: uniqWith(digitalAccommodations, isEqual),
    testingLocations: Array.from(testingLocations),
  }
})

const CoordinatorStudentFilters = connect(state => {
  const testedWithAccommAvailable = getDeadlineIsPast(state, 'testedWithAccomStartDate')
  const {
    settingsEducationPeriod: { selectedIsCurrent },
  } = state
  return {
    isLevelTwo: state.user.data.isLevelTwo,
    students: state.studentsByOrg.studentMap,
    courses: state.studentsByOrg.courseMap,
    teachers: state.studentsByOrg.teacherMap,
    sections: state.courses.entities.sections,
    accommodationCategories: {
      ...state.studentsByOrg.accommodationCategoryMap,
      ...(testedWithAccommAvailable || !selectedIsCurrent
        ? { stwa: 'Student tested with an accommodation' }
        : {}),
    },
    examWindowRefData: getExamWindowRefData(state),
  }
})(props => {
  const {
    isLevelTwo,
    courses,
    allExams,
    visibleExams,
    teachers = {},
    students,
    sections,
    accommodationCategories,
    examWindowRefData,
    activeFilters,
  } = props
  const totalVisibleStudents = getTotalVisibleStudents(visibleExams, students)
  const totalStudentCount = getTotalStudents(students)
  const totalVisibleEnrollments = visibleExams.length
  const totalEnrollmentCount = allExams.length
  const showFilterContainer = totalStudentCount || isLevelTwo
  const {
    windows,
    subsidies,
    fees,
    nonstockedApprovals,
    exceptionApprovals,
    orderApprovals,
    digitalAccommodations,
    testingLocations,
  } = getAvailableFilterData({ allExams, examWindowRefData })

  return (
    <>
      {showFilterContainer ? (
        <>
          <div className="container" style={{ marginTop: 20 }}>
            <FilterResultsCount
              visibleCount={totalVisibleStudents}
              totalCount={totalStudentCount}
              resultsTypeText="student"
            />
            <FilterResultsCount
              visibleCount={totalVisibleEnrollments}
              totalCount={totalEnrollmentCount}
              resultsTypeText="enrollment"
            />
          </div>
          <FilterContainer
            label="Student Filters"
            activeFilters={activeFilters}
            getFilterTagData={(filter, value) =>
              getStudentsFilterDataTag(
                { courses, teachers, sections, examWindowRefData, accommodationCategories },
                filter,
                value
              )
            }
          >
            <CourseFilterDropdown courses={courses} />
            <AdminFilterDropdown windows={windows} label="Exam Date" />
            {isLevelTwo ? <OrderExamFilterDropdown /> : null}
            <TeacherFilterDropdown teachers={Object.values(teachers)} />
            {isLevelTwo ? <FeeStatusFilterDropdown /> : null}
            {isLevelTwo ? (
              <AccommodationsFilterDropdown digitalAccommodations={digitalAccommodations} />
            ) : null}
            <SSDIDFilterDropdown />
            <StudentAccommodationsFilterDropdown
              studentAccommodationCategories={accommodationCategories}
            />
            <CourseTypeFilterDropdown courses={courses} />
            {isLevelTwo ? (
              <>
                <OrderStatusFilterDropdown />
                <SubsidyFilterDropdown subsidies={subsidies} />
                <FeesFilterDropdown existingFees={fees} />
                <ApprovalRequestFilterDropdown
                  nonstockedApprovals={nonstockedApprovals}
                  exceptionApprovals={exceptionApprovals}
                  orderApprovals={orderApprovals}
                />
                <TestLocationFilterDropdown testingLocations={testingLocations} />
              </>
            ) : null}
          </FilterContainer>
        </>
      ) : null}
      {Object.keys(activeFilters).length && !totalVisibleStudents && totalStudentCount ? (
        <FilterNoResultsMessage text="students" />
      ) : null}
    </>
  )
})

CoordinatorStudentFilters.displayName = 'CoordinatorStudentFilters'

export default CoordinatorStudentFilters
