import { Link } from 'react-router-dom'
import { openModal } from '../../../actions/app'
import { ShippingAddress, ChangeAddressInstructions } from '../../common'
import { NAV_LINKS } from '../../../constants/NavConstants'

const mapStateToProps = state => ({ subOrderProcessing: state.ordersSummary.subOrderProcessing })

class OrdersShipTo extends Component {
  constructor(props) {
    super(props)
    this.modalButton = createRef()
  }

  render() {
    const { openModal, subOrderProcessing } = this.props

    return (
      <div>
        <h2 className="h3">Ship To</h2>
        <ShippingAddress />
        <button
          type="button"
          className="btn-link"
          style={{ padding: 0, border: 0, marginBottom: 10 }}
          ref={this.modalButton}
          onClick={() => openModal('TextModal', {
            title: 'Change Shipping Address',
            BodyComponent: ChangeAddressInstructions,
            buttonLabel: 'OK',
            modalCloseFocusElem: this.modalButton.current,
          })}
        >
          Change
        </button>
        { subOrderProcessing
          && <div><strong><Link to={NAV_LINKS.shipments}>View Shipments <i className="cb-glyph cb-east" /></Link></strong></div> }
      </div>
    )
  }
}


export default connect(mapStateToProps, { openModal })(OrdersShipTo)
