import { NAV_LINKS } from '../../constants/NavConstants'

// eslint-disable-next-line import/prefer-default-export
export const coordinatorNeedsToSetupSchool = (user, settingsEducationPeriod) => {
  const {
    hasAccess,
    data: {
      acceptedGeneralTAC,
      isCoordinator,
      isSchoolAdmin,
      isDistrictAdmin,
      isDistrictFundingAdmin,
      isDistrictSuperAdmin,
      isCSR,
      roles,
      selectedOrgId,
    },
  } = user
  const { selectedIsCurrent } = settingsEducationPeriod
  let needsSetup = selectedOrgId
    ? roles.find(r => r.orgId === selectedOrgId).needsSetup
    : roles.find(r => r.needsSetup)
  // If viewing a prior academic year, then skip setup process
  if (needsSetup && !selectedIsCurrent) {
    needsSetup = false
  }
  return Boolean(
    hasAccess &&
      acceptedGeneralTAC &&
      isCoordinator &&
      needsSetup &&
      !isCSR &&
      !isSchoolAdmin &&
      !isDistrictAdmin &&
      !isDistrictFundingAdmin &&
      !isDistrictSuperAdmin
  )
}

export const getMyAPUrl = ({ isStudent }) => {
  const { student, prof } = Config.MYAP_URL
  return isStudent ? student : prof
}

export const getDefaultRoute = ({ isStudent, isTeacher }) => {
  return isStudent
    ? NAV_LINKS.profile
    : isTeacher
    ? window.location.pathname === NAV_LINKS.examreadiness
      ? NAV_LINKS.examreadiness
      : NAV_LINKS.courses
    : NAV_LINKS.dashboard
}
