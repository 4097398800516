import { useHistory } from 'react-router-dom'
import GrantorAddress from './GrantorAddress'
import { getDistrictReimbursementGrantor, isDirty } from './utils'
import { NAV_LINKS } from '../../constants/NavConstants'
import { TYPE_DISTRICT } from '../../constants/InvoiceConstants'

const DistrictReimbursement = ({ data }) => {
  const history = useHistory()
  const grantor = getDistrictReimbursementGrantor(data)

  if (grantor) {
    return (
      <>
        <h3 className="h4">District Reimbursement Copy</h3>
        <p>
          To receive reimbursement for your AP Exam fees, you can send the district reimbursement
          copy to the address below:
        </p>
        <GrantorAddress {...grantor} />
        <button
          type="button"
          className="btn btn-sm btn-primary"
          disabled={isDirty(data)}
          onClick={() => history.push(`${NAV_LINKS.invoice}/${TYPE_DISTRICT.toLowerCase()}`)}
        >
          View District Copy
        </button>
      </>
    )
  }

  return null
}

export default DistrictReimbursement
