import { Input, Text, AtLeastOneChecked } from '../common'
import { TYPE_SELECT, TYPE_MULTI_SELECT } from '../../constants/SurveyConstants'

const SurveySelectQuestion = ({ values, question, children }) => {
  const { qid, question: label, type, required, options } = question
  const multiSelectField = type === TYPE_MULTI_SELECT

  const legendStyles = {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '1.5',
    paddingTop: '1em',
    paddingBottom: '.5em',
    marginBottom: '.5em',
    border: 'none',
    fontFamily: 'Roboto, sans-serif',
  }

  const multiSelectDescriptionStyles = {
    fontSize: '14px',
    fontWeight: 'normal',
  }

  if (multiSelectField || type === TYPE_SELECT) {
    return (
      <fieldset key={qid} role="radiogroup" aria-labelledby={qid} aria-required={required}>
        <legend
          className={required ? 'cb-required' : ''}
          id={qid}
          role="presentation"
          style={legendStyles}
        >
          <span dangerouslySetInnerHTML={{ __html: `${label}` }} />
          {multiSelectField ? (
            <span style={multiSelectDescriptionStyles}> [select all that apply]</span>
          ) : (
            ''
          )}
        </legend>
        {options.map(({ label, value, inline }, index) => {
          return (
            <Fragment key={`${qid}-${value}`}>
              {multiSelectField ? (
                <Input
                  type="checkbox"
                  name={`${qid}[${index}].value|${value}`}
                  value={value}
                  label={<span dangerouslySetInnerHTML={{ __html: `${label}` }} />}
                  validate={required ? [AtLeastOneChecked] : []}
                  showError={false}
                />
              ) : (
                <Input
                  type="radio"
                  name={`${qid}.value`}
                  value={value}
                  label={<span dangerouslySetInnerHTML={{ __html: `${label}` }} />}
                  isRequired={required}
                />
              )}
              {inline ? (
                <Text
                  name={multiSelectField ? `${qid}[${index}].text` : `${qid}.text`}
                  ariaLabelledBy={`${qid}[${index}]`}
                  placeholder="Enter reason"
                  isRequired={
                    multiSelectField
                      ? values?.[qid]?.[index]?.[`value|${value}`]
                      : values?.[qid]?.value === value
                  }
                  disabled={
                    multiSelectField
                      ? !values?.[qid]?.[index]?.[`value|${value}`]
                      : values?.[qid]?.value !== value
                  }
                  formGroupStyle={{ marginTop: '8px' }}
                  maxlength="500"
                  errorOnPristine={true}
                />
              ) : null}
            </Fragment>
          )
        })}
        {children}
      </fieldset>
    )
  }
  return null
}

export default SurveySelectQuestion
