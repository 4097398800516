import { Loader } from '@myap/ui-library/esm/components'
import { fetchCourses } from '../../../actions/course'
import { DIGITAL_EXAM } from '../../../constants/SettingsConstants'
import { getAllExamWindows } from '../../../selectors/examWindows'
import { sortColumnByKey } from '../../../utils/sort'

const AvailableCourses = connect(
  state => {
    const { selectedOrgId } = state.user.data
    const { selectedEducationPeriod } = state.settingsEducationPeriod
    const windowsByTestCd = getAllExamWindows(state)
    const { entities, fetching, error, orgId, educationPeriodCd } = state.courses
    const digitalOnlyCourses = Object.keys(windowsByTestCd).reduce((arr, testCd) => {
      const hasDigitalAdmin = Object.values(windowsByTestCd[testCd]).find(
        w => w.examFormat === DIGITAL_EXAM
      )
      const { courseName, sections = [] } = entities.courses[testCd] || {}
      const hasStudentTakingExam = sections.find(id => entities.sections[id]?.enrollmentCount)
      return hasDigitalAdmin && hasStudentTakingExam
        ? [...arr, { testCode: parseInt(testCd, 10), courseName }]
        : arr
    }, [])

    return {
      orgId: selectedOrgId,
      educationPeriod: selectedEducationPeriod,
      filteredCourses: sortColumnByKey(digitalOnlyCourses, 'courseName', 'asc'),
      fetching,
      error,
      shouldFetch:
        !fetching &&
        selectedOrgId &&
        selectedEducationPeriod &&
        (selectedOrgId !== orgId || selectedEducationPeriod !== educationPeriodCd),
    }
  },
  { fetchCourses }
)(
  ({
    fetchCourses,
    orgId,
    educationPeriod,
    shouldFetch,
    fetching,
    error,
    component: Component,
    showLoading = true,
    filteredCourses = [],
    defaultCourses = [],
  }) => {
    const [courses, setCourses] = useState(filteredCourses)

    useEffect(() => {
      if (shouldFetch) fetchCourses(orgId, educationPeriod)
    }, [])

    useEffect(() => {
      if (error && defaultCourses.length) setCourses(defaultCourses)
    }, [error])

    useEffect(() => {
      if (filteredCourses.length) setCourses(filteredCourses)
    }, [filteredCourses])

    return (
      <>
        {fetching && showLoading ? (
          <Loader />
        ) : courses.length ? (
          <Component courses={courses} />
        ) : null}
      </>
    )
  }
)

export default AvailableCourses
