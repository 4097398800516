import { isFeatureEnabled } from '../../selectors/features'

/** USAGE **
Note: Feature is considered ENABLED if it does NOT appear in the list of
      feature flags.

<Flag
  name="NAME_OF_FEATURE"
  renderEnabled={() =>
    <div>What to render if feature is enabled</div>
  }
  renderDisabled{() =>
    <div>What to render if feature is disabled</div>
  }
/>

<Flag
  name="NAME_OF_FEATURE"
  renderEnabled={<BootstrapLabel text="Feature Enabled" type="success" />}
  renderDisabled{<BootstrapLabel text="Feature Disabled" type="danger" />}
/>

<Flag name="NAME_OF_FEATURE">
  <div>What to render if feature is enabled</div>
</Flag>

 **/

const mapStateToProps = (state, ownProps) => {
  const { name, renderEnabled, renderDisabled } = ownProps
  const featureEnabled = isFeatureEnabled(state, name)
  return {
    featureEnabled,
    renderEnabled,
    renderDisabled
  }
}

class Flag extends Component {

  render() {
    const { featureEnabled, renderEnabled, renderDisabled, children } = this.props

    if (featureEnabled && children) {
      return children
    }

    if (featureEnabled) {
      if (renderEnabled) {
        return renderEnabled
      }
    } else if (renderDisabled) {
      return renderDisabled
    }

    return null
  }
}

export default connect(mapStateToProps)(Flag)
