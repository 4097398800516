import axios, { getServerErrorMessage } from '../utils/axios'
import * as c from '../constants/ExamRoomConstants'
import { reformatExamRooms } from '../components/examRoom/utils'

const getRoomObj = (
  { sessionId, testCd },
  { id, assignedCapacity, totalCapacity, name, proctorNames, description },
  add
) => ({
  type: add ? c.ADD_EXAM_ROOM_FULFILLED : c.EDIT_EXAM_ROOM_FULFILLED,
  payload: {
    sessionId,
    testCd,
    room: { id, assignedCapacity, totalCapacity, name, proctorNames, description },
  },
})

export const fetchExamRoomSchedule = (orgId, educationPeriod) => async dispatch => {
  dispatch({ type: c.FETCH_EXAM_ROOM_SCHEDULE_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/paapro/v1/exam-day-management/organizations/${orgId}/education-periods/${educationPeriod}/sessions`
    )
    dispatch({
      type: c.FETCH_EXAM_ROOM_SCHEDULE_FULFILLED,
      payload: { data: reformatExamRooms(data) },
    })
  } catch (err) {
    dispatch({ type: c.FETCH_EXAM_ROOM_SCHEDULE_REJECTED, payload: getServerErrorMessage(err) })
  }
}

// data sent to this endpoint and data coming from this endpoint will be in array format
// since the UI will only add one room at a time, the request/response data will always be an array of one
export const addExamRoom = (props, fields) => async dispatch => {
  const { orgId, educationPeriod, sessionId, testCd } = props
  dispatch({ type: c.ADD_EXAM_ROOM_PENDING })
  try {
    const { data } = await axios.post(
      `${Config.API_URL}/paapro/v1/exam-day-management/organizations/${orgId}/education-periods/${educationPeriod}/sessions/${sessionId}/courses/${testCd}/rooms`,
      [fields]
    )
    const room = { ...fields, ...data[0] }
    dispatch(getRoomObj(props, room, true))
    return room // send room back to form for create and reassign flow
  } catch (err) {
    dispatch({ type: c.ADD_EXAM_ROOM_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const editExamRoom = (props, fields) => async dispatch => {
  const { orgId, educationPeriod, sessionId, testCd } = props
  dispatch({ type: c.EDIT_EXAM_ROOM_PENDING })

  try {
    const { data } = await axios.put(
      `${Config.API_URL}/paapro/v1/exam-day-management/organizations/${orgId}/education-periods/${educationPeriod}/sessions/${sessionId}/courses/${testCd}/rooms/${fields.id}`,
      fields
    )
    dispatch(getRoomObj(props, fields))
  } catch (err) {
    dispatch({ type: c.EDIT_EXAM_ROOM_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const deleteExamRoom = props => async dispatch => {
  const { orgId, educationPeriod, sessionId, testCd, id } = props
  dispatch({ type: c.DELETE_EXAM_ROOM_PENDING })

  try {
    const response = await axios.delete(
      `${Config.API_URL}/paapro/v1/exam-day-management/organizations/${orgId}/education-periods/${educationPeriod}/sessions/${sessionId}/courses/${testCd}/rooms/${id}`
    )
    dispatch({ type: c.DELETE_EXAM_ROOM_FULFILLED })
  } catch (err) {
    dispatch({ type: c.DELETE_EXAM_ROOM_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const fetchAssignableStudents = ({
  orgId,
  educationPeriod,
  sessionId,
  testCd,
}) => async dispatch => {
  dispatch({ type: c.FETCH_ASSIGNABLE_STUDENTS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/paapro/v1/exam-day-management/organizations/${orgId}/education-periods/${educationPeriod}/sessions/${sessionId}/courses/${testCd}/sections`
    )
    dispatch({
      type: c.FETCH_ASSIGNABLE_STUDENTS_FULFILLED,
      payload: { assignableStudents: data.map((d, i) => ({ ...d, sectionId: `section${i}` })) },
    })
  } catch (err) {
    dispatch({ type: c.FETCH_ASSIGNABLE_STUDENTS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const fetchExamRoomStudents = (
  orgId,
  educationPeriod,
  { sessionId, testCd, id }
) => async dispatch => {
  dispatch({ type: c.FETCH_EXAM_ROOM_STUDENTS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/paapro/v1/exam-day-management/organizations/${orgId}/education-periods/${educationPeriod}/sessions/${sessionId}/courses/${testCd}/rooms/${id}/students`
    )
    dispatch({ type: c.FETCH_EXAM_ROOM_STUDENTS_FULFILLED, payload: { [id]: data } })
  } catch (err) {
    dispatch({ type: c.FETCH_EXAM_ROOM_STUDENTS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const assignStudents = (props, newStudentList) => async dispatch => {
  const { orgId, educationPeriod, sessionId, testCd, id } = props
  const ids = newStudentList.reduce(
    (acc, n) => (n.roomAssignment?.id === id ? acc : [...acc, n.id]),
    []
  )
  const newCapacity = newStudentList.length

  dispatch({ type: c.ASSIGN_STUDENTS_PENDING })
  try {
    const response = await axios.post(
      `${Config.API_URL}/paapro/v1/exam-day-management/organizations/${orgId}/education-periods/${educationPeriod}/sessions/${sessionId}/courses/${testCd}/rooms/${id}/students`,
      ids
    )
    dispatch({ type: c.ASSIGN_STUDENTS_FULFILLED, payload: { [id]: newStudentList } })
    dispatch(getRoomObj(props, { ...props, assignedCapacity: newCapacity }))
  } catch (err) {
    dispatch({ type: c.ASSIGN_STUDENTS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const unassignStudents = props => async dispatch => {
  const { orgId, educationPeriod, room, selectedStudents } = props
  const { testCd, id, sessionId, assignedCapacity } = room
  const newCapacity = assignedCapacity - selectedStudents.length

  dispatch({ type: c.UNASSIGN_STUDENTS_PENDING })

  try {
    const response = await axios.post(
      `${Config.API_URL}/paapro/v1/exam-day-management/organizations/${orgId}/education-periods/${educationPeriod}/sessions/${sessionId}/courses/${testCd}/rooms/${id}/students:removeStudents`,
      selectedStudents
    )
    dispatch({
      type: c.UNASSIGN_STUDENTS_FULFILLED,
      payload: { students: selectedStudents, roomId: id },
    })
    dispatch(getRoomObj(room, { ...room, assignedCapacity: newCapacity }))
  } catch (err) {
    dispatch({ type: c.UNASSIGN_STUDENTS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const reassignStudents = props => async dispatch => {
  const { orgId, educationPeriod, oldRoom, selectedStudents, newRoom } = props
  const { testCd, sessionId } = oldRoom
  const numOfAffectedStudents = selectedStudents.length
  const originalRoomCapacity = oldRoom.assignedCapacity - numOfAffectedStudents
  const newRoomCapacity = newRoom.assignedCapacity + numOfAffectedStudents
  dispatch({ type: c.UNASSIGN_STUDENTS_PENDING })

  try {
    const response = await axios.post(
      `${Config.API_URL}/paapro/v1/exam-day-management/organizations/${orgId}/education-periods/${educationPeriod}/sessions/${sessionId}/courses/${testCd}/rooms/${newRoom.id}/students:moveStudents`,
      selectedStudents
    )
    dispatch({
      type: c.UNASSIGN_STUDENTS_FULFILLED,
      payload: { students: selectedStudents, roomId: oldRoom.id },
    })
    dispatch(getRoomObj(oldRoom, { ...oldRoom, assignedCapacity: originalRoomCapacity }))
    dispatch(getRoomObj(oldRoom, { ...newRoom, assignedCapacity: newRoomCapacity }))
  } catch (err) {
    console.info(err)
    dispatch({ type: c.UNASSIGN_STUDENTS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const updateSelectedStudents = (add, studentId) => dispatch =>
  dispatch({
    type: add ? c.SELECT_ASSIGNED_STUDENT : c.UNSELECT_ASSIGNED_STUDENT,
    payload: studentId,
  })

export const resetSelectedStudents = () => ({ type: c.RESET_ASSIGNED_STUDENTS })
