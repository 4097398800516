import { useHistory } from 'react-router-dom'
import { Error } from '@myap/ui-library/esm/components/apricot'
import { Modal } from '../../components/common'
import { deleteExamRoom } from '../../actions/examRoom'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = state => state.examRoomSchedule

const ExamRoomDeleteModal = props => {
  const { courseName, name, modalCloseFocusElem, deleting, deleted, derror, deleteExamRoom } = props
  const history = useHistory()
  const title = `${courseName} ${name}`

  useEffect(() => {
    if (deleted) history.replace(NAV_LINKS.examroom)
  }, [deleted])

  return (
    <Modal
      shouldCloseModal={deleted}
      modalCloseFocusElem={modalCloseFocusElem}
      headerTitle={`${title}: Confirm Delete Room`}
      footerActions={[
        { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false, isDisabled: deleting },
        {
          buttonLabel: 'Yes, Delete Room',
          isPrimary: true,
          isDisabled: deleting,
          busy: deleting,
          onClick: () => deleteExamRoom(props),
        },
      ]}
    >
      Are you sure you want to delete {title}? All students assigned to this room will be
      unassigned.
      {derror ? <Error title="Error deleting room" message={derror} /> : null}
    </Modal>
  )
}

export default connect(
  mapStateToProps,
  { deleteExamRoom }
)(ExamRoomDeleteModal)
