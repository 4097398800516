import * as c from '../constants/StatusConstants'

const initialState = {
  fetching: false,
  fetched: false, //will only be false before initial fetch attempt
  error: null,
  stale: false,
  data: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case c.FETCH_STATUS_SUMMARY_PENDING:
      return { ...state, fetching: true, error: null }
    case c.FETCH_STATUS_SUMMARY_REJECTED:
      return { ...state, fetching: false, fetched: true, error: payload }
    case c.FETCH_STATUS_SUMMARY_FULFILLED:
      return { ...state, fetching: false, fetched: true, error: null, stale: false, data: payload }
    
    case c.UPDATE_STATUS_SUMMARY:
      return { ...state, stale: true }
    
    default:
      return state
  }
}
