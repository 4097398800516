import { Link } from 'react-router-dom'
import { NAV_LINKS } from '../../constants/NavConstants'
import { SETTINGS_PANELS } from '../../constants/SettingsConstants'

const mapStateToProps = ({
  settingsOrg,
  user: {
    data: { selectedOrgId },
  },
  invoices: { data = {} },
}) => ({
  purchaseOrderNumber: settingsOrg[selectedOrgId]?.data?.schoolInformation?.purchaseOrderNumber,
  data,
})

const PurchaseOrder = ({
  purchaseOrderNumber,
  title = 'Purchase Order Number',
  content = '<link>',
  linkTitle = 'Add or edit',
  data,
}) => {
  const [before, after] = content.split('<link>')

  return (
    <>
      <h3 className="h4">{title}</h3>
      {purchaseOrderNumber ? <p>{purchaseOrderNumber}</p> : null}
      <p>
        {before}{' '}
        <Link to={`${NAV_LINKS.settings}?scroll=${SETTINGS_PANELS.partForm}`}>{linkTitle}</Link>{' '}
        {after}
      </p>
    </>
  )
}

export default connect(mapStateToProps)(PurchaseOrder)
