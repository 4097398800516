/* props
  - form
  - disabled (optional)
  - name
  - isRequired (optional)
  - openToDate (optional)
  - minDate (optional)
  - maxDate (optional)
  - label (optional)
*/

import {
  formatDate,
  parse,
  isValidDefaultFormat,
  isValidDate,
  isDateSameOrBefore,
  isDateSameOrAfter,
  DEFAULT_DATEFORMAT,
} from '@myap/ui-library/esm/date'
import { change, getFormValues } from 'redux-form'
import DatePicker from 'react-datepicker'
import { AddRequiredValidation, Text, Label } from '..'

import '../../../assets/style/scss/datepicker/datepicker-cssmodules.scss'

const DEFAULT_FORMAT = DEFAULT_DATEFORMAT.toUpperCase()

const mapStateToProps = (state, ownProps) => {
  const { form, name } = ownProps
  const formValues = form && getFormValues(form)(state)

  return {
    selected: formValues && formValues[name],
  }
}

const checkDateFormat = val =>
  val && isValidDefaultFormat(val)
    ? undefined
    : `Error: Invalid date format. Must be ${DEFAULT_FORMAT}.`
const checkIsValidDate = val => (val && isValidDate(val) ? undefined : 'Error: Invalid date.')
const checkDateRangeMin = min => val =>
  (min && val && isDateSameOrBefore(min, val)) || !min
    ? undefined
    : isValidDefaultFormat(min)
    ? `Error: Date can not be on or before ${formatDate(min)}.`
    : undefined //Not showing an error because min is not a valid date
const checkDateRangeMax = max => val =>
  (max && val && isDateSameOrAfter(max, val)) || !max
    ? undefined
    : isValidDefaultFormat(max)
    ? `Error: Date can not be on or after ${formatDate(max)}.`
    : undefined //Not showing an error because max is not a valid date
const getNewValidationArray = ({ minDate, maxDate, isRequired, disabled, validate = [] }) => {
  if (disabled) return []
  return AddRequiredValidation(isRequired, [
    ...validate,
    checkDateFormat,
    checkIsValidDate,
    checkDateRangeMin(minDate),
    checkDateRangeMax(maxDate),
  ])
}

class DatePickerTrigger extends Component {
  render() {
    const { isOpen, label, disabled, onKeyDown, id } = this.props

    return (
      <div>
        <button
          type="button"
          aria-haspopup={true}
          aria-label={`Open calender for ${label}`}
          onClick={e => {
            document.getElementById(this.props.input).focus()
            this.props.toggle(!isOpen)
          }}
          onKeyDown={onKeyDown}
          disabled={disabled}
          className="btn-link ui-datepicker-trigger"
          id={id}
        >
          <span className="sr-only">Use the calendar popup to select a date.</span>
        </button>
      </div>
    )
  }
}

class DatePickerComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false, validation: [] }
  }

  componentDidMount() {
    this.setState({ validation: getNewValidationArray(this.props) })
  }

  componentDidUpdate(prevProps) {
    const { isRequired, disabled, minDate, maxDate } = this.props
    if (
      prevProps.isRequired !== isRequired ||
      prevProps.disabled !== disabled ||
      prevProps.minDate !== minDate ||
      prevProps.maxDate !== maxDate
    ) {
      this.setState({ validation: getNewValidationArray(this.props) })
    }
  }

  toggleDatepicker(isOpen) {
    this._calendar.setOpen(isOpen)
    this.setState({ isOpen })
  }

  render() {
    const {
      name,
      label,
      showLabel = true,
      isRequired,
      disabled,
      change,
      form,
      minDate,
      maxDate,
      selected,
      openToDate,
      onChangeTextAction,
      onChangeAction,
      labelStyle,
      labelClasses,
      inputStyle,
      wrapperStyle,
    } = this.props
    const { isOpen, validation } = this.state
    const hasValidValue = isValidDefaultFormat(selected)
    const value = hasValidValue ? selected : undefined

    return (
      <>
        {showLabel && (
          <Label
            name={name}
            label={label}
            classes={labelClasses}
            isRequired={isRequired}
            style={labelStyle}
          />
        )}
        <div
          className={`react-datepicker-container ${disabled ? 'disabled' : ''}`}
          style={wrapperStyle}
          ref={node => (this.container = node)}
        >
          <Text
            maxlength={DEFAULT_DATEFORMAT.length}
            name={name}
            placeholder={DEFAULT_FORMAT}
            isRequired={isRequired}
            label=""
            showErrorIcon={false}
            validate={validation}
            inputStyle={inputStyle}
            disabled={disabled}
            onChange={e => {
              onChangeTextAction && onChangeTextAction(e)
            }}
          />

          <DatePicker
            ref={c => (this._calendar = c)}
            fixedHeight={true}
            id={`${name}DatePickerTrigger`}
            name={name}
            dateFormat={DEFAULT_DATEFORMAT}
            disabled={disabled}
            openToDate={isValidDate(openToDate) ? parse(openToDate) : new Date()}
            maxDate={parse(maxDate)}
            minDate={parse(minDate)}
            selected={parse(value)}
            popperPlacement="top-start"
            popperModifiers={{
              offset: {
                enabled: true,
                offset: '0, -24px',
              },
            }}
            onClickOutside={e =>
              this.toggleDatepicker(this.container && this.container.contains(e.target))
            }
            onSelect={() => this.toggleDatepicker(false)}
            onChange={val => {
              change(form, name, formatDate(val))
              onChangeAction && onChangeAction()
            }}
            customInput={
              <DatePickerTrigger
                label={label}
                isOpen={isOpen}
                input={name}
                toggle={this.toggleDatepicker.bind(this)}
                calendar={this._calendar}
              />
            }
          />
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  { change }
)(DatePickerComponent)
