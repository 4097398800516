/*
 * Component for creating a data table containing sortable column headers.
 *
 * @prop {object} tableAttributes (optional) - key/value pairs of your TABLE attributes, passed in exactly as you want them written
 * @prop {object} data - The data object that's displayed in the individual table rows and used for sorting
 * @prop {func} rowBuilder - The function used to build and format the individual table rows
 * @prop {array} columns - Array of key/value pair objects for handling the column headers
 * @prop {bool} tbody (optional) - If true, wraps the individual rows in TBODY tags
 * @prop {string} tableCaption (optional) - Populates the CAPTION tag for the data table. Accepts:
 * * headerText {object} - Text to be displayed in the column header
 * * * title {string} - Column header (TH) text
 * * * srOnly {bool} - Determines if the Header text contains the class of "sr-only"
 * * headerAttributes (optional) - key/value pairs of your TH attributes, passed in exactly as you want them written
 * * sortFunction (optional) {function} - Custom sorting function to be used for sorting the data
 * * key {string} - Object key whose value we're using in our sort function
 * * sorting {string} - Default sort order. Values can be "asc", or "desc"
 * * defaultSort (optional) {bool} - If true, this column will be used to sort the data on page load
 *
 * */

import SortableTableHeader from './SortableTableHeader'
import { sortColumnByKey, checkForSameness } from '../../../utils/sort'

export default class SortableTable extends Component {
  constructor(props) {
    super(props)
    this.table = createRef()
    this.state = { ...this.getDefaultSortState(props.data), stickyHeaderOffset: null }
    this.shouldHaveStickyHeader = this.shouldHaveStickyHeader.bind(this)
  }

  componentDidMount() {
    this.props.stickyHeader && document.addEventListener('scroll', this.shouldHaveStickyHeader)
  }

  componentWillUnmount() {
    this.props.stickyHeader && document.removeEventListener('scroll', this.shouldHaveStickyHeader)
  }

  shouldHaveStickyHeader() {
    const { top, bottom } = this.table.current.getBoundingClientRect()
    const localNavOffset = document.getElementsByClassName('cb-adjust-local-nav').length
      ? document.getElementsByClassName('cb-local-navigation')[0]?.getBoundingClientRect().height
      : 0
    const headerHeight = this.getHeaderHeight()
    const topOffset = this.isTableHeaderSticky() ? headerHeight : 0
    const isPastTable = bottom - localNavOffset - headerHeight < 0
    const shouldBeSticky = localNavOffset && top < topOffset + localNavOffset
    this.setState({ stickyHeaderOffset: shouldBeSticky && !isPastTable ? localNavOffset : null })
  }

  getHeaderHeight() {
    return this.table.current.getElementsByTagName('thead')[0].getBoundingClientRect().height
  }

  isTableHeaderSticky() {
    return Number.isInteger(this.state.stickyHeaderOffset)
  }

  componentDidUpdate(prevProps) {
    const { sortindex, sorting = 'asc' } = this.state
    const { data } = this.props

    if (prevProps.data !== data) this.setState({ data: this.sortData(sortindex, sorting) })
  }

  getDefaultSortState() {
    const { columns } = this.props
    let initState = {}

    columns.forEach((column, i) => {
      if (!column.defaultSort) return

      initState = {
        sortindex: i,
        sorting: column.sorting,
        data: this.sortData(i, column.sorting),
      }
    })

    return initState
  }

  sortData(sortindex, sorting) {
    const { columns, data } = this.props
    const { key } = columns[sortindex]

    if (columns[sortindex].sortFunction && typeof columns[sortindex].sortFunction === 'function')
      return columns[sortindex].sortFunction(data, key, sorting)

    return sortColumnByKey(data, key, sorting)
  }

  onStateChange(index, sorting) {
    const { data, sortindex } = this.state
    const sortDir = sorting === 'asc' && index === sortindex ? 'desc' : 'asc'
    const { columns } = this.props

    this.setState({
      sortindex: index,
      sorting: sortDir,
      data: checkForSameness(data, columns[index].key) ? data : this.sortData(index, sortDir),
    })
  }

  render() {
    const { sortindex, sorting, data, stickyHeaderOffset } = this.state
    const {
      tableAttributes,
      columns,
      tableCaption,
      rowBuilder,
      tbody = true,
      stickyHeader,
    } = this.props
    const stickyTableStyle = stickyHeader
      ? {
          tableLayout: 'fixed',
          position: 'relative',
          marginTop: this.isTableHeaderSticky() ? this.getHeaderHeight() : 0,
        }
      : {}
    const tableStyle = tableAttributes.style
      ? { ...tableAttributes.style, ...stickyTableStyle }
      : stickyTableStyle

    return (
      <table {...tableAttributes} ref={this.table} style={tableStyle}>
        {tableCaption ? (
          <caption className={tableCaption.srOnly ? 'sr-only' : ''}>{tableCaption.caption}</caption>
        ) : null}
        <SortableTableHeader
          columns={columns}
          sorting={sorting}
          active={sortindex}
          stickyHeaderOffset={stickyHeaderOffset}
          onStateChange={this.onStateChange.bind(this)}
        />
        {tbody ? <tbody>{rowBuilder(data)}</tbody> : rowBuilder(data)}
      </table>
    )
  }
}
