import numeral from 'numeral'
import {
  isValidPhoneNumber,
  getCountryCallingCode,
  formatIncompletePhoneNumber,
} from 'libphonenumber-js/max'

export const dollarFormat = num => numeral(num).format('$0,0[.]00')
export const dollarDecimalFormat = num => numeral(num).format('$0,0.00')
export const percentFormat = num => numeral(num).format('0%')
export const numberFormat = num => numeral(num).format('0,0')
export const safeNumber = num => {
  const intNum = parseInt(num, 10)
  return !Number.isNaN(intNum) ? intNum : 0
}

export const formatPhoneNumber = (phoneString, countryCode = null) => {
  if (!countryCode) {
    const p = phoneString ? phoneString.toString().replace(/[^0-9]/g, '') : ''
    const m = p.match(/^(\d{3})(\d{3})(\d{4})$/)
    return !m ? p : `(${m[1]}) ${m[2]} - ${m[3]}`
  }
  return formatIncompletePhoneNumber(phoneString, countryCode)
}

export const showNumbersOnly = val => (val ? val.replace(/[^0-9]/g, '') : val)

export const convertToNumberString = val => (val ? val.toString().replace(/[^0-9]/g, '') : '')

export const formatZip = zipString => {
  const z = convertToNumberString(zipString)
  const m = z.match(/^(\d{5})(\d{4})$/)
  return !m ? z : `${m[1]}-${m[2]}`
}

export const validatePhoneNumberByCountryCode = (text, countryCode) => {
  const len = text ? text.toString().length : 0
  const callCode = getCountryCallingCode(countryCode)
  if (countryCode === 'US') {
    return len === 10 && isValidPhoneNumber(text, countryCode)
  }
  return callCode === text.substring(0, callCode.length) && isValidPhoneNumber(text, countryCode)
}
