import * as c from '../constants/OrderConstants'
import axios, { getServerErrorMessage } from '../utils/axios'
import { sortColumnByKey } from '../utils/sort'

export const fetchSpecialExamRequests = (orgId, edPeriod) => async dispatch => {
  dispatch({ type: c.FETCH_SPECIAL_EXAM_REQUESTS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/v2/orders/organizations/${orgId}/education-periods/${edPeriod}/nonstocked-approval-requests`
    )
    const processedData = data.map(d => ({
      ...d,
      approvalStatusSortOrder: c.APPROVAL_STATUS_SORT_ORDER[d.etsApprovalStatus],
    }))
    dispatch({ type: c.FETCH_SPECIAL_EXAM_REQUESTS_FULFILLED, payload: { data: processedData } })
  } catch (err) {
    dispatch({ type: c.FETCH_SPECIAL_EXAM_REQUESTS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const fetchSecureMaterialsRequests = (orgId, edPeriod) => async dispatch => {
  dispatch({ type: c.FETCH_SECURE_MATERIALS_REQUESTS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${edPeriod}/secure-materials-approval-request`
    )
    const processedData = data.map(d => ({
      ...d,
      approvalStatusSortOrder: c.APPROVAL_STATUS_SORT_ORDER[d.etsApprovalStatus],
      items: sortColumnByKey(d.items, 'description', 'asc'),
    }))
    dispatch({
      type: c.FETCH_SECURE_MATERIALS_REQUESTS_FULFILLED,
      payload: { data: processedData },
    })
  } catch (err) {
    dispatch({
      type: c.FETCH_SECURE_MATERIALS_REQUESTS_REJECTED,
      payload: getServerErrorMessage(err),
    })
  }
}

export const fetchNonSecureMaterialsRequests = (orgId, edPeriod) => async dispatch => {
  dispatch({ type: c.FETCH_NON_SECURE_MATERIALS_REQUESTS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${edPeriod}/non-secure-materials-approval-request`
    )
    const processedData = data.map(d => ({
      ...d,
      approvalStatusSortOrder: c.APPROVAL_STATUS_SORT_ORDER[d.etsApprovalStatus],
      items: sortColumnByKey(d.items, 'description', 'asc'),
    }))
    dispatch({
      type: c.FETCH_NON_SECURE_MATERIALS_REQUESTS_FULFILLED,
      payload: { data: processedData },
    })
  } catch (err) {
    dispatch({
      type: c.FETCH_NON_SECURE_MATERIALS_REQUESTS_REJECTED,
      payload: getServerErrorMessage(err),
    })
  }
}

export const fetchExceptionTestingRequests = (orgId, edPeriod) => async dispatch => {
  dispatch({ type: c.FETCH_EXCEPTION_TESTING_EXAM_REQUESTS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${edPeriod}/exception-test-approval-requests`
    )
    dispatch({ type: c.FETCH_EXCEPTION_TESTING_EXAM_REQUESTS_FULFILLED, payload: { data } })
  } catch (err) {
    dispatch({
      type: c.FETCH_EXCEPTION_TESTING_EXAM_REQUESTS_REJECTED,
      payload: getServerErrorMessage(err),
    })
  }
}

export const fetchOrdersAfterDeadlineRequests = (orgId, edPeriod) => async dispatch => {
  dispatch({ type: c.FETCH_ORDERS_AFTER_DEADLINE_REQUESTS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${edPeriod}/orders-after-deadline-approval-requests`
    )
    const processedData = data.map(d => ({
      ...d,
      approvalStatusSortOrder: c.APPROVAL_STATUS_SORT_ORDER[d.etsApprovalStatus],
    }))
    dispatch({
      type: c.FETCH_ORDERS_AFTER_DEADLINE_REQUESTS_FULFILLED,
      payload: { data: processedData },
    })
  } catch (err) {
    dispatch({
      type: c.FETCH_ORDERS_AFTER_DEADLINE_REQUESTS_REJECTED,
      payload: getServerErrorMessage(err),
    })
  }
}

export const fetchExceptionTestingApprovalRequestDetail = id => async dispatch => {
  dispatch({ type: c.FETCH_EXCEPTION_TESTING_AR_DETAILS_PENDING, payload: { id } })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/orders/special-order/${id}/exception-tests-approval-request-details`
    )
    dispatch({ type: c.FETCH_EXCEPTION_TESTING_AR_DETAILS_FULFILLED, payload: { id, data } })
  } catch (err) {
    dispatch({
      type: c.FETCH_EXCEPTION_TESTING_AR_DETAILS_REJECTED,
      payload: { id, error: getServerErrorMessage(err) },
    })
  }
}

export const fetchOrdersAfterDeadlineApprovalRequestDetail = id => async dispatch => {
  dispatch({ type: c.FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_PENDING, payload: { id } })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/orders/special-order/${id}/orders-after-deadline-approval-request-details`
    )
    dispatch({ type: c.FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_FULFILLED, payload: { id, data } })
  } catch (err) {
    dispatch({
      type: c.FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_REJECTED,
      payload: { id, error: getServerErrorMessage(err) },
    })
  }
}

export const resetExceptionTestingApprovalRequestDetail = () => dispatch => {
  dispatch({ type: c.RESET_EXCEPTION_TESTING_AR_DETAILS })
}

export const resetOrdersAfterDeadlineApprovalRequestDetail = () => dispatch => {
  dispatch({ type: c.RESET_ORDERS_AFTER_DEADLINE_AR_DETAILS })
}

export const fetchOffCycleTestingRequests = (orgId, edPeriod) => async dispatch => {
  dispatch({ type: c.FETCH_OFF_CYCLE_TESTING_REQUESTS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${edPeriod}/approval-requests/off-cycle`
    )
    const processedData = data.map(d => ({
      ...d,
      approvalStatusSortOrder: c.APPROVAL_STATUS_SORT_ORDER[d.etsApprovalStatus],
    }))
    dispatch({
      type: c.FETCH_OFF_CYCLE_TESTING_REQUESTS_FULFILLED,
      payload: { data: processedData },
    })
  } catch (err) {
    dispatch({
      type: c.FETCH_OFF_CYCLE_TESTING_REQUESTS_REJECTED,
      payload: getServerErrorMessage(err),
    })
  }
}
