import * as c from '../constants/NotificationConstants'

const initialState = {
  [c.AP_NOTIFICATIONS]: {
    fetching: false,
    fetched: false,
    error: null,
    data: [],
  },
  [c.HOLD_NOTIFICATION]: {
    fetching: false,
    fetched: false,
    error: null,
    data: {},
  },
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case c.FETCH_NOTIFICATION_PENDING:
    case c.DISMISS_NOTIFICATION_PENDING:
      return {
        ...state,
        [payload.type]: { ...state[payload.type], fetching: true, fetched: false, error: null },
      }

    case c.FETCH_NOTIFICATION_FULFILLED:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          fetching: false,
          fetched: true,
          data: payload.data,
        },
      }
    case c.DISMISS_NOTIFICATION_FULFILLED:
      return {
        ...state,
        [payload.type]: { ...state[payload.type], fetching: false, fetched: true, data: [] },
      }

    case c.FETCH_NOTIFICATION_REJECTED:
    case c.DISMISS_NOTIFICATION_REJECTED:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          fetching: false,
          fetched: false,
          error: payload.error,
        },
      }

    default:
      return state
  }
}
