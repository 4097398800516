import { PACKING_LIST_LABEL_MAPPING } from '../../constants/PackingListConstants'

import packingStyles from '../../assets/style/scss/packing-details.scss'

const NonStockExamComponent = ({ id = '', students, inline }) => {
  return (
    <div className={inline ? `${packingStyles.inline}` : ''} style={{ padding: '12px 24px 24px 0' }}>
      <h4>{PACKING_LIST_LABEL_MAPPING[id] || ''}</h4>
      {students}
    </div>
  )
}

export default NonStockExamComponent
