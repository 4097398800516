import { Error } from '@myap/ui-library/esm/components'
import { fetchExamDecisionsSettings, fetchOrgSettings } from '../../actions/settingsOrg'
import { fetchDeadlinesSettings, fetchExamWindows } from '../../actions/settingsGlobal'
import { getAllExamWindows } from '../../selectors/examWindows'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId, isTeacher, isCoordinator, isDistrictFundingAdmin, isPreAPOnly },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsDeadlines: { fetching: fetchingDeadlines, error: errDeadlines },
    settingsExamWindows: { fetching: fetchingExamWindows, error: errExamWindows },
    ordersSubmission: { submitted: orderSubmitted },
    app: { fetchActionsOnChange },
  } = state
  const examWindowDataForOrg = getAllExamWindows(state)
  const { fetching: fetchingOrgSettings, error: errOrgSettings } =
    state.settingsOrg[selectedOrgId] || {}
  const { fetching: fetchingExamDecisionsSettings, error: errExamDecisions } =
    state.settingsExamDecisions[selectedOrgId] || {}
  const isProfessional = isTeacher || isCoordinator

  return {
    selectedOrgId,
    selectedEducationPeriod,
    fetchActionsOnChange,
    shouldFetchOrgSettings: !fetchingOrgSettings && isCoordinator && !isPreAPOnly,
    shouldFetchExamDecisions: !fetchingExamDecisionsSettings && isProfessional,
    shouldFetchDeadlines: !fetchingDeadlines && (isProfessional || isDistrictFundingAdmin),
    shouldFetchExamWindows:
      !fetchingExamWindows && isProfessional && !Object.keys(examWindowDataForOrg).length,
    hasError: errDeadlines || errOrgSettings || errExamDecisions || errExamWindows,
    orderSubmitted,
  }
}

const FetchByOrgIdOrYear = connect(mapStateToProps)(props => {
  const {
    selectedEducationPeriod,
    selectedOrgId,
    fetchOrgSettings,
    fetchExamDecisionsSettings,
    fetchDeadlinesSettings,
    fetchExamWindows,
    shouldFetchOrgSettings,
    shouldFetchExamDecisions,
    shouldFetchDeadlines,
    shouldFetchExamWindows,
    fetchActionsOnChange,
    orderSubmitted,
    hasError,
    children,
  } = props
  const [prevActions, setPrevActions] = useState(fetchActionsOnChange)

  const executeActions = actions => {
    if (selectedOrgId && selectedEducationPeriod)
      actions.forEach(({ name, action }) => {
        //console.log(`***Triggering action for: ${name}`)
        action(selectedOrgId, selectedEducationPeriod)
      })
  }

  useEffect(() => {
    const fetchVitalData = async () => {
      if (shouldFetchOrgSettings) {
        await fetchOrgSettings(selectedOrgId, selectedEducationPeriod)
      }

      if (shouldFetchExamDecisions) {
        await fetchExamDecisionsSettings(selectedOrgId, selectedEducationPeriod)
      }

      if (shouldFetchDeadlines) {
        await fetchDeadlinesSettings(selectedEducationPeriod)
      }

      if (shouldFetchExamWindows) {
        await fetchExamWindows(selectedOrgId, selectedEducationPeriod)
      }

      await executeActions(fetchActionsOnChange)
    }

    if (selectedEducationPeriod && selectedOrgId) {
      fetchVitalData()
    }
  }, [selectedEducationPeriod, selectedOrgId])

  useEffect(() => {
    const newActions = fetchActionsOnChange.filter(
      ({ name: id1 }) => !prevActions.some(({ name: id2 }) => id2 === id1)
    )
    if (newActions.length > 0) {
      //console.log('***Trigger for added actions ', newActions)
      executeActions(newActions)
    }
    setPrevActions(fetchActionsOnChange)
  }, [fetchActionsOnChange])

  useEffect(() => {
    if (orderSubmitted) {
      executeActions(fetchActionsOnChange)
    }
  }, [orderSubmitted])

  if (hasError)
    return (
      <Error
        title="Error Retrieving Data"
        message="An error occurred. Please try again later."
        useWrapper={true}
      />
    )

  return children
})

export default connect(mapStateToProps, {
  fetchExamDecisionsSettings,
  fetchOrgSettings,
  fetchDeadlinesSettings,
  fetchExamWindows,
})(FetchByOrgIdOrYear)
