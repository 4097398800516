import { fetchOrdersAfterDeadlineCourses, saveOrdersAfterDeadlineCourses } from '../../../actions/csr'
import { LockUnlockComponent } from '.'

import styles from '../../../assets/style/scss/csr.scss'

export default () => (
  <LockUnlockComponent
    compId="ordersAfterDeadlineCourses"
	introText="Select the courses to unlock ordering after the final deadline for. This capability will be active for 24 hours."
	emptyText="There are no courses to order exams for after the final deadline."
    fnFetch={fetchOrdersAfterDeadlineCourses}
    fnSave={saveOrdersAfterDeadlineCourses}
    itemProps={{ itemId: 'testCd', itemDescription: 'courseName' }}
  />
)
