import { openModal } from '../../../actions/app'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openModal }, dispatch)
}

export class EditSectionButton extends Component {
  constructor() {
    super()
    this.editSectionModal = this.editSectionModal.bind(this)
  }

  editSectionModal() {
    const { section, openModal } = this.props
    openModal('CreateAndEditSectionModal', {
      section,
      creating: false,
      modalCloseFocusElem: this.modalCloseFocusElem,
    })
  }

  render() {
    return (
      <button
        type="button"
        className="btn-link cb-small-font-size"
        aria-label={`Edit section ${this.props.section.sectionName}`}
        onClick={() => this.editSectionModal()}
        ref={node => (this.modalCloseFocusElem = node)}
      >
        <span aria-hidden="true" className="cb-glyph cb-compose" data-alt="Edit Section" />
      </button>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(EditSectionButton)
