import { reducer as formReducer } from 'redux-form'
import * as c from '../constants/FormConstants'

export default (state, action) => {
  return action?.payload?.formName
    ? formReducer.plugin({
        [action?.payload?.formName]: (state, { type, payload }) => {
          switch (type) {
            // This action will set a field's value to undefined.
            // Useful when removing a field from a form when that field is an array (like, an array
            // of checkboxs), otherwise, the app will crash as untouch() or clearFields() actions
            // will blow up when trying to remove fields where the value is an array.
            case c.ANNIHILATE_FIELD:
              return {
                ...state,
                values: {
                  ...state.values,
                  [payload.fieldName]: undefined,
                },
                registeredFields: {
                  ...state.registeredFields,
                  [payload.fieldName]: undefined,
                },
                fields: {
                  ...state.fields,
                  [payload.fieldName]: undefined,
                },
              }

            default:
              return state
          }
        },
      })(state, action)
    : formReducer(state, action)
}
