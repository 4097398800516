import { Error } from '@myap/ui-library/esm/components/apricot'
import { Modal, SortableTable, TableRowSelection } from '../../components/common'
import { getRooms } from '../../components/examRoom/utils'
import { reassignStudents } from '../../actions/examRoom'
import { openModal } from '../../actions/app'
import { plural } from '../../utils/common'

const mapStateToProps = (state, { room }) => {
  const { examRoomSchedule, examRoomStudents, user, settingsEducationPeriod } = state
  const { selectedStudents, saving, saved, serror } = examRoomStudents
  const numOfSelectedStudents = selectedStudents.length
  const { testCd, id } = room
  const allAvailableRooms = getRooms(examRoomSchedule.data)

  return {
    rooms: allAvailableRooms.reduce((acc, r) => {
      const remainingCapacity = r.totalCapacity - r.assignedCapacity
      return r.testCd === testCd &&
        r.id !== id &&
        remainingCapacity &&
        remainingCapacity >= numOfSelectedStudents
        ? [...acc, { ...r, remainingCapacity }]
        : acc
    }, []),
    selectedStudents,
    saving,
    saved,
    serror,
    orgId: user.data.selectedOrgId,
    educationPeriod: parseInt(settingsEducationPeriod.selectedEducationPeriod, 10),
  }
}

const ExamRoomMoveModal = props => {
  const [closeModal, setCloseModal] = useState(false)
  const [newRoom, setNewRoom] = useState(null)
  const {
    modalCloseFocusElem,
    room,
    rooms,
    selectedStudents,
    saving,
    saved,
    serror,
    reassignStudents,
    openModal,
    orgId,
    educationPeriod,
  } = props
  const { courseName, testCd, sessionId, name } = room
  const title = `${courseName} ${name}`
  const hasRooms = rooms.length
  const numOfStudentSelections = selectedStudents.length

  useEffect(() => {
    if (saved) setCloseModal(saved)
  }, [saved])

  const openCreateModal = () => {
    openModal('ExamRoomCreateModal', {
      courseName,
      testCd,
      sessionId,
      oldRoom: room,
      modalCloseFocusElem,
    })
  }

  return (
    <Modal
      shouldCloseModal={closeModal}
      modalCloseFocusElem={modalCloseFocusElem}
      headerTitle={`${title}: Move Students`}
      footerActions={[
        { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
        {
          buttonLabel: hasRooms ? 'Move Students' : 'Create New Room',
          isPrimary: true,
          isDisabled: saving || (hasRooms ? !newRoom : false),
          isDismissable: !hasRooms,
          busy: saving,
          onClick: () => {
            if (hasRooms) reassignStudents({ selectedStudents, oldRoom: room, newRoom, orgId, educationPeriod })
            else openCreateModal()
          },
        },
      ]}
    >
      {hasRooms ? (
        <>
          <div className="row">
            <div className="col-xs-8">
              Select the room to move{' '}
              <strong>
                {numOfStudentSelections} {plural(numOfStudentSelections, 'student')}
              </strong>{' '}
              to
            </div>
            <div className="col-xs-4 cb-align-right">
              <button type="button" className="btn-link" onClick={openCreateModal}>
                <i className="cb-glyph cb-plus" aria-hidden={true} />
                Create Room
              </button>
            </div>
          </div>
          <SortableTable
            tableAttributes={{
              id: 'availableRoomsForMove',
              summary:
                'Available rooms are listed in ascending order by name. Additional sorting options are available by clicking on the column headers.',
              className: 'table cb-no-table-border',
            }}
            data={rooms}
            rowBuilder={data =>
              data.map((room, i) => (
                <TableRowSelection
                  key={i}
                  rowId={room.id}
                  inputType="radio"
                  inputName="availableRoomsForMoveSelection"
                  label={`Select ${room.name} room`}
                  isChecked={newRoom?.id === room.id}
                  onSelection={({ checked }) => checked && setNewRoom(room)}
                >
                  <th scope="row">{room.name}</th>
                  <td>
                    {room.remainingCapacity} of {room.totalCapacity}{' '}
                    {plural(room.remainingCapacity, 'seat')} available
                  </td>
                </TableRowSelection>
              ))
            }
            columns={[
              { headerText: { title: 'Select Student', srOnly: true } },
              {
                headerText: { title: 'Room Name' },
                key: 'name',
                sorting: 'asc',
                defaultSort: true,
              },
              {
                headerText: { title: 'Available Seats' },
              },
            ]}
            tableCaption={{
              caption: `A view of all available rooms to move students`,
              srOnly: true,
            }}
          />
          {serror ? <Error title="Error reassigning student" message={serror} /> : null}
        </>
      ) : (
        <p>There are no other rooms for {courseName}? Would you like to create a new room?</p>
      )}
    </Modal>
  )
}

export default connect(
  mapStateToProps,
  { reassignStudents, openModal }
)(ExamRoomMoveModal)
