import { Link } from 'react-router-dom'
import { openModal } from '../../../actions/app'
import { SortableTable, Loader, Error } from '../../common'
import {
  ORDER_TAB_EXAMS,
  ORDER_NOT_SUBMITTED,
  SECURE_MATERIAL_STATUS,
  SECURE_MATERIAL_APPROVAL_NOT_REQUIRED,
} from '../../../constants/OrderConstants'
import {
  COURSE_FILTER,
  ORDER_EXAM_FILTER,
  ADMIN_FILTER,
  FEE_STATUS_FILTER,
  SSD_FILTER,
  FILTER_YES,
} from '../../../constants/FilterConstants'
import {
  STUDENT_ENROLLMENT_STATUS_YES,
  STUDENT_ENROLLMENT_STATUS_UNDECIDED,
  STUDENT_ENROLLMENT_STATUS_NO,
  STUDENT_ENROLLMENT_STATUS_UNUSED,
  REDUCED_FEE_STATUS,
} from '../../../constants/StudentConstants'
import { isEmpty } from '../../../utils/common'
import { sortColumnByDate, sortColumnByKey } from '../../../utils/sort'
import { numberFormat } from '../../../utils/numbers'
import { SubmitButton, DownloadOrderDetailsButton } from '.'
import DisplayExamDate from '../../common/DisplayExamDate'
import style from '../../../assets/style/scss/orders.scss'
import tableUtilStyles from '../../../assets/style/scss/table-utils.scss'
import { getAllExamWindows } from '../../../selectors/examWindows'
import { TEST_ADMIN_OFFCYCLE } from '../../../constants/SettingsConstants'

const mapStateToProps = state => {
  const {
    fetching,
    error,
    courseExamSummaries: summaries,
    mainOrderSummary: m,
    unsubmittedChangesSummary: u,
  } = state.ordersSummary
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsOrg,
  } = state
  const { data: coursesWithSecureMaterials } = state.coursesWithSecureMaterials
  const allowedToOrder = settingsOrg.allowedToOrder[selectedOrgId]

  return {
    showSubmitOrderButton:
      allowedToOrder &&
      ((m && m.orderStatus === ORDER_NOT_SUBMITTED && m.numberExams > 0) || (u && !u.empty)),
    summaries,
    fetching,
    error,
    coursesWithSecureMaterials: coursesWithSecureMaterials || [],
    examWindows: getAllExamWindows(state),
  }
}

const hasSecureMaterials = (coursesWithSecureMaterials, testCd) =>
  coursesWithSecureMaterials.indexOf(testCd) !== -1
// const hasSecureMaterials = (coursesWithSecureMaterials, testCd) => coursesWithSecureMaterials.some(item => item.testCd === testCd)
const hasSecureMaterialsApprovalStatus = (coursesWithSecureMaterials, testCd, testDayType) =>
  coursesWithSecureMaterials.find(
    item => item.testCd === testCd && item.testDayType === testDayType
  )

const tertiaryDateSort = (data, key, sorting) =>
  sortColumnByKey(sortColumnByDate(data, 'testDate', sorting), key, sorting)

const headerColumns = [
  {
    headerText: { title: 'Course' },
    key: 'courseName',
    sorting: 'asc',
    defaultSort: true,
  },
  {
    headerText: { title: 'Exam Date' },
    headerAttributes: {
      className: `${style['exam-date']}`,
    },
    key: 'testDate',
    sorting: 'asc',
    sortFunction: (data, key, sorting) =>
      sortColumnByDate(sortColumnByKey(data, 'courseName', sorting), key, sorting),
  },
  {
    headerText: { title: 'Reduced Fee' },
    headerAttributes: {
      className: `${style['exam-reduced-fee']}`,
    },
    key: ['numberReducedFee', 'courseName'],
    sorting: 'asc',
    sortFunction: tertiaryDateSort,
  },
  {
    headerText: { title: 'SSD Materials' },
    headerAttributes: {
      className: `${style['exam-ssd']}`,
    },
    key: ['numberSpecialAccomodations', 'courseName'],
    sorting: 'asc',
    sortFunction: tertiaryDateSort,
  },
  {
    headerText: { title: 'Not Taking Exam' },
    headerAttributes: {
      className: `${style['exam-not-taking']}`,
    },
    key: ['numberNotTakingExams', 'courseName'],
    sorting: 'asc',
    sortFunction: tertiaryDateSort,
  },
  {
    headerText: { title: 'Total Exams' },
    headerAttributes: {
      className: `${style['exam-total-exams']}`,
    },
    key: ['numberExams', 'courseName'],
    sorting: 'asc',
    sortFunction: tertiaryDateSort,
  },
]

const getAllExamsCount = (courseData, examWindow) => {
  const isOffCycle =
    examWindow[courseData.testCd]?.[courseData.testDayType]?.testAdmin === TEST_ADMIN_OFFCYCLE
  if (isOffCycle) {
    return numberFormat(courseData.numberOffCyclePendingApproval)
  }
  return numberFormat(
    courseData.numberExceptionExamsPendingApproval +
      courseData.numberOrderedAfterDeadlinePendingApproval
  )
}

class ExamTabs extends Component {
  constructor() {
    super()
    this.renderCourses = this.renderCourses.bind(this)
  }

  renderCourses(courses) {
    const { coursesWithSecureMaterials, openModal, examWindows } = this.props

    return courses.map(c => {
      const secureMaterialAndStatus = hasSecureMaterialsApprovalStatus(
        coursesWithSecureMaterials,
        c.testCd,
        c.testDayType
      )
      const link = `/students?${COURSE_FILTER.filter}=${c.testCd}&${ADMIN_FILTER.filter}=${c.testDayType}`
      const nodeName = `modalCDOrderCloseFocusElem${c.testCd}-${c.testDayType}`

      return (
        <tr key={`${c.testCd}_${c.testDayType}`}>
          <th scope="row">
            <div>
              <Link to={link}>
                <span className="sr-only">View all students enrolled in this exam: </span>
                {c.courseName}
              </Link>
            </div>
            {!isEmpty(secureMaterialAndStatus) &&
            c.numberExams > 0 &&
            !isEmpty(secureMaterialAndStatus.status) &&
            secureMaterialAndStatus.status !== SECURE_MATERIAL_APPROVAL_NOT_REQUIRED ? (
              <div style={{ fontSize: '.8rem' }}>
                CD Status: {SECURE_MATERIAL_STATUS[secureMaterialAndStatus.status]}
              </div>
            ) : null}
            {!isEmpty(secureMaterialAndStatus) && c.numberExams > 0 ? (
              <div>
                <button
                  type="button"
                  className="btn-link"
                  ref={node => (this[nodeName] = node)}
                  style={{ borderWidth: 0, padding: 0, fontSize: '.8rem' }}
                  onClick={e => {
                    e.preventDefault()
                    openModal('OrderSecureMaterialsModal', {
                      course: c,
                      modalCloseFocusElem: this[nodeName],
                    })
                  }}
                >
                  View/Edit CD Order
                </button>
              </div>
            ) : null}
          </th>
          <td className={`${style['exam-date']} ${tableUtilStyles['with-icon']}`}>
            <div className={`${tableUtilStyles['exam-date-wrapper']} ${tableUtilStyles.flexible}`}>
              <DisplayExamDate course={c} />
            </div>
          </td>
          <td className={style['exam-reduced-fee']}>
            <Link
              to={`${link}&${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_YES}&${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_UNUSED}&${FEE_STATUS_FILTER.filter}=${REDUCED_FEE_STATUS}`}
            >
              <span className="sr-only">
                View all students enrolled in this exam with a reduced fee:{' '}
              </span>
              {numberFormat(c.numberReducedFee)}
            </Link>
          </td>
          <td className={style['exam-ssd']}>
            <Link
              to={`${link}&${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_YES}&${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_UNUSED}&${SSD_FILTER.filter}=${FILTER_YES}`}
            >
              <span className="sr-only">View all students enrolled in this exam: </span>
              {numberFormat(c.numberSpecialAccomodations)}
            </Link>
            {c.numberSSDPendingApproval > 0 ? (
              <>
                {' '}
                <span style={{ whiteSpace: 'nowrap' }}>
                  ({numberFormat(c.numberSSDPendingApproval)} Pending)
                </span>
              </>
            ) : null}
          </td>
          <td className={style['exam-not-taking']}>
            <div>
              <Link
                to={`${link}&${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_NO}&${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_UNDECIDED}`}
              >
                <span className="sr-only">View all students not taking this exam: </span>
                {numberFormat(c.numberNotTakingExams)}
              </Link>
            </div>
          </td>
          <td className={style['exam-total-exams']}>
            <div>
              <Link
                to={`${link}&${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_YES}&${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_UNUSED}`}
              >
                <span className="sr-only">View all students taking this exam: </span>
                {numberFormat(c.numberExams)}
              </Link>
              {getAllExamsCount(c, examWindows) > 0 ? (
                <>
                  {' '}
                  <span style={{ whiteSpace: 'nowrap' }}>
                    ({getAllExamsCount(c, examWindows)} Pending)
                  </span>
                </>
              ) : null}
            </div>
          </td>
        </tr>
      )
    })
  }

  render() {
    const { summaries = [], tabLabel, fetching, error, showSubmitOrderButton } = this.props
    const sumLen = summaries.length

    if (error) return <Error title="Error Retrieving Data" message={error} />

    if (fetching) return <Loader />

    return (
      <div role="tabpanel" aria-labelledby={tabLabel}>
        {sumLen ? (
          <CSSTransition
            classNames="animate-fade"
            in={true}
            timeout={500}
            appear={true}
            enter={false}
            exit={false}
          >
            <div>
              <DownloadOrderDetailsButton />
              <SortableTable
                tableAttributes={{
                  id: ORDER_TAB_EXAMS,
                  summary:
                    'Registrations are listed in ascending order by Course name. Additional sorting options are available by clicking on the column headers.',
                  className: `table cb-table-striped-reverse cb-no-table-border ${style['exam-registrations-table']}`,
                }}
                data={summaries}
                rowBuilder={this.renderCourses}
                columns={headerColumns}
                tableCaption={{
                  caption: 'A consolidated view of all student registrations',
                  srOnly: true,
                }}
                tbody={true}
              />
            </div>
          </CSSTransition>
        ) : (
          <p>There are no exam orders available.</p>
        )}

        {showSubmitOrderButton ? (
          <div className="row" style={{ marginTop: 40 }}>
            <div className="col-md-offset-8 col-md-4">
              <div className="row">
                <div className="col-xs-offset-6 col-xs-6">
                  <SubmitButton />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default connect(mapStateToProps, { openModal })(ExamTabs)
