import { Transition } from 'react-transition-group'

export default ({ inProp, durationIn=150, durationOut=1000, message='Complete', children, overrideDefaultStyle={}, overrideTransitionStyles={}, onExited=null }) => {
  const defaultStyle = children ? {} : {
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(58, 145, 63)',
    padding: '4px 6px',
    borderRadius: '3px',
    opacity: '0.01'
  }
  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1, transition: `opacity ${durationIn}ms ease-in` },
    exiting: { opacity: 1 },
    exited: { opacity: 0, transition: `opacity ${durationOut}ms ease-in` }
  }
  const onExitedProp = onExited ? { onExited } : {}
  return (
    <Transition in={inProp} timeout={{ enter: durationIn, exit: durationOut }} {...onExitedProp}>
      {(state) => (
        <div style={{
          ...defaultStyle,
          ...overrideDefaultStyle,
          ...transitionStyles[state],
          ...overrideTransitionStyles[state]}}
          aria-live="polite"
          aria-atomic="true"
        >
          {children || message}
        </div>
      )}
    </Transition>
  )
}