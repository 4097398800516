import { useHistory } from 'react-router-dom'
import { submit, getFormError } from 'redux-form'
import { Modal } from '../../components/common'
import { INCIDENT_REPORT_FORM_NAME } from '../../constants/IncidentReportConstants'
import { isEmpty } from '../../utils/common'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = state => {
  const {
    incidentReport: { submitting, submitted },
  } = state
  return {
    submitting,
    submitted,
    formSubmitErrors: getFormError(INCIDENT_REPORT_FORM_NAME)(state),
  }
}

const ConfirmIncidentReportModal = ({
  submitting,
  submitted,
  submit,
  formSubmitErrors,
  onCloseAction,
}) => {
  const history = useHistory()
  useEffect(() => {
    return () => {
      if (submitted) {
        history.push(NAV_LINKS.students)
      }
    }
  }, [submitted])

  const closeButton = {
    buttonLabel: submitted ? 'Close' : 'Cancel',
    isDismissable: true,
    isPrimary: !!submitted,
    isDisabled: submitting,
  }

  const submitButton = {
    buttonLabel: 'Submit',
    onClick: () => submit(INCIDENT_REPORT_FORM_NAME),
    isDismissable: false,
    isPrimary: true,
    busy: submitting,
    isDisabled: submitting,
  }
  return (
    <Modal
      headerTitle={submitted ? 'Incident Report Submitted' : 'Confirm Submission'}
      footerActions={submitted ? [closeButton] : [closeButton, submitButton]}
      onCloseAction={() =>
        submitted && isEmpty(formSubmitErrors) && onCloseAction && onCloseAction()
      }
      shouldCloseModal={!isEmpty(formSubmitErrors)}
    >
      {submitted ? (
        <p>
          The Incident Report has been submitted. You can view and print the report from Incident
          Report Submission History page.
        </p>
      ) : (
        <p>Once an Incident Report has been submitted, you will not be able to make any changes.</p>
      )}
    </Modal>
  )
}

export default connect(mapStateToProps, {
  submit,
})(ConfirmIncidentReportModal)
