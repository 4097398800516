import CoordinatorLink from './CoordinatorLink'

const DAS_PORTAL_URL = 'https://apaudio.ets.org/'

const mapStateToProps = state => {
  const {
    user: {
      data: { isCoordinator, isCSR },
    },
    status: {
      data: { examRegistrations },
    },
  } = state

  return {
    canAccess: isCoordinator && !isCSR,
    showLink: examRegistrations?.hasDasExams,
  }
}

const DasPortalLink = ({ canAccess, showLink, description }) =>
  showLink ? (
    <CoordinatorLink
      linkName="DAS Portal"
      linkUrl={DAS_PORTAL_URL}
      disabled={!canAccess}
      description={description}
    />
  ) : null

export default connect(mapStateToProps)(DasPortalLink)
