import { Modal, Error } from '../../components/common'
import { expireAllJoinCodes, fetchExpireJoinCodesStatus } from '../../actions/section'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedRole, roles },
    },
    expireJoinCodes: { expiring, expired, expireError },
  } = state
  const { orgName, orgId } = roles[selectedRole]

  return {
    orgName,
    orgId,
    expiring,
    expired,
    expireError,
  }
}

const ConfirmExpireAllJoinCodesModal = ({
  orgName,
  orgId,
  expiring,
  expired,
  expireError,
  modalCloseFocusElem,
  expireAllJoinCodes,
  fetchExpireJoinCodesStatus,
}) => {
  const footerActions = () => {
    if (expired) return [{ buttonLabel: 'Close', isDismissable: true, isPrimary: true }]

    return [
      { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
      {
        buttonLabel: 'Continue',
        isPrimary: true,
        onClick: () => expireAllJoinCodes(orgId),
        isDisabled: expiring,
        busy: expiring,
      },
    ]
  }

  return (
    <div>
      <Modal
        modalCloseFocusElem={modalCloseFocusElem}
        headerTitle="Confirm Expire All Join Codes"
        footerActions={footerActions()}
        onCloseAction={() => fetchExpireJoinCodesStatus(orgId)}
      >
        <div>
          {expired ? (
            <p>
              Existing join codes will be expired and new join codes will be created for all class
              sections at {orgName}.
            </p>
          ) : (
            <p>
              Are you sure you want to deactivate all join codes for {orgName} and generate new
              ones?
            </p>
          )}
          {expireError ? <Error message={expireError} /> : null}
        </div>
      </Modal>
    </div>
  )
}

export default connect(
  mapStateToProps,
  { expireAllJoinCodes, fetchExpireJoinCodesStatus }
)(ConfirmExpireAllJoinCodesModal)
