import { isDateWithinRange } from '@myap/ui-library/esm/date'
import { getExamFormats } from './examWindows'
import {
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
  INCIDENT_REPORT_PAPER_FIELD_NAMES as PAPER_FIELDS,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES as DIGITAL_FIELDS,
} from '../constants/IncidentReportConstants'
import { PARTICIPATING } from '../constants/SettingsConstants'
import { isEmpty } from '../utils/common'
import { isFeatureDisabled } from './features'

export const getIncidentReportAvailability = state => {
  const {
    user: {
      data: { isLevelOne, isPreAPOnly, selectedOrgId },
    },
    settingsOrg,
    settingsEducationPeriod: { availableEducationPeriods, current },
    settingsDeadlines: { data: deadlines = {} },
  } = state
  const numberEnrollments = state?.status?.data?.studentRegistrations?.numberEnrollments
  const settingsOrgData = settingsOrg?.[selectedOrgId]?.data ?? {}
  const orderedByAnotherSchool = settingsOrgData?.examAdministration?.orderByAnotherSchool ?? false
  const isParticipating = settingsOrgData?.participationStatus === PARTICIPATING ?? false
  const canSchoolOrder = isParticipating && !orderedByAnotherSchool
  const { incidentReportActiveDate } = deadlines?.[current] ?? {}
  const academicYrEndDate = new Date(availableEducationPeriods?.[current]?.academicYrEndDate)

  const visible =
    !isPreAPOnly &&
    !isLevelOne &&
    canSchoolOrder &&
    (isFeatureDisabled(state, 'STATUS_PANEL') || numberEnrollments > 0)

  const active = isDateWithinRange(new Date(), incidentReportActiveDate, academicYrEndDate) ?? false

  return { visible, active }
}

export const initializeIncidentReportData = ({ data, allExamWindows, coordinatorInfo }) => {
  const { firstName, lastName, telephone, email } = coordinatorInfo
  const selectedCourse = !isEmpty(data?.exam?.[SHARED_FIELDS.testCode.name])
    ? parseInt(data?.exam?.[SHARED_FIELDS.testCode.name], 10)
    : undefined
  const selectedAdminWindow = !isEmpty(data?.exam?.[SHARED_FIELDS.examDate.name])
    ? data?.exam?.[SHARED_FIELDS.examDate.name]
    : undefined
  const { paper, hybrid, digital, noEndOfCourseExam } = getExamFormats({
    allExamWindows,
    testCd: selectedCourse,
    adminWindow: selectedAdminWindow,
  })
  // Process provided data object into a flat object based on examFormat and noEndOfCourseExam,
  // otherwise, return default values for any fields not populated by provided data.
  const processedData = {
    [SHARED_FIELDS.firstName.name]: data?.coordinator?.[SHARED_FIELDS.firstName.name] ?? firstName,
    [SHARED_FIELDS.lastName.name]: data?.coordinator?.[SHARED_FIELDS.lastName.name] ?? lastName,
    [SHARED_FIELDS.phone.name]: data?.coordinator?.[SHARED_FIELDS.phone.name] ?? telephone,
    [SHARED_FIELDS.email.name]: data?.coordinator?.[SHARED_FIELDS.email.name] ?? email,
    [SHARED_FIELDS.section.name]:
      data?.exam?.[SHARED_FIELDS.section.name] ?? SHARED_FIELDS.section.default,
    [SHARED_FIELDS.testCode.name]:
      data?.exam?.[SHARED_FIELDS.testCode.name] ?? SHARED_FIELDS.testCode.default,
    [SHARED_FIELDS.examDate.name]:
      data?.exam?.[SHARED_FIELDS.examDate.name] ?? SHARED_FIELDS.examDate.default,
    [SHARED_FIELDS.examForm.name]:
      data?.exam?.[SHARED_FIELDS.examForm.name] ?? SHARED_FIELDS.examForm.default,
    [SHARED_FIELDS.formCode.name]:
      data?.exam?.[SHARED_FIELDS.formCode.name] ?? SHARED_FIELDS.formCode.default,
    [SHARED_FIELDS.interruptionMinutesInd.name]:
      !isEmpty(data?.incidentType?.[SHARED_FIELDS.interruptionMinutes.name]) ??
      SHARED_FIELDS.interruptionMinutesInd.default,
    [SHARED_FIELDS.interruptionMinutes.name]:
      data?.incidentType?.[SHARED_FIELDS.interruptionMinutes.name] ??
      SHARED_FIELDS.interruptionMinutes.default,
    [SHARED_FIELDS.disturbanceMinutesInd.name]:
      !isEmpty(data?.incidentType?.[SHARED_FIELDS.disturbanceMinutes.name]) ??
      SHARED_FIELDS.disturbanceMinutesInd.default,
    [SHARED_FIELDS.disturbanceMinutes.name]:
      data?.incidentType?.[SHARED_FIELDS.disturbanceMinutes.name] ??
      SHARED_FIELDS.disturbanceMinutes.default,
    [SHARED_FIELDS.illnessInd.name]:
      data?.incidentType?.[SHARED_FIELDS.illnessInd.name] ?? SHARED_FIELDS.illnessInd.default,
    [SHARED_FIELDS.misconductInd.name]:
      data?.incidentType?.misconduct?.[SHARED_FIELDS.misconductInd.name] ??
      SHARED_FIELDS.misconductInd.default,
    [SHARED_FIELDS.studentDismissedInd.name]:
      data?.incidentType?.misconduct?.[SHARED_FIELDS.studentDismissedInd.name] ??
      SHARED_FIELDS.studentDismissedInd.default,
    [SHARED_FIELDS.usedProhibitedDeviceInd.name]:
      data?.incidentType?.misconduct?.type?.[SHARED_FIELDS.usedProhibitedDeviceInd.name] ??
      SHARED_FIELDS.usedProhibitedDeviceInd.default,
    [SHARED_FIELDS.removeExamMaterialsInd.name]:
      data?.incidentType?.misconduct?.type?.[SHARED_FIELDS.removeExamMaterialsInd.name] ??
      SHARED_FIELDS.removeExamMaterialsInd.default,
    [SHARED_FIELDS.otherInd.name]:
      data?.incidentType?.[SHARED_FIELDS.otherInd.name] ?? SHARED_FIELDS.otherInd.default,
    [SHARED_FIELDS.lateTestingRequiredInd.name]:
      data?.[SHARED_FIELDS.lateTestingRequiredInd.name] ??
      SHARED_FIELDS.lateTestingRequiredInd.default,
    [SHARED_FIELDS.altExamOrderedInd.name]:
      data?.[SHARED_FIELDS.altExamOrderedInd.name] ?? SHARED_FIELDS.altExamOrderedInd.default,
    [SHARED_FIELDS.fullTestCenterImpactedInd.name]:
      data?.[SHARED_FIELDS.fullTestCenterImpactedInd.name] ??
      SHARED_FIELDS.fullTestCenterImpactedInd.default,
    [SHARED_FIELDS.studentFirstName.name]: SHARED_FIELDS.studentFirstName.default,
    [SHARED_FIELDS.studentLastName.name]: SHARED_FIELDS.studentLastName.default,
    [SHARED_FIELDS.studentApId.name]: SHARED_FIELDS.studentApId.default,
    [SHARED_FIELDS.students.name]:
      data?.[SHARED_FIELDS.students.name] ?? SHARED_FIELDS.students.default,
    [SHARED_FIELDS.incidentDetail.name]:
      data?.[SHARED_FIELDS.incidentDetail.name] ?? SHARED_FIELDS.incidentDetail.default,
    ...(paper || noEndOfCourseExam
      ? {
          [PAPER_FIELDS.defectiveMatsInd.name]:
            (data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.multipleChoiceInd.name] ||
              data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.freeResponseInd.name] ||
              data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.orangeBookletInd.name] ||
              !isEmpty(
                data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.masterAudioCDs.name]
              )) ??
            PAPER_FIELDS.defectiveMatsInd.default,
          [PAPER_FIELDS.multipleChoiceInd.name]:
            data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.multipleChoiceInd.name] ??
            PAPER_FIELDS.multipleChoiceInd.default,
          [PAPER_FIELDS.freeResponseInd.name]:
            data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.freeResponseInd.name] ??
            PAPER_FIELDS.freeResponseInd.default,
          [PAPER_FIELDS.orangeBookletInd.name]:
            data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.orangeBookletInd.name] ??
            PAPER_FIELDS.orangeBookletInd.default,
          [PAPER_FIELDS.masterAudioCDsInd.name]:
            !isEmpty(data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.masterAudioCDs.name]) ??
            PAPER_FIELDS.masterAudioCDsInd.default,
          [PAPER_FIELDS.masterAudioCDs.name]:
            data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.masterAudioCDs.name] ??
            PAPER_FIELDS.masterAudioCDs.default,
          [PAPER_FIELDS.equipProbsInd.name]:
            data?.incidentType?.[PAPER_FIELDS.equipProbsInd.name] ??
            PAPER_FIELDS.equipProbsInd.default,
          [PAPER_FIELDS.earlyOpeningSectionInd.name]:
            data?.incidentType?.[PAPER_FIELDS.earlyOpeningSectionInd.name] ??
            PAPER_FIELDS.earlyOpeningSectionInd.default,
          [PAPER_FIELDS.mixUpFreeResponseBooksInd.name]:
            data?.incidentType?.[PAPER_FIELDS.mixUpFreeResponseBooksInd.name] ??
            PAPER_FIELDS.mixUpFreeResponseBooksInd.default,
          [PAPER_FIELDS.wrngColorInkInd.name]:
            data?.incidentType?.[PAPER_FIELDS.wrngColorInkInd.name] ??
            PAPER_FIELDS.wrngColorInkInd.default,
          [PAPER_FIELDS.misplacedAnswersInd.name]:
            data?.incidentType?.[PAPER_FIELDS.misplacedAnswersInd.name] ??
            PAPER_FIELDS.misplacedAnswersInd.default,
          [PAPER_FIELDS.missingExamMaterialsInd.name]:
            data?.incidentType?.[PAPER_FIELDS.missingExamMaterialsInd.name] ??
            PAPER_FIELDS.missingExamMaterialsInd.default,
          [PAPER_FIELDS.overtimingMinutesInd.name]:
            !isEmpty(data?.incidentType?.[PAPER_FIELDS.overtimingMinutes.name]) ??
            PAPER_FIELDS.overtimingMinutesInd.default,
          [PAPER_FIELDS.overtimingMinutes.name]:
            data?.incidentType?.[PAPER_FIELDS.overtimingMinutes.name] ??
            PAPER_FIELDS.overtimingMinutes.default,
          [PAPER_FIELDS.undertimingMinutesInd.name]:
            !isEmpty(data?.incidentType?.[PAPER_FIELDS.undertimingMinutes.name]) ??
            PAPER_FIELDS.undertimingMinutesInd.default,
          [PAPER_FIELDS.undertimingMinutes.name]:
            data?.incidentType?.[PAPER_FIELDS.undertimingMinutes.name] ??
            PAPER_FIELDS.undertimingMinutes.default,
          [PAPER_FIELDS.usedInkOnAnswerSheetInd.name]:
            data?.incidentType?.[PAPER_FIELDS.usedInkOnAnswerSheetInd.name] ??
            PAPER_FIELDS.usedInkOnAnswerSheetInd.default,
          [PAPER_FIELDS.usedExtraPaperInd.name]:
            data?.incidentType?.[PAPER_FIELDS.usedExtraPaperInd.name] ??
            PAPER_FIELDS.usedExtraPaperInd.default,
          [PAPER_FIELDS.sharedSameAPIDInd.name]:
            data?.incidentType?.[PAPER_FIELDS.sharedSameAPIDInd.name] ??
            PAPER_FIELDS.sharedSameAPIDInd.default,
          [PAPER_FIELDS.testingOffScheduleInd.name]:
            data?.incidentType?.[PAPER_FIELDS.testingOffScheduleInd.name] ??
            PAPER_FIELDS.testingOffScheduleInd.default,
        }
      : hybrid
      ? {
          [PAPER_FIELDS.defectiveMatsInd.name]:
            (data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.multipleChoiceInd.name] ||
              data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.freeResponseInd.name] ||
              data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.orangeBookletInd.name] ||
              !isEmpty(
                data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.masterAudioCDs.name]
              )) ??
            PAPER_FIELDS.defectiveMatsInd.default,
          [PAPER_FIELDS.multipleChoiceInd.name]:
            data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.multipleChoiceInd.name] ??
            PAPER_FIELDS.multipleChoiceInd.default,
          [PAPER_FIELDS.freeResponseInd.name]:
            data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.freeResponseInd.name] ??
            PAPER_FIELDS.freeResponseInd.default,
          [PAPER_FIELDS.orangeBookletInd.name]:
            data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.orangeBookletInd.name] ??
            PAPER_FIELDS.orangeBookletInd.default,
          [PAPER_FIELDS.masterAudioCDsInd.name]:
            !isEmpty(data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.masterAudioCDs.name]) ??
            PAPER_FIELDS.masterAudioCDsInd.default,
          [PAPER_FIELDS.masterAudioCDs.name]:
            data?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.masterAudioCDs.name] ??
            PAPER_FIELDS.masterAudioCDs.default,
          [PAPER_FIELDS.earlyOpeningSectionInd.name]:
            data?.incidentType?.[PAPER_FIELDS.earlyOpeningSectionInd.name] ??
            PAPER_FIELDS.earlyOpeningSectionInd.default,
          [PAPER_FIELDS.mixUpFreeResponseBooksInd.name]:
            data?.incidentType?.[PAPER_FIELDS.mixUpFreeResponseBooksInd.name] ??
            PAPER_FIELDS.mixUpFreeResponseBooksInd.default,
          [PAPER_FIELDS.sharedSameAPIDInd.name]:
            data?.incidentType?.[PAPER_FIELDS.sharedSameAPIDInd.name] ??
            PAPER_FIELDS.sharedSameAPIDInd.default,
          [PAPER_FIELDS.wrngColorInkInd.name]:
            data?.incidentType?.[PAPER_FIELDS.wrngColorInkInd.name] ??
            PAPER_FIELDS.wrngColorInkInd.default,
          [PAPER_FIELDS.usedExtraPaperInd.name]:
            data?.incidentType?.[PAPER_FIELDS.usedExtraPaperInd.name] ??
            PAPER_FIELDS.usedExtraPaperInd.default,
          [PAPER_FIELDS.misplacedAnswersInd.name]:
            data?.incidentType?.[PAPER_FIELDS.misplacedAnswersInd.name] ??
            PAPER_FIELDS.misplacedAnswersInd.default,
          [PAPER_FIELDS.missingExamMaterialsInd.name]:
            data?.incidentType?.[PAPER_FIELDS.missingExamMaterialsInd.name] ??
            PAPER_FIELDS.missingExamMaterialsInd.default,
          [DIGITAL_FIELDS.batteryDiedInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.batteryDiedInd.name] ??
            DIGITAL_FIELDS.batteryDiedInd.default,
          [DIGITAL_FIELDS.connectionDroppedInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.connectionDroppedInd.name] ??
            DIGITAL_FIELDS.connectionDroppedInd.default,
          [DIGITAL_FIELDS.deviceFrozeOrCrashInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.deviceFrozeOrCrashInd.name] ??
            DIGITAL_FIELDS.deviceFrozeOrCrashInd.default,
          [DIGITAL_FIELDS.deviceDidNotMeetReqInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.deviceDidNotMeetReqInd.name] ??
            DIGITAL_FIELDS.deviceDidNotMeetReqInd.default,
          [DIGITAL_FIELDS.loanerDeviceIssueInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.loanerDeviceIssueInd.name] ??
            DIGITAL_FIELDS.loanerDeviceIssueInd.default,
          [DIGITAL_FIELDS.anotherTestAppInstalledInd.name]:
            data?.incidentType?.studentTechIssue?.[
              DIGITAL_FIELDS.anotherTestAppInstalledInd.name
            ] ?? DIGITAL_FIELDS.anotherTestAppInstalledInd.default,
          [DIGITAL_FIELDS.blueBookNotInstalledInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.blueBookNotInstalledInd.name] ??
            DIGITAL_FIELDS.blueBookNotInstalledInd.default,
          [DIGITAL_FIELDS.examSetUpNotCompletedInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.examSetUpNotCompletedInd.name] ??
            DIGITAL_FIELDS.examSetUpNotCompletedInd.default,
          [DIGITAL_FIELDS.noAdmissionTicketInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.noAdmissionTicketInd.name] ??
            DIGITAL_FIELDS.noAdmissionTicketInd.default,
          [DIGITAL_FIELDS.studentTechIssueOther.name]:
            data?.incidentType?.studentTechIssue?.otherInd ??
            DIGITAL_FIELDS.studentTechIssueOther.default,
          [DIGITAL_FIELDS.couldNotOpenInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.couldNotOpenInd.name] ??
            DIGITAL_FIELDS.couldNotOpenInd.default,
          [DIGITAL_FIELDS.couldNotSignInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.couldNotSignInd.name] ??
            DIGITAL_FIELDS.couldNotSignInd.default,
          [DIGITAL_FIELDS.testNotAvailableInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.testNotAvailableInd.name] ??
            DIGITAL_FIELDS.testNotAvailableInd.default,
          [DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name]:
            data?.incidentType?.blueBookProblem?.[
              DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name
            ] ?? DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.default,
          [DIGITAL_FIELDS.inAccurateTimingInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.inAccurateTimingInd.name] ??
            DIGITAL_FIELDS.inAccurateTimingInd.default,
          [DIGITAL_FIELDS.contentDisplayIssueInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.contentDisplayIssueInd.name] ??
            DIGITAL_FIELDS.contentDisplayIssueInd.default,
          [DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name]:
            data?.incidentType?.blueBookProblem?.[
              DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name
            ] ?? DIGITAL_FIELDS.calculatorClockOrToolIssueInd.default,
          [DIGITAL_FIELDS.answerNotSubmittedInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.answerNotSubmittedInd.name] ??
            DIGITAL_FIELDS.answerNotSubmittedInd.default,
          [DIGITAL_FIELDS.blueBookProblemOther.name]:
            data?.incidentType?.blueBookProblem?.otherInd ??
            DIGITAL_FIELDS.blueBookProblemOther.default,
          [DIGITAL_FIELDS.staffBatteryDiedInd.name]:
            data?.incidentType?.staffTechIssue?.[DIGITAL_FIELDS.staffBatteryDiedInd.propName] ??
            DIGITAL_FIELDS.staffBatteryDiedInd.default,
          [DIGITAL_FIELDS.staffConnectionDroppedInd.name]:
            data?.incidentType?.staffTechIssue?.[DIGITAL_FIELDS.staffConnectionDroppedInd.propName] ??
            DIGITAL_FIELDS.staffConnectionDroppedInd.default,
          [DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.name]:
            data?.incidentType?.staffTechIssue?.[DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.propName] ??
            DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.default,
          [DIGITAL_FIELDS.staffDeviceIssueInd.name]:
            data?.incidentType?.staffTechIssue?.[DIGITAL_FIELDS.staffDeviceIssueInd.propName] ??
            DIGITAL_FIELDS.staffDeviceIssueInd.default,
          [DIGITAL_FIELDS.staffTechIssueOther.name]:
            data?.incidentType?.staffTechIssue?.[DIGITAL_FIELDS.staffTechIssueOther.propName] ??
            DIGITAL_FIELDS.staffTechIssueOther.default,
          [DIGITAL_FIELDS.couldNotAccessTDTKInd.name]:
            data?.incidentType?.testDayToolKitProblem?.[
              DIGITAL_FIELDS.couldNotAccessTDTKInd.name
            ] ?? DIGITAL_FIELDS.couldNotAccessTDTKInd.default,
          [DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name]:
            data?.incidentType?.testDayToolKitProblem?.[
              DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name
            ] ?? DIGITAL_FIELDS.inAccurateStudentRosterDataInd.default,
          [DIGITAL_FIELDS.studentCheckInIssueInd.name]:
            data?.incidentType?.testDayToolKitProblem?.[
              DIGITAL_FIELDS.studentCheckInIssueInd.name
            ] ?? DIGITAL_FIELDS.studentCheckInIssueInd.default,
          [DIGITAL_FIELDS.inAccurateStudentStatusInd.name]:
            data?.incidentType?.testDayToolKitProblem?.[
              DIGITAL_FIELDS.inAccurateStudentStatusInd.name
            ] ?? DIGITAL_FIELDS.inAccurateStudentStatusInd.default,
          [DIGITAL_FIELDS.testDayToolKitProblemOther.name]:
            data?.incidentType?.testDayToolKitProblem?.otherInd ??
            DIGITAL_FIELDS.testDayToolKitProblemOther.default,
          [DIGITAL_FIELDS.networkOutageInd.name]:
            data?.incidentType?.facilityTechIssue?.[DIGITAL_FIELDS.networkOutageInd.name] ??
            DIGITAL_FIELDS.networkOutageInd.default,
          [DIGITAL_FIELDS.powerOutageInd.name]:
            data?.incidentType?.facilityTechIssue?.[DIGITAL_FIELDS.powerOutageInd.name] ??
            DIGITAL_FIELDS.powerOutageInd.default,
          [DIGITAL_FIELDS.facilityTechIssueOther.name]:
            data?.incidentType?.facilityTechIssue?.otherInd ??
            DIGITAL_FIELDS.facilityTechIssueOther.default,
        }
      : digital
      ? {
          [DIGITAL_FIELDS.batteryDiedInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.batteryDiedInd.name] ??
            DIGITAL_FIELDS.batteryDiedInd.default,
          [DIGITAL_FIELDS.connectionDroppedInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.connectionDroppedInd.name] ??
            DIGITAL_FIELDS.connectionDroppedInd.default,
          [DIGITAL_FIELDS.deviceFrozeOrCrashInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.deviceFrozeOrCrashInd.name] ??
            DIGITAL_FIELDS.deviceFrozeOrCrashInd.default,
          [DIGITAL_FIELDS.deviceDidNotMeetReqInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.deviceDidNotMeetReqInd.name] ??
            DIGITAL_FIELDS.deviceDidNotMeetReqInd.default,
          [DIGITAL_FIELDS.loanerDeviceIssueInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.loanerDeviceIssueInd.name] ??
            DIGITAL_FIELDS.loanerDeviceIssueInd.default,
          [DIGITAL_FIELDS.anotherTestAppInstalledInd.name]:
            data?.incidentType?.studentTechIssue?.[
              DIGITAL_FIELDS.anotherTestAppInstalledInd.name
            ] ?? DIGITAL_FIELDS.anotherTestAppInstalledInd.default,
          [DIGITAL_FIELDS.blueBookNotInstalledInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.blueBookNotInstalledInd.name] ??
            DIGITAL_FIELDS.blueBookNotInstalledInd.default,
          [DIGITAL_FIELDS.examSetUpNotCompletedInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.examSetUpNotCompletedInd.name] ??
            DIGITAL_FIELDS.examSetUpNotCompletedInd.default,
          [DIGITAL_FIELDS.noAdmissionTicketInd.name]:
            data?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.noAdmissionTicketInd.name] ??
            DIGITAL_FIELDS.noAdmissionTicketInd.default,
          [DIGITAL_FIELDS.studentTechIssueOther.name]:
            data?.incidentType?.studentTechIssue?.otherInd ??
            DIGITAL_FIELDS.studentTechIssueOther.default,
          [DIGITAL_FIELDS.couldNotOpenInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.couldNotOpenInd.name] ??
            DIGITAL_FIELDS.couldNotOpenInd.default,
          [DIGITAL_FIELDS.couldNotSignInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.couldNotSignInd.name] ??
            DIGITAL_FIELDS.couldNotSignInd.default,
          [DIGITAL_FIELDS.testNotAvailableInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.testNotAvailableInd.name] ??
            DIGITAL_FIELDS.testNotAvailableInd.default,
          [DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name]:
            data?.incidentType?.blueBookProblem?.[
              DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name
            ] ?? DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.default,
          [DIGITAL_FIELDS.inAccurateTimingInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.inAccurateTimingInd.name] ??
            DIGITAL_FIELDS.inAccurateTimingInd.default,
          [DIGITAL_FIELDS.contentDisplayIssueInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.contentDisplayIssueInd.name] ??
            DIGITAL_FIELDS.contentDisplayIssueInd.default,
          [DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name]:
            data?.incidentType?.blueBookProblem?.[
              DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name
            ] ?? DIGITAL_FIELDS.calculatorClockOrToolIssueInd.default,
          [DIGITAL_FIELDS.answerNotSubmittedInd.name]:
            data?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.answerNotSubmittedInd.name] ??
            DIGITAL_FIELDS.answerNotSubmittedInd.default,
          [DIGITAL_FIELDS.blueBookProblemOther.name]:
            data?.incidentType?.blueBookProblem?.otherInd ??
            DIGITAL_FIELDS.blueBookProblemOther.default,
          [DIGITAL_FIELDS.staffBatteryDiedInd.name]:
            data?.incidentType?.staffTechIssue?.[DIGITAL_FIELDS.staffBatteryDiedInd.propName] ??
            DIGITAL_FIELDS.staffBatteryDiedInd.default,
          [DIGITAL_FIELDS.staffConnectionDroppedInd.name]:
            data?.incidentType?.staffTechIssue?.[DIGITAL_FIELDS.staffConnectionDroppedInd.propName] ??
            DIGITAL_FIELDS.staffConnectionDroppedInd.default,
          [DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.name]:
            data?.incidentType?.staffTechIssue?.[DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.propName] ??
            DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.default,
          [DIGITAL_FIELDS.staffDeviceIssueInd.name]:
            data?.incidentType?.staffTechIssue?.[DIGITAL_FIELDS.staffDeviceIssueInd.propName] ??
            DIGITAL_FIELDS.staffDeviceIssueInd.default,
          [DIGITAL_FIELDS.staffTechIssueOther.name]:
            data?.incidentType?.staffTechIssue?.[DIGITAL_FIELDS.staffTechIssueOther.propName] ??
            DIGITAL_FIELDS.staffTechIssueOther.default,
          [DIGITAL_FIELDS.networkOutageInd.name]:
            data?.incidentType?.facilityTechIssue?.[DIGITAL_FIELDS.networkOutageInd.name] ??
            DIGITAL_FIELDS.networkOutageInd.default,
          [DIGITAL_FIELDS.powerOutageInd.name]:
            data?.incidentType?.facilityTechIssue?.[DIGITAL_FIELDS.powerOutageInd.name] ??
            DIGITAL_FIELDS.powerOutageInd.default,
          [DIGITAL_FIELDS.facilityTechIssueOther.name]:
            data?.incidentType?.facilityTechIssue?.otherInd ??
            DIGITAL_FIELDS.facilityTechIssueOther.default,
          [DIGITAL_FIELDS.couldNotAccessTDTKInd.name]:
            data?.incidentType?.testDayToolKitProblem?.[
              DIGITAL_FIELDS.couldNotAccessTDTKInd.name
            ] ?? DIGITAL_FIELDS.couldNotAccessTDTKInd.default,
          [DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name]:
            data?.incidentType?.testDayToolKitProblem?.[
              DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name
            ] ?? DIGITAL_FIELDS.inAccurateStudentRosterDataInd.default,
          [DIGITAL_FIELDS.studentCheckInIssueInd.name]:
            data?.incidentType?.testDayToolKitProblem?.[
              DIGITAL_FIELDS.studentCheckInIssueInd.name
            ] ?? DIGITAL_FIELDS.studentCheckInIssueInd.default,
          [DIGITAL_FIELDS.inAccurateStudentStatusInd.name]:
            data?.incidentType?.testDayToolKitProblem?.[
              DIGITAL_FIELDS.inAccurateStudentStatusInd.name
            ] ?? DIGITAL_FIELDS.inAccurateStudentStatusInd.default,
          [DIGITAL_FIELDS.testDayToolKitProblemOther.name]:
            data?.incidentType?.testDayToolKitProblem?.otherInd ??
            DIGITAL_FIELDS.testDayToolKitProblemOther.default,
        }
      : {}),
    isDraft: false, // Always initialize isDraft to false as this prop only matters when saving
  }

  return processedData
}
