import * as c from '../constants/AppConstants'

const initialState = {
	modal: { name: null , props: null, transitioning: false },
	breakpoint: { name: null, prefix: null, width: null },
	tab: null,
	error: null,
	fetchActionsOnChange: [], // array of objects: { name: 'arbitrary unique identifier', action: <action creator to be executed> }
}

const modal = (state={}, { type, name, props }) => {
	switch(type) {
		case c.MODAL_OPEN:
			return { ...state, name, props }
		case c.MODAL_START_CLOSE:
			return {...state, transitioning: true}
		case c.MODAL_FINISH_CLOSE:
			return {...state, name, transitioning: false}
		default:
			return state
	}
}

export default (state=initialState, actions) => {
	switch(actions.type) {
		case c.MODAL_OPEN:
		case c.MODAL_START_CLOSE:
		case c.MODAL_FINISH_CLOSE:
			return { ...state, modal: modal(state.modal, actions) }
		case c.GENERIC_APP_ERROR:
			return { ...state, error: actions.payload.message}
		case c.BREAKPOINT_CHANGE:
			return { ...state, breakpoint: {...actions.payload}}
		case c.ADD_FETCH_ACTION:
			return { ...state, fetchActionsOnChange: [ ...state.fetchActionsOnChange, actions.payload ]}
		case c.REMOVE_FETCH_ACTION:
			const filteredActions = state.fetchActionsOnChange.filter(action => action.name !== actions.payload)
			return { ...state, fetchActionsOnChange: [ ...filteredActions ] }
		default:
			return state
	}
}
