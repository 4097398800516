export const FETCH_ORG_STUDENTS_PENDING = 'FETCH_ORG_STUDENTS_PENDING'
export const FETCH_ORG_STUDENTS_FULFILLED = 'FETCH_ORG_STUDENTS_FULFILLED'
export const FETCH_ORG_STUDENTS_REJECTED = 'FETCH_ORG_STUDENTS_REJECTED'

export const FETCH_STUDENT_DETAILS_PENDING = 'FETCH_STUDENT_DETAILS_PENDING'
export const FETCH_STUDENT_DETAILS_FULFILLED = 'FETCH_STUDENT_DETAILS_FULFILLED'
export const FETCH_STUDENT_DETAILS_REJECTED = 'FETCH_STUDENT_DETAILS_REJECTED'

export const RESET_STUDENT_DATA = 'RESET_STUDENT_DATA'

export const UPDATE_ORG_STUDENT_PENDING = 'UPDATE_ORG_STUDENT_PENDING'
export const UPDATE_ORG_STUDENT_REJECTED = 'UPDATE_ORG_STUDENT_REJECTED'

export const UPDATE_STUDENT_EXAM_INTENT_FULFILLED = 'UPDATE_STUDENT_EXAM_INTENT_FULFILLED'
export const UPDATE_STUDENT_EXAM_INTENT_UNUSED_FULFILLED =
  'UPDATE_STUDENT_EXAM_INTENT_UNUSED_FULFILLED'
export const UPDATE_STUDENT_EXAM_TESTWINDOW_FULFILLED = 'UPDATE_STUDENT_EXAM_TESTWINDOW_FULFILLED'
export const UPDATE_STUDENT_EXAM_TESTWINDOW_INTENT_FULFILLED =
  'UPDATE_STUDENT_EXAM_TESTWINDOW_INTENT_FULFILLED'
export const UPDATE_STUDENT_EXAM_SSDMATERIALS_FULFILLED =
  'UPDATE_STUDENT_EXAM_SSDMATERIALS_FULFILLED'
export const UPDATE_STUDENT_EXAM_DIGITAL_ACCOMMODATIONS_FULFILLED =
  'UPDATE_STUDENT_EXAM_DIGITAL_ACCOMMODATIONS_FULFILLED'
export const UPDATE_STUDENT_FEESTATUS_FULFILLED = 'UPDATE_STUDENT_FEESTATUS_FULFILLED'
export const UPDATE_STUDENT_ENROLLMENT_SUBSIDIES_FULFILLED =
  'UPDATE_STUDENT_ENROLLMENT_SUBSIDIES_FULFILLED'
export const TRANSFER_OUT_STUDENT_FULFILLED = 'TRANSFER_OUT_STUDENT_FULFILLED'
export const TRANSFER_OUT_STUDENT_WITHPENDING_FULFILLED =
  'TRANSFER_OUT_STUDENT_WITHPENDING_FULFILLED'
export const CHANGE_STUDENT_SECTION_FULFILLED = 'CHANGE_STUDENT_SECTION_FULFILLED'
export const DROP_STUDENT_ENROLLMENT_FULFILLED = 'DROP_STUDENT_ENROLLMENT_FULFILLED'
export const DROP_STUDENT_FULFILLED = 'DROP_STUDENT_FULFILLED'
export const CHANGE_TESTING_WITH_ACCOMMODATIONS_FULFILLED =
  'CHANGE_TESTING_WITH_ACCOMMODATIONS_FULFILLED'
export const UPDATE_STUDENT_REGISTRATION_SUBSIDY_FULFILLED =
  'UPDATE_STUDENT_REGISTRATION_SUBSIDY_FULFILLED'

// type of student update, used in reducer
export const UPDATE_TYPE_FEE = 'fee'
export const UPDATE_TYPE_DATE = 'date'
export const UPDATE_TYPE_INTENT = 'intent'
export const UPDATE_TYPE_DATE_INTENT = 'date-intent'
export const UPDATE_TYPE_UNUSED = 'unused'
export const UPDATE_TYPE_CHANGE = 'change'
export const UPDATE_TYPE_DROP = 'drop'
export const UPDATE_TYPE_TRANSFER = 'transfer'
export const UPDATE_TYPE_SSD = 'ssd'
export const UPDATE_TYPE_DIGITAL_ACCOMMODATIONS = 'digacc'
export const UPDATE_TYPE_ENROLLMENT_SUBSIDIES = 'enrollment-subsidies'
export const UPDATE_TYPE_TESTED_WITH_ACCOM_IND = 'tested-with-accom'
export const UPDATE_TYPE_REGISTRATION_SUBSIDY = 'update-registration-subsidy'

export const RESET_UPDATE_TYPE = 'RESET_UPDATE_TYPE'

export const FETCH_ALLOWED_SSD_MATERIALS_PENDING = 'FETCH_ALLOWED_SSD_MATERIALS_PENDING'
export const FETCH_ALLOWED_SSD_MATERIALS_FULFILLED = 'FETCH_ALLOWED_SSD_MATERIALS_FULFILLED'
export const FETCH_ALLOWED_SSD_MATERIALS_REJECTED = 'FETCH_ALLOWED_SSD_MATERIALS_REJECTED'

export const FETCH_STUDENTS_BYSECTION_PENDING = 'FETCH_STUDENTS_BYSECTION_PENDING'
export const FETCH_STUDENTS_BYSECTION_FULFILLED = 'FETCH_STUDENTS_BYSECTION_FULFILLED'
export const FETCH_STUDENTS_BYSECTION_REJECTED = 'FETCH_STUDENTS_BYSECTION_REJECTED'

export const UPDATE_SECTION_STUDENT_PENDING = 'UPDATE_SECTION_STUDENT_PENDING'
export const UPDATE_SECTION_STUDENT_REJECTED = 'UPDATE_SECTION_STUDENT_REJECTED'

export const UPDATE_SECTION_STUDENT_EXAM_FULFILLED = 'UPDATE_SECTION_STUDENT_EXAM_FULFILLED'
export const DROP_SECTION_STUDENT_FULFILLED = 'DROP_SECTION_STUDENT_FULFILLED'
export const MOVE_SECTION_STUDENT_FULFILLED = 'MOVE_SECTION_STUDENT_FULFILLED'

export const FETCH_SSD_APPROVAL_STATUS_PENDING = 'FETCH_SSD_APPROVAL_STATUS_PENDING'
export const FETCH_SSD_APPROVAL_STATUS_FULFILLED = 'FETCH_SSD_APPROVAL_STATUS_FULFILLED'
export const FETCH_SSD_APPROVAL_STATUS_REJECTED = 'FETCH_SSD_APPROVAL_STATUS_REJECTED'

export const FETCH_SSD_APPROVAL_HISTORY_PENDING = 'FETCH_SSD_APPROVAL_HISTORY_PENDING'
export const FETCH_SSD_APPROVAL_HISTORY_FULFILLED = 'FETCH_SSD_APPROVAL_HISTORY_FULFILLED'
export const FETCH_SSD_APPROVAL_HISTORY_REJECTED = 'FETCH_SSD_APPROVAL_HISTORY_REJECTED'

export const FETCH_STUDENT_CHANGE_LOG_PENDING = 'FETCH_STUDENT_CHANGE_LOG_PENDING'
export const FETCH_STUDENT_CHANGE_LOG_FULFILLED = 'FETCH_STUDENT_CHANGE_LOG_FULFILLED'
export const FETCH_STUDENT_CHANGE_LOG_REJECTED = 'FETCH_STUDENT_CHANGE_LOG_REJECTED'

export const UPDATE_STUDENT_HAS_IMPACTING_CHANGES = 'UPDATE_STUDENT_HAS_IMPACTING_CHANGES'

export const TOGGLE_STUDENT_ACTION_MENU = 'TOGGLE_STUDENT_ACTION_MENU'

export const STUDENT_ENROLLMENT_STATUS_UNUSED = 'UNUSED'
export const STUDENT_ENROLLMENT_STATUS_UNDECIDED = 'UNDECIDED'
export const STUDENT_ENROLLMENT_STATUS_NO_OTHER = 'NO_OTHER'
export const STUDENT_ENROLLMENT_STATUS_YES = 'YES'
export const STUDENT_ENROLLMENT_STATUS_NO = 'NO'
export const STUDENT_ENROLLMENT_STATUS = {
  [STUDENT_ENROLLMENT_STATUS_YES]: 'Yes',
  [STUDENT_ENROLLMENT_STATUS_NO_OTHER]: 'No (other)',
  [STUDENT_ENROLLMENT_STATUS_NO]: 'No',
  [STUDENT_ENROLLMENT_STATUS_UNDECIDED]: 'Undecided',
  [STUDENT_ENROLLMENT_STATUS_UNUSED]: 'Unused',
}

export const STUDENT_CHANGELOG_STATUS_NEW = 'NEW'
export const STUDENT_CHANGELOG_STATUS_UPDATE = 'UPDATE'
export const STUDENT_CHANGELOG_STATUS_ACTIVE = 'ACTIVE'
export const STUDENT_CHANGELOG_STATUS_INACTIVE = 'INACTIVE'
export const STUDENT_CHANGELOG_STATUS_STANDARD = 'STANDARD'
export const STUDENT_CHANGELOG_STATUS_REDUCED = 'REDUCED'
export const STUDENT_CHANGELOG_STATUS = {
  [STUDENT_ENROLLMENT_STATUS_YES]: 'Yes',
  [STUDENT_ENROLLMENT_STATUS_NO_OTHER]: 'No (other)',
  [STUDENT_ENROLLMENT_STATUS_NO]: 'No',
  [STUDENT_ENROLLMENT_STATUS_UNDECIDED]: 'Undecided',
  [STUDENT_ENROLLMENT_STATUS_UNUSED]: 'Unused',
  [STUDENT_CHANGELOG_STATUS_NEW]: 'New',
  [STUDENT_CHANGELOG_STATUS_UPDATE]: 'Update',
  [STUDENT_CHANGELOG_STATUS_ACTIVE]: 'Active',
  [STUDENT_CHANGELOG_STATUS_INACTIVE]: 'Inactive',
  [STUDENT_CHANGELOG_STATUS_STANDARD]: 'Standard',
  [STUDENT_CHANGELOG_STATUS_REDUCED]: 'Reduced',
}

export const STANDARD_FEE_STATUS = 'STANDARD'
export const REDUCED_FEE_STATUS = 'REDUCED'
export const STUDENT_FEE_STATUS = {
  [STANDARD_FEE_STATUS]: 'Standard',
  [REDUCED_FEE_STATUS]: 'Reduced',
}

export const SELECT_SSD_MATERIALS_FORM = 'selectSSDMaterialsForm'

export const ALLOWED_SSD_MATERIALS = {
  fontSizes: 'fontSizes',
  answerSheets: 'answerSheets',
  readerCopys: 'readerCopys',
  printedScripts: 'printedScripts',
  multipleDayTestings: 'multipleDayTestings',
  otherAccommodations: 'otherAccommodations',
  notOrderedSSDMaterials: 'notOrderedSSDMaterials',
}

export const SSD_MATERIALS_FIELDS = {
  fontSize: { name: 'fontSize' }, // Used for referencing the property name, is not an actual field
  '14point': { name: 'fontSize', value: 1 },
  '20point': { name: 'fontSize', value: 3 },
  customFontSize: { name: 'fontSize', value: 2 },
  customFontSizeNumber: { name: 'customFontSize', value: '' },
  braille: { name: 'fontSize', value: 4 },
  assistiveTechnologyCompatible: { name: 'fontSize', value: 13 },
  largeBlockAnswerSheet: { name: 'largeBlockAnswerSheet', value: 5 },
  readerCopy: { name: 'readerCopy', value: 6 },
  printedScript: { name: 'printedScript', value: 7 },
  multipleDayTesting: { name: 'multipleDayTesting', value: 8 },
  limitedTimeTesting: { name: 'limitedTimeTesting', value: 9 },
  otherAccommodations: { name: 'otherAccommodations', value: 11 },
  ssdMaterialsNotOrdered: { name: 'ssdMaterialsNotOrdered', value: 12 },
}

export const SSD_MATERIALS_FORM_DEFAULTS = {
  specialFormatsCheck: false,
  readerMaterialCheck: false,
  testingTimeCheck: false,
  otherAccommodationsCheck: false,
  [SSD_MATERIALS_FIELDS.fontSize.name]: null,
  [SSD_MATERIALS_FIELDS.customFontSizeNumber.name]: '',
  [SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name]: false,
  [SSD_MATERIALS_FIELDS.readerCopy.name]: false,
  [SSD_MATERIALS_FIELDS.printedScript.name]: false,
  [SSD_MATERIALS_FIELDS.multipleDayTesting.name]: false,
  [SSD_MATERIALS_FIELDS.limitedTimeTesting.name]: false,
  [SSD_MATERIALS_FIELDS.otherAccommodations.name]: null,
  [SSD_MATERIALS_FIELDS.ssdMaterialsNotOrdered.name]: false,
}

export const FILTER_NOT_SUBMITTED = 'PENDING_SUBMISSION'
export const SSD_SUBMITTED = 'APPROVAL_PENDING'
export const SSD_UPDATED_NOT_SUBMITTED = 'PENDING_RESUBMISSION'
export const SSD_UPDATED = 'APPROVAL_PENDING'
export const SSD_CANCELLED_NOT_SUBMITTED = 'PENDING_RESUBMISSION'
export const SSD_CANCELLED_SUBMITTED = 'APPROVAL_NOT_REQUIRED'
export const SSD_APPROVED = 'APPROVED'
export const SSD_REQUIRES_INFO = 'REQUIRES_INFO'
export const SSD_DENIED = 'REJECTED'
export const SSD_APPROVAL_STATUS = {
  [FILTER_NOT_SUBMITTED]: 'Pending Submission',
  [SSD_SUBMITTED]: 'Pending Approval',
  [SSD_UPDATED_NOT_SUBMITTED]: 'Pending Resubmission',
  [SSD_UPDATED]: 'Pending Approval',
  [SSD_CANCELLED_NOT_SUBMITTED]: 'Pending Resubmission',
  [SSD_CANCELLED_SUBMITTED]: 'Approval Not Required',
  [SSD_APPROVED]: 'Approved',
  [SSD_REQUIRES_INFO]: 'Needs Info',
  [SSD_DENIED]: 'Denied',
}

export const SELECT_SPECIAL_DIGITAL_FORMATS_FORM = 'selectSpecialDigitalFormatsForm'

export const SDF_ACCESSIBILITY = 'accessibility'
export const SDF_EXTENDED_TIME = 'extendedTime'
export const SDF_BREAKS = 'extraBreak'
export const SDF_MULTIDAY = 'multiday'
export const SDF_LIMITED_TIME = 'limitedTime'
export const SDF_ADDITIONAL_ACCOMMODATIONS = 'additionalAccomms'
export const SDF_PAPER_BASED_EXAM = 'paperFormats'
export const SDF_FREE_RESPONSE_BOOKLET = 'freeResponseBlankBooklet'
export const PAPERFORMATS_PAPER = 'paper'
export const PAPERFORMATS_BRAILLE = 'braille'
export const PAPERFORMATS_CUSTOM_SIZE = '021'
export const PAPERFORMATS_14POINT = '022'
export const PAPERFORMATS_20POINT = '023'
export const PAPERFORMATS_LARGEPRINT_A = '024'
export const PAPERFORMATS_LARGEPRINT_B = '032'
export const PAPERFORMATS_WRITERSCRIBE = '034'
export const HUMAN_READER_PAPER = '025'
export const HUMAN_READER_DIGITAL = '204'
export const MULTIDAY_ONLY2DAY = 'only2Day'
export const MULTIDAY_OVER2DAY = 'over2Day'
export const MULTIDAY_MULTIDAY = 'multiday'

export const SDF_SORT_ORDER = [
  SDF_ACCESSIBILITY,
  SDF_ADDITIONAL_ACCOMMODATIONS,
  SDF_EXTENDED_TIME,
  SDF_BREAKS,
  SDF_LIMITED_TIME,
  SDF_FREE_RESPONSE_BOOKLET,
  SDF_MULTIDAY,
  SDF_PAPER_BASED_EXAM,
]

// It's important here that PAPER is the first element
export const PAPERFORMATS_SORT_ORDER = [
  PAPERFORMATS_PAPER,
  PAPERFORMATS_BRAILLE,
  PAPERFORMATS_WRITERSCRIBE,
  HUMAN_READER_PAPER,
  HUMAN_READER_DIGITAL,
  PAPERFORMATS_14POINT,
  PAPERFORMATS_20POINT,
  PAPERFORMATS_CUSTOM_SIZE,
  PAPERFORMATS_LARGEPRINT_A,
  PAPERFORMATS_LARGEPRINT_B,
]

export const MULTIDAY_SORT_ORDER = [
  MULTIDAY_ONLY2DAY,
  MULTIDAY_OVER2DAY,
  HUMAN_READER_PAPER,
  HUMAN_READER_DIGITAL,
  PAPERFORMATS_WRITERSCRIBE,
  PAPERFORMATS_14POINT,
  PAPERFORMATS_20POINT,
  PAPERFORMATS_CUSTOM_SIZE,
  PAPERFORMATS_LARGEPRINT_A,
  PAPERFORMATS_LARGEPRINT_B,
]

export const SEE_SUBMISSION_DEADLINE_MSG = 'See Submission Deadline'

export const STUDENT_DROP_OPTION_KEEP = 'keepExam'
export const STUDENT_DROP_OPTION_REMOVE = 'removeExam'
export const STUDENT_DROP_OPTION_UNUSED = 'markUnused'
export const STUDENT_DROP_OPTIONS = {
  [STUDENT_DROP_OPTION_KEEP]: 'Drop student and keep exam',
  [STUDENT_DROP_OPTION_REMOVE]: 'Drop student and remove exam',
  [STUDENT_DROP_OPTION_UNUSED]: 'Drop student and mark exam unused',
}

export const TYPE_ORDER_ENROLLMENT = 'ORDER_EXAM'
export const TYPE_ORDER_SUBMISSION = 'ORDER_SUBMISSION'
export const TYPE_ENROLLMENT = 'ENROLLMENT'
export const TYPE_SUBSIDY_EXCLUSION = 'SUBSIDY_EXCLUSION'
export const TYPE_FEE_STATUS = 'FEE_STATUS'
export const TYPE_EXAM_REFUND = 'EXAM_REFUND'

export const STUDENT_CHANGE_LOG_TYPE = {
  [TYPE_ORDER_ENROLLMENT]: 'Order Exam?',
  [TYPE_ORDER_SUBMISSION]: 'Order Submission',
  [TYPE_ENROLLMENT]: 'Enrollment',
  [TYPE_SUBSIDY_EXCLUSION]: 'Subsidy Exclusion',
  [TYPE_FEE_STATUS]: 'Fee Status',
  [TYPE_EXAM_REFUND]: 'Exam Refund',
}

export const STUDENT_MULTISELECT_CHECK = 'CHECK_STUDENT_MULTISELECT'
export const STUDENT_MULTISELECT_UNCHECK = 'UNCHECK_STUDENT_MULTISELECT'
export const UPDATE_STUDENT_MULTISELECT_PENDING = 'UPDATE_STUDENT_MULTISELECT_PENDING'
export const UPDATE_STUDENT_MULTISELECT_FULFILLED = 'UPDATE_STUDENT_MULTISELECT_FULFILLED'
export const UPDATE_STUDENT_MULTISELECT_REJECTED = 'UPDATE_STUDENT_MULTISELECT_REJECTED'
export const UPDATE_STUDENT_MULTISELECT_RESET = 'UPDATE_STUDENT_MULTISELECT_RESET'

export const EXCLUDE_SUBSIDIES = 'EXCLUDE'
export const INCLUDE_SUBSIDIES = 'INCLUDE'
export const EXCLUDE_FROM_SUBSIDIES = 'excludeSubsidy'
export const INCLUDE_IN_SUBSIDIES = 'includeSubsidy'
export const MANUALLY_APPLIED = 'manuallyApplied'

export const USER_PROMPT_NONE = 'NONE'
export const USER_PROMPT_STANDARD = 'INFORMATIVE'
export const USER_PROMPT_POPUP = 'ACTIONABLE'

export const DOWNLOAD_SUCCESS = 'SUCCESS'
export const DOWNLOAD_FAILED = 'FAILED'
