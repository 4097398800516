import * as c from '../constants/ExamRoomConstants'
import { MODAL_OPEN } from '../constants/AppConstants'
import template from './template'
import { updateRoom, removeStudents } from '../components/examRoom/utils'

export const examRoomSchedule = (state, action) => {
  switch (action.type) {
    case c.ADD_EXAM_ROOM_PENDING:
    case c.EDIT_EXAM_ROOM_PENDING:
      return { ...state, saved: false, saving: true, serror: null }

    case c.ADD_EXAM_ROOM_REJECTED:
    case c.EDIT_EXAM_ROOM_REJECTED:
      return { ...state, saved: false, saving: false, serror: action.payload }

    case c.ADD_EXAM_ROOM_FULFILLED:
    case c.EDIT_EXAM_ROOM_FULFILLED:
      return {
        ...state,
        saved: true,
        serror: null,
        saving: false,
        data: updateRoom(state.data, action.payload),
      }


      
    case c.DELETE_EXAM_ROOM_PENDING:
      return { ...state, deleting: true, derror: null, deleted: false }
    case c.DELETE_EXAM_ROOM_REJECTED:
      return { ...state, deleting: false, derror: action.payload, deleted: false }
    case c.DELETE_EXAM_ROOM_FULFILLED:
      return { ...state, deleting: false, derror: null, deleted: true }

    case MODAL_OPEN:
      return { ...state, serror: null, saved: false, deleted: false }

    default:
      return template(state, action, c, 'EXAM_ROOM_SCHEDULE')
  }
}

export const examRoomStudents = (state = { selectedStudents: [] }, action) => {
  switch (action.type) {
    case c.ASSIGN_STUDENTS_PENDING:
    case c.UNASSIGN_STUDENTS_PENDING:
      return { ...state, serror: null, saving: true, saved: false }

    case c.ASSIGN_STUDENTS_REJECTED:
    case c.UNASSIGN_STUDENTS_REJECTED:
      return { ...state, saving: false, saved: false, serror: action.payload }

    case c.ASSIGN_STUDENTS_FULFILLED:
      return {
        ...state,
        serror: null,
        saving: false,
        saved: true,
        ...action.payload,
      }

    case c.UNASSIGN_STUDENTS_FULFILLED:
      return {
        ...state,
        saving: false,
        saved: true,
        serror: null,
        [action.payload.roomId]: removeStudents(
          state[action.payload.roomId],
          action.payload.students
        ),
        selectedStudents: [],
      }

    case c.SELECT_ASSIGNED_STUDENT:
      return { ...state, selectedStudents: [...state.selectedStudents, action.payload] }
    case c.UNSELECT_ASSIGNED_STUDENT:
      return {
        ...state,
        selectedStudents: state.selectedStudents.filter(id => id !== action.payload),
      }
    case c.RESET_ASSIGNED_STUDENTS:
      return { ...state, selectedStudents: [] }

    case MODAL_OPEN:
      return { ...state, serror: null, saved: false }
    default:
      return template(state, action, c, 'EXAM_ROOM_STUDENTS')
  }
}

export const examRoomAssignableStudents = (state, action) =>
  template(state, action, c, 'ASSIGNABLE_STUDENTS')
