import { useHistory } from 'react-router-dom'
import { Modal } from '../../components/common'
import { hasUnsubmittedChanges } from '../../selectors/order'
import { isTakingExam } from '../../selectors/section'

const mapStateToProps = state => ({
  changeOrderExists: hasUnsubmittedChanges(state.status.data.changeOrderStatus),
  isTeacher: state.user.data.isTeacher,
})

const DropStudentSuccessModal = ({
  studentName,
  courseName,
  sectionName,
  isRemoveExamWithCostImplications,
  isTeacher,
  isKeepExam,
  isMarkUnused,
  exam,
  changeOrderExists,
  modalCloseFocusElem,
}) => {
  const history = useHistory()

  const getText = () => {
    const isNotRemovingExam = isKeepExam || isMarkUnused

    if (isNotRemovingExam && isTeacher)
      return (
        <p>
          {studentName} has been dropped from {sectionName}. This student is currently registered
          for the {courseName} exam. Contact your school's AP coordinator if the student's exam
          registration needs to be changed.
        </p>
      )
    if (isNotRemovingExam)
      return <p>Student has been moved to "Dropped Students" exam only section for {courseName}.</p>
    if (isTakingExam(exam) && !isTeacher)
      return (
        <p>
          {studentName}&#39;s {courseName} exam has been removed.
          {isRemoveExamWithCostImplications &&
            ' You will need to submit your changes on the orders placed before they apply.'}
        </p>
      )

    return (
      <p>
        {studentName} has been dropped from {courseName}.
      </p>
    )
  }

  const headerTitle = () => {
    if (isRemoveExamWithCostImplications && changeOrderExists)
      return `Change Order ${changeOrderExists ? 'Updated' : 'Created'}`

    return 'Student Dropped'
  }

  const footerActions = () => {
    if (isRemoveExamWithCostImplications) {
      return [
        { buttonLabel: 'Continue Updating Order', isDismissable: true, isPrimary: false },
        {
          buttonLabel: 'View Order',
          isPrimary: true,
          isDismissable: true,
          onClick: () => history.push('/orders'),
        },
      ]
    }

    return [{ buttonLabel: 'Close', isDismissable: true, isPrimary: true }]
  }

  return (
    <Modal
      headerTitle={headerTitle()}
      modalCloseFocusElem={modalCloseFocusElem}
      footerActions={footerActions()}
    >
      {getText()}
    </Modal>
  )
}

export default connect(mapStateToProps)(DropStudentSuccessModal)
