import { Tooltip } from '../../common'
import FilterDropdown from '../common/FilterDropdown'
import {
  ORDER_EXAM_FILTER,
  FEE_STATUS_FILTER,
  SSD_FILTER,
  SSD_DIGITAL_FILTER,
  SSD_FILTER_VALUES,
  STUDENT_ACCOMMODATIONS_FILTER,
  FILTER_YES,
  FILTER_NO,
  SSD_DIGITAL_NONE,
  SSDID_FILTER,
  FILTER_DEFAULT_VALUES,
  ORDER_STATUS_FILTER,
  ORDER_STATUS_FILTER_VALUES,
  SUBSIDY_FILTER,
  SUBSIDY_NONE,
  SUBSIDY_FILTER_VALUES,
  FEES_FILTER,
  FEES_FILTER_VALUES,
  REFUNDED_FEE,
  SPECIAL_MATERIALS_APPROVAL_REQUEST_FILTER,
  EXCEPTION_ORDERING_APPROVAL_REQUEST_FILTER,
  ORDER_AFTER_DEADLINE_APPROVAL_REQUEST_FILTER,
  TEST_LOCATION_FILTER,
  TEST_LOCATION_FILTER_VALUES,
} from '../../../constants/FilterConstants'
import {
  STUDENT_ENROLLMENT_STATUS,
  STUDENT_FEE_STATUS,
  SDF_ACCESSIBILITY,
  SDF_EXTENDED_TIME,
  SDF_BREAKS,
  SDF_MULTIDAY,
  SDF_LIMITED_TIME,
  SDF_ADDITIONAL_ACCOMMODATIONS,
  SDF_FREE_RESPONSE_BOOKLET,
} from '../../../constants/StudentConstants'
import { APPROVAL_STATUS } from '../../../constants/OrderConstants'

export const OrderExamFilterDropdown = ({ label = ORDER_EXAM_FILTER.label }) => (
  <FilterDropdown
    label={label}
    id="orderExam"
    categories={[
      {
        key: ORDER_EXAM_FILTER.filter,
        options: Object.keys(STUDENT_ENROLLMENT_STATUS).map(key => ({
          label: STUDENT_ENROLLMENT_STATUS[key],
          value: key,
        })),
      },
    ]}
  />
)

export const FeeStatusFilterDropdown = ({ label = FEE_STATUS_FILTER.label }) => (
  <FilterDropdown
    label={label}
    id="apFeeStatus"
    categories={[
      {
        key: FEE_STATUS_FILTER.filter,
        options: Object.keys(STUDENT_FEE_STATUS).map(key => ({
          label: `${STUDENT_FEE_STATUS[key]} Fee`,
          value: key,
        })),
      },
    ]}
  />
)

export const AccommodationsFilterDropdown = ({
  label = SSD_FILTER.groupLabel,
  digitalAccommodations = [],
}) => {
  const categories = []
  categories.push({
    label: SSD_FILTER.label,
    key: SSD_FILTER.filter,
    options: [
      { label: SSD_FILTER_VALUES[FILTER_YES], value: FILTER_YES },
      { label: SSD_FILTER_VALUES[FILTER_NO], value: FILTER_NO },
    ],
  })
  if (digitalAccommodations.length) {
    categories.push({
      label: SSD_DIGITAL_FILTER.label,
      key: SSD_DIGITAL_FILTER.filter,
      options: [
        { label: SSD_FILTER_VALUES[SDF_ACCESSIBILITY], value: SDF_ACCESSIBILITY },
        {
          label: SSD_FILTER_VALUES[SDF_ADDITIONAL_ACCOMMODATIONS],
          value: SDF_ADDITIONAL_ACCOMMODATIONS,
        },
        { label: SSD_FILTER_VALUES[SDF_EXTENDED_TIME], value: SDF_EXTENDED_TIME },
        { label: SSD_FILTER_VALUES[SDF_BREAKS], value: SDF_BREAKS },
        { label: SSD_FILTER_VALUES[SDF_FREE_RESPONSE_BOOKLET], value: SDF_FREE_RESPONSE_BOOKLET },
        { label: SSD_FILTER_VALUES[SDF_LIMITED_TIME], value: SDF_LIMITED_TIME },
        { label: SSD_FILTER_VALUES[SDF_MULTIDAY], value: SDF_MULTIDAY },
        { label: SSD_FILTER_VALUES[SSD_DIGITAL_NONE], value: SSD_DIGITAL_NONE },
      ],
    })
  }

  return <FilterDropdown label={label} id="accommodations" categories={categories} />
}

export const StudentAccommodationsFilterDropdown = ({ studentAccommodationCategories = {} }) => {
  const accommodationCategoryCodes = Object.keys(studentAccommodationCategories)

  if (accommodationCategoryCodes?.length) {
    return (
      <FilterDropdown
        label={STUDENT_ACCOMMODATIONS_FILTER.label}
        id="accommodationCategories"
        categories={[
          {
            key: STUDENT_ACCOMMODATIONS_FILTER.filter,
            options: accommodationCategoryCodes.map(code => ({
              label: studentAccommodationCategories[code],
              value: code,
            })),
          },
        ]}
      />
    )
  }
  return null
}

export const SSDIDFilterDropdown = ({ label = SSDID_FILTER.label }) => (
  <FilterDropdown
    label={
      <>
        {label}{' '}
        <Tooltip
          title="A student will have an SSD ID if they have a record in SSD Online. This filter identifies any students with an SSD ID, but doesn't distinguish whether or not a student has an approved accommodation. Once you've applied the filter on the student roster page for students with an SSD ID, you still need to view the student details page to review the accommodations for a student."
          placement="top"
          container="body"
          label="More information: SSD ID information"
          style={{
            display: 'inline-block',
            verticalAlign: 'top',
            paddingLeft: '5px',
            fontSize: '.7em',
          }}
        >
          <i className="cb-glyph cb-glyph-circular cb-question" />
        </Tooltip>
      </>
    }
    id="ssdId"
    categories={[
      {
        key: SSDID_FILTER.filter,
        options: Object.keys(FILTER_DEFAULT_VALUES).map(key => ({
          label: FILTER_DEFAULT_VALUES[key],
          value: key,
        })),
      },
    ]}
  />
)

export const OrderStatusFilterDropdown = ({ label = ORDER_STATUS_FILTER.label }) => (
  <FilterDropdown
    label={label}
    id="orderStatus"
    categories={[
      {
        key: ORDER_STATUS_FILTER.filter,
        options: Object.keys(ORDER_STATUS_FILTER_VALUES).map(key => ({
          label: ORDER_STATUS_FILTER_VALUES[key],
          value: key,
        })),
      },
    ]}
  />
)

export const SubsidyFilterDropdown = ({ subsidies, label = SUBSIDY_FILTER.label }) => {
  let options = []
  options = subsidies.map(s => ({ label: s, value: s }))
  if (options.length) {
    options.push({ label: SUBSIDY_FILTER_VALUES[SUBSIDY_NONE], value: SUBSIDY_NONE })
    return (
      <FilterDropdown
        id="subsidy"
        label={label}
        categories={[{ key: SUBSIDY_FILTER.filter, options }]}
      />
    )
  }
  return null
}

export const FeesFilterDropdown = ({ existingFees, label = FEES_FILTER.label }) => {
  // This destructuring is just to get a list of fees without refunded, we're not going to use
  // the refunded const we pulled off
  const { [REFUNDED_FEE]: refunded, ...fees } = FEES_FILTER_VALUES
  const options = existingFees.find(f => f === REFUNDED_FEE) ? FEES_FILTER_VALUES : fees
  return (
    <FilterDropdown
      label={label}
      id="fees"
      categories={[
        {
          key: FEES_FILTER.filter,
          options: Object.keys(options).map(key => ({
            label: FEES_FILTER_VALUES[key],
            value: key,
          })),
        },
      ]}
    />
  )
}

export const ApprovalRequestFilterDropdown = ({
  nonstockedApprovals,
  exceptionApprovals,
  orderApprovals,
  label = SPECIAL_MATERIALS_APPROVAL_REQUEST_FILTER.groupLabel,
}) => {
  const getDropdownItems = arr => arr.map(a => ({ label: APPROVAL_STATUS[a], value: a }))
  const categories = []

  if (nonstockedApprovals.length)
    categories.push({
      label: SPECIAL_MATERIALS_APPROVAL_REQUEST_FILTER.label,
      key: SPECIAL_MATERIALS_APPROVAL_REQUEST_FILTER.filter,
      options: getDropdownItems(nonstockedApprovals),
    })
  if (exceptionApprovals.length)
    categories.push({
      label: EXCEPTION_ORDERING_APPROVAL_REQUEST_FILTER.label,
      key: EXCEPTION_ORDERING_APPROVAL_REQUEST_FILTER.filter,
      options: getDropdownItems(exceptionApprovals),
    })
  if (orderApprovals.length)
    categories.push({
      label: ORDER_AFTER_DEADLINE_APPROVAL_REQUEST_FILTER.label,
      key: ORDER_AFTER_DEADLINE_APPROVAL_REQUEST_FILTER.filter,
      options: getDropdownItems(orderApprovals),
    })

  return categories.length ? (
    <FilterDropdown id="approvalStatus" label={label} categories={categories} />
  ) : null
}

export const TestLocationFilterDropdown = ({
  label = TEST_LOCATION_FILTER.label,
  testingLocations = [],
}) => {
  if (testingLocations.length > 1) {
    return (
      <FilterDropdown
        label={label}
        id="testLocation"
        categories={[
          {
            key: TEST_LOCATION_FILTER.filter,
            options: testingLocations.map(key => ({
              label: TEST_LOCATION_FILTER_VALUES[key],
              value: key,
            })),
          },
        ]}
      />
    )
  }
  return null
}
