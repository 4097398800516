import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { persistState } from '@redux-devtools/core'
// import DevTools from '../containers/DevTools';
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import promise from 'redux-promise-middleware'
import * as reducers from '../reducers'

const logger = createLogger({
  level: 'info',
  collapsed: true,
})

const reducer = combineReducers({ ...reducers })

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancers = composeEnhancers(
  // Middleware you want to use in development:
  applyMiddleware(thunkMiddleware, logger, promise),
  // DevTools.instrument(),
  persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/))
)

const store = (initialState = {}) => {
  const store = createStore(reducer, initialState, enhancers)

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index').default
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}

export default store()
