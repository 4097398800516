import AuthorizedTeachersTableRow from './AuthorizedTeachersTableRow'
import { Error, Loader } from '../../common'
import { sortColumnByKey } from '../../../utils/sort'

const mapStateToProps = state => {
  const {
    teachersGrantedAccess: { apcaGrantedCourses: data = [], fetching, error },
  } = state

  return {
    authorizations: sortColumnByKey(
      data,
      ['courseName', 'firstName', 'lastName'],
      ['asc', 'asc', 'asc']
    ),
    fetching,
    error,
  }
}

const AuthorizedTeachersTable = ({ fetching, error, authorizations }) => {
  if (error) return <Error title="Error Retrieving Data" message={error} />

  if (fetching) return <Loader size="md" />

  if (authorizations.length)
    return (
      <>
        <table className="table" summary="Teachers are grouped in ascending order by course.">
          <caption className="sr-only">
            A list of all teachers who are authorized to teach AP and Pre-AP courses.
          </caption>
          <thead>
            <tr role="row">
              <th scope="col" role="columnheader" style={{ textAlign: 'center' }}>
                Teacher Name
              </th>
              <th scope="col" role="columnheader" style={{ textAlign: 'center' }}>
                Course
              </th>
              <th scope="col" role="columnheader" style={{ textAlign: 'center' }}>
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {authorizations.map((authorization, index) => (
              <AuthorizedTeachersTableRow
                key={`${authorization.proId}_${authorization.testCd}_${authorization.onlineInd}`}
                num={index}
                authorization={authorization}
              />
            ))}
          </tbody>
        </table>
      </>
    )

  return (
    <p>There are no teachers who are currently authorized to teach AP Courses at this school.</p>
  )
}

export default connect(mapStateToProps)(AuthorizedTeachersTable)
