import { getFormValues, change, clearFields, untouch } from 'redux-form'
import { Input, InlineText, Text, AtLeastOneChecked } from '../common'
import SurveyTextQuestion from './SurveyTextQuestion'
import SurveySelectQuestion from './SurveySelectQuestion'
import { annihilateField } from '../../actions/form'
import { isEmpty } from '../../utils/common'
import { sortColumnByKey } from '../../utils/sort'
import {
  EDUCATIONPERIOD24_COURSE_SURVEY_FORM,
  TYPE_TEXT,
  TYPE_NUMERIC_RANGE,
  TYPE_SELECT,
  TYPE_MULTI_SELECT,
} from '../../constants/SurveyConstants'

const mapStateToProps = state => {
  return {
    currentValues: getFormValues(EDUCATIONPERIOD24_COURSE_SURVEY_FORM)(state),
  }
}

export default connect(mapStateToProps, {
  change,
  clearFields,
  untouch,
  annihilateField,
})(
  ({
    surveyQuestions,
    // responses = {},
    currentValues = {},
    change,
    clearFields,
    untouch,
    annihilateField,
  }) => {
    const questions = sortColumnByKey(Object.values(surveyQuestions), ['sequence'], ['asc'])

    // For clearing text fields associated with an input if that input is not selected
    useEffect(() => {
      const questionIds = Object.keys(currentValues)
      questionIds.forEach(qid => {
        const questionSource = questions.find(q => q.qid === qid)
        // We only care about SELECT and MULTI_SELECT fields
        switch (questionSource?.type) {
          case TYPE_SELECT:
            // Find the corresponding question option
            const selectedOption = questionSource?.options.find(
              opt => opt.value === currentValues[qid].value
            )
            const inline = selectedOption?.inline ?? false
            // RADIO: if field has multiple keys and one key is 'text'
            if (
              Object.keys(currentValues[qid]).length > 1 &&
              !isEmpty(currentValues[qid].text) &&
              !inline
            ) {
              // Remove text prop by setting the field's value to just the value
              change(EDUCATIONPERIOD24_COURSE_SURVEY_FORM, qid, { value: currentValues[qid].value })
            }
            break

          case TYPE_MULTI_SELECT:
            // CHECKBOX: if field is an array and any element of that array has multiple keys
            if (Array.isArray(currentValues[qid])) {
              currentValues[qid].forEach((res, index) => {
                if (!isEmpty(res?.text)) {
                  const nonTextKey = Object.keys(res).find(key => key !== 'text')
                  // If the value of the key that isn't 'text' is false, clear the text field
                  if (!res[nonTextKey]) {
                    // Remove text prop by setting the field's value to just the value
                    change(EDUCATIONPERIOD24_COURSE_SURVEY_FORM, `${qid}[${index}]`, {
                      [nonTextKey]: false,
                    })
                  }
                }
              })
            }
            break
        }
      })
    }, [currentValues])

    // For removing follow-up questions if parent question's response is no longer selected
    useEffect(() => {
      const fieldsToClear = []
      const currentQuestionIds = Object.keys(currentValues)
      currentQuestionIds.forEach(cqid => {
        if (!questions.find(q => q.qid === cqid) && currentValues[cqid] !== undefined) {
          fieldsToClear.push(cqid)
          if (Array.isArray(currentValues[cqid])) {
            // Set the qid value in form reducer to undefined so it's no longer an array
            // so untouch and clearFields don't blow up
            annihilateField({
              formName: EDUCATIONPERIOD24_COURSE_SURVEY_FORM,
              fieldName: cqid,
            })
          }
        }
      })
      if (fieldsToClear.length > 0) {
        untouch(EDUCATIONPERIOD24_COURSE_SURVEY_FORM, ...fieldsToClear)
        clearFields(EDUCATIONPERIOD24_COURSE_SURVEY_FORM, false, false, ...fieldsToClear)
      }
    }, [currentValues])

    if (questions.length) {
      return questions.map(currentQuestion => (
        <Fragment key={currentQuestion.qid}>
          {currentQuestion?.type == TYPE_TEXT || currentQuestion?.type == TYPE_NUMERIC_RANGE ? (
            <SurveyTextQuestion question={currentQuestion} />
          ) : null}
          {currentQuestion?.type == TYPE_SELECT || currentQuestion?.type == TYPE_MULTI_SELECT ? (
            <SurveySelectQuestion question={currentQuestion} values={currentValues} />
          ) : null}
        </Fragment>
      ))
    }

    return null
  }
)
