import { isTakingExam } from '../section'
import { isDroppedWithCost } from '../student'
import * as c from '../../constants/FilterConstants'
import {
  STUDENT_FEE_STATUS,
  STUDENT_ENROLLMENT_STATUS,
  SSD_MATERIALS_FIELDS,
} from '../../constants/StudentConstants'
import { APPROVAL_STATUS } from '../../constants/OrderConstants'
import { DIGITAL_EXAM, HYBRID_EXAM, PAPER_EXAM } from '../../constants/SettingsConstants'
import { isEmpty } from '../../utils/common'
import { filterByKey, searchByKeys } from './utils'

/** *********************************************************** */
/** ***************** DATA FILTERING FUNCTIONS **************** */
/** *********************************************************** */
const filterByApprovalsGroup = (dataElem, valuesToFilterBy) =>
  valuesToFilterBy.find(({ value, filter }) => {
    let objCompareKey = 'etsNonStockedApprovalStatus'
    if (filter === c.EXCEPTION_ORDERING_APPROVAL_REQUEST_FILTER.filter)
      objCompareKey = 'etsExceptionApprovalStatus'
    else if (filter === c.ORDER_AFTER_DEADLINE_APPROVAL_REQUEST_FILTER.filter)
      objCompareKey = 'etsOrderAfterDeadlineApprovalStatus'
    return dataElem[objCompareKey] === value
  })

const filterBySSDGroup = (dataElem, valuesToFilterBy) => {
  const { examFormat, specialDigitalFormats = {}, ssdMaterials } = dataElem
  const isDigital = examFormat === DIGITAL_EXAM
  const isHybrid = examFormat === HYBRID_EXAM
  const isPaper = examFormat === PAPER_EXAM
  return (
    isTakingExam(dataElem) &&
    valuesToFilterBy.find(check => {
      const { filter, value } = check
      if (filter === c.SSD_FILTER.filter) {
        return (
          isPaper &&
          ((value === c.FILTER_YES &&
            (ssdMaterials?.[SSD_MATERIALS_FIELDS.ssdMaterialsNotOrdered.name] ||
              ssdMaterials?.[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name] ||
              ssdMaterials?.[SSD_MATERIALS_FIELDS.limitedTimeTesting.name] ||
              ssdMaterials?.[SSD_MATERIALS_FIELDS.multipleDayTesting.name] ||
              ssdMaterials?.[SSD_MATERIALS_FIELDS.otherAccommodations.name] ||
              ssdMaterials?.[SSD_MATERIALS_FIELDS.readerCopy.name] ||
              ssdMaterials?.[SSD_MATERIALS_FIELDS.printedScript.name] ||
              ssdMaterials?.[SSD_MATERIALS_FIELDS.fontSize.name])) ||
            (value === c.FILTER_NO &&
              !ssdMaterials?.[SSD_MATERIALS_FIELDS.ssdMaterialsNotOrdered.name] &&
              !ssdMaterials?.[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name] &&
              !ssdMaterials?.[SSD_MATERIALS_FIELDS.limitedTimeTesting.name] &&
              !ssdMaterials?.[SSD_MATERIALS_FIELDS.multipleDayTesting.name] &&
              !ssdMaterials?.[SSD_MATERIALS_FIELDS.otherAccommodations.name] &&
              !ssdMaterials?.[SSD_MATERIALS_FIELDS.readerCopy.name] &&
              !ssdMaterials?.[SSD_MATERIALS_FIELDS.printedScript.name] &&
              !ssdMaterials?.[SSD_MATERIALS_FIELDS.fontSize.name]))
        )
      }
      if (isDigital || isHybrid) {
        if (value === c.SSD_DIGITAL_NONE) {
          return (
            isEmpty(specialDigitalFormats) ||
            Object.keys(specialDigitalFormats).every(group =>
              Object.keys(specialDigitalFormats[group]).every(
                cd => specialDigitalFormats[group][cd] === false
              )
            )
          )
        }
        if (!isEmpty(specialDigitalFormats[value])) {
          return Object.keys(specialDigitalFormats[value]).some(
            accom => specialDigitalFormats[value][accom] === true
          )
        }
      }
      return false
    })
  )
}

const filterBySSDID = (dataElem, valuesToFilterBy) =>
  valuesToFilterBy.find(({ value }) => {
    return (
      (value === c.FILTER_YES &&
        dataElem.ssdId !== undefined &&
        dataElem.ssdId.toString().length > 0) ||
      (value === c.FILTER_NO && dataElem.ssdId === undefined)
    )
  })

const filterByTeacher = (dataElem, valuesToFilterBy) => {
  const { sectionTeachers = [] } = dataElem
  return valuesToFilterBy.find(({ value }) => sectionTeachers.find(t => t.toString() === value))
}

const filterByCapstoneCourse = (dataElem, valuesToFilterBy) =>
  valuesToFilterBy.find(({ value }) => {
    const isAPCapstoneCourse = dataElem.testCd === 94 || dataElem.testCd === 95
    return value === c.FILTER_YES ? isAPCapstoneCourse : !isAPCapstoneCourse
  })

const filterBySubsidy = (dataElem, valuesToFilterBy) => {
  const { subsidies = [] } = dataElem
  return valuesToFilterBy.find(({ value }) => {
    if (value === c.SUBSIDY_NONE) {
      return subsidies.length === 0
    }
    return subsidies.find(s => s === value)
  })
}

const filterByFees = (dataElem, valuesToFilterBy) => {
  const { examPrice = {} } = dataElem
  if (Object.keys(examPrice).length) {
    return valuesToFilterBy.find(({ value }) => {
      let boolVal = true
      let filterTerm = value
      const splitFilter = value.split('_')
      if (splitFilter.length === 2) {
        if (splitFilter[0] === 'not') {
          boolVal = false
        }
        filterTerm = splitFilter[1]
      }
      let found = false
      if (!isEmpty(examPrice[filterTerm])) {
        if (typeof examPrice[filterTerm] === 'boolean') {
          if (filterTerm === c.REFUNDED_FEE) {
            found = examPrice[filterTerm] === boolVal && examPrice[c.HELD_REFUND_FEE] === false
          } else {
            found = examPrice[filterTerm] === boolVal
          }
        } else {
          found = examPrice[filterTerm] !== null && examPrice[filterTerm] !== 0
        }
      }
      return found
    })
  }
  return false
}

const filterByAdmin = (dataElem, valuesToFilterBy) => {
  return valuesToFilterBy.find(({ value }) => {
    if (value === c.EXAM_DATE_CONFLICTS_OPTION.value) {
      return !isEmpty(dataElem.hasConflict)
    }
    return dataElem.testWindow?.toString() === value?.toString() && !isDroppedWithCost(dataElem)
  })
}

const filterByStudentAccommodations = (dataElem, valuesToFilterBy) => {
  return valuesToFilterBy.find(({ value }) => {
    return (
      dataElem.accommodationCategoryCodes?.includes(value) ||
      (value === 'stwa' && dataElem.testedWithAccomInd)
    )
  })
}

export const STUDENT_FILTER_FUNCTIONS = {
  [c.COURSE_FILTER.filter]: (d, v) => filterByKey(d, v, 'testCd'),
  [c.COURSE_TYPE_FILTER.filter]: (d, v) => filterByKey(d, v, 'testProgramCd'),
  [c.ADMIN_FILTER.filter]: filterByAdmin,
  [c.ORDER_STATUS_FILTER.filter]: (d, v) => filterByKey(d, v, 'orderStatus'),
  [c.FEE_STATUS_FILTER.filter]: (d, v) => filterByKey(d, v, 'feesStatus'),
  [c.SECTION_FILTER.filter]: (d, v) => filterByKey(d, v, 'sectionId'),
  [c.ORDER_EXAM_FILTER.filter]: (d, v) => filterByKey(d, v, 'examIntent'),
  [c.TEST_LOCATION_FILTER.filter]: (d, v) => filterByKey(d, v, 'testLocation'),
  [c.APPROVALS_GROUP.group]: filterByApprovalsGroup,
  [c.SSD_GROUP.group]: filterBySSDGroup,
  [c.SSDID_FILTER.filter]: filterBySSDID,
  [c.TEACHER_FILTER.filter]: filterByTeacher,
  [c.CAPSTONE_FILTER.filter]: filterByCapstoneCourse,
  [c.SUBSIDY_FILTER.filter]: filterBySubsidy,
  [c.FEES_FILTER.filter]: filterByFees,
  [c.STUDENT_NAME_ID_FILTER.filter]: (d, v) => searchByKeys(d, v, ['studentId'], ['lastName']),
  [c.STUDENT_ACCOMMODATIONS_FILTER.filter]: filterByStudentAccommodations,
}

export const getStudentsFilterDataTag = (state, filter, value) => {
  const {
    courses = {},
    teachers = {},
    sections = {},
    examWindowRefData = {},
    accommodationCategories = {},
  } = state
  let displayValue
  let filterProps

  switch (filter) {
    case c.ADMIN_FILTER.filter:
      filterProps = { label: 'Exam Date' }
      displayValue = examWindowRefData[value]?.displayName
        ? examWindowRefData[value]?.displayName
        : value === c.EXAM_DATE_CONFLICTS_OPTION.value
        ? c.EXAM_DATE_CONFLICTS_OPTION.label
        : ''
      break
    case c.CAPSTONE_FILTER.filter:
      filterProps = c.CAPSTONE_FILTER
      displayValue = value === c.FILTER_YES ? 'Yes' : 'No'
      break
    case c.COURSE_FILTER.filter:
      filterProps = c.COURSE_FILTER
      displayValue = courses[value]?.name
      break
    case c.COURSE_TYPE_FILTER.filter:
      filterProps = c.COURSE_TYPE_FILTER
      displayValue = c.COURSE_TYPE_FILTER_VALUES[value]
      break
    case c.EXCEPTION_ORDERING_APPROVAL_REQUEST_FILTER.filter:
      filterProps = c.EXCEPTION_ORDERING_APPROVAL_REQUEST_FILTER
      displayValue = APPROVAL_STATUS[value]
      break
    case c.FEES_FILTER.filter:
      filterProps = c.FEES_FILTER
      displayValue = c.FEES_FILTER_VALUES[value]
      break
    case c.FEE_STATUS_FILTER.filter:
      filterProps = c.FEE_STATUS_FILTER
      displayValue = STUDENT_FEE_STATUS[value]
      break
    case c.ORDER_AFTER_DEADLINE_APPROVAL_REQUEST_FILTER.filter:
      filterProps = c.ORDER_AFTER_DEADLINE_APPROVAL_REQUEST_FILTER
      displayValue = APPROVAL_STATUS[value]
      break
    case c.ORDER_EXAM_FILTER.filter:
      filterProps = c.ORDER_EXAM_FILTER
      displayValue = STUDENT_ENROLLMENT_STATUS[value]
      break
    case c.ORDER_STATUS_FILTER.filter:
      filterProps = c.ORDER_STATUS_FILTER
      displayValue = c.ORDER_STATUS_FILTER_VALUES[value]
      break
    case c.SECTION_FILTER.filter: {
      const { sectionTeachers = [], sectionName, courseName } = sections[value] || {}
      filterProps = c.SECTION_FILTER
      displayValue =
        sectionName &&
        `${courseName} - ${sectionName}${
          sectionTeachers.length ? ` - ${sectionTeachers.map(t => t.teacherName).join(', ')}` : ''
        }`
      break
    }
    case c.SPECIAL_MATERIALS_APPROVAL_REQUEST_FILTER.filter:
      filterProps = c.SPECIAL_MATERIALS_APPROVAL_REQUEST_FILTER
      displayValue = APPROVAL_STATUS[value]
      break
    case c.SSD_DIGITAL_FILTER.filter:
      filterProps = c.SSD_DIGITAL_FILTER
      displayValue = c.SSD_FILTER_VALUES[value]
      break
    case c.SSD_FILTER.filter:
      filterProps = c.SSD_FILTER
      displayValue = c.SSD_FILTER_VALUES[value]
      break
    case c.SSDID_FILTER.filter:
      filterProps = c.SSDID_FILTER
      displayValue = c.FILTER_DEFAULT_VALUES[value]
      break
    case c.STUDENT_NAME_ID_FILTER.filter:
      filterProps = c.STUDENT_NAME_ID_FILTER
      displayValue = `'${value}'`
      break
    case c.SUBSIDY_FILTER.filter:
      filterProps = c.SUBSIDY_FILTER
      displayValue = value === c.SUBSIDY_NONE ? 'None' : decodeURIComponent(value)
      break
    case c.TEACHER_FILTER.filter:
      filterProps = c.TEACHER_FILTER
      displayValue = teachers[value]?.name
      break
    case c.TEST_LOCATION_FILTER.filter:
      filterProps = c.TEST_LOCATION_FILTER
      displayValue = c.TEST_LOCATION_FILTER_VALUES[value]
      break
    case c.STUDENT_ACCOMMODATIONS_FILTER.filter:
      filterProps = c.STUDENT_ACCOMMODATIONS_FILTER
      displayValue = accommodationCategories[value]
      break
    default:
      filterProps = {}
      displayValue = ''
  }

  return { ...filterProps, displayValue }
}
