import { isDateTimePast, parse, isValidDate } from '@myap/ui-library/esm/date'
import { normalizeSSDMaterials } from '../utils/normalize'
import axios, { dispatchError } from '../utils/axios'
import * as c from '../constants/SettingsConstants'
import { queryParamsToObj, isEmpty } from '../utils/common'
import { getAdminWindowDisplayNameForRefData } from '../selectors/examWindows'

const getEducationPeriodCd = getState => getState().settingsEducationPeriod.selectedEducationPeriod

export const fetchDeadlinesSettings = educationPeriodCd => (dispatch, getState) => {
  const config = {
    method: 'GET',
    url: `${Config.API_URL}/settings/order-deadlines`,
    params: { educationPeriodCd },
  }
  const {
    settingsDeadlines: {
      data: { [educationPeriodCd]: deadlinesForSelectedEducationPeriod },
    },
  } = getState()
  // If deadlines for selected edpd have already been cached, don't re-fetch
  if (!isEmpty(deadlinesForSelectedEducationPeriod)) {
    return null
  }
  dispatch({ type: c.FETCH_DEADLINES_PENDING })
  return axios
    .request(config)
    .then(res => {
      const { deadlines } = res.data
      const obj = {}
      Object.keys(deadlines).forEach(key => (obj[`${key}IsPast`] = isDateTimePast(deadlines[key])))
      dispatch({
        type: c.FETCH_DEADLINES_FULFILLED,
        payload: { educationPeriodCd, deadlines: { ...deadlines, ...obj } },
      })
    })
    .catch(dispatchError.bind(this, dispatch, c.FETCH_DEADLINES_REJECTED))
}

export const fetchAllowedSSDMaterials = () => async dispatch => {
  try {
    dispatch({ type: c.FETCH_ALLOWED_SSD_MATERIALS_PENDING })
    const res = await axios.get(`${Config.API_URL}/settings/allowed-ssd-materials`)
    const { data } = await res
    dispatch({
      type: c.FETCH_ALLOWED_SSD_MATERIALS_FULFILLED,
      payload: normalizeSSDMaterials(data),
    })
  } catch (err) {
    dispatchError(dispatch, c.FETCH_ALLOWED_SSD_MATERIALS_REJECTED)
  }
}

export const fetchSpecialDigitalFormatsMetadata = () => async dispatch => {
  try {
    dispatch({ type: c.FETCH_SPECIAL_DIGITAL_FORMATS_PENDING })
    const res = await axios.get(
      `${Config.API_URL}/ssd-accommodations/special-digital-formats-metadata`
    )
    const { data } = await res
    dispatch({
      type: c.FETCH_SPECIAL_DIGITAL_FORMATS_FULFILLED,
      payload: data,
    })
  } catch (err) {
    dispatchError(dispatch, c.FETCH_SPECIAL_DIGITAL_FORMATS_REJECTED)
  }
}

export const fetchExamPricing = () => (dispatch, getState) => {
  const config = {
    method: 'GET',
    url: `${Config.API_URL}/settings/exam-pricing`,
    params: { educationPeriodCd: getEducationPeriodCd(getState) },
  }

  dispatch({ type: c.FETCH_EXAM_PRICING_PENDING })
  return axios
    .request(config)
    .then(res => {
      dispatch({ type: c.FETCH_EXAM_PRICING_FULFILLED, payload: res.data })
    })
    .catch(dispatchError.bind(this, dispatch, c.FETCH_EXAM_PRICING_REJECTED))
}

export const setSelectedEducationPeriod = code => (dispatch, getState) => {
  const isCurrent =
    getState().settingsEducationPeriod.availableEducationPeriods[code].chronology === 'current'
  dispatch({ type: c.SET_SELECTED_EDUCATION_PERIOD, payload: code })
  dispatch({ type: c.SET_SELECTED_IS_CURRENT_EDUCATION_PERIOD, payload: isCurrent })
}

export const clearSelectedEducationPeriod = () => dispatch => {
  dispatch({ type: c.CLEAR_SELECTED_EDUCATION_PERIOD })
  dispatch({ type: c.SET_SELECTED_IS_CURRENT_EDUCATION_PERIOD, payload: false })
}

export const fetchEducationPeriod =
  ({ idType, id }) =>
  async dispatch => {
    let serviceUrl = ''
    const orgRequest = `/paapro/v1/organizations/${id}/education-periods`
    const studentRequest = `/paapro/v1/student/${id}/education-periods`
    const genericRequest = '/paapro/v1/referencedata/education-periods/current'

    switch (idType) {
      case 'orgId':
        serviceUrl = orgRequest
        break

      case 'studentId':
        serviceUrl = studentRequest
        break

      default:
        serviceUrl = genericRequest
    }

    dispatch({ type: c.FETCH_EDUCATION_PERIOD_PENDING })
    try {
      let response
      response = await axios.get(`${Config.API_URL}${serviceUrl}`)
      const preselected = queryParamsToObj(window.location.search)?.period
      let current = null
      let previous = null
      let availableEducationPeriods = null

      // When requesting education period without an id, response will be an object,
      // otherwise, response should be an array containing education period objects.
      if (Array.isArray(response.data) && response.data?.length > 0) {
        availableEducationPeriods = response.data.reduce((obj, period) => {
          if (period.chronology === 'current') current = period.code
          else if (period.chronology === 'previous') previous = period.code
          return { ...obj, [period.code]: { ...period } }
        }, {})
      } else {
        // Data is object or zero length array
        if (Array.isArray(response.data)) {
          // Request generic education period so we at least have something
          response = await axios.get(`${Config.API_URL}${genericRequest}`)
        }
        availableEducationPeriods = { [response.data.code]: response.data }
        current = response.data.code
      }

      const today = new Date()
      const startDate = availableEducationPeriods[current]?.academicYrStartDate
      // const isTransitionPeriod = isValidDate(startDate)
      //   ? today.getMonth() === parse(startDate).getMonth() &&
      //     today.getFullYear() === parse(startDate).getFullYear()
      //   : true // Default to true so order can't be submitted if there's no academicYrStartDate

      await dispatch({
        type: c.FETCH_EDUCATION_PERIOD_FULFILLED,
        payload: {
          availableEducationPeriods,
          current,
          previous,
          isTransitionPeriod: false,
        },
      })

      const selected =
        preselected && availableEducationPeriods[preselected] ? preselected : current || previous
      dispatch(setSelectedEducationPeriod(selected))
    } catch (err) {
      dispatchError(dispatch, c.FETCH_EDUCATION_PERIOD_REJECTED)
    }
  }

const generateExamWindowRefData = data => {
  const examWindowRefData = {}
  Object.keys(data).forEach(testCd => {
    Object.keys(data[testCd]).forEach(adminWindowName => {
      const currentAdminWindow = data?.[testCd]?.[adminWindowName]
      const { adminWindow, sequence, requiresUnlocking, testAdmin, examFormat } = currentAdminWindow
      const { sequence: existingSequence = 0 } = examWindowRefData[adminWindowName] ?? {}
      // We want to retain the highest value of sequence number
      Object.assign(examWindowRefData, {
        [adminWindowName]: {
          adminWindow,
          displayName: getAdminWindowDisplayNameForRefData(currentAdminWindow),
          sequence: existingSequence > sequence ? existingSequence : sequence,
          requiresUnlocking,
          testAdmin,
          examFormat,
        },
      })
    })
  })
  return examWindowRefData
}

export const fetchExamWindows = (orgId, educationPeriodCd) => async dispatch => {
  try {
    dispatch({ type: c.FETCH_EXAM_WINDOWS_PENDING })
    const { data } = await axios.get(
      `${Config.API_URL}/paapro/v1/exam-windows/education-periods/${educationPeriodCd}`,
      { params: { orgId } }
    )
    const titleCase = str => str.replace(/\b\S/g, t => t.toUpperCase())
    const examWindowDataForCourses = {}
    Object.keys(data).forEach(course => {
      data[course].forEach(visibleWindow => {
        examWindowDataForCourses[course] = {
          ...examWindowDataForCourses[course],
          [visibleWindow.adminWindow]: {
            ...visibleWindow,
            displayName:
              visibleWindow.displayName || titleCase(visibleWindow.adminWindow.toLowerCase()),
          },
        }
      })
    })
    const examWindowRefData = generateExamWindowRefData(examWindowDataForCourses)
    dispatch({
      type: c.FETCH_EXAM_WINDOWS_FULFILLED,
      payload: { orgId, educationPeriodCd, data: examWindowDataForCourses, examWindowRefData },
    })
  } catch (err) {
    dispatchError(dispatch, c.FETCH_EXAM_WINDOWS_REJECTED, err)
  }
}
