import { Route, Redirect } from 'react-router-dom'
import { useCatapultAuth } from '../../utils/hooks'
import { NAV_LINKS } from '../../constants/NavConstants'

const PrivateRoute = ({ component: Component, componentProps, ...props }) => {
  const {
    location: { search, pathname },
  } = props
  const { isAuthed } = useCatapultAuth()

  return isAuthed ? (
    <Component {...componentProps} {...props} />
  ) : (
    <Redirect to={{ pathname: NAV_LINKS.login, search, from: pathname }} />
  )
}

export default ({ component, componentProps, ...props }) => {
  return (
    <Route
      {...props}
      render={props => (
        <PrivateRoute component={component} componentProps={componentProps} {...props} />
      )}
    />
  )
}
