import axios, { getServerErrorMessage } from '../utils/axios'
import { isSubmitted, hasUnsubmittedChanges } from '../selectors/order'
import * as c from '../constants/StatusConstants'

export const fetchStatusSummary = (orgId, educationPeriodCd) => async (dispatch, getState) => {
  const { user: { data: { isCoordinator }}} = getState()

  if (!isCoordinator)
    throw new Error('Only coordinators may request status')

  dispatch({ type: c.FETCH_STATUS_SUMMARY_PENDING })

  try {
    const { data } = await axios.get(`${Config.API_URL}/paapro/v1/organizations/${orgId}/education-periods/${educationPeriodCd}/status`)
    const { orderStatus, changeOrderStatus } = data.examRegistrations
    dispatch({
      type: c.FETCH_STATUS_SUMMARY_FULFILLED,
      payload: {
        ...data,
        isSubmitted: isSubmitted(orderStatus),
        hasUnsubmittedChanges: hasUnsubmittedChanges(changeOrderStatus),
      },
    })
  } catch (err) {
    dispatch({ type: c.FETCH_STATUS_SUMMARY_REJECTED, payload: getServerErrorMessage(err) })
  }
}
