import {
  FETCH_PACKING_LIST_DETAILS_PENDING,
  FETCH_PACKING_LIST_DETAILS_FULFILLED,
  FETCH_PACKING_LIST_DETAILS_REJECTED,
  UPDATE_PACKING_CARTONS_PENDING,
  UPDATE_PACKING_CARTONS_FULFILLED,
  UPDATE_PACKING_CARTONS_REJECTED,
  UPDATE_PACKING_LIST_ITEM_PENDING,
  UPDATE_PACKING_LIST_ITEM_FULFILLED,
  UPDATE_PACKING_LIST_ITEM_REJECTED,
  RESET_UPDATE_PACKING_LIST_ITEM,
  FLUSH_PACKING_LIST_DETAILS,
  SUBMIT_PACKING_LIST_FULFILLED,
  SUBMIT_PACKING_LIST_PENDING,
  SUBMIT_PACKING_LIST_REJECTED,
} from '../constants/PackingListConstants'

const initialSubmissionState = {
  error: null,
  fetching: false,
  fetched: false,
  examType: '',
  noOfCartonsReturned: '',
  packingListId: '',
  packingListItems: [],
  shipmentDueDate: '',
  status: '',
  testDayType: '',
  totalCourses: null,
  carton: {
    error: null,
    sending: false,
    sent: false,
  },
  updatePackingListItem: {
    error: null,
    updating: null,
    updated: null,
  },
  submitPackingList: {
    error: null,
    submitting: null,
    submitted: null,
  },
}

export default (state = initialSubmissionState, { type, payload }) => {
  switch (type) {
    case FETCH_PACKING_LIST_DETAILS_PENDING:
      return {
        ...state,
        fetching: true,
        error: null,
        fetched: false,
        carton: { ...initialSubmissionState.carton },
      }

    case FETCH_PACKING_LIST_DETAILS_FULFILLED:
      return { ...state, ...payload, error: null, fetching: false, fetched: true }

    case FETCH_PACKING_LIST_DETAILS_REJECTED:
      return { ...state, error: payload, fetching: false, fetched: false }

    case UPDATE_PACKING_CARTONS_FULFILLED:
      return {
        ...state,
        carton: { error: null, sending: false, sent: true },
        status: payload.data.status,
        noOfCartonsReturned: payload.data.noOfCartonsReturned,
      }
    case UPDATE_PACKING_CARTONS_PENDING:
      return { ...state, carton: { error: null, sending: true, sent: false } }
    case UPDATE_PACKING_CARTONS_REJECTED:
      return { ...state, carton: { error: payload, sending: false, sent: false } }

    case SUBMIT_PACKING_LIST_FULFILLED:
      return {
        ...state,
        submitPackingList: { error: null, submitting: false, submitted: true },
        status: payload.data.status,
        noOfCartonsReturned: payload.data.noOfCartonsReturned,
      }
    case SUBMIT_PACKING_LIST_PENDING:
      return { ...state, submitPackingList: { error: null, submitting: true, submitted: false } }
    case SUBMIT_PACKING_LIST_REJECTED:
      return {
        ...state,
        submitPackingList: { error: payload, submitting: false, submitted: false },
      }

    case RESET_UPDATE_PACKING_LIST_ITEM:
      return {
        ...state,
        updatePackingListItem: {
          packingListItemId: null,
          error: null,
          updating: false,
          updated: null,
        },
      }
    case UPDATE_PACKING_LIST_ITEM_PENDING:
      return {
        ...state,
        updatePackingListItem: {
          packingListItemId: payload,
          error: null,
          updating: true,
          updated: false,
        },
      }
    case UPDATE_PACKING_LIST_ITEM_FULFILLED: {
      const pIDIndex = state.packingListItems.findIndex(
        p => p.packingListItemId === payload.packingListItemId
      )
      const newPackingListItems = state.packingListItems.slice()
      newPackingListItems[pIDIndex] = { ...newPackingListItems[pIDIndex], ...payload }
      return {
        ...state,
        packingListItems: newPackingListItems,
        updatePackingListItem: {
          packingListItemId: payload.packingListItemId,
          error: null,
          updating: false,
          updated: true,
        },
      }
    }
    case UPDATE_PACKING_LIST_ITEM_REJECTED:
      return {
        ...state,
        updatePackingListItem: {
          packingListItemId: payload.packingListItemId,
          error: payload.error,
          updating: false,
          updated: false,
        },
      }

    case FLUSH_PACKING_LIST_DETAILS:
      return initialSubmissionState

    default:
      return state
  }
}
