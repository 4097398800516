import {Error} from '../../components/common'

export default () => (
  <CSSTransition classNames="animate-fade" in={true} timeout={500} appear={true} enter={false} exit={false}>
    <div className="container" style={{marginTop:'50px'}}>
      <div className="row">
        <div className="col-xs-12">
          <Error title="Access Denied" message="You do not have access. Please check with your coordinator." />
        </div>
      </div>
    </div>
  </CSSTransition>
)
