import { CSVLink } from 'react-csv'
import { formatDateTime } from '@myap/ui-library/esm/date'

const mapStateToProps = state => ({
  disabledByUserType: state.user.data.isCSR && !state.user.data.isETS,
})

const DownloadToCSV = props => {
  const {
    style = {},
    title,
    header,
    filename,
    disabled,
    data = [],
    timestampHeaderKey,
    transformData,
    disabledByUserType,
  } = props
  const btnRef = useRef(null)
  const [csvData, setData] = useState(data)

  async function process() {
    const processedData = transformData ? await transformData() : data
    const timestampHeader = timestampHeaderKey
      ? [
          {
            [timestampHeaderKey]: `Generated on ${formatDateTime(
              new Date(),
              'MMMM d, yyyy h:mm a'
            )}`,
          },
          {},
        ]
      : []
    await setData([...timestampHeader, ...processedData])
    btnRef.current.link.click()
  }
  return (
    <div style={style}>
      <button
        type="button"
        disabled={disabledByUserType || disabled}
        onClick={process}
        className="btn-link cb-small-font-size"
        style={{ padding: 0 }}
      >
        <i className="cb-glyph cb-download" aria-hidden={true} /> Download {title}
      </button>
      <CSVLink
        data={csvData}
        headers={header}
        ref={btnRef}
        style={{ display: 'none' }}
        filename={`${filename}.csv`}
      >
        Downloading
      </CSVLink>
    </div>
  )
}

export default connect(mapStateToProps)(DownloadToCSV)
