import { formatter, suppressQuantity } from './utils'

import styles from '../../assets/style/scss/invoices.scss'

export const TransferTable = ({ data, title }) => (
  <div className={styles['transfer-table']}>
    <h3 className="h4">{title}</h3>
    {data.length ? (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className={styles.description}>
                Description
              </th>
              <th scope="col" className={styles.quantity}>
                Quantity
              </th>
              <th scope="col" className={styles.price}>
                Unit Price
              </th>
            </tr>
          </thead>

          <tbody>
            {data.map((d, i) => (
              <tr key={`${d.product}_${i}`}>
                <th col="row">{d.description}</th>
                <td>{!suppressQuantity(d) ? d.quantity : ''}</td>
                <td>{formatter(d.unitPrice)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <p>This invoice does not contain any {title.toLowerCase()}.</p>
    )}
  </div>
)

export default TransferTable
