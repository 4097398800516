import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { Link } from 'react-router-dom'
import PurchaseOrder from './PurchaseOrder'
import BillingContact from './BillingContact'
import InvoiceStatus from './InvoiceStatus'
import InvoiceTable from './InvoiceTable'
import InvoiceTotals from './InvoiceTotals'
import InvoiceChangeMessage from './InvoiceChangeMessage'
import ProgramInvoice from './ProgramInvoice'
import StateDirect from './StateDirect'
import StateReimbursement from './StateReimbursement'
import DistrictReimbursement from './DistrictReimbursement'
import InvoiceNumbers from './InvoiceNumbers'
import { isCentralBill } from './utils'

import styles from '../../assets/style/scss/invoices.scss'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = ({
  settingsEducationPeriod: { selectedEducationPeriod },
  invoices: { data },
  settingsDeadlines: { data: settingsDeadlinesData },
  app: {
    breakpoint: { name },
  },
}) => {
  const paymentDeadline = settingsDeadlinesData?.[selectedEducationPeriod]?.paymentDeadline
  return {
    paymentDeadlineDate: formatDate(paymentDeadline, DATETIME_FORMATS.longMonthDayYear),
    data,
    breakpoint: name,
  }
}

const InvoiceAvailable = ({ paymentDeadlineDate, data = {}, breakpoint }) => (
  <div className={`row ${styles[breakpoint]}`}>
    <div className="col-lg-9 col-md-8">
      <InvoiceStatus className="invoice-status" />
      <InvoiceChangeMessage {...data} />
      <InvoiceTable title="Fees" data={data.fees} />
      <InvoiceTable title="Credits" data={data.credits} />
      <InvoiceTotals {...data} />
    </div>
    <div className={`col-lg-3 col-md-4 ${styles['invoice-sidebar']}`}>
      <InvoiceNumbers customerNumber={data?.orgId} invoiceNumber={data?.invoiceNum} />
      <ProgramInvoice data={data} date={paymentDeadlineDate} />
      <StateDirect data={data} />
      <StateReimbursement data={data} />
      <DistrictReimbursement data={data} />
      {data?.hasInvoiceHistory ? (
        <Link to={NAV_LINKS.invoicehistory} className={styles['invoice-sidebar-link']}>
          View Invoice History
        </Link>
      ) : null}
      {!isCentralBill(data) ? (
        <>
          <hr />
          <BillingContact />
          <PurchaseOrder content="<link> purchase order numbers." linkTitle="Add or edit" />
        </>
      ) : null}
    </div>
  </div>
)

export default connect(mapStateToProps)(InvoiceAvailable)
