import styles from '../../../../assets/style/scss/csr.scss'

export default ({ action, title, className, children, disabled }) => (
  <div
    className={`${styles['browse-items']} ${styles[className]} ${disabled ? styles.disabled : ''}`}
  >
    <h5>
      <a
        href="#"
        onClick={e => {
          e.preventDefault()
          if (!disabled) action(e)
        }}
      >
        {title}
        <i className="cb-glyph cb-east" aria-hidden={true} />
      </a>
    </h5>
    {children}
  </div>
)
