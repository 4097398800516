import { dismissNotification } from '../../../actions/notifications'
import { MERGE_NOTIFICATION, AP_NOTIFICATIONS } from '../../../constants/NotificationConstants'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId, isCSR, isSchoolAdmin },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
  } = state
  const { data, fetching, error } = state.notifications[AP_NOTIFICATIONS]
  const notifications = data?.filter(
    notification => notification.messageType === MERGE_NOTIFICATION
  )
  return {
    orgId: selectedOrgId,
    educationPeriodCd,
    isCSR,
    isSchoolAdmin,
    notifications,
    fetching,
    error,
  }
}

const CoordinatorMergeNotification = ({
  orgId,
  educationPeriodCd,
  notifications,
  isCSR,
  isSchoolAdmin,
  error,
  dismissNotification,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [show, setShow] = useState(true)
  const notificationCount = notifications ? notifications.length : 0
  const visibleData = notificationCount
    ? expanded
      ? notifications
      : notifications.slice(0, 1)
    : []

  const removeMergeNotification = () => {
    setShow(false)
    dismissNotification({
      orgId,
      educationPeriodCd,
      notificationType: MERGE_NOTIFICATION,
      messageIds: notifications.map(n => n.messageId),
    })
  }

  if (isCSR || isSchoolAdmin) return null

  if (notificationCount && show && !error) {
    return (
      <div
        role="alert"
        className="alert alert-warning cb-alert-heading notification"
        style={{ marginTop: -21, border: 0, backgroundColor: 'rgb(0, 119, 200, .15)' }}
      >
        <div className="container" style={{ position: 'relative' }}>
          <h2>Student Accounts Merged</h2>
          {visibleData.map((d, i) => (
            <p key={i}>{d.message}</p>
          ))}
          {notificationCount > 1 ? (
            <p style={{ marginBottom: 0 }}>
              <button
                type="button"
                className="btn-link"
                aria-expanded={expanded}
                onClick={() => setExpanded(!expanded)}
                style={{ padding: 0, border: 0, fontWeight: 700 }}
              >
                see {expanded ? 'less' : 'more'}
                <i
                  className={`cb-glyph ${expanded ? 'cb-minus' : 'cb-plus'}`}
                  aria-hidden={true}
                  style={{ fontSize: '.8em', paddingLeft: 3 }}
                />
              </button>
            </p>
          ) : null}
          <button
            type="button"
            className="close"
            style={{ position: 'absolute', top: '-15px', right: 30 }}
            onClick={removeMergeNotification}
          >
            <span className="sr-only">close</span>
          </button>
        </div>
      </div>
    )
  }
  return null
}

export default connect(mapStateToProps, { dismissNotification })(CoordinatorMergeNotification)
