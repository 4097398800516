import { profile as MyApProfile } from '@myap/ui-library'
import { Profile } from '../../components/teacher'
import {
  setNavLink,
  unsetNavLink,
  suppressSchoolMenu,
  unSuppressSchoolMenu,
} from '../../actions/nav'

export default connect(state => ({}), {
  setNavLink,
  unsetNavLink,
  suppressSchoolMenu,
  unSuppressSchoolMenu,
})(({ setNavLink, unsetNavLink, suppressSchoolMenu, unSuppressSchoolMenu }) => {
  const { apcentralUrl } = MyApProfile()

  useEffect(() => {
    setNavLink(apcentralUrl, 'Back to AP Central')
    suppressSchoolMenu()
    return () => {
      unsetNavLink()
      unSuppressSchoolMenu()
    }
  }, [])
  return (
    <div className="container" style={{ marginTop: '2em' }}>
      <Profile />
    </div>
  )
})
