import { BasicInput } from '..'

const TableRowSelection = props => {
  const {
    children,
    rowId,
    onSelection,
    srOnlyLabel = true,
    label,
    inputType = 'checkbox',
    isChecked = false,
    disabled = false,
    ariaProps,
    inputName,
  } = props
  const [checked, setChecked] = useState(isChecked)

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  return (
    <tr className={checked ? 'cb-selected' : ''} id={rowId}>
      <td className={`cb-table-select cb-table-select-${inputType}`}>
        <BasicInput
          type={inputType}
          srOnlyLabel={srOnlyLabel}
          label={label}
          disabled={disabled}
          input={{
            ...ariaProps,
            checked,
            name: inputName || `${rowId}-${inputType}`,
            value: rowId,
            onChange: e => {
              setChecked(e.target.checked)
              if (onSelection) onSelection(e.target)
            },
          }}
        />
      </td>
      {children}
    </tr>
  )
}

export default TableRowSelection
