import * as c from '../constants/NavConstants'

const initialState = {
  linkRoute: '',
  linkText: '',
  suppressNav: false,
  suppressSchoolMenu: false,
  returnApp: '',
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case c.SET_NAV_LINK:
      return { ...state, linkRoute: payload.route, linkText: payload.text }
    case c.UNSET_NAV_LINK:
      return { ...state, linkRoute: '', linkText: '' }
    case c.SUPPRESS_NAV:
      return { ...state, suppressNav: true }
    case c.UN_SUPPRESS_NAV:
      return { ...state, suppressNav: false }
    case c.SUPPRESS_SCHOOL_MENU:
      return { ...state, suppressSchoolMenu: true }
    case c.UN_SUPPRESS_SCHOOL_MENU:
      return { ...state, suppressSchoolMenu: false }
    case c.SET_RETURN_APP:
      return { ...state, returnApp: payload }
    default:
      return state
  }
}
