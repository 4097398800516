import { useSubscription } from '@apollo/client'
import TeacherCourse from './TeacherCourse'
import AvailableCourses from '../common/AvailableCourses'
import {
  reprocessExamReadinessStatus,
  updateExamReadinessStatus,
} from '../../../actions/examReadiness'
import { setNavLink, unsetNavLink } from '../../../actions/nav'
import { isEmpty } from '../../../utils/common'
import { getAppsyncClient } from '../common/appsync'
import { TEACHER_EXAM_READINESS_SUBSCRIPTION } from '../../../queries/subscriptions'

const TeacherExamReadiness = connect(
  state => ({
    ...state.examReadiness,
    educationPeriodCd: state.settingsEducationPeriod.selectedEducationPeriod,
  }),
  { setNavLink, unsetNavLink, reprocessExamReadinessStatus, updateExamReadinessStatus }
)(
  ({
    setNavLink,
    unsetNavLink,
    examObj = {},
    courses = [],
    realTimeUpdatesDisabledMsg,
    sectionMap,
    educationPeriodCd,
    updateExamReadinessStatus,
  }) => {
    const noResults = isEmpty(examObj)
    useEffect(() => {
      setNavLink(Config.MYAP_URL.prof, 'Back to My AP')
      return () => unsetNavLink()
    }, [])

    if (!realTimeUpdatesDisabledMsg && !noResults)
      Object.keys(sectionMap).map(sectionId =>
        useSubscription(TEACHER_EXAM_READINESS_SUBSCRIPTION, {
          client: getAppsyncClient(),
          variables: { educationPeriodCd, sectionId },
          onSubscriptionData: updateExamReadinessStatus,
        })
      )

    if (noResults)
      return (
        <p className="container" style={{ marginTop: '2em' }}>
          You do not have any students taking exams.
        </p>
      )

    return <AvailableCourses component={TeacherCourse} defaultCourses={courses} />
  }
)

export default TeacherExamReadiness
