import { useLocation } from 'react-router-dom'
import { Loader, Error } from '@myap/ui-library/esm/components'
import { fetchDocusignUrl } from '../../actions/settingsOrg'
import { queryParamsToObj } from '../../utils/common'

const DocusignRedirect = ({}) => {
  const { search } = useLocation()
  const { close, orgId, edPeriod } = queryParamsToObj(search)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState()

  const getDocusignUrl = async (orgId, edPeriod) => {
    const { data = {}, error } = await fetchDocusignUrl(orgId, edPeriod)
    setFetching(false)
    setError(Boolean(error))
    setRedirectUrl(data?.redirectUrl)
  }

  const closeBrowser = () => window.close()

  useEffect(() => {
    if (orgId && edPeriod) {
      getDocusignUrl(orgId, edPeriod)
    }
  }, [])

  useEffect(() => {
    if (close) {
      closeBrowser()
    }
  }, [close])

  useEffect(() => {
    if (redirectUrl) window.location = redirectUrl
  }, [redirectUrl])

  return (
    <div className="container" style={{ marginTop: 50 }}>
      {error && (
        <>
          <Error
            title="Error Loading Document"
            message="Your participation form could not be loaded, please try again."
          />
          <div style={{ marginTop: 50, textAlign: 'center' }}>
            <button type="button" className="btn btn-sm btn-secondary" onClick={closeBrowser()}>
              Close
            </button>
          </div>
        </>
      )}
      {fetching && (
        <h1 className="h2" style={{ position: 'relative', paddingLeft: 25 }}>
          <Loader size="sm" style={{ position: 'absolute', top: 5, left: 0 }} /> Loading
          Participation Form...
        </h1>
      )}
    </div>
  )
}

export default DocusignRedirect
