import * as c from '../constants/SettingsConstants'

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case c.REMOVE_TEACHER_GRANTED_ACCESS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }
    case c.REMOVE_TEACHER_GRANTED_ACCESS_FULFILLED:
      return { ...state, fetching: false, fetched: true }
    case c.REMOVE_TEACHER_GRANTED_ACCESS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    case c.RESET_GRANT_REVOKE_TEACHER_ACCESS:
      return initialState

    default:
      return state
  }
}
