import { formatDateTime, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { BasicInput, Loader, Error } from '../../../common'
import { SelectAllOrNone, ActionBar } from '..'

import styles from '../../../../assets/style/scss/csr.scss'

const mapStateToProps = state => {
  return {
    educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
    orgId: state.user.data.selectedOrgId,
  }
}

const Checkbox = ({
  updateSelections,
  selections,
  item,
  itemProps: { itemId, itemDescription },
  compId,
}) => {
  const { locked, unlockExpiryDate, [itemId]: id, [itemDescription]: description } = item
  const unlockedDate = unlockExpiryDate ? (
    <div>
      {locked ? ' Expired ' : ' Expires '}
      {formatDateTime(unlockExpiryDate, DATETIME_FORMATS.shortMonthDayYearTime)}
    </div>
  ) : null
  const msgId = `${compId}-${id}-msg`

  return (
    <div style={{ marginTop: 20 }} className={styles['tool-body-item']}>
      <BasicInput
        type="checkbox"
        input={{
          name: compId,
          value: id,
          checked: Boolean(selections.find(s => s[itemId] === id)),
          onChange: e => updateSelections(item, e.target.checked),
          'aria-describedby': msgId,
        }}
        label={description}
      />
      <div style={{ marginLeft: 27 }} tabIndex="-1" id={msgId}>
        {locked ? (
          <div style={{ opacity: '.8' }}>
            Locked
            {unlockedDate}
          </div>
        ) : (
          <div style={{ color: '#3A913F' }}>
            Unlocked
            {unlockedDate}
          </div>
        )}
      </div>
    </div>
  )
}

const hasItems = (data, locked) => Boolean(data.find(d => d.locked === locked))

const LockingComponent = ({
  educationPeriod,
  orgId,
  fnFetch,
  fnSave,
  introText,
  compId,
  emptyText = 'None Available',
  itemProps,
}) => {
  const containerRef = useRef(null)
  const [fetching, setFetching] = useState(true)
  const [fetchingError, setFetchingError] = useState(null)
  const [data, setData] = useState([])
  const [selections, setSelections] = useState([])
  const [saving, setSaving] = useState(null)
  const [savingError, setSavingError] = useState(null)
  const selectionsLen = selections.length
  const dataLen = data.length

  const fetchData = async () => {
    await setFetching(true)
    const { data = [], error = null } = await fnFetch(orgId, educationPeriod)
    setData(data)
    setFetchingError(error)
    setFetching(false)
  }

  useEffect(() => {
    if (fnFetch) {
      fetchData()
    }
  }, [])

  const save = async locked => {
    const itemsToSave = selections.filter(s => s.locked === locked)
    const savingState = await setSaving(true)
    const { data = [], error = null } = await fnSave(itemsToSave, orgId, educationPeriod)

    if (error) {
      setSaving(false)
      setSavingError(error)
    } else {
      setSaving(false)
      setData(data)
      setSavingError(null)
      setSelections(selections.filter(s => !itemsToSave.find(item => item.locked === s.locked)))
    }
  }

  const getObjToSave = obj => {
    const { itemId, itemOptionalAttrs = [] } = itemProps
    return {
      [itemId]: obj[itemId],
      locked: !obj.locked,
      ...itemOptionalAttrs.reduce((acc, attr) => ({ ...acc, [attr]: obj[attr] }), {}),
    }
  }

  const updateSelections = (selection, add) => {
    const { itemId } = itemProps
    setSelections(
      add
        ? [...selections, getObjToSave(selection)]
        : selections.filter(s => s[itemId] !== selection[itemId])
    )
  }

  const ItemComponents = () => {
    return data.length ? (
      data.map(item => (
        <Checkbox
          itemProps={itemProps}
          item={item}
          compId={compId}
          updateSelections={updateSelections}
          selections={selections}
          key={item[itemProps.itemId]}
        />
      ))
    ) : (
      <p className={styles['tool-body-item']} style={{ marginTop: 20 }}>
        <em>{emptyText}</em>
      </p>
    )
  }

  const getButtons = () => {
    const hasUnlockedItems = hasItems(data, false)
    const hasLockedItems = hasItems(data, true)
    const hasSelectedUnlockedItems = hasItems(selections, false)
    const hasSelectedLockedItems = hasItems(selections, true)
    const buttons = []

    if (hasUnlockedItems)
      buttons.push({
        title: 'Lock',
        disabled: !hasSelectedLockedItems || saving,
        onClick: () => save(true),
      })
    if (hasLockedItems)
      buttons.push({
        title: 'Unlock',
        disabled: !hasSelectedUnlockedItems || saving,
        onClick: () => save(false),
      })

    return buttons
  }

  if (fetching) return <Loader />

  if (fetchingError)
    return <Error title="Error Retrieving Data" message={fetchingError} style={{ padding: 10 }} />

  return (
    <>
      <div className={styles['tool-body-inner']} ref={containerRef}>
        <p className={styles['tool-body-item']}>{introText}</p>
        <SelectAllOrNone
          selectAll={() => setSelections(data.map(getObjToSave))}
          selectNone={() => setSelections([])}
          dataLen={dataLen}
          selectionsLen={selectionsLen}
        />
        {savingError && (
          <Error
            title="Error Saving Data"
            message={savingError}
            style={{ margin: 0, padding: 15 }}
          />
        )}
        <ItemComponents />
      </div>
      <ActionBar buttons={getButtons()} />
    </>
  )
}

export default connect(mapStateToProps)(LockingComponent)
