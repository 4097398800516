import {
  Text,
  ConfirmEmailFields,
  PhoneValidation,
  LimitedStringValidation,
  LetterValidation,
} from '../../common'
import { SCHOOL_INFORMATION_FIELDS as FIELDS } from '../../../constants/SetupConstants'
import { DOMESTIC_PHONE_LENGTH, INTERNATIONAL_PHONE_LENGTH } from '../../../constants/SettingsConstants'
import { convertToNumberString } from '../../../utils/numbers'
import PhoneNumber from '../../common/forms/PhoneNumber'

export default ({ hideTitle, email, countryCode }) => {
  const isDomestic = countryCode === 'US'
  return (
    <fieldset>
      {!hideTitle ? (
        <legend className="h2" style={{ border: 0, paddingTop: '40px' }}>
          Technology Coordinator
        </legend>
      ) : null}
      <div className="row">
        <div className="col-xs-5">
          <Text
            label="First Name"
            name={FIELDS.TECH_COORD_FNAME.name}
            placeholder="Enter first name"
            maxlength={20}
            isRequired={FIELDS.TECH_COORD_FNAME.required}
            validate={[LimitedStringValidation]}
          />
        </div>
        <div className="col-xs-2">
          <Text
            label="MI"
            name={FIELDS.TECH_COORD_MI.name}
            placeholder=" "
            maxlength={1}
            isRequired={FIELDS.TECH_COORD_MI.required}
            validate={[LetterValidation]}
          />
        </div>
        <div className="col-xs-5">
          <Text
            label="Last Name"
            name={FIELDS.TECH_COORD_LNAME.name}
            placeholder="Enter last name"
            maxlength={30}
            isRequired={FIELDS.TECH_COORD_LNAME.required}
            validate={[LimitedStringValidation]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-5">
          <PhoneNumber
            label="Telephone"
            name={FIELDS.TECH_COORD_PHONE.name}
            placeholder={isDomestic ? 'Enter a 10 digit number' : 'Enter telephone number'}
            isRequired={FIELDS.TECH_COORD_PHONE.required}
            validate={[PhoneValidation]}
            countryCode={countryCode}
            shouldFormat={true}
            normalize={convertToNumberString}
            maxlength={isDomestic ? DOMESTIC_PHONE_LENGTH : INTERNATIONAL_PHONE_LENGTH}
          />
        </div>
      </div>
      <ConfirmEmailFields
        label="Email"
        emailProps={FIELDS.TECH_COORD_EMAIL}
        commonPlaceholder="email address"
        showConfirmErrorOnLoad={Boolean(email)}
        maxlength={50}
      />
    </fieldset>
  )
}
