export const FETCH_SURVEY_DATA_PENDING = 'FETCH_SURVEY_DATA_PENDING'
export const FETCH_SURVEY_DATA_FULFILLED = 'FETCH_SURVEY_DATA_FULFILLED'
export const FETCH_SURVEY_DATA_REJECTED = 'FETCH_SURVEY_DATA_REJECTED'

export const SAVE_SURVEY_DATA_PENDING = 'SAVE_SURVEY_DATA_PENDING'
export const SAVE_SURVEY_DATA_FULFILLED = 'SAVE_SURVEY_DATA_FULFILLED'
export const SAVE_SURVEY_DATA_REJECTED = 'SAVE_SURVEY_DATA_REJECTED'

export const EDUCATIONPERIOD24_COURSE_SURVEY_FORM = 'educationPeriod24CourseSurveyForm'
export const TYPE_SELECT = 'SELECT'
export const TYPE_TEXT = 'TEXT'
export const TYPE_TEXT_AREA = 'TEXT_AREA'
export const TYPE_MULTI_SELECT = 'MULTI_SELECT'
export const TYPE_NUMERIC_RANGE = 'NUMERIC_RANGE'

export const EDUCATIONPERIOD24_COURSE_SURVEY_SCHEMA = {
  questions: [
    {
      qid: 'Q1',
      question:
        'When is your school most likely finalize decisions about the 2023-24 master schedule?',
      response: {
        type: TYPE_SELECT,
        required: true,
        options: [
          {
            value: 'finalized',
            label: 'We have already finalized our decisions',
          },
          {
            value: 'oct22',
            label: 'October 2022',
          },
          {
            value: 'nov22',
            label: 'November 2022',
          },
          {
            value: 'dec22',
            label: 'December 2022',
          },
          {
            value: 'jan23',
            label: 'January 2023',
          },
          {
            value: 'feb23',
            label: 'February 2023',
          },
          {
            value: 'mar23',
            label: 'March 2023',
          },
          {
            value: 'apr23',
            label: 'April 2023',
          },
          {
            value: 'may23',
            label: 'May 2023',
          },
          {
            value: 'summer',
            label: 'Summer 2023',
          },
        ],
      },
    },
    {
      qid: 'Q2',
      question: 'How many sections of standard precalculus does your school currently offer?',
      response: {
        type: TYPE_SELECT,
        required: true,
        options: [
          {
            value: '0',
            label: '0',
          },
          {
            value: '1',
            label: '1',
          },
          {
            value: '2',
            label: '2',
          },
          {
            value: '3',
            label: '3',
          },
          {
            value: 'more_than_3',
            label: 'More than 3',
          },
        ],
      },
    },
    {
      qid: 'Q3',
      question: 'Does your school plan to add AP Pre-Calculus to the 2023-24 master schedule?',
      response: {
        type: TYPE_SELECT,
        required: true,
        options: [
          {
            value: 'Y',
            label: 'Yes',
          },
          {
            value: 'N',
            label: 'No',
          },
          {
            value: 'U',
            label: 'Unsure',
          },
        ],
      },
    },
    {
      qid: 'Q6',
      question: 'In which grade(s) does your school plan to offer AP Precalculus?',
      pResponse: 'Y',
      pqid: 'Q3',
      response: {
        type: TYPE_MULTI_SELECT,
        required: true,
        options: [
          {
            value: '9',
            label: '9',
          },
          {
            value: '10',
            label: '10',
          },
          {
            value: '11',
            label: '11',
          },
          {
            value: '12',
            label: '12',
          },
          {
            value: 'U',
            label: 'Unsure',
          },
        ],
      },
    },
    {
      qid: 'Q7',
      question: 'How many sections of AP Precalculus does your school plan to offer?',
      pResponse: 'Y',
      pqid: 'Q3',
      response: {
        type: TYPE_NUMERIC_RANGE,
        required: true,
        range: '1-99',
      },
    },
    {
      qid: 'Q8',
      question: 'Will these sections replace your current precalculus offering?',
      pResponse: 'Y',
      pqid: 'Q3',
      response: {
        type: TYPE_SELECT,
        required: true,
        options: [
          {
            value: 'Y',
            label: 'Yes',
          },
          {
            value: 'N',
            label: 'No',
          },
        ],
      },
    },
    {
      qid: 'Q9',
      question: (
        <>Why doesn&#39;t your school plan to offer AP Precalculus in the next academic year?</>
      ),
      pResponse: 'N',
      pqid: 'Q3',
      response: {
        type: TYPE_SELECT,
        required: true,
        options: [
          {
            value: 'no_room',
            label: <>We don&#39;t have room in the 2023-24 master schedule.</>,
          },
          {
            value: 'no_teacher',
            label: <>We don&#39;t have a qualified teacher.</>,
          },
          {
            value: 'students_not_interested',
            label: 'Our students are not interested.',
          },
          {
            value: 'not_affordable',
            label:
              'We cannot afford to offer a new AP course (e.g., teacher training, new textbooks)',
          },
          {
            value: 'other',
            label: 'Other',
            inline: true,
          },
        ],
      },
    },
    {
      qid: 'Q10',
      question: 'Why did you choose Not Sure?',
      pResponse: 'U',
      pqid: 'Q3',
      response: {
        type: TYPE_MULTI_SELECT,
        required: true,
        options: [
          {
            value: 'needs_planning',
            label: <>My school hasn&#39;t started planning for 2023-24 yet.</>,
          },
          {
            value: 'no_plans',
            label: (
              <>
                I haven&#39;t seen the 2023-24 master schedule or heard of any plans for offering
                new AP courses.
              </>
            ),
          },
          {
            value: 'staff_not_familiar',
            label: (
              <>
                I don&#39;t think my school&#39;s AP teachers or administration know about AP
                Precalculus.
              </>
            ),
          },
          {
            value: 'students_not_interested',
            label: <>I&#39;m not sure if students will be interested in AP Precalculus.</>,
          },
          {
            value: 'no_teacher',
            label: (
              <>I&#39;m not sure if we have the appropriate teacher staff for AP Precalculus.</>
            ),
          },
          {
            value: 'other',
            label: 'Other',
            inline: true,
          },
        ],
      },
    },
    {
      qid: 'Q4',
      question:
        'Does your school plan to add AP Computer Science Principles to the 2023-24 master schedule?',
      response: {
        type: TYPE_SELECT,
        required: true,
        options: [
          {
            value: 'Y',
            label: 'Yes',
          },
          {
            value: 'N',
            label: 'No',
          },
          {
            value: 'U',
            label: 'Unsure',
          },
          {
            value: 'O',
            label: 'We already offer AP Computer Science Principles',
          },
        ],
      },
    },
    {
      qid: 'Q11',
      question: 'In which grade(s) does your school plan to offer AP Computer Science Principles?',
      pResponse: 'Y',
      pqid: 'Q4',
      response: {
        type: TYPE_MULTI_SELECT,
        required: true,
        options: [
          {
            value: '9',
            label: '9',
          },
          {
            value: '10',
            label: '10',
          },
          {
            value: '11',
            label: '11',
          },
          {
            value: '12',
            label: '12',
          },
        ],
      },
    },
    {
      qid: 'Q12',
      question:
        'How many sections of AP Computer Science Principals does your school plan to offer?',
      pResponse: 'Y',
      pqid: 'Q4',
      response: {
        type: TYPE_NUMERIC_RANGE,
        required: true,
        range: '1-99',
      },
    },
    {
      qid: 'Q13',
      question: (
        <>
          Why doesn&#39;t your school plan to offer AP Computer Science Principles in the next
          academic year?
        </>
      ),
      pResponse: 'N',
      pqid: 'Q4',
      response: {
        type: TYPE_SELECT,
        required: true,
        options: [
          {
            value: 'no_room',
            label: <>We don&#39;t have room in the 2023-24 master schedule.</>,
          },
          {
            value: 'no_teacher',
            label: <>We don&#39;t have a qualified teacher.</>,
          },
          {
            value: 'students_not_interested',
            label: 'Our students are not interested.',
          },
          {
            value: 'cannot_afford',
            label:
              'We cannot afford to offer a new AP course (e.g., teacher training, new textbooks)',
          },
          {
            value: 'other',
            label: 'Other',
            inline: true,
          },
        ],
      },
    },
    {
      qid: 'Q14',
      question: 'Why did you choose Not Sure?',
      pResponse: 'U',
      pqid: 'Q4',
      response: {
        type: TYPE_MULTI_SELECT,
        required: true,
        options: [
          {
            value: 'needs_planning',
            label: <>My school hasn&#39;t started planning for 2023-24 yet.</>,
          },
          {
            value: 'no_plans',
            label: (
              <>
                I haven&#39;t seen the 2023-24 master schedule or heard of any plans for offering
                new AP courses.
              </>
            ),
          },
          {
            value: 'staff_not_familiar',
            label: (
              <>
                I don&#39;t think my school&#39;s AP teachers or administration know about AP
                Computer Science Principles.
              </>
            ),
          },
          {
            value: 'students_not_interested',
            label: (
              <>
                I&#39;m not sure if students will be interested in AP Computer Science Principles.
              </>
            ),
          },
          {
            value: 'no_teacher',
            label: (
              <>
                I&#39;m not sure if we have appropriate teaching staff for AP Computer Science
                Principles.
              </>
            ),
          },
          {
            value: 'other',
            label: 'Other',
            inline: true,
          },
        ],
      },
    },
    {
      qid: 'Q5',
      question: 'Does your school plan to add AP Seminar to the 2023-24 master schedule?',
      response: {
        type: TYPE_SELECT,
        required: true,
        options: [
          {
            value: 'Y',
            label: 'Yes',
          },
          {
            value: 'N',
            label: 'No',
          },
          {
            value: 'U',
            label: 'Unsure',
          },
          {
            value: 'O',
            label: 'We already offer AP Seminar',
          },
        ],
      },
    },
    {
      qid: 'Q15',
      question: 'In which grade(s) does your school plan to offer AP Seminar?',
      pResponse: 'Y',
      pqid: 'Q5',
      response: {
        type: TYPE_MULTI_SELECT,
        required: true,
        options: [
          {
            value: '9',
            label: '9',
          },
          {
            value: '10',
            label: '10',
          },
          {
            value: '11',
            label: '11',
          },
          {
            value: '12',
            label: '12',
          },
        ],
      },
    },
    {
      qid: 'Q16',
      question: 'How many sections of AP Seminar does your school plan to offer?',
      pResponse: 'Y',
      pqid: 'Q5',
      response: {
        type: TYPE_NUMERIC_RANGE,
        required: true,
        range: '1-99',
      },
    },
    {
      qid: 'Q17',
      question: (
        <>Why doesn&#39;t your school plan to offer AP Seminar in the next academic year?</>
      ),
      pResponse: 'N',
      pqid: 'Q5',
      response: {
        type: TYPE_SELECT,
        required: true,
        options: [
          {
            value: 'no_room',
            label: <>We don&#39;t have room in the 2023-24 master schedule.</>,
          },
          {
            value: 'no_teacher',
            label: <>We don&#39;t have a qualified teacher.</>,
          },
          {
            value: 'not_interested',
            label: 'Our students are not interested.',
          },
          {
            value: 'cannot_afford',
            label:
              'We cannot afford to offer a new AP course (e.g., teacher training, new textbooks)',
          },
          {
            value: 'other',
            label: 'Other',
            inline: true,
          },
        ],
      },
    },
    {
      qid: 'Q18',
      question: 'Why did you choose Not Sure?',
      pResponse: 'U',
      pqid: 'Q5',
      response: {
        type: TYPE_MULTI_SELECT,
        required: true,
        options: [
          {
            value: 'needs_planning',
            label: <>My school hasn&#39;t started planning for 2023-24 yet.</>,
          },
          {
            value: 'no_plans',
            label: (
              <>
                I haven&#39;t seen the 2023-24 master schedule or heard of any plans for offering
                new AP courses.
              </>
            ),
          },
          {
            value: 'staff_not_familiar',
            label: (
              <>
                I don&#39;t think my school&#39;s AP teachers or administration know about AP
                Seminar.
              </>
            ),
          },
          {
            value: 'students_not_interested',
            label: <>I&#39;m not sure if students will be interested in AP Seminar.</>,
          },
          {
            value: 'no_teacher',
            label: <>I&#39;m not sure if we have appropriate teaching staff for AP Seminar.</>,
          },
          {
            value: 'other',
            label: 'Other',
            inline: true,
          },
        ],
      },
    },
  ],
}
