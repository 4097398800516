import * as c from '../constants/ExamReadinessConstants'
import template from './template'

export default (state, action) => {
  const { type, payload } = action
  switch (type) {
    case c.REPROCESS_EXAM_STATUSES:
      return {
        ...state,
        examObj: { ...state.examObj, ...payload.examObj },
        examRefreshAlarms: {
          ...state.examRefreshAlarms,
          [payload.alarmId]: {
            ...state.examRefreshAlarms[payload.alarmId],
            checkpoints: payload.checkpoints,
          },
        },
      }
    case c.UPDATE_EXAM_STATUS:
    case c.UPDATE_MAKEUP_STATUS_FULFILLED:
      return {
        ...state,
        examObj: { ...state.examObj, ...payload.examObj },
      }
    case c.UPDATE_MAKEUP_STATUS_PENDING:
      return {
        ...state,
        examObj: {
          ...state.examObj,
          [payload]: { ...state.examObj[payload], makeupStatusSaving: true },
        },
      }
    case c.UPDATE_MAKEUP_STATUS_REJECTED:
      return {
        ...state,
        examObj: {
          ...state.examObj,
          [payload.studentExamId]: {
            ...state.examObj[payload.studentExamId],
            makeupStatusSaving: false,
            makeupStatusError: payload.error,
          },
        },
      }

    default:
      return template(state, action, c, 'EXAM_READINESS')
  }
}
