import { reduxForm, change, formValueSelector } from 'redux-form'
import { BasicInput } from '../common'
import { changeStep } from '../../actions/funding'
import { StepButtons } from '.'
import {
  TOTAL_FUNDING_STEPS,
  FUNDING_CENTRAL_BILL_CONFIRMATION_FORM_NAME,
  FUNDING_CENTRAL_BILL_CONFIRMATION_FORM_FIELDS as FIELDS,
} from '../../constants/FundingConstants'

const mapStateToProps = state => {
  const selector = formValueSelector(FUNDING_CENTRAL_BILL_CONFIRMATION_FORM_NAME)
  return {
    initialValues: {
      [FIELDS.confirmCentralBilling.name]: false,
    },
    currentValues: {
      [FIELDS.confirmCentralBilling.name]: selector(state, FIELDS.confirmCentralBilling.name),
    },
  }
}

const ConfirmCentralBillFunding = ({ currentValues, handleSubmit, change }) => {
  return (
    <>
      <form onSubmit={handleSubmit} style={{ margin: '24px 150px' }}>
        <BasicInput
          type="checkbox"
          label="I attest that I understand the implications of creating a Central Billing arrangement between my district and the College Board, and will pay all invoiced charges that my selected schools incur."
          input={{
            name: FIELDS.confirmCentralBilling.name,
            onChange: () =>
              change(
                FIELDS.confirmCentralBilling.name,
                !currentValues[FIELDS.confirmCentralBilling.name]
              ),
          }}
          required={FIELDS.confirmCentralBilling.required}
        />
      </form>
      <StepButtons
        form={FUNDING_CENTRAL_BILL_CONFIRMATION_FORM_NAME}
        previousStep={3}
        totalSteps={TOTAL_FUNDING_STEPS}
      />
    </>
  )
}

export default connect(mapStateToProps, { changeStep, change })(
  reduxForm({
    form: FUNDING_CENTRAL_BILL_CONFIRMATION_FORM_NAME,
    onSubmit: (vals, dispatch, { changeStep }) => changeStep(5),
  })(ConfirmCentralBillFunding)
)
