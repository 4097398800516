import { reduxForm } from 'redux-form'
import { Text, Textarea, PositiveNumberValidation, normalizeToNumber } from '../common'
import { EXAM_ROOM_FORM, EXAM_ROOM_FIELDS as FIELDS } from '../../constants/ExamRoomConstants'
import { addExamRoom, editExamRoom, reassignStudents } from '../../actions/examRoom'

const ExamRoomForm = () => {
  return (
    <form>
      <p>
        Refer to the{' '}
        <a
          href="https://apcentral.collegeboard.org/ap-coordinators/resource-library"
          target="_blank"
          title="AP Coordinator's Manual (Opens in a new window)"
        >
          AP Coordinator&#39;s Manual
        </a>{' '}
        for complete testing procedures, seating policies, and proctoring requirements.
      </p>
      <div className="row">
        <div className="col-xs-8">
          <Text
            label="Room Name"
            isRequired={FIELDS.roomName.required}
            name={FIELDS.roomName.field}
          />
        </div>
        <div className="col-xs-4">
          <Text
            label="Capacity"
            isRequired={FIELDS.capacity.required}
            name={FIELDS.capacity.field}
            normalize={normalizeToNumber}
            maxlength="3"
            validate={[PositiveNumberValidation]}
          />
        </div>
      </div>
      <Text
        label="Name of Proctor(s)"
        isRequired={FIELDS.proctor.required}
        name={FIELDS.proctor.field}
      />
      <Textarea
        label={
          <>
            Description <em>(optional)</em>
          </>
        }
        placeholder="Enter Description"
        name={FIELDS.description.field}
      />
    </form>
  )
}

export default connect(
  null,
  { addExamRoom, editExamRoom, reassignStudents }
)(
  reduxForm({
    form: EXAM_ROOM_FORM,
    onSubmit: async (values, dispatch, props) => {
      const {
        addExamRoom,
        editExamRoom,
        isEdit,
        oldRoom,
        isCreateAndReassign,
        reassignStudents,
        selectedStudents,
        orgId,
        educationPeriod,
      } = props

      if (isCreateAndReassign) {
        const newRoom = await addExamRoom(props, values)
        await reassignStudents({ selectedStudents, orgId, educationPeriod, oldRoom, newRoom })
      } else if (isEdit) await editExamRoom(props, values)
      else await addExamRoom(props, values)
    },
  })(ExamRoomForm)
)
