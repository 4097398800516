import RosterManagementForm from '../../../containers/forms/RosterManagementForm'
import { StepTracker, StepButtons } from '..'
import { ROSTER_MANAGEMENT_FORM } from '../../../constants/SetupConstants'
import { changeStep } from '../../../actions/settingsOrg'

const mapStateToProps = (state, ownProps) => {
  const { backStep } = ownProps
  const { selectedOrgId, selectedRole, roles } = state.user.data
  const orgSettings = state.settingsOrg[selectedOrgId] || {}
  const { updated } = orgSettings
  const isLevelOne = roles[selectedRole].level === 1 || orgSettings?.data?.examAdministration?.orderByAnotherSchool

  return {
    backStep,
    updated,
    isLevelOne,
  }
}

class SetupRosterManagement extends Component {
  componentDidUpdate(prevProps) {
    const { updated, isLevelOne, changeStep } = this.props

    if (prevProps.updated !== updated && updated === true) {
      changeStep(isLevelOne ? 4 : 5)
      window.scrollTo(0, 0)
    }

  }

  render() {
    const { backStep } = this.props

    return (
      <>
        <StepTracker />

        <h3 className="h1" style={{ marginTop: '40px' }}>Teacher Management of the Student Roster</h3>
        <p>By default, AP teachers can manage changes to the student roster by dropping students from their class sections or moving  students between their own class sections.</p>
        <p>If at anytime you want to restrict your teachers&#39; ability to drop or move students from their class sections, you can disable this feature here or through the <strong>Settings</strong> page:</p>
        <RosterManagementForm />
        <p><strong>Note:</strong> If you leave this option enabled, you may want to disable it <strong><em>after</em></strong> you submit your exam order to avoid unintended adjustments to the roster.</p>

        <StepButtons backStep={backStep} form={ROSTER_MANAGEMENT_FORM} />
      </>
    )
  }
}

export default connect(mapStateToProps, { changeStep })(SetupRosterManagement)
