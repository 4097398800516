import { useHistory } from 'react-router-dom'
import { isDateWithinRange, isDateAfter, formatDate } from '@myap/ui-library/esm/date'
import {
  ContactInformation,
  SchoolSelection,
  FundingType,
  FundingConfiguration,
  FinalConfirmation,
} from '../../components/funding'
import { setSelectedRole } from '../../actions/user'
import {
  changeStep,
  fetchDistrictFunding,
  fetchDistrictFundingLockDate,
  setDistrictFundingTypeData,
  setSavedDistrictFundingData,
} from '../../actions/funding'
import { Loader } from '../../components/common'
import { getDistrictOrgId } from '../../selectors/user'
import { isFeatureEnabled } from '../../selectors/features'
import { isEmpty } from '../../utils/common'
import { NAV_LINKS } from '../../constants/NavConstants'
import { FUNDING_CENTRAL_BILL, FUNDING_SUBSIDY } from '../../constants/FundingConstants'

const mapStateToProps = state => {
  const {
    user: {
      data: { isDistrictFundingAdmin, isDistrictSuperAdmin, acceptedFundingTAC, selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    settingsDeadlines: { data: deadlines },
    districtFundingLockStatus: {
      fetching: fetchingDistrictLockStatus,
      error: districtLockStatusError,
      data: districtLockStatus,
    },
    funding: { step, fetching: fetchingFunding, fetched, saved, data, error: fundingError },
  } = state
  const orgId = getDistrictOrgId(state)
  const { districtFundingStartDate, districtFundingLockDate } = deadlines?.[educationPeriodCd] ?? {}
  const { unlockExpiryDate } = districtLockStatus ?? {}

  const fundingLockDate = isDateAfter(unlockExpiryDate, districtFundingLockDate)
    ? unlockExpiryDate
    : districtFundingLockDate
  const today = formatDate(new Date())
  const fundingLocked = !isDateWithinRange(today, districtFundingStartDate, fundingLockDate)

  return {
    isEnabled: isFeatureEnabled(state, 'DISTRICT_FUNDING'),
    isDistrictFundingAdmin,
    isDistrictSuperAdmin,
    acceptedFundingTAC,
    selectedOrgId,
    orgId,
    educationPeriodCd,
    step,
    fetching: fetchingDistrictLockStatus || fetchingFunding,
    fetched,
    saved,
    data,
    error: fundingError || districtLockStatusError,
    fundingLocked,
    hasStep1Data: !isEmpty(data?.grantor?.grantorName),
  }
}

const DistrictFundingPage = ({
  isEnabled,
  isDistrictFundingAdmin,
  isDistrictSuperAdmin,
  acceptedFundingTAC,
  selectedOrgId,
  orgId,
  educationPeriodCd,
  step,
  fetching,
  fetched,
  saved,
  hasStep1Data,
  data,
  error,
  title,
  fundingLocked,
  changeStep,
  fetchDistrictFunding,
  fetchDistrictFundingLockDate,
  setSelectedRole,
  setDistrictFundingTypeData,
  setSavedDistrictFundingData,
}) => {
  const history = useHistory()

  useEffect(() => {
    if (!isEnabled) {
      window.location = Config.MYAP_URL.prof
    }
  }, [])

  useEffect(() => {
    if (selectedOrgId !== orgId) {
      // Set role to that of the District Admin
      setSelectedRole(orgId)
    }
  }, [])

  useEffect(() => {
    document.title = `${title} | AP Registration and Ordering`
  }, [])

  useEffect(() => {
    if ((isDistrictFundingAdmin || isDistrictSuperAdmin) && !acceptedFundingTAC) {
      history.push(NAV_LINKS.tac)
    } else if (orgId && educationPeriodCd) {
      fetchDistrictFunding(orgId, educationPeriodCd)
      fetchDistrictFundingLockDate({ orgId, educationPeriodCd })
    }
  }, [orgId, educationPeriodCd, isDistrictSuperAdmin, isDistrictFundingAdmin, acceptedFundingTAC])

  useEffect(() => {
    if (fetched) {
      if (!hasStep1Data) {
        changeStep(1)
      } else {
        if (!isEmpty(data?.subsidies)) {
          setDistrictFundingTypeData(FUNDING_SUBSIDY)
        } else {
          setDistrictFundingTypeData(FUNDING_CENTRAL_BILL)
        }
        setSavedDistrictFundingData(data)
        changeStep(5)
      }
    }
  }, [fetched])

  useEffect(() => {
    if (saved && !error) {
      fetchDistrictFunding(orgId, educationPeriodCd)
    }
  }, [saved])

  // Do not render page if District Funding has been disabled or if user
  // has not accepted funding Terms & Conditions
  if (!isEnabled || ((isDistrictFundingAdmin || isDistrictSuperAdmin) && !acceptedFundingTAC))
    return null

  if (fetching && !saved)
    return (
      <div className="container" style={{ marginTop: 20 }}>
        <Loader />
      </div>
    )

  const DisplayFundingStep = () => {
    switch (step) {
      case 1:
        return <ContactInformation />

      case 2:
        return <SchoolSelection />

      case 3:
        return <FundingType />

      case 4:
        return <FundingConfiguration />

      case 5:
        return <FinalConfirmation />

      default:
        return null
    }
  }

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <>
        <div className="container" style={{ marginTop: 20 }}>
          {fundingLocked && !hasStep1Data ? (
            <p>
              District Funding setup is currently locked.{' '}
              <a href={Config.MYAP_URL.prof}>Return to MyAP</a>
            </p>
          ) : (
            <DisplayFundingStep />
          )}
        </div>
      </>
    </CSSTransition>
  )
}

export default connect(mapStateToProps, {
  changeStep,
  fetchDistrictFunding,
  fetchDistrictFundingLockDate,
  setSelectedRole,
  setDistrictFundingTypeData,
  setSavedDistrictFundingData,
})(DistrictFundingPage)
