import { ExamAdministration } from '../../setup/'
import { Error, Loader, CustomerServiceContact } from '../../common'
import { fetchOrgSettings } from '../../../actions/settingsOrg'

const mapStateToProps = state => {
  const { selectedOrgId } = state.user.data
  const { fetching, fetched, error } = state.settingsOrg[selectedOrgId] || {}
  const { selectedEducationPeriod: educationPeriodCd } = state.settingsEducationPeriod
  return { fetching, fetched, error, selectedOrgId, educationPeriodCd }
}

class ExamAdministrationPanel extends Component {
  componentDidMount() {
    const { fetchOrgSettings, selectedOrgId, educationPeriodCd, fetching, fetched } = this.props

    if (!fetching && !fetched) {
      fetchOrgSettings(selectedOrgId, educationPeriodCd)
    }
  }

  render() {
    const { fetching, fetched, error } = this.props

    if (fetching) {
      return <Loader />
    }

    if (error) {
      return <Error title="Error Retrieving Data" message="An error occurred. Please try again later." />
    }

    if (fetched) {
      return (
        <div>
          <p>If you need to change who is administering your AP exams, please contact:</p>
          <CustomerServiceContact />
          <ExamAdministration readOnly={true} />
        </div>
      )
    }

    return null
  }
}

export default connect(mapStateToProps, { fetchOrgSettings })(ExamAdministrationPanel)
