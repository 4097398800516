import { formatDate, manipulateYear } from '@myap/ui-library/esm/date'
import { iam } from '@myap/ui-library/esm/profile'
import { SubmissionError } from 'redux-form'
import * as c from '../constants/OrderConstants'
import axios, { dispatchError, getServerErrorMessage } from '../utils/axios'
import {
  normalizeMaxAllowedSecureMaterials,
  normalizeSelectedSecureMaterialsForCourse,
} from '../utils/normalize'
import { isEmpty } from '../utils/common'
import { sortColumnByKey } from '../utils/sort'
import { getExamWindowRefData } from '../selectors/examWindows'

const { getJWTToken, getAuthSession } = iam()

const getOrgIdAndEdPeriod = getState => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
  } = getState()
  return { selectedOrgId, educationPeriodCd: selectedEducationPeriod }
}

const modifyExamSummariesForEndOfCourseExam = summaries => {
  return summaries
    ? summaries.map(c => {
        // In order to sort No EOC courses to the bottom of the table sorted by exam date, we'll add
        // a year to the provided exam date.
        if (c.noEndOfCourseExam) {
          // Because there is no consensus on which term to use
          if (c.examDate !== undefined) {
            c.examDate = formatDate(manipulateYear(c.examDate, 1))
          } else if (c.testDate !== undefined) {
            c.testDate = formatDate(manipulateYear(c.testDate, 1))
          }
        }
        return { ...c }
      })
    : []
}

const alignMaterialsDataForMaterialsAdjustmentTable = data =>
  data.map(m => ({ ...m, materialAdjustment: m.quantityAdjusted, materialName: m.ancillaryCdName }))
const alignScoreReportingDataForMaterialsAdjustmentTable = data => {
  return Object.keys(data).reduce((arr, key) => {
    return data[key] !== null
      ? [
          ...arr,
          {
            materialName: c.SCORE_REPORTING_SERVICES_LABELS[key],
            materialAdjustment: data[key],
          },
        ]
      : arr
  }, [])
}

export const fetchUnsubmittedOrderChanges = (orgId, educationPeriodCd) => async dispatch => {
  const urlUnsubmittedChanges = () =>
    axios.get(
      `${Config.API_URL}/paapro/v1/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/summary/pending`
    )
  const urlUnsubmittedSecureMaterialsChanges = () =>
    axios.get(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/ancillary-material/pending-submit-ancillary-materials`
    )

  dispatch({ type: c.FETCH_ORDER_UNSUBMITTED_CHANGES_PENDING })
  try {
    const [{ data: unsubmittedChanges }, { data: unsubmittedSecureMaterials }] = await axios.all([
      urlUnsubmittedChanges(),
      urlUnsubmittedSecureMaterialsChanges(),
    ])
    const { allExamsSummary, examSummaries, scoreReportingOrderChangeSummary } = unsubmittedChanges
    dispatch({
      type: c.FETCH_ORDER_UNSUBMITTED_CHANGES_FULFILLED,
      payload: {
        unsubmittedExamChanges: {
          allExamsSummary,
          examSummaries: modifyExamSummariesForEndOfCourseExam(examSummaries),
        },
        unsubmittedMaterialChanges: [
          ...alignScoreReportingDataForMaterialsAdjustmentTable(
            scoreReportingOrderChangeSummary || {}
          ),
          ...alignMaterialsDataForMaterialsAdjustmentTable(unsubmittedSecureMaterials || []),
        ],
      },
    })
  } catch (err) {
    dispatch({
      type: c.FETCH_ORDER_UNSUBMITTED_CHANGES_REJECTED,
      payload: getServerErrorMessage(err),
    })
  }
}

export const fetchExamOrderHistory = (orgId, educationPeriodCd) => async (dispatch, getState) => {
  dispatch({ type: c.FETCH_ORDER_HISTORY_PENDING })
  try {
    const {
      data: { mainOrder, additionalOrders },
    } = await axios.get(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/summary/history`
    )

    dispatch({
      type: c.FETCH_ORDER_HISTORY_FULFILLED,
      payload: {
        mainOrder: {
          ...mainOrder,
          examSummaries: modifyExamSummariesForEndOfCourseExam(mainOrder.examSummaries),
          materialSummaries: [
            ...alignScoreReportingDataForMaterialsAdjustmentTable(
              mainOrder.scoreReportingOrderChangeSummary || {}
            ),
            ...alignMaterialsDataForMaterialsAdjustmentTable(
              mainOrder.materialAdjustmentHistory || []
            ),
          ],
        },
        additionalOrders: additionalOrders.map(a => ({
          ...a,
          examSummaries: modifyExamSummariesForEndOfCourseExam(a.examSummaries),
          materialSummaries: [
            ...alignScoreReportingDataForMaterialsAdjustmentTable(
              a.scoreReportingOrderChangeSummary || {}
            ),
            ...alignMaterialsDataForMaterialsAdjustmentTable(a.materialAdjustmentHistory || []),
          ],
        })),
      },
    })
  } catch (err) {
    dispatch({ type: c.FETCH_ORDER_HISTORY_REJECTED, payload: getServerErrorMessage(err) })
  }
}

const fetchApprovalRequestCounts =
  ({ orgId, educationPeriodCd }) =>
  async dispatch => {
    dispatch({ type: c.FETCH_APPROVAL_REQUEST_COUNTS_PENDING })
    try {
      const { data } = await axios.get(
        `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/approval-queue-counts`
      )
      dispatch({ type: c.FETCH_APPROVAL_REQUEST_COUNTS_FULFILLED, payload: data })
    } catch (err) {
      dispatch({
        type: c.FETCH_APPROVAL_REQUEST_COUNTS_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const fetchExamsWithSecureMaterials =
  ({ orgId, educationPeriodCd }) =>
  async dispatch => {
    dispatch({ type: c.FETCH_EXAMS_WITH_SECURE_MATERIALS_PENDING })
    try {
      const response = await axios.get(
        `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/secure-materials-exam-statuses`
      )
      dispatch({
        type: c.FETCH_EXAMS_WITH_SECURE_MATERIALS_FULFILLED,
        payload: { data: response.data },
      })
    } catch (err) {
      dispatch({
        type: c.FETCH_EXAMS_WITH_SECURE_MATERIALS_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const fetchExamOrderSummary = (orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_EXAM_ORDER_SUMMARY_PENDING })
  try {
    const response = await axios.get(
      `${Config.API_URL}/paapro/v1/order-exam-summary/organizations/${orgId}/education-periods/${educationPeriodCd}`
    )
    const { data } = response
    data.courseExamSummaries = modifyExamSummariesForEndOfCourseExam(data.courseExamSummaries)
    data.courseExamSummaries = data.courseExamSummaries.map(c => {
      return {
        ...c,
        numberNotTakingExams:
          parseInt(c.numberNotTakingExams, 10) + parseInt(c.numberUndecided, 10),
      }
    })
    dispatch({ type: c.FETCH_EXAM_ORDER_SUMMARY_FULFILLED, payload: data })
    dispatch(fetchExamsWithSecureMaterials({ orgId, educationPeriodCd }))
    dispatch(fetchApprovalRequestCounts({ orgId, educationPeriodCd }))
  } catch (err) {
    dispatch({ type: c.FETCH_EXAM_ORDER_SUMMARY_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const fetchProgramStats = (orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_PROGRAM_STATS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/v1/program-summary/organizations/${orgId}/education-periods/${educationPeriodCd}`
    )
    dispatch({ type: c.FETCH_PROGRAM_STATS_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: c.FETCH_PROGRAM_STATS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const fetchLabelSortOrder = (orgId, educationPeriodCd) => dispatch => {
  dispatch({ type: c.FETCH_STUDENT_LABEL_SORT_ORDER_PENDING })

  return axios
    .get(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/label-sort-order`
    )
    .then(res =>
      dispatch({
        type: c.FETCH_STUDENT_LABEL_SORT_ORDER_FULFILLED,
        payload: {
          labelSortOrder: res.data.labelSortOrder || c.STUDENT_LABEL_SORT_ORDER_SORT_BYNAME,
        },
      })
    )
    .catch(dispatchError.bind(this, dispatch, c.FETCH_STUDENT_LABEL_SORT_ORDER_REJECTED))
}

export const confirmExamOrderSubmissionCanceled = () => dispatch =>
  dispatch({ type: c.CONFIRM_EXAM_ORDERS_CANCELED })
export const confirmExamOrderSubmissionSubmitted = () => dispatch =>
  dispatch({ type: c.CONFIRM_EXAM_ORDERS_SUBMITTED })
export const confirmExamOrderSubmissionPending = () => dispatch =>
  dispatch({ type: c.CONFIRM_EXAM_ORDERS_PENDING })

export const submitExamOrders = () => (dispatch, getState) => {
  const { selectedOrgId, educationPeriodCd } = getOrgIdAndEdPeriod(getState)
  dispatch({ type: c.SUBMIT_EXAM_ORDERS_PENDING })
  return axios
    .post(
      `${Config.API_URL}/orders/organizations/${selectedOrgId}/education-periods/${educationPeriodCd}/submission`
    )
    .then(res => {
      dispatch({ type: c.SUBMIT_EXAM_ORDERS_FULFILLED, payload: res.data })
    })
    .catch(dispatchError.bind(this, dispatch, c.SUBMIT_EXAM_ORDERS_REJECTED))
}

export const submitLabelSortOrder = (values, orgId, educationPeriodCd) => dispatch => {
  dispatch({ type: c.SUBMIT_STUDENT_LABEL_SORT_ORDER_PENDING })

  return axios
    .post(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/label-sort-order`,
      values
    )
    .then(res => {
      dispatch({ type: c.SUBMIT_STUDENT_LABEL_SORT_ORDER_FULFILLED, payload: values })
    })
    .catch(err => {
      throw new SubmissionError({ _error: getServerErrorMessage(err) })
    })
}

export const fetchMaxAllowedSecureMaterials =
  ({ testCd, testDayType }) =>
  async (dispatch, getState) => {
    const { selectedOrgId, educationPeriodCd } = getOrgIdAndEdPeriod(getState)
    dispatch({ type: c.FETCH_MAX_ALLOWED_SECURE_MATERIALS_PENDING })
    try {
      const response = await axios.get(
        `${Config.API_URL}/orders/organizations/${selectedOrgId}/education-periods/${educationPeriodCd}/max-allowed-secure-materials`,
        { params: { testCd, testDayType } }
      )
      dispatch({
        type: c.FETCH_MAX_ALLOWED_SECURE_MATERIALS_FULFILLED,
        payload: normalizeMaxAllowedSecureMaterials(response.data),
      })
    } catch (err) {
      dispatch({
        type: c.FETCH_MAX_ALLOWED_SECURE_MATERIALS_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const fetchSelectedSecureMaterials =
  ({ testCd, testDayType }) =>
  async (dispatch, getState) => {
    const { selectedOrgId, educationPeriodCd } = getOrgIdAndEdPeriod(getState)
    dispatch({ type: c.FETCH_SELECTED_SECURE_MATERIALS_PENDING })
    try {
      const response = await axios.get(
        `${Config.API_URL}/orders/organizations/${selectedOrgId}/education-periods/${educationPeriodCd}/secure-ancillary-materials`,
        { params: { testCd, testDayType } }
      )
      let payloadData = {}
      if (!isEmpty(response.data) && Object.keys(response.data).length > 0) {
        payloadData = {
          ...response.data,
          ...normalizeSelectedSecureMaterialsForCourse(response.data.items),
        }
      }
      dispatch({ type: c.FETCH_SELECTED_SECURE_MATERIALS_FULFILLED, payload: payloadData })
    } catch (err) {
      dispatch({
        type: c.FETCH_SELECTED_SECURE_MATERIALS_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const updateSelectedSecureMaterials = data => async (dispatch, getState) => {
  const { selectedOrgId, educationPeriodCd } = getOrgIdAndEdPeriod(getState)
  const config = {
    method: 'post',
    url: `${Config.API_URL}/orders/organizations/${selectedOrgId}/education-periods/${educationPeriodCd}/secure-ancillary-materials`,
    data,
  }
  try {
    dispatch({ type: c.UPDATE_SELECTED_SECURE_MATERIALS_PENDING })
    const res = await axios.request(config)
    dispatch({ type: c.UPDATE_SELECTED_SECURE_MATERIALS_FULFILLED, payload: res.data })
  } catch (err) {
    const errMsg = getServerErrorMessage(err)
    dispatch({ type: c.UPDATE_SELECTED_SECURE_MATERIALS_REJECTED, payload: errMsg })
    throw new SubmissionError({ _error: errMsg })
  }
}

export const fetchScoreReportingServices = (orgId, educationPeriodCd) => async dispatch => {
  try {
    dispatch({ type: c.FETCH_SCORE_REPORTING_SERVICES_PENDING })
    const { data } = await axios.get(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/score-reporting-order`
    )
    dispatch({ type: c.FETCH_SCORE_REPORTING_SERVICES_FULFILLED, payload: data })
  } catch (err) {
    dispatch({
      type: c.FETCH_SCORE_REPORTING_SERVICES_REJECTED,
      payload: getServerErrorMessage(err),
    })
  }
}

export const saveScoreReportingServices = (orgId, educationPeriodCd, updates) => async dispatch => {
  try {
    dispatch({ type: c.SAVE_SCORE_REPORTING_SERVICES_PENDING })
    const { data } = await axios.post(
      `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/score-reporting-order`,
      updates
    )
    dispatch({ type: c.SAVE_SCORE_REPORTING_SERVICES_FULFILLED, payload: data })
    dispatch(fetchExamOrderSummary(orgId, educationPeriodCd)) // saving creates pending order, need to refetch summary
  } catch (err) {
    dispatch({
      type: c.SAVE_SCORE_REPORTING_SERVICES_REJECTED,
      payload: getServerErrorMessage(err),
    })
  }
}

export const fetchFreeResponseBookletsForDownload =
  (orgId, educationPeriodCd) => async dispatch => {
    try {
      dispatch({ type: c.FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_PENDING })
      const config = {
        headers: {
          'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
          'X-CB-Catapult-Authorization-Token': getJWTToken(),
        },
      }
      const {
        data: { frbResponses },
      } = await axios.get(
        `${Config.API_GATE_URL}/digitalfrb/organizations/${orgId}/education-periods/${educationPeriodCd}/frb-responses`,
        config
      )
      dispatch({
        type: c.FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_FULFILLED,
        payload: frbResponses,
      })
    } catch (err) {
      dispatch({
        type: c.FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const fetchFreeResponseBookletDownloadUrl =
  ({ orgId, educationPeriodCd, courses, examFormat }) =>
  async dispatch => {
    try {
      dispatch({ type: c.FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_PENDING, payload: courses })
      const config = {
        headers: {
          'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
          'X-CB-Catapult-Authorization-Token': getJWTToken(),
        },
      }
      const { data } = await axios.get(
        `${Config.API_GATE_URL}/digitalfrb/organizations/${orgId}/education-periods/${educationPeriodCd}/courses/${courses}/exam-formats/${examFormat}/frb-download`,
        config
      )
      const testCd = data?.testCd
      const frbDownloadUrl = data?.frbDownloadUrl
      const lastDownloadDate = data?.lastDownloadDate
      dispatch({
        type: c.FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_FULFILLED,
        payload: { testCd, examFormat, frbDownloadUrl, lastDownloadDate },
      })
    } catch (err) {
      dispatch({
        type: c.FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_REJECTED,
        payload: { course: courses, examFormat, error: getServerErrorMessage(err) },
      })
    }
  }

export const setApprovalRequestsFilter = selectedFilter => dispatch => {
  dispatch({ type: c.SET_APPROVAL_REQUEST_FILTER, payload: selectedFilter })
}

const processTestWindow = (testWindowData = []) => {
  const buildingObj = {}
  testWindowData.forEach(val => {
    const selectedQuantity = val.quantity
    const providedReasonForOrder = val.reasonForOrder
    const quantityOrdered = val.quantityOrdered || 0
    buildingObj[`cd_${val.ancillaryCd}`] = {
      ...val,
      selectedQuantity,
      providedReasonForOrder,
      quantityOrdered,
    }
  })
  return buildingObj
}

const sortNonSecureItems = items => sortColumnByKey(items, 'description', 'asc')

export const fetchCoordNonSecureAncillaryMaterials =
  (orgId, educationPeriodCd, testDayType = '') =>
  async dispatch => {
    const testDayTypeWindow = !isEmpty(testDayType) ? `?testDayType=${testDayType}` : ''
    try {
      dispatch({ type: c.FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_PENDING })
      const { data } = await axios.get(
        `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/non-secure-ancillary-materials${testDayTypeWindow}`
      )
      const processedData = {}
      Object.keys(data.items).forEach(
        key => (processedData[key] = processTestWindow(sortNonSecureItems(data.items[key])))
      )
      dispatch({
        type: c.FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_FULFILLED,
        payload: {
          data: {
            orgId: data.orgId,
            educationPeriodCd: data.educationPeriodCd,
            ...processedData,
          },
        },
      })
    } catch (err) {
      dispatch({
        type: c.FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

const buildFormData = (data = {}) => {
  const buildingObj = {}
  Object.keys(data).forEach(
    key =>
      (buildingObj[key] = {
        ancillaryCd: data[key].ancillaryCd,
        selectedQuantity: data[key].selectedQuantity,
        providedReasonForOrder: data[key].providedReasonForOrder,
        reasonValidationError: data[key].reasonValidationError,
      })
  )
  return buildingObj
}

export const setupNonSecureAncillaryMaterialsFormData = data => dispatch =>
  dispatch({
    type: c.SETUP_NON_SECURE_ANCILLARY_MATERIALS_FORM_DATA,
    payload: { ...buildFormData(data) },
  })

export const saveCoordNonSecureAncillaryMaterials =
  ({ orgId, educationPeriodCd, changedData, initialDataByTestWindow, examWindowRefData }) =>
  async dispatch => {
    try {
      dispatch({ type: c.SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_PENDING })
      const allInitialData = Object.keys(examWindowRefData).reduce(
        (acc, adminWindow) => Object.assign(acc, initialDataByTestWindow[adminWindow]),
        {}
      )
      const submitUpdates = Object.keys(allInitialData)
        .map(key => ({ ...allInitialData[key], ...changedData[key] }))
        .filter(item => {
          const { quantity, selectedQuantity, reasonForOrder, providedReasonForOrder } = item
          return (
            selectedQuantity !== 0 ||
            quantity !== selectedQuantity ||
            reasonForOrder !== providedReasonForOrder
          )
        })
        .map(item => {
          const { ancillaryCd, selectedQuantity, providedReasonForOrder } = item
          return {
            ancillaryCd,
            quantity: selectedQuantity,
            reasonForOrder: providedReasonForOrder,
          }
        })
      const { data } = await axios.post(
        `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/non-secure-ancillary-materials`,
        submitUpdates
      )
      const processedData = {}
      Object.keys(data.items).forEach(
        key => (processedData[key] = processTestWindow(sortNonSecureItems(data.items[key])))
      )
      dispatch({
        type: c.SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_FULFILLED,
        payload: {
          data: { orgId: data.orgId, educationPeriodCd: data.educationPeriodCd, ...processedData },
        },
      })
      dispatch(fetchExamOrderSummary(orgId, educationPeriodCd)) // saving creates pending order, need to refetch summary
    } catch (err) {
      dispatch({
        type: c.SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const clearNonSecureAncillaryMaterialsFormData = () => dispatch =>
  dispatch({ type: c.CLEAR_NON_SECURE_ANCILLARY_MATERIALS_FORM_DATA })

export const changeNonSecureMaterialSelectedQuantity =
  (newQuantity, ancillaryCd) => (dispatch, getState) => {
    dispatch({
      type: c.CHANGE_NON_SECURE_MATERIAL_SELECTED_QUANTITY,
      payload: { newQuantity, ancillaryCd },
    })
  }

export const changeNonSecureMaterialProvidedReason =
  (newReasonForOrder, ancillaryCd) => dispatch => {
    dispatch({
      type: c.CHANGE_NON_SECURE_MATERIAL_PROVIDED_REASON,
      payload: { newReasonForOrder, ancillaryCd },
    })
  }

export const setNonSecureMaterialsReasonValidationError =
  (reasonValidationError, ancillaryCd) => dispatch => {
    dispatch({
      type: c.SET_NON_SECURE_MATERIAL_REASON_VALIDATION_ERROR,
      payload: { reasonValidationError, ancillaryCd },
    })
  }

export const fetchVolumeRebates = (orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_VOLUME_REBATE_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/paapro/v1/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/large-volume-rebate`
    )
    dispatch({ type: c.FETCH_VOLUME_REBATE_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: c.FETCH_VOLUME_REBATE_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const saveVolumeRebates = (orgId, educationPeriodCd, selections) => async dispatch => {
  dispatch({ type: c.SAVE_VOLUME_REBATE_PENDING })
  try {
    await axios.post(
      `${Config.API_URL}/paapro/v1/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/large-volume-rebate`,
      selections
    )
    dispatch({
      type: c.SAVE_VOLUME_REBATE_FULFILLED,
      payload: { largeVolumeRebateUses: selections },
    })
  } catch (err) {
    dispatch({ type: c.SAVE_VOLUME_REBATE_REJECTED, payload: getServerErrorMessage(err) })
  }
}
