import { reduxForm, SubmissionError } from 'redux-form'
import get from 'lodash-es/get'
import set from 'lodash-es/set'
import {
  isDateSameOrBefore,
  isDateSameOrAfter,
  formatDate,
  isDateBefore,
  manipulateYear,
  DATETIME_FORMATS,
} from '@myap/ui-library/esm/date'
import { Input, StreetAddress, ChangeAddressInstructions } from '../../components/common'
import {
  StepTracker,
  APCoordinator,
  TechnologyCoordinator,
  Principal,
  AuthorizedStaff,
  StudentPopulation,
  StepButtons,
  SchoolInfo,
  StudentFRPL,
  DeviceQuestions,
} from '../../components/setup'
import {
  SCHOOL_INFORMATION_FORM,
  SCHOOL_INFORMATION_FIELDS as FIELDS,
  DEVICE_IPAD,
  DEVICE_CHROMEBOOKS,
  DEVICE_MAC,
  DEVICE_WINDOWS,
  DEVICE_NONE,
  DISTRIBUTED_IND,
  AVAILABLE_IND,
} from '../../constants/SetupConstants'
import { updateOrgSettings, changeStep } from '../../actions/settingsOrg'
import { openModal } from '../../actions/app'
import { CheckDateFormat } from '../../components/common/forms/Validations'
import { isEmpty } from '../../utils/common'

const mapStateToProps = state => {
  const { selectedOrgId, isLevelOne } = state.user.data
  const settings = state.settingsOrg[selectedOrgId] || {}
  const { selectedEducationPeriod, availableEducationPeriods } = state.settingsEducationPeriod
  const {
    data,
    data: {
      schoolInformation: {
        address: { countryCode },
      },
    },
  } = settings

  const mergedInitialValues = {
    ...data,
    schoolInformation: {
      ...data.schoolInformation,
      schoolInfo: {
        ...data.schoolInformation.schoolInfo,
        reducedFeeAvgCostInd: null, // indicatorsto help with student fees form
        standardFeeAvgCostInd: null, // indicatorsto help with student fees form
        deviceAvailability: {
          ...data.schoolInformation.schoolInfo?.deviceAvailability,
          [DEVICE_CHROMEBOOKS]: {
            ...{
              [DISTRIBUTED_IND]: false,
              [AVAILABLE_IND]: false,
            },
            ...data.schoolInformation.schoolInfo?.deviceAvailability?.[DEVICE_CHROMEBOOKS],
          },
          [DEVICE_IPAD]: {
            ...{
              [DISTRIBUTED_IND]: false,
              [AVAILABLE_IND]: false,
            },
            ...data.schoolInformation.schoolInfo?.deviceAvailability?.[DEVICE_IPAD],
          },
          [DEVICE_MAC]: {
            ...{
              [DISTRIBUTED_IND]: false,
              [AVAILABLE_IND]: false,
            },
            ...data.schoolInformation.schoolInfo?.deviceAvailability?.[DEVICE_MAC],
          },
          [DEVICE_WINDOWS]: {
            ...{
              [DISTRIBUTED_IND]: false,
              [AVAILABLE_IND]: false,
            },
            ...data.schoolInformation.schoolInfo?.deviceAvailability?.[DEVICE_WINDOWS],
          },
          [DEVICE_NONE]: {
            ...{
              [DISTRIBUTED_IND]: false,
              [AVAILABLE_IND]: false,
            },
            ...data.schoolInformation.schoolInfo?.deviceAvailability?.[DEVICE_NONE],
          },
        },
      },
    },
  }

  return {
    initialValues: mergedInitialValues,
    countryCode,
    [`minimum${FIELDS.POP9.name}`]: 0,
    [`minimum${FIELDS.POP10.name}`]: 0,
    [`minimum${FIELDS.POP11.name}`]: 0,
    [`minimum${FIELDS.POP12.name}`]: 0,
    [`minimum${FIELDS.REDUCED_FEE_PCT.name}`]: 0,
    [`maximum${FIELDS.REDUCED_FEE_PCT.name}`]: 100,
    [`minimum${FIELDS.STUDENTS_USING_PROVIDED_DEVICES.name}`]: 0,
    [`maximum${FIELDS.STUDENTS_USING_PROVIDED_DEVICES.name}`]: 100,
    [`maximum${FIELDS.REDUCED_FEE_AVG_COST.name}`]: 150,
    [`maximum${FIELDS.STANDARD_FEE_AVG_COST.name}`]: 150,
    academicYearStart: availableEducationPeriods[selectedEducationPeriod]?.academicYrStartDate,
    isLevelOne,
  }
}

const CoordinatorSetupSchoolInfoForm = props => {
  const {
    backStep,
    countryCode,
    initialValues: {
      schoolInformation: {
        name,
        coordinator,
        technologyCoordinator,
        principal,
        additionalStaff,
        studentPopulation,
        schoolInfo,
        address,
        handleSubmit,
      },
    },
    openModal,
  } = props || {}
  let instructionsLink

  return (
    <div>
      <StepTracker />
      <h3 className="h1" style={{ marginTop: 40 }}>
        Complete Required School Information
      </h3>
      <p>
        Please complete and confirm the information about your school. Unless otherwise noted, this
        information is required to participate in AP exam administration and ordering. By providing
        AP coordinator, principal and additional authorized staff email addresses below, you
        acknowledge and agree to receive email communications from the College Board related to the
        AP Program and the AP Exam administration.
      </p>

      <h4 className="h2">School</h4>
      <div className="row" style={{ marginBottom: 40 }}>
        <div className="col-xs-6">
          <div style={{ textAlign: 'center', border: '1px solid #ccc', padding: '40px 0' }}>
            <div>
              <strong>{name}</strong>
            </div>
            <StreetAddress {...address} />
          </div>
        </div>
        <div className="col-xs-6">
          <p>
            If there is an error in the name or address,{' '}
            <a
              href="javascript:void(0)"
              ref={node => (instructionsLink = node)}
              onClick={() => {
                openModal('TextModal', {
                  title: 'Change School Address',
                  BodyComponent: ChangeAddressInstructions,
                  modalCloseFocusElem: instructionsLink,
                })
              }}
            >
              please click here to view instructions
            </a>
            .
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <APCoordinator {...coordinator} countryCode={countryCode} />
        <Principal {...principal} countryCode={countryCode} />
        <TechnologyCoordinator {...technologyCoordinator} countryCode={countryCode} />
        <AuthorizedStaff {...additionalStaff} />
        <StudentPopulation {...studentPopulation} />
        <StudentFRPL {...schoolInfo} />
        <SchoolInfo {...schoolInfo} />
        <DeviceQuestions {...schoolInfo} />
      </form>

      <div className="form-group text-right" style={{ marginTop: 40 }}>
        <Input
          type="checkbox"
          label="I confirm this information is correct."
          name={FIELDS.CONFIRM.name}
          isRequired={FIELDS.CONFIRM.required}
        />
      </div>

      <StepButtons backStep={backStep} form={SCHOOL_INFORMATION_FORM} />
    </div>
  )
}

export default connect(mapStateToProps, { openModal, updateOrgSettings })(
  reduxForm({
    form: SCHOOL_INFORMATION_FORM,
    validate: (values, props) => {
      const { academicYearStart, isLevelOne } = props
      const absoluteStartDate = manipulateYear(academicYearStart, -1)
      const startDate = get(values, FIELDS.START_DATE.name)
      const endDate = get(values, FIELDS.END_DATE.name)
      const errors = {}
      if (isDateSameOrBefore(endDate, startDate)) {
        set(errors, FIELDS.END_DATE.name, `Error: Date must be after first day of class.`)
      }
      if (isDateSameOrAfter(startDate, endDate)) {
        set(errors, FIELDS.START_DATE.name, `Error: Date must be before last day of class.`)
      }
      if (isDateBefore(startDate, absoluteStartDate)) {
        set(
          errors,
          FIELDS.START_DATE.name,
          `Error: Date cannot be before ${formatDate(
            absoluteStartDate,
            DATETIME_FORMATS.longMonthDayYear
          )}.`
        )
      }
      if (!isLevelOne) {
        const reducedFeeCost = get(values, FIELDS.REDUCED_FEE_AVG_COST.name)
        if (reducedFeeCost === null) {
          set(errors, FIELDS.REDUCED_FEE_AVG_COST.name, 'Error: One option must be selected')
        }
        const standardFeeCost = get(values, FIELDS.STANDARD_FEE_AVG_COST.name)
        if (standardFeeCost === null) {
          set(errors, FIELDS.STANDARD_FEE_AVG_COST.name, 'Error: One option must be selected')
        }

        const gpaWeightagePolicy = get(values, FIELDS.GPA_WEIGHTAGE_POLICY.name)
        if (isEmpty(gpaWeightagePolicy)) {
          set(errors, FIELDS.GPA_WEIGHTAGE_POLICY.name, 'Error: One option must be selected')
        }
      }

      const deviceAvailability =
        get(values, 'schoolInformation.schoolInfo.deviceAvailability') ?? {}
      const deviceAvailabilityKeys = Object.keys(deviceAvailability)
      let deviceSelected = false
      deviceAvailabilityKeys.forEach(device => {
        if (
          deviceAvailability[device].availableInLabOrCartInd ||
          deviceAvailability[device].distributedToStudentInd
        ) {
          deviceSelected = true
        }
      })
      if (!deviceSelected) {
        // This error will not be displayed, but will prevent form submit button from being enabled
        set(
          errors,
          FIELDS.DEVICE_AVAILABILITY.name,
          'Error: At least one device option must be selected'
        )
      }

      return errors
    },
    onSubmit: (data, dispatch, props) => {
      const error = {}
      // const standardPayDate = get(data, FIELDS.STANDARD_PAY_DATE.name)
      // const standardPayDateInvalid = CheckDateFormat(standardPayDate)
      // const standardFeeNotApplicableRadio = get(data, FIELDS.STANDARD_FEE_COLLECTION.name)
      // if (standardFeeNotApplicableRadio === false && isEmpty(standardPayDate)) {
      //   set(error, FIELDS.STANDARD_PAY_DATE.name, 'Error: A date must be provided')
      //   throw new SubmissionError(error)
      // }
      // if (standardFeeNotApplicableRadio === false && standardPayDateInvalid) {
      //   set(error, FIELDS.STANDARD_PAY_DATE.name, standardPayDateInvalid)
      //   throw new SubmissionError(error)
      // }
      // const reducedPayDate = get(data, FIELDS.REDUCED_PAY_DATE.name)
      // const reducedPayDateInvalid = CheckDateFormat(reducedPayDate)
      // const reducedFeeNotApplicableRadio = get(data, FIELDS.REDUCED_FEE_COLLECTION.name)
      // if (reducedFeeNotApplicableRadio === false && isEmpty(reducedPayDate)) {
      //   set(error, FIELDS.REDUCED_PAY_DATE.name, 'Error: A date must be provided')
      //   throw new SubmissionError(error)
      // }
      // if (reducedFeeNotApplicableRadio === false && reducedPayDateInvalid) {
      //   set(error, FIELDS.REDUCED_PAY_DATE.name, reducedPayDateInvalid)
      //   throw new SubmissionError(error)
      // }

      const { reducedFeeAvgCostInd, standardFeeAvgCostInd, ...schoolInfoForSubmit } =
        data.schoolInformation.schoolInfo

      return props.updateOrgSettings({
        ...props.initialValues,
        ...{
          ...data,
          schoolInformation: {
            ...data.schoolInformation,
            schoolInfo: {
              ...schoolInfoForSubmit,
            },
          },
        },
      })
    },
    onSubmitSuccess: (data, dispatch, props) => {
      dispatch(changeStep(2))
      window.scrollTo(0, 0)
    },
  })(CoordinatorSetupSchoolInfoForm)
)
