import * as c from '../constants/OrderConstants'
import template, { save, saveInitialState } from './template'

const initialSubmissionState = {
  submitting: false,
  submitted: false,
  confirming: false,
  fetching: false,
  error: null,
}

const initialStudentLabelsState = {
  labelSortOrder: 'N',
  fetching: false,
  error: null,
}

const initialNonSecureMaterialsState = {
  fetching: false,
  fetched: false,
  error: null,
  saving: false,
  saved: false,
  savingError: null,
  data: {},
}

export const ordersSummary = (state, { type, payload }) => {
  switch (type) {
    case c.SUBMIT_EXAM_ORDERS_FULFILLED:
      return { ...state, ...payload }

    case c.FETCH_APPROVAL_REQUEST_COUNTS_FULFILLED:
      return {
        ...state,
        approvalRequestSummary: {
          dateLastModified: payload.lastModifiedDate,
          numberUnsubmitted: payload.totalUnsubmitted,
          numberApprovalPending: payload.totalPendingApproval,
          numberApproved: payload.totalApproved,
          numberRejected: payload.totalRejected,
          numberRequiresInfo: payload.totalRequiresInfo,
          empty:
            !payload.totalUnsubmitted &&
            !payload.totalPendingApproval &&
            !payload.totalApproved &&
            !payload.totalRejected &&
            !payload.totalRequiresInfo,
        },
      }

    default:
      return template(state, { type, payload }, c, 'EXAM_ORDER_SUMMARY')
  }
}

export const ordersUnsubmittedChanges = (state, action) => {
  switch (action.type) {
    case c.SUBMIT_EXAM_ORDERS_FULFILLED:
      return { fetching: false, fetched: false, error: null }
    default:
      return template(state, action, c, 'ORDER_UNSUBMITTED_CHANGES')
  }
}

export const ordersHistory = (state, action) => template(state, action, c, 'ORDER_HISTORY')
export const ordersSpecialExamRequests = (state, action) =>
  template(state, action, c, 'SPECIAL_EXAM_REQUESTS')
export const ordersSecureMaterialsRequests = (state, action) =>
  template(state, action, c, 'SECURE_MATERIALS_REQUESTS')
export const ordersNonSecureMaterialsRequests = (state, action) =>
  template(state, action, c, 'NON_SECURE_MATERIALS_REQUESTS')
export const ordersExceptionTestingRequests = (state, action) =>
  template(state, action, c, 'EXCEPTION_TESTING_EXAM_REQUESTS')
export const ordersAfterDeadlineRequests = (state, action) =>
  template(state, action, c, 'ORDERS_AFTER_DEADLINE_REQUESTS')
export const offCycleTestingRequests = (state, action) =>
  template(state, action, c, 'OFF_CYCLE_TESTING_REQUESTS')
export const ordersProgramStats = (state, action) => template(state, action, c, 'PROGRAM_STATS')

export const exceptionTestingApprovalDetails = (state = {}, { type, payload }) => {
  switch (type) {
    case c.FETCH_EXCEPTION_TESTING_AR_DETAILS_PENDING:
      return { ...state, [payload.id]: { fetching: true, fetched: false, error: null, data: {} } }
    case c.FETCH_EXCEPTION_TESTING_AR_DETAILS_FULFILLED:
      return { ...state, [payload.id]: { fetching: false, fetched: true, data: payload.data } }
    case c.FETCH_EXCEPTION_TESTING_AR_DETAILS_REJECTED:
      return { ...state, [payload.id]: { fetching: false, fetched: false, error: payload.error } }
    case c.RESET_EXCEPTION_TESTING_AR_DETAILS:
      return {}
    default:
      return state
  }
}

export const ordersAfterDeadlineApprovalDetails = (state = {}, { type, payload }) => {
  switch (type) {
    case c.FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_PENDING:
      return { ...state, [payload.id]: { fetching: true, fetched: false, error: null, data: {} } }
    case c.FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_FULFILLED:
      return { ...state, [payload.id]: { fetching: false, fetched: true, data: payload.data } }
    case c.FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_REJECTED:
      return { ...state, [payload.id]: { fetching: false, fetched: false, error: payload.error } }
    case c.RESET_ORDERS_AFTER_DEADLINE_AR_DETAILS:
      return {}
    default:
      return state
  }
}

export const ordersSubmission = (state = initialSubmissionState, { type, payload }) => {
  switch (type) {
    case c.SUBMIT_EXAM_ORDERS_PENDING:
      return { ...state, submitting: true }
    case c.SUBMIT_EXAM_ORDERS_FULFILLED:
      return { ...initialSubmissionState, submitted: true }
    case c.SUBMIT_EXAM_ORDERS_REJECTED:
      return { ...state, submitting: false, error: payload }
    case c.CONFIRM_EXAM_ORDERS_PENDING:
      return { ...state, confirming: true }
    case c.CONFIRM_EXAM_ORDERS_SUBMITTED:
    case c.CONFIRM_EXAM_ORDERS_CANCELED:
    case c.FETCH_EXAM_ORDER_SUMMARY_FULFILLED:
      return initialSubmissionState
    default:
      return state
  }
}

export const ordersStudentLabels = (state = initialStudentLabelsState, { type, payload }) => {
  switch (type) {
    case c.FETCH_STUDENT_LABEL_SORT_ORDER_PENDING:
      return { ...state, fetching: true, error: null }

    case c.SUBMIT_STUDENT_LABEL_SORT_ORDER_PENDING:
      return { ...state, fetching: false, error: null }

    case c.FETCH_STUDENT_LABEL_SORT_ORDER_FULFILLED:
    case c.SUBMIT_STUDENT_LABEL_SORT_ORDER_FULFILLED:
      return { ...state, labelSortOrder: payload.labelSortOrder, error: null, fetching: false }

    case c.FETCH_STUDENT_LABEL_SORT_ORDER_REJECTED:
      return { ...state, error: payload, fetching: false }

    default:
      return state
  }
}

export const coursesWithSecureMaterials = (state, action) =>
  template(state, action, c, 'EXAMS_WITH_SECURE_MATERIALS')

export const maxAllowedSecureMaterialsForCourse = (state, action) =>
  template(state, action, c, 'MAX_ALLOWED_SECURE_MATERIALS')

const initialSecureMaterialsForCourse = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  error: null,
  data: {},
}
export const secureMaterialsForCourse = (
  state = initialSecureMaterialsForCourse,
  { type, payload }
) => {
  switch (type) {
    case c.FETCH_SELECTED_SECURE_MATERIALS_PENDING:
      return { ...state, ...initialSecureMaterialsForCourse, fetching: true }
    case c.UPDATE_SELECTED_SECURE_MATERIALS_PENDING:
      return { ...state, updating: true, updated: false, error: null }

    case c.FETCH_SELECTED_SECURE_MATERIALS_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: { ...payload } }
    case c.UPDATE_SELECTED_SECURE_MATERIALS_FULFILLED:
      return { ...state, updating: false, updated: true, data: { ...payload } }

    case c.FETCH_SELECTED_SECURE_MATERIALS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }
    case c.UPDATE_SELECTED_SECURE_MATERIALS_REJECTED:
      return { ...state, updating: false, updated: false, error: payload }

    default:
      return state
  }
}

const initialStateScoreReportingServices = {
  fetching: false,
  fetched: false,
  error: null,
  locked: true,
  mainOrderSummary: {},
  unSubmittedChanges: {},
}

export const ordersScoreReportingServices = (
  state = initialStateScoreReportingServices,
  { type, payload }
) => {
  switch (type) {
    case c.SAVE_SCORE_REPORTING_SERVICES_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }
    case c.SAVE_SCORE_REPORTING_SERVICES_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        mainOrderSummary: payload.mainOrderSummary,
        unsubmittedChanges: payload.unsubmittedChanges,
      }
    case c.SAVE_SCORE_REPORTING_SERVICES_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    case c.FETCH_SCORE_REPORTING_SERVICES_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }
    case c.FETCH_SCORE_REPORTING_SERVICES_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        locked: payload.locked,
        mainOrderSummary: payload.mainOrderSummary,
        unsubmittedChanges: payload.unsubmittedChanges,
      }
    case c.FETCH_SCORE_REPORTING_SERVICES_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    default:
      return state
  }
}

export const ordersNonSecureAncillaryMaterials = (
  state = initialNonSecureMaterialsState,
  { type, payload }
) => {
  switch (type) {
    case c.FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }

    case c.FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: { [payload.data.orgId]: payload.data },
      }

    case c.FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    case c.SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_PENDING:
      return { ...state, saving: true, saved: false, savingError: null }

    case c.SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_FULFILLED:
      return { ...state, saving: false, saved: true, data: { [payload.data.orgId]: payload.data } }

    case c.SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_REJECTED:
      return { ...state, saving: false, saved: false, savingError: payload }

    case c.SETUP_NON_SECURE_ANCILLARY_MATERIALS_FORM_DATA:
      return { ...state, formData: payload }

    case c.CLEAR_NON_SECURE_ANCILLARY_MATERIALS_FORM_DATA:
      return { ...state, formData: {} }

    case c.CHANGE_NON_SECURE_MATERIAL_SELECTED_QUANTITY:
      return {
        ...state,
        formData: {
          ...state.formData,
          [payload.ancillaryCd]: {
            ...state.formData[payload.ancillaryCd],
            selectedQuantity: payload.newQuantity,
          },
        },
      }

    case c.CHANGE_NON_SECURE_MATERIAL_PROVIDED_REASON:
      return {
        ...state,
        formData: {
          ...state.formData,
          [payload.ancillaryCd]: {
            ...state.formData[payload.ancillaryCd],
            providedReasonForOrder: payload.newReasonForOrder,
          },
        },
      }

    case c.SET_NON_SECURE_MATERIAL_REASON_VALIDATION_ERROR:
      return {
        ...state,
        formData: {
          ...state.formData,
          [payload.ancillaryCd]: {
            ...state.formData[payload.ancillaryCd],
            reasonValidationError: payload.reasonValidationError,
          },
        },
      }

    default:
      return state
  }
}

export const orderVolumeRebates = (state, action) => {
  switch (action.type) {
    case c.SAVE_VOLUME_REBATE_PENDING:
    case c.SAVE_VOLUME_REBATE_REJECTED:
    case c.SAVE_VOLUME_REBATE_FULFILLED:
      return save(state, action, c, 'VOLUME_REBATE')
    default:
      return template({ ...state, ...saveInitialState }, action, c, 'VOLUME_REBATE')
  }
}

const initialFrbDownloadsState = {
  fetchingFRBs: false,
  fetchedFRBs: false,
  fetchingFRBDetails: false,
  fetchedFRBDetails: false,
  data: [],
  error: null,
  downloadError: {},
}

export const frbDownloads = (state = initialFrbDownloadsState, { type, payload }) => {
  switch (type) {
    case c.FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_PENDING:
      return { ...state, fetchingFRBs: true, fetchedFRBs: false, error: null }
    case c.FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_FULFILLED:
      return { ...state, fetchingFRBs: false, fetchedFRBs: true, data: payload }
    case c.FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_REJECTED:
      return { ...state, fetchingFRBs: false, fetchedFRBs: false, error: payload }

    case c.FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_PENDING:
      return {
        ...state,
        fetchingFRBDetails: true,
        fetchedFRBDetails: false,
        downloadError: { ...state.downloadError, [payload]: null },
      }
    case c.FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_FULFILLED:
      return {
        ...state,
        fetchingFRBDetails: false,
        fetchedFRBDetails: true,
        data: state.data.map(frb =>
          frb.testCd === payload.testCd && frb.examFormat === payload.examFormat
            ? { ...frb, ...payload }
            : frb
        ),
      }
    case c.FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_REJECTED:
      return {
        ...state,
        fetchingFRBDetails: false,
        fetchedFRBDetails: false,
        downloadError: {
          ...state.downloadError,
          [`${payload.course}_${payload.examFormat}`]: payload.error,
        },
      }

    default:
      return state
  }
}
