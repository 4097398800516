import { formatPhoneNumber } from '../../../utils/numbers'
import Text from './Text'

const formatNumber = countryCode => text => {
  const len = text ? text.toString().length : 0
  if (len === 0) return ''
  if (document.activeElement.value) return text
  return formatPhoneNumber(text, countryCode)
}

export default props => {
  const { countryCode, shouldFormat } = props
  const newProps = {
    ...props,
    format: shouldFormat && countryCode ? formatNumber(countryCode) : null,
  }
  return <Text {...newProps} />
}
