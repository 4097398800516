import { Tooltip } from './'
import { isEmpty } from '../../utils/common'

/**
 * Options for 'type':
 * default, primary, success, info, warning, danger
 **/


export default ({
  text,
  type = 'default',
  alignment = { marginLeft: '15px' }, // example: { float: 'right', marginRight: '15px' }
  tooltip = {}
}) => {
  const labelStyles = {
    display       : 'inline',
    padding       : '3px 4px',
    fontSize      : '85%',
    fontWeight    : 'normal',
    lineHeight    : '1',
    textAlign     : 'center',
    whiteSpace    : 'nowrap',
    verticalAlign : 'baseline',
    borderRadius  : '.25em'
  }
  switch (type) {
    // Colors for primary, success, info, warning, danger currently use Bootstrap coloring,
    // modify to match APRO branding as needed. NOTE: There may be issues with accessibility and
    // color contrast.
    case 'primary':
      labelStyles['color'] = 'rgb(255, 255, 255)'
      labelStyles['backgroundColor'] = 'rgb(51, 122, 183)'
      break

    case 'success':
      labelStyles['color'] = 'rgb(255, 255, 255)'
      labelStyles['backgroundColor'] = 'rgb(92, 184, 92)'
      break

    case 'info':
      labelStyles['color'] = 'rgb(255, 255, 255)'
      labelStyles['backgroundColor'] = 'rgb(91, 192, 222)'
      break

    case 'warning':
      labelStyles['color'] = 'rgb(255, 255, 255)'
      labelStyles['backgroundColor'] = 'rgb(240, 173, 78)'
      break

    case 'danger':
      labelStyles['color'] = 'rgb(255, 255, 255)'
      labelStyles['backgroundColor'] = 'rgb(217, 83, 79)'
      break

    case 'default':
    default:
      labelStyles['color'] = 'rgb(80, 80, 80)'
      labelStyles['backgroundColor'] = 'rgb(240, 240, 240)'
      break
  }
  const label = <span style={labelStyles}>{text}</span>
  let labelOutput

  if (!isEmpty(tooltip)) {
    labelOutput = (
      <Tooltip
        title={tooltip?.body}
        placement={tooltip?.placement ? tooltip.placement : 'top'}
        container={tooltip?.container ? tooltip.container : 'body'}
        label={tooltip?.label}
      >
        {label}
      </Tooltip>
    )
  } else {
    labelOutput = label
  }

  return <div style={{ display: 'inline-block', ...alignment }}>{labelOutput}</div>
}
