import { SelectSchool, SelectAcademicYear, BackLink } from '.'
import styles from '../../assets/style/scss/navigation.scss'

const mapStateToProps = (state, ownProps) => {
  const {
    nav: { linkRoute, linkText, suppressNav },
  } = state
  const { unsavedForm, onRoleChange, selectYearStyles } = ownProps
  return {
    linkRoute,
    linkText,
    unsavedForm,
    onRoleChange,
    selectYearStyles,
    suppressNav,
  }
}

export class CoordinatorSecondaryNavigation extends Component {
  render() {
    const {
      linkRoute,
      linkText,
      suppressNav,
      isCoordinator,
      unsavedForm,
      onRoleChange,
      selectYearStyles,
    } = this.props

    if (!suppressNav) {
      if (linkRoute !== '' && linkText !== '') {
        return (
          <div className={`secondary-navigation ${styles['secondary-nav']}`}>
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <BackLink linkText={linkText} linkRoute={linkRoute} />
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div
            className={styles['secondary-nav']}
            aria-label="school and academic year views"
            role="navigation"
          >
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <SelectSchool unsavedForm={unsavedForm} onRoleChange={onRoleChange} />
                  <SelectAcademicYear
                    unsavedForm={unsavedForm}
                    onRoleChange={onRoleChange}
                    style={selectYearStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }
    } else return null
  }
}

export default connect(mapStateToProps)(CoordinatorSecondaryNavigation)
