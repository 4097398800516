import { useCallback } from 'react'
import { formValueSelector, initialize } from 'redux-form'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { fetchAvailableCourses } from '../../actions/course'
import {
  Text,
  Select,
  LimitedStringValidation,
  LetterValidation,
  FormCodeValidation,
} from '../common'
import { initializeIncidentReportData } from '../../selectors/incidentReports'
import { getAllCourses } from '../../selectors/course'
import { getSortedExamWindowsForCourse } from '../../selectors/examWindows'
import { getCoordinatorContactInfo } from '../../selectors/settings'
import {
  INCIDENT_REPORT_FORM_NAME,
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
  COURSES_WITH_HIDDEN_EXAM_FORM_SECTION,
  SHARED_FIELDS_LIST,
} from '../../constants/IncidentReportConstants'
import {
  PAPER_EXAM,
  TEST_ADMIN_EXCEPTION,
  TEST_ADMIN_OFFCYCLE,
} from '../../constants/SettingsConstants'
import { isEmpty } from '../../utils/common'

const mapStateToProps = (state, { draft }) => {
  const {
    availableCourses: { fetching: fetchingCourses, fetched: fetchedCourses, error: errorCourses },
  } = state
  const selector = formValueSelector(INCIDENT_REPORT_FORM_NAME)
  const sharedValues = selector(state, ...SHARED_FIELDS_LIST)
  const selectedCourse = !isEmpty(sharedValues[SHARED_FIELDS.testCode.name])
    ? parseInt(sharedValues[SHARED_FIELDS.testCode.name], 10)
    : !isEmpty(draft?.exam?.[SHARED_FIELDS.testCode.name])
    ? draft.exam[SHARED_FIELDS.testCode.name]
    : undefined
  const availableExamWindows = selectedCourse
    ? getSortedExamWindowsForCourse(state, selectedCourse)
    : []

  return {
    draft,
    fetchingCourses,
    fetchedCourses,
    errorCourses,
    allCourses: getAllCourses({ state, apOnly: true }),
    availableExamWindows,
    coordinatorInfo: getCoordinatorContactInfo(state),
  }
}

function IncidentReportExamInformationFields({
  currentValues,
  draft,
  orgId,
  educationPeriodCd,
  examFormat,
  fetchingCourses,
  fetchedCourses,
  errorCourses,
  allCourses,
  availableExamWindows,
  allExamWindows,
  coordinatorInfo,
  setSelectedTestCd,
  setSelectedExamDate,
  fetchAvailableCourses,
  initialize,
}) {
  const allCoursesLength = allCourses?.length ?? 0
  const fetchCourses = useCallback(() => {
    if (orgId && educationPeriodCd && !fetchingCourses && !fetchedCourses && !allCoursesLength) {
      fetchAvailableCourses({ orgId, educationPeriodCd })
    }
  }, [orgId, educationPeriodCd, allCoursesLength, fetchingCourses, fetchedCourses])
  const courseOptions = allCourses.map(({ testCd, courseName }) => ({
    label: courseName,
    value: testCd,
  }))
  const examDateOptions = availableExamWindows.map(
    ({ displayName, examDateTime, adminWindow, noEndOfCourseExam, testAdmin }) => {
      let label = ''
      if (noEndOfCourseExam) {
        label = 'Standard'
      } else if (testAdmin === TEST_ADMIN_EXCEPTION) {
        label = `${displayName} Testing`
      } else if (testAdmin === TEST_ADMIN_OFFCYCLE) {
        label = displayName
      } else {
        label = `${displayName} - ${formatDate(examDateTime, DATETIME_FORMATS.mediumMonthDay)}`
      }
      return {
        label,
        value: adminWindow,
      }
    }
  )

  const legendStyles = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    border: 'none',
    marginBottom: '16px',
    lineHeight: '24px',
  }

  // fetch available courses
  useEffect(() => {
    fetchCourses()
  }, [fetchCourses])

  return (
    <div className="row">
      <div className="col-sm-6 col-xs-12">
        <h3>Exam Information</h3>
        <fieldset>
          <div className="row">
            <div className="col-xs-12">
              <Text
                name={SHARED_FIELDS.section.name}
                isRequired={SHARED_FIELDS.section.required}
                label="Exam Section"
                placeholder="Enter Section of the Exam"
                validate={[LimitedStringValidation]}
                maxlength={20}
                errorOnPristine={true}
              />
              <Select
                name={SHARED_FIELDS.testCode.name}
                input={{
                  name: SHARED_FIELDS.testCode.name,
                  onChange: e => {
                    const val = e.target.value
                    setSelectedTestCd(val)
                    initialize(
                      INCIDENT_REPORT_FORM_NAME,
                      initializeIncidentReportData({
                        data: {
                          coordinator: {
                            [SHARED_FIELDS.firstName.name]: isEmpty(draft)
                              ? currentValues[SHARED_FIELDS.firstName.name]
                              : undefined,
                            [SHARED_FIELDS.lastName.name]: isEmpty(draft)
                              ? currentValues[SHARED_FIELDS.lastName.name]
                              : undefined,
                            [SHARED_FIELDS.phone.name]: isEmpty(draft)
                              ? currentValues[SHARED_FIELDS.phone.name]
                              : undefined,
                            [SHARED_FIELDS.email.name]: isEmpty(draft)
                              ? currentValues[SHARED_FIELDS.email.name]
                              : undefined,
                          },
                          exam: {
                            [SHARED_FIELDS.testCode.name]: val, // this field
                            [SHARED_FIELDS.examDate.name]: null,
                            [SHARED_FIELDS.section.name]: isEmpty(
                              currentValues[SHARED_FIELDS.testCode.name]
                            )
                              ? currentValues[SHARED_FIELDS.section.name]
                              : undefined,
                          },
                        },
                        allExamWindows,
                        coordinatorInfo,
                      }),
                      { keepDirty: false }
                    )
                  },
                  value: currentValues[SHARED_FIELDS.testCode.name],
                }}
                isRequired={SHARED_FIELDS.testCode.required}
                options={courseOptions}
                label="Exam Title"
              />
              <Select
                name={SHARED_FIELDS.examDate.name}
                input={{
                  name: SHARED_FIELDS.examDate.name,
                  onChange: e => {
                    const val = e.target.value
                    setSelectedExamDate(val)
                    initialize(
                      INCIDENT_REPORT_FORM_NAME,
                      initializeIncidentReportData({
                        data: {
                          coordinator: {
                            [SHARED_FIELDS.firstName.name]: isEmpty(draft)
                              ? currentValues[SHARED_FIELDS.firstName.name]
                              : undefined,
                            [SHARED_FIELDS.lastName.name]: isEmpty(draft)
                              ? currentValues[SHARED_FIELDS.lastName.name]
                              : undefined,
                            [SHARED_FIELDS.phone.name]: isEmpty(draft)
                              ? currentValues[SHARED_FIELDS.phone.name]
                              : undefined,
                            [SHARED_FIELDS.email.name]: isEmpty(draft)
                              ? currentValues[SHARED_FIELDS.email.name]
                              : undefined,
                          },
                          exam: {
                            [SHARED_FIELDS.examDate.name]: val, // this field
                            [SHARED_FIELDS.testCode.name]:
                              currentValues[SHARED_FIELDS.testCode.name],
                            [SHARED_FIELDS.section.name]: isEmpty(
                              currentValues[SHARED_FIELDS.examDate.name]
                            )
                              ? currentValues[SHARED_FIELDS.section.name]
                              : undefined,
                          },
                        },
                        allExamWindows,
                        coordinatorInfo,
                      }),
                      { keepDirty: false }
                    )
                  },
                  value: currentValues[SHARED_FIELDS.examDate.name],
                }}
                isRequired={SHARED_FIELDS.examDate.required}
                options={examDateOptions}
                label="Exam Date"
                disabled={!examDateOptions.length}
              />
            </div>
          </div>
        </fieldset>
      </div>
      {examFormat === PAPER_EXAM &&
      !COURSES_WITH_HIDDEN_EXAM_FORM_SECTION.includes(
        parseInt(currentValues[SHARED_FIELDS.testCode.name], 10)
      ) ? (
        <div className="col-sm-6 col-xs-12">
          <h3>Exam Form</h3>
          <fieldset>
            <legend style={legendStyles}>
              The form information appears in the lower right corner of the Section I
              multiple-choice and Section II free-response exam booklets. Please include the form
              (e.g., O, A, I) and the form code (e.g., 4RBP).
            </legend>
            <div className="row">
              <div className="col-xs-12">
                <Text
                  name={SHARED_FIELDS.examForm.name}
                  isRequired={!currentValues?.isDraft && SHARED_FIELDS.examForm.required}
                  label="Form"
                  validate={!currentValues?.isDraft ? [LetterValidation] : []}
                  maxlength={1}
                  errorOnPristine={!currentValues?.isDraft}
                />
                <Text
                  name={SHARED_FIELDS.formCode.name}
                  isRequired={!currentValues?.isDraft && SHARED_FIELDS.formCode.required}
                  label="Form Code"
                  validate={!currentValues?.isDraft ? [FormCodeValidation] : []}
                  maxlength={20}
                  errorOnPristine={!currentValues?.isDraft}
                />
              </div>
            </div>
          </fieldset>
        </div>
      ) : null}
    </div>
  )
}

export default connect(mapStateToProps, { fetchAvailableCourses, initialize })(
  IncidentReportExamInformationFields
)
