import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { educationPeriodDateSpread } from '@myap/ui-library/esm/date'
import { fetchProgramStats } from '../../../actions/orders'
import { addFetchAction, removeFetchAction } from '../../../actions/app'
import { Loader, Error } from '../../common'
import { ORDER_EXAM_FILTER, COURSE_TYPE_FILTER } from '../../../constants/FilterConstants'
import {
  STUDENT_ENROLLMENT_STATUS_YES,
  STUDENT_ENROLLMENT_STATUS_UNDECIDED,
  STUDENT_ENROLLMENT_STATUS_UNUSED,
} from '../../../constants/StudentConstants'
import { COURSE_TYPE_AP } from '../../../constants/CourseConstants'
import { dollarFormat, numberFormat } from '../../../utils/numbers'
import style from '../../../assets/style/scss/program-statistics.scss'

const mapStateToProps = (state, ownProps) => {
  const { availableEducationPeriods, selectedIsCurrent, selectedEducationPeriod } =
    state.settingsEducationPeriod

  return {
    ...state.ordersProgramStats,
    educationPeriods: availableEducationPeriods,
    selectedIsCurrent,
    selectedEducationPeriod,
  }
}

const buildProgramSummaryRow = (data, spread, linked) => {
  const {
    courseSummaries,
    educationPeriodCd,
    statistics: { numberStudentsEnrolled, numberExams, numberUndecided, totalCost },
  } = data

  return (
    <tr key={educationPeriodCd}>
      <th scope="row">{spread}</th>
      <td>
        {linked ? (
          <Link to={`/courses?${COURSE_TYPE_FILTER.filter}=${COURSE_TYPE_AP}`}>
            <span className="sr-only">View all courses: </span>
            {numberFormat(courseSummaries.length)}
          </Link>
        ) : (
          numberFormat(courseSummaries.length)
        )}
      </td>
      <td>
        {linked ? (
          <Link to={`/students?${COURSE_TYPE_FILTER.filter}=${COURSE_TYPE_AP}`}>
            <span className="sr-only">View all enrolled students: </span>
            {numberFormat(numberStudentsEnrolled)}
          </Link>
        ) : (
          numberFormat(numberStudentsEnrolled)
        )}
      </td>
      <td>
        {linked ? (
          <Link
            to={`/students?${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_YES}&${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_UNUSED}`}
          >
            <span className="sr-only">View all students registered for an exam: </span>
            {numberFormat(numberExams)}
          </Link>
        ) : (
          numberFormat(numberExams)
        )}
      </td>
      <td>
        {linked ? (
          <Link to={`/students?${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_UNDECIDED}`}>
            <span className="sr-only">View all undecided students: </span>
            {numberFormat(numberUndecided)}
          </Link>
        ) : (
          numberFormat(numberUndecided)
        )}
      </td>
      <td>{dollarFormat(totalCost)}</td>
    </tr>
  )
}

const ProgramSummary = ({
  fetching,
  error,
  currentYearSummary = {},
  previousYearSummary = {},
  fetchProgramStats,
  addFetchAction,
  removeFetchAction,
  educationPeriods,
  selectedIsCurrent,
  selectedEducationPeriod,
}) => {
  const currentYearEducationSpread = educationPeriodDateSpread(
    educationPeriods[selectedEducationPeriod]?.academicYrStartDate,
    educationPeriods[selectedEducationPeriod]?.academicYrEndDate
  )
  const [fall, spring] = currentYearEducationSpread.split('/')
  const pastYearEducationSpread = `${parseInt(fall, 10) - 1}/${parseInt(spring, 10) - 1}`

  useEffect(() => {
    addFetchAction({
      name: 'fetchProgramStats',
      action: (orgId, educationPeriodCd) => fetchProgramStats(orgId, educationPeriodCd),
    })
    return () => removeFetchAction('fetchProgramStats')
  }, [])

  if (error) return <Error title="Error Retrieving Data" message={error} />

  if (fetching) return <Loader />

  return (
    <>
      <h2 className="h3" style={{ marginBottom: '8px' }}>
        Program Summary
      </h2>
      <table
        className={style['summary-table']}
        summary="Program years are listed as current academic year first, then previous academic year last."
        style={!selectedIsCurrent ? { marginBottom: '82px' } : {}}
      >
        <caption className="sr-only">
          A consolidated view of program data for current and previous academic years.
        </caption>
        <thead>
          <tr role="row">
            <th scope="col" role="columnheader">
              Year
            </th>
            <th scope="col" role="columnheader">
              Courses
            </th>
            <th scope="col" role="columnheader">
              AP Students
            </th>
            <th scope="col" role="columnheader">
              Exams
            </th>
            <th scope="col" role="columnheader">
              Undecided Exams
            </th>
            <th scope="col" role="columnheader">
              Total Cost
            </th>
            {/* <th scope="col" role="columnheader">Uncollected Fees</th> */}
          </tr>
        </thead>
        <tbody>
          {Object.keys(currentYearSummary).length
            ? buildProgramSummaryRow(
                currentYearSummary,
                currentYearEducationSpread,
                selectedIsCurrent
              )
            : null}
          {Object.keys(previousYearSummary).length
            ? buildProgramSummaryRow(previousYearSummary, pastYearEducationSpread)
            : null}
        </tbody>
      </table>
      {selectedIsCurrent && <Link to="/orders/programstatistics">More program statistics</Link>}
    </>
  )
}

export default connect(mapStateToProps, { fetchProgramStats, addFetchAction, removeFetchAction })(
  ProgramSummary
)
