import { useHistory } from 'react-router-dom'
import { formValueSelector, initialize, change } from 'redux-form'
import { setNavLink, unsetNavLink } from '../../actions/nav'
import { NAV_LINKS } from '../../constants/NavConstants'
import { Loader } from '../../components/common'
import {
  CreateIncidentReportHeader,
  CreateIncidentReportPaperForm,
  CreateIncidentReportHybridForm,
  CreateIncidentReportDigitalForm,
} from '../../components/incidentReports'
import { fetchDraftIncidentReport, resetIncidentReport, resetDraftIncidentReport } from '../../actions/incidentReports'
import {
  getIncidentReportAvailability,
  initializeIncidentReportData,
} from '../../selectors/incidentReports'
import { getOrgIdAndEdPeriod } from '../../selectors/user'
import { getAllExamWindows, getExamFormats } from '../../selectors/examWindows'
import { getCoordinatorContactInfo } from '../../selectors/settings'
import {
  INCIDENT_REPORT_FORM_NAME,
  SHARED_FIELDS_LIST,
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
} from '../../constants/IncidentReportConstants'
import { isEmpty } from '../../utils/common'

const mapStateToProps = state => {
  const {
    user: {
      data: {
        isCSR,
        isETS,
        isSDP,
        isSchoolAdmin,
        isDistrictAdmin,
        isDistrictFundingAdmin,
        isDistrictSuperAdmin,
      },
    },
    incidentReport: { fetching, draft },
  } = state
  const { orgId, educationPeriodCd } = getOrgIdAndEdPeriod(state)
  const { active } = getIncidentReportAvailability(state)
  const notAllowedToSubmit =
    isCSR ||
    isETS ||
    isSDP ||
    isSchoolAdmin ||
    isDistrictAdmin ||
    isDistrictFundingAdmin ||
    isDistrictSuperAdmin

  const selector = formValueSelector(INCIDENT_REPORT_FORM_NAME)
  const sharedValues = selector(state, ...SHARED_FIELDS_LIST)
  const allExamWindows = getAllExamWindows(state)

  const selectedCourse = !isEmpty(sharedValues[SHARED_FIELDS.testCode.name])
    ? parseInt(sharedValues[SHARED_FIELDS.testCode.name], 10)
    : !isEmpty(draft?.exam?.[SHARED_FIELDS.testCode.name])
    ? draft.exam[SHARED_FIELDS.testCode.name]
    : undefined
  const selectedAdminWindow =
    !isEmpty(sharedValues[SHARED_FIELDS.examDate.name]) ||
    sharedValues[SHARED_FIELDS.examDate.name] === null
      ? sharedValues[SHARED_FIELDS.examDate.name]
      : !isEmpty(draft?.exam?.[SHARED_FIELDS.examDate.name])
      ? draft.exam[SHARED_FIELDS.examDate.name]
      : undefined
  const { paper, hybrid, digital, noEndOfCourseExam } = getExamFormats({
    allExamWindows,
    testCd: selectedCourse,
    adminWindow: selectedAdminWindow,
  })

  const isPaper = paper || noEndOfCourseExam
  const isHybrid = hybrid
  const isDigital = digital

  return {
    orgId,
    educationPeriodCd,
    allExamWindows,
    isPaper,
    isHybrid,
    isDigital,
    active,
    fetching,
    notAllowedToSubmit,
    draft,
    coordinatorInfo: getCoordinatorContactInfo(state),
  }
}

const CreateIncidentReportPage = ({
  orgId,
  educationPeriodCd,
  allExamWindows,
  isPaper,
  isHybrid,
  isDigital,
  active,
  fetching,
  notAllowedToSubmit,
  draft,
  coordinatorInfo,
  setNavLink,
  unsetNavLink,
  fetchDraftIncidentReport,
  resetIncidentReport,
  resetDraftIncidentReport,
  initialize,
  change,
}) => {
  const history = useHistory()
  const [selectedTestCd, setSelectedTestCd] = useState(null)
  const [selectedExamDate, setSelectedExamDate] = useState(null)

  useEffect(() => {
    setNavLink(NAV_LINKS.students, 'Back to Students')
    return () => unsetNavLink()
  }, [])

  useEffect(() => {
    // Scroll view to top of page
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!active) {
      // Redirect back to Students page
      history.replace(NAV_LINKS.students)
    }
  }, [active])

  useEffect(() => {
    if (orgId && educationPeriodCd) {
      fetchDraftIncidentReport({ orgId, educationPeriodCd })
    }
    return () => resetIncidentReport()
  }, [orgId, educationPeriodCd])

  useEffect(() => {
    // Initialize with draft data if available
    if (!isEmpty(draft)) {
      const initializedData = initializeIncidentReportData({
        data: draft,
        allExamWindows,
        coordinatorInfo,
      })
      initialize(INCIDENT_REPORT_FORM_NAME, initializedData, { keepDirty: false })
      // After initializing the form with draft data, reset the draft data so
      // those values aren't persisted after making further changes
      resetDraftIncidentReport()
    }
  }, [draft, allExamWindows, coordinatorInfo])

  useEffect(() => {
    change(INCIDENT_REPORT_FORM_NAME, SHARED_FIELDS.testCode.name, selectedTestCd)
  }, [selectedTestCd])

  useEffect(() => {
    change(INCIDENT_REPORT_FORM_NAME, SHARED_FIELDS.examDate.name, selectedExamDate)
  }, [selectedExamDate])

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <div className="container">
        <h2>AP Coordinator Incident Report Form</h2>
        <CreateIncidentReportHeader />
        {fetching ? (
          <Loader />
        ) : isPaper ? (
          <CreateIncidentReportPaperForm
            notAllowedToSubmit={notAllowedToSubmit}
            setSelectedTestCd={setSelectedTestCd}
            setSelectedExamDate={setSelectedExamDate}
          />
        ) : isHybrid ? (
          <CreateIncidentReportHybridForm
            notAllowedToSubmit={notAllowedToSubmit}
            setSelectedTestCd={setSelectedTestCd}
            setSelectedExamDate={setSelectedExamDate}
          />
        ) : isDigital ? (
          <CreateIncidentReportDigitalForm
            notAllowedToSubmit={notAllowedToSubmit}
            setSelectedTestCd={setSelectedTestCd}
            setSelectedExamDate={setSelectedExamDate}
          />
        ) : null}
      </div>
    </CSSTransition>
  )
}

export default connect(mapStateToProps, {
  setNavLink,
  unsetNavLink,
  fetchDraftIncidentReport,
  resetIncidentReport,
  resetDraftIncidentReport,
  initialize,
  change,
})(CreateIncidentReportPage)
