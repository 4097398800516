import { BasicInput } from '../../common'
import AvailableCourses from '../common/AvailableCourses'

import styles from '../../../assets/style/scss/exam-readiness.scss'

const CoordinatorPracticePicker = ({ selectedCourse = {}, setSelectedCourse }) => {
  const { testCode } = selectedCourse

  return (
    <AvailableCourses
      component={({ courses }) => {
        return (
          <div className={styles['coordinator-course-picker']}>
            {courses.map(c => (
              <BasicInput
                key={c.testCode}
                type="radio"
                label={c.courseName}
                input={{
                  checked: testCode === c.testCode,
                  name: 'coordinatorPracticePicker',
                  value: c.testCode,
                  onChange: () =>
                    setSelectedCourse({ testCode: c.testCode, courseName: c.courseName }),
                }}
              />
            ))}
          </div>
        )
      }}
    />
  )
}

export default CoordinatorPracticePicker
