import { isDateBefore } from '@myap/ui-library/esm/date'
import { Modal, Error } from '../../../common'
import UpdateTable from '../../../orders/coordinator/UpdateTable'
import TestingLocation from './common/TestingLocation'
import {
  isExamDigital,
  isDigitalExamInCutoffPeriod,
  getExamDay,
} from '../../../../selectors/examWindows'
import { STUDENT_ENROLLMENT_STATUS_YES } from '../../../../constants/StudentConstants'
import { isAdministeringDigitalExams } from '../../../../selectors/settings'

import style from '../../../../assets/style/scss/orders.scss'

const mapStateToProps = (state, { exam }) => {
  const {
    studentsByOrg: { exams },
  } = state
  const { testCd, testWindow, studentId } = exam
  const matchingYesEnrollment = exams.filter(
    exam =>
      exam.studentId === studentId &&
      exam.testCd === testCd &&
      exam.examIntent === STUDENT_ENROLLMENT_STATUS_YES
  )
  const hasOtherEnrollmentWithinDigitalWarningPeriod =
    matchingYesEnrollment.length === 1 &&
    isDigitalExamInCutoffPeriod(state, matchingYesEnrollment[0])
  const isOtherBeforeExamDay =
    matchingYesEnrollment.length === 1 &&
    isDateBefore(
      new Date(),
      getExamDay(state, matchingYesEnrollment[0].testCd, matchingYesEnrollment[0].testWindow)
    )

  return {
    isDigital: isExamDigital(state, testCd, testWindow),
    otherEnrollmentWithinDigitalWarningPeriod:
      hasOtherEnrollmentWithinDigitalWarningPeriod && isOtherBeforeExamDay,
    administeringDigitalExams: isAdministeringDigitalExams(state),
  }
}

const IntroText = ({ examIntent, courseName, otherEnrollmentWithinDigitalWarningPeriod }) => {
  const isExamIntentYes = examIntent === STUDENT_ENROLLMENT_STATUS_YES

  if (otherEnrollmentWithinDigitalWarningPeriod) {
    return (
      <>
        <div
          className="alert alert-warning cb-alert-heading"
          style={{ marginBottom: 0 }}
          role="alert"
          tabIndex="0"
        >
          <h2>Cannot Undo</h2>
        </div>
        <p>
          <strong>Important:</strong> If you indicate that the student has tested or will be testing
          on a different exam date, they won&#39;t be able to test on the exam date they are
          currently assigned to. Click <strong>Update</strong> to proceed with changing the exam
          date on which student has tested or will be testing. Click <strong>Cancel</strong> to keep
          the student&#39;s current exam date.
        </p>
      </>
    )
  }

  return isExamIntentYes ? (
    <p>
      You are indicating that the student below will take the exam for <strong>{courseName}</strong>
      . The cost for this exam is noted below.
    </p>
  ) : (
    <p>
      You are indicating that the student below will not use the exam for{' '}
      <strong>{courseName}</strong>. The cost for this is noted below.
    </p>
  )
}

export default connect(mapStateToProps)(
  ({
    exam: incomingExam,
    exam: { examIntent, testLocation },
    isDigital,
    otherEnrollmentWithinDigitalWarningPeriod,
    courseName,
    studentName,
    modalStyles,
    shouldCloseModal,
    modalCloseFocusElem,
    error,
    administeringDigitalExams,
    getFooterActions,
  }) => {
    const [exam, setExam] = useState(incomingExam)
    const [attested, setAttested] = useState(isDigital || !administeringDigitalExams)
    const updateTestLocation = e => setExam({ ...exam, testLocation: e.target.value })
    const disableUpdateButton = examIntent === STUDENT_ENROLLMENT_STATUS_YES && !attested
    const updatedExam = () => exam

    return (
      <Modal
        modalStyles={modalStyles}
        headerTitle="Exam Use Change"
        shouldCloseModal={shouldCloseModal}
        modalCloseFocusElem={modalCloseFocusElem}
        footerActions={getFooterActions(updatedExam, { disabled: disableUpdateButton })}
      >
        {error ? <Error title="Error Updating Student" message={error} /> : null}

        <IntroText
          examIntent={examIntent}
          courseName={courseName}
          otherEnrollmentWithinDigitalWarningPeriod={otherEnrollmentWithinDigitalWarningPeriod}
        />
        <TestingLocation
          isDigital={isDigital}
          examIntent={examIntent}
          testLocation={testLocation}
          updateTestLocation={updateTestLocation}
          attestTestLocation={e => setAttested(e.target.checked)}
        />
        <hr className={style['order-border-warning']} />
        <UpdateTable exam={exam} studentName={studentName} />
      </Modal>
    )
  }
)
