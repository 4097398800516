const ExpandCollapseLinks = () => (
  <div className="cb-accordion-controls">
    <button className="expand-all btn cb-accordion-btn">Expand All</button>
    <span className="cb-separator"></span>
    <button className="collapse-all btn cb-accordion-btn">Collapse All</button>
  </div>
)

const Panel = ({ PanelBody, openByDefault = false, id, title, panelId }) => {
  const [loadBody, setLoadBody] = useState(openByDefault)
  const { SPACE } = APRICOT.utils.keys

  const togglePanelOpen = () => setLoadBody(!loadBody)

  return (
    <div className="panel panel-default" id={panelId}>
      <div className="panel-heading">
        <h4 className="panel-title">
          <a
            className={openByDefault ? 'cb-accordion-show' : 'collapsed'}
            role="button"
            data-toggle="collapse"
            data-parent={`#${id}`}
            onClick={togglePanelOpen}
            onKeyDown={e => {
              // Space key should also expand/collapse accordion
              if (e.keyCode === SPACE) togglePanelOpen
            }}
          >
            {typeof title === 'string' || title === 'function' ? title : React.createElement(title)}
          </a>
        </h4>
      </div>
      <div role="region" className={`panel-collapse collapse ${openByDefault ? 'in' : ''}`}>
        <div className="panel-body">{loadBody ? <PanelBody /> : null}</div>
      </div>
    </div>
  )
}

const Accordions = props => {
  const containerRef = useRef(null)
  const { showExpandCollapse = false, id, panels } = props

  useEffect(() => {
    // make sure apricot initializes accordion, so accessibility elements are automatically added
    $(containerRef.current).cbAccordion()
  }, [])

  return (
    <div className="cb-accordion" ref={containerRef}>
      {showExpandCollapse ? <ExpandCollapseLinks /> : null}
      <div className="panel-group" id={id}>
        {panels.map(p => {
          return <Panel key={p.panelId} {...p} id={id} />
        })}
      </div>
    </div>
  )
}

export default Accordions
