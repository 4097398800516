// size can be 'sm', 'md', 'lg', default='lg'
// Note that the "Loading" text displayed in the middle of the large loader
// is hardcoded by the Apricot styles, changing the message here will not
// alter that text.
export default ({ message = 'Loading...', loaderClass, size, style }) => (
  <div
    aria-live="polite"
    role="progressbar"
    aria-valuetext={message}
    className={`${loaderClass || 'cb-loader-gray'} cb-loader cb-loader-${size || 'lg'}`}
    style={style || { margin: '25px auto' }}
  >
    <span className="sr-only">{message}</span>
  </div>
)
