import { useLocation } from 'react-router-dom'
import { BasicInput } from '../../common'
import { addFilter, getFilters, removeFilter } from '../../../actions/filters'
import styles from '../../../assets/style/scss/filters.scss'

const Checkbox = ({ value, index, label, category, search, makeTwoColumns }) => {
  const id = `filterItem-${category}-${index}`
  const activeFilters = getFilters(search)[category] || []
  const shouldBeChecked = activeFilters.find(f => f === value.toString())

  return (
    <div className={makeTwoColumns ? styles['two-column'] : ''} key={id}>
      <BasicInput
        type="checkbox"
        input={{
          name: id,
          value,
          checked: Boolean(shouldBeChecked),
          onChange: e => {
            if (e.target.checked) addFilter(category, value)
            else removeFilter(category, value)
          },
        }}
        label={label}
      />
    </div>
  )
}

const FilterDropdown = ({ categories = [], breakpoint, label, id }) => {
  const { search } = useLocation()
  const containerRef = useRef(null)
  const [open, setOpen] = useState(false)
  const { ESC } = APRICOT.utils.keys
  const dropdownId = `filter${id}`
  const arrowClass = open ? 'cb-glyph cb-up' : 'cb-glyph cb-down'
  const makeTwoColumns = categories?.length === 1 && categories[0]?.options?.length > 10

  const checkClickedOutsideDropdown = e => {
    if (open && containerRef.current && !containerRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const checkEscapePressed = e => {
    if (e.keyCode === ESC && open) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', checkClickedOutsideDropdown)
    document.addEventListener('keydown', checkEscapePressed)
    return () => {
      document.removeEventListener('click', checkClickedOutsideDropdown)
      document.removeEventListener('keydown', checkEscapePressed)
    }
  })

  return (
    <div className={`${styles['filter-component']} ${styles[breakpoint]}`}>
      <div className={`${styles.filter} ${open ? styles['filter-open'] : ''}`} ref={containerRef}>
        <button
          type="button"
          id={dropdownId}
          aria-haspopup={true}
          aria-expanded={open}
          onClick={() => setOpen(!open)}
        >
          {label}
          <i className={`${styles['menu-indicator']} ${arrowClass}`} aria-hidden={true} />
        </button>
        <div
          className={`${styles['filter-dropdown']} ${makeTwoColumns ? styles.large : ''} ${
            open ? '' : 'hidden'
          }`}
          aria-labelledby={dropdownId}
          aria-hidden={!open}
        >
          {categories.map((category, i) => (
            <Fragment key={`dropdown-${id}-${i}`}>
              {category.label ? <div className={styles.header}>{category.label}</div> : null}
              {category.options.map((opt, i) => (
                <Checkbox
                  {...opt}
                  category={category.key}
                  index={i}
                  key={`options-${id}-${i}`}
                  search={search}
                  makeTwoColumns={makeTwoColumns}
                />
              ))}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({ breakpoint: state.app.breakpoint.name }))(FilterDropdown)
