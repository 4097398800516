import * as c from '../constants/FeatureConstants'

const initialState = {
  flags: [],
  fetching: false,
  fetched: false,
  error: null
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case c.FETCH_FEATURES_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      }
    case c.FETCH_FEATURES_FULFILLED:
      return {
        ...state,
        flags: payload,
        fetching: false,
        fetched: true
      }
    case c.FETCH_FEATURES_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: payload
      }
    case c.SET_ENABLE_FEATURE:
      return {
        ...state,
        flags: state.flags.filter(f => f !== payload)
      }
    case c.SET_DISABLE_FEATURE:
      if (state.flags.findIndex(f => f === payload) === -1) {
        return {
          ...state,
          flags: [ ...state.flags, payload ]
        }
      } else {
        return { ...state }
      }


    default:
      return state
  }
}
