import { transferStudentOut } from '../../actions/studentsByOrg'
import { resetStudentUpdate } from '../../actions/studentsCommon'
import { openModal } from '../../actions/app'
import { Modal, Error, Warning, BasicInput } from '../../components/common'
import { UPDATE_TYPE_TRANSFER } from '../../constants/StudentConstants'

const mapStateToProps = (state, { exam, storeLocation }) => {
  const { selectedOrgId, roles, selectedRole } = state.user.data
  const { update: { type, updating, error, id }} = state[storeLocation]
  const isActive = type === UPDATE_TYPE_TRANSFER && exam.examId === id

  return {
    active: isActive,
    updating: isActive && updating,
    error: isActive && error,
    educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
    selectedOrgId,
    orgName: roles[selectedRole].orgName,
  }
}

class ConfirmTransferStudentOutModal extends Component {
  state = { checked: false, shouldCloseModal: false, active: false }

  static getDerivedStateFromProps(props, state) {
    if (state.active && !props.active)
      return { shouldCloseModal: true }

    return { active: props.active }
  }

  componentWillUnmount() {
    const { resetStudentUpdate } = this.props
    resetStudentUpdate()
  }

  onCloseAction() {
    const { shouldCloseModal, studentName, orgName, openModal } = this.props

    if (shouldCloseModal)
      openModal('StudentTransferredOutModal', { studentName, orgName })
  }

  executeTransfer() {
    const { exam, selectedOrgId, educationPeriod, transferStudentOut } = this.props
    transferStudentOut(exam, selectedOrgId, educationPeriod)
  }

  footerActions() {
    const { updating } = this.props
    const { checked } = this.state

    return [
      { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
      {
        buttonLabel: 'Submit',
        isPrimary: true,
        onClick: () => this.executeTransfer(),
        isDisabled:  updating || !checked,
        busy: updating,
      }
    ]
  }

  warningMessage() {
    const { studentName, orgName, exam: { studentId }} = this.props
    const { checked } = this.state

    return (
      <>
        <p>You are about to transfer <strong>{studentName}</strong> out of the AP Registration and Ordering system for {orgName}. This student will be dropped from all courses and any associated exams will be removed from your order. Once the student is removed, this cannot be undone.</p>
        <div style={{ marginLeft: 48, marginTop: '1em', fontSize: '.875em' }}>
          <BasicInput
            type="checkbox"
            input={{
              name: 'confirmTransferOut',
              value: studentId,
              checked,
              onChange: () => this.setState({ checked: !checked }),
            }}
            label={`I attest that ${studentName} is no longer a student at ${orgName}.`}
          />
        </div>
      </>
    )
  }

  /*
*/

  render() {
    const { error, modalCloseFocusElem } = this.props
    const { shouldCloseModal } = this.state

    return (
      <Modal
        shouldCloseModal={shouldCloseModal}
        onCloseAction={this.onCloseAction.bind(this)}
        headerTitle="Transfer Out"
        footerActions={this.footerActions()}
        modalCloseFocusElem={modalCloseFocusElem}
      >
        {error
          ? <Error title="Error Transferring Student" message={error} />
          : <Warning
              title="Cannot Undo"
              message={this.warningMessage()}
              wrapContent={false}
          />
        }
      </Modal>
    )
  }
}

export default connect(mapStateToProps, { transferStudentOut, resetStudentUpdate, openModal })(ConfirmTransferStudentOutModal)
