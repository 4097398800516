import * as c from '../constants/SectionConstants'

const initialState = {
  expiring: false,
  expired: false,
  expireError: null,
  fetching: false,
  fetched: false,
  error: null,
  data: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case c.RESET_EXPIRE_ALL_JOIN_CODES:
      return { ...state, expiring: false, expired: false, expireError: null }

    case c.EXPIRE_ALL_JOIN_CODES_PENDING:
      return { ...state, expiring: true, expired: false, expireError: null }

    case c.EXPIRE_ALL_JOIN_CODES_FULFILLED:
      return { ...state, expiring: false, expired: true }

    case c.EXPIRE_ALL_JOIN_CODES_REJECTED:
      return { ...state, expiring: false, expired: false, expireError: payload }

    case c.FETCH_EXPIRE_JOIN_CODES_STATUS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }

    case c.FETCH_EXPIRE_JOIN_CODES_STATUS_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: payload }

    case c.FETCH_EXPIRE_JOIN_CODES_STATUS_REJECTED:
      return { ...state, fetching: false, fetched: true, error: payload }

    default:
      return state
  }
}
