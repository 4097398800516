import { Link } from 'react-router-dom'
import { StreetAddress } from '../common'
import { NAV_LINKS } from '../../constants/NavConstants'
import { SETTINGS_PANELS } from '../../constants/SettingsConstants'

const mapStateToProps = ({
  settingsOrg,
  user: {
    data: { selectedOrgId },
  },
  invoices: { data = {} },
}) => ({
  ...(settingsOrg[selectedOrgId]?.data?.schoolInformation.billing.contactInformation || {}),
  data,
})

const BillingContact = ({
  firstName,
  lastName,
  middleInitial,
  email,
  address,
  date,
  title = "Your School's Billing Contact",
  linkTitle = 'Edit School Billing Info',
  afterLinkText = '',
  data,
}) => (
  <>
    <h3 className="h4">{title}</h3>
    {date ? <div>On {date}, we will email the invoice to:</div> : null}
    <div className="full-name">
      {firstName} {middleInitial} {lastName}
    </div>
    <div className="email-address">{email}</div>
    <StreetAddress {...address} />

    <p>
      <Link to={`${NAV_LINKS.settings}?scroll=${SETTINGS_PANELS.partForm}`}>{linkTitle}</Link>{' '}
      {afterLinkText}
    </p>
  </>
)

export default connect(mapStateToProps)(BillingContact)
