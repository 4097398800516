import InvoiceItemizedRows from './InvoiceItemizedRows'
import { formatter } from './utils'

import styles from '../../assets/style/scss/invoices.scss'

export default ({ type, data = [], isCentralBill }) => {
  const total = data.reduce((acc, d) => acc + d.amount, 0)

  return data.length ? (
    <div className={styles['invoice-table']}>
      <h3 className="h4" style={{ textTransform: 'capitalize' }}>
        {type} Reimbursement
      </h3>
      {isCentralBill ? (
        <p>
          After your district sends the amount your institution owes to the AP Program, it is your
          district&#39;s responsibility to work directly with your {type} to receive reimbursement
          for AP Exam fees.
        </p>
      ) : (
        <p>
          After sending the amount your institution owes to the AP Program, it is your school&#39;s
          responsibility to work directly with your {type} to receive reimbursement for AP Exam
          fees.
        </p>
      )}

      <div className="table-responsive">
        <table className="table cb-no-table-border">
          <thead>
            <tr>
              <th scope="col" className={styles.description}>
                Exam Subsidy
              </th>
              <th scope="col" className={styles.quantity}>
                Quantity
              </th>
              <th scope="col" className={styles.price}>
                Credits Per Item
              </th>
              <th scope="col" className={styles.amount}>
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <InvoiceItemizedRows data={data} />
          </tbody>
        </table>
        <div className={styles['invoice-total']}>
          <div style={{ textTransform: 'capitalize' }}>Total Reimbursement</div>
          <div>{formatter(total)}</div>
        </div>
      </div>
    </div>
  ) : null
}
