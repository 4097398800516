/* eslint-disable no-nested-ternary */
import { Loader, Error } from '@myap/ui-library/esm/components'
import { Modal, BasicSelect } from '../../components/common'
import { fetchAssignableStudents, assignStudents } from '../../actions/examRoom'
import { filterStudents } from '../../components/examRoom/utils'
import { plural } from '../../utils/common'
import ExamRoomAssignableStudentsTable from '../../components/examRoom/ExamRoomAssignableStudentsTable'
import { EXAM_ROOM_FILTERS } from '../../constants/ExamRoomConstants'
import styles from '../../assets/style/scss/exam-room.scss'

const mapStateToProps = (state, { id }) => {
  const {
    user,
    settingsEducationPeriod,
    examRoomAssignableStudents,
    examRoomStudents: { [id]: students = [], saving, saved, serror },
  } = state

  return {
    orgId: user.data.selectedOrgId,
    educationPeriod: settingsEducationPeriod.selectedEducationPeriod,
    ...examRoomAssignableStudents,
    students: students.map(s => ({ ...s, roomAssignment: { id }})),
    saving,
    saved,
    serror,
  }
}

const DEFAULT_FILTER = EXAM_ROOM_FILTERS[0].value

const ExamRoomAssignStudentsModal = props => {
  const {
    fetchAssignableStudents,
    assignStudents,
    fetching,
    saving,
    serror,
    saved,
    error,
    assignableStudents = [],
    students,
    courseName,
    name,
    modalCloseFocusElem,
    totalCapacity = 0,
    id: roomId,
  } = props
  const [selectedStudents, setSelectedStudents] = useState(students)
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const visibleStudents = filterStudents(filter, assignableStudents)
  const selectedCount = selectedStudents.length
  const assignableCount = assignableStudents.length
  const availableSeats = totalCapacity - selectedCount
  const isOverCapacity = selectedCount > totalCapacity

  useEffect(() => {
    fetchAssignableStudents(props)
  }, [])

  return (
    <Modal
      shouldCloseModal={saved}
      modalCloseFocusElem={modalCloseFocusElem}
      headerTitle={`Assign Students: ${courseName} ${name}`}
      footerActions={[
        { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
        {
          buttonLabel: 'Add Students',
          isPrimary: true,
          isDisabled:
            fetching ||
            error ||
            saving ||
            isOverCapacity ||
            !assignableCount ||
            selectedCount <= students.length,
          busy: saving,
          onClick: () => assignStudents(props, selectedStudents),
        },
      ]}
    >
      <div className={styles['manual-assignment-modal']}>
        {fetching ? (
          <Loader />
        ) : error ? (
          <Error message={error} title="Error retrieving available students" />
        ) : !assignableCount ? (
          <p>There are no students available to assign to this room.</p>
        ) : (
          <>
            <p>
              Select students to assign to this room. Refer to the{' '}
              <a
                href="https://apcentral.collegeboard.org/ap-coordinators/resource-library"
                target="_blank"
                title="AP Coordinator's Manual (Opens in a new window)"
              >
                AP Coordinator&#39;s Manual
              </a>{' '}
              to view room assignment requirements for students who require a separate testing room.
            </p>
            <div className="row">
              <div
                className={`col-sm-6 ${isOverCapacity ? 'cb-error-msg' : ''}`}
                style={{ paddingTop: 12 }}
              >
                {selectedCount} Selected,{' '}
                {isOverCapacity
                  ? `${Math.abs(availableSeats)} Over Capacity`
                  : `${availableSeats} ${availableSeats > 0 ? 'More' : ''} ${plural(
                      availableSeats,
                      'Seat'
                    )} Available`}
              </div>
              <div className="col-sm-6">
                <BasicSelect
                  input={{
                    'aria-label': 'Filter assignable students',
                    name: 'filterAssignableStudents',
                    value: filter,
                    onChange: e => setFilter(EXAM_ROOM_FILTERS[e.target.value]?.value),
                  }}
                  options={EXAM_ROOM_FILTERS}
                  showEmptyOption={false}
                  style={{ marginBottom: 5 }}
                  truncate={27}
                />
              </div>
            </div>
            <div className={styles['assignable-table-container']}>
              <ExamRoomAssignableStudentsTable
                visibleSections={visibleStudents}
                assignableSections={assignableStudents}
                name={name}
                setSelectedStudents={setSelectedStudents}
                selectedStudents={selectedStudents}
                roomId={roomId}
              />
            </div>
            {isOverCapacity ? (
              <p className={styles['warning-icon']}>
                You are {Math.abs(availableSeats)} over this room&#39;s capacity.
              </p>
            ) : null}
            {serror ? <Error title="Error saving room assignments" message={serror} /> : null}
          </>
        )}
      </div>
    </Modal>
  )
}

export default connect(
  mapStateToProps,
  { fetchAssignableStudents, assignStudents }
)(ExamRoomAssignStudentsModal)
