import { isValid, isDirty, submit } from 'redux-form'
import { resetUpdatePackingListItems } from '../../actions/packingList'
import { Modal, Error } from '../../components/common'
import PackingListQuantityDiscrepanciesForm from '../forms/PackingListQuantityDiscrepanciesForm'
import { CHANGE_PACKING_LIST_ITEMS_FORM } from '../../constants/PackingListConstants'

const mapStateToProps = (state, ownProps) => {
  const {
    packingListSummary: { packingShipmentsId },
    packingListDetails: {
      updatePackingListItem: { updating, updated, error },
    },
  } = state
  const {
    headerTitle,
    modalCloseFocusElem,
    materials,
    shippedQuantity,
    packingListItemId,
    unused = 0,
  } = ownProps
  return {
    headerTitle,
    modalCloseFocusElem,
    packingShipmentsId,
    materials,
    shippedQuantity,
    packingListItemId,
    unused,
    updating,
    updated,
    error,
    dirty: isDirty(CHANGE_PACKING_LIST_ITEMS_FORM)(state),
    valid: isValid(CHANGE_PACKING_LIST_ITEMS_FORM)(state),
  }
}

class ChangePackingListItemsModal extends Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    const { resetUpdatePackingListItems } = this.props
    resetUpdatePackingListItems()
  }

  submitForm() {
    const { submit } = this.props
    submit(CHANGE_PACKING_LIST_ITEMS_FORM)
  }

  footerActions() {
    const { valid, updating, dirty } = this.props
    return [
      {
        buttonLabel: 'Cancel',
        isDismissable: true,
        isPrimary: false,
        isDisabled: updating,
      },
      {
        buttonLabel: 'Save',
        isPrimary: true,
        onClick: this.submitForm,
        isDisabled: !valid || updating || !dirty,
        busy: updating,
      },
    ]
  }

  render() {
    const {
      headerTitle,
      modalCloseFocusElem,
      materials,
      shippedQuantity,
      packingListItemId,
      unused,
      updated,
      error,
    } = this.props

    return (
      <Modal
        headerTitle={headerTitle}
        shouldCloseModal={updated}
        modalCloseFocusElem={modalCloseFocusElem}
        footerActions={this.footerActions()}
      >
        {error && <Error message={error} />}
        <p>Enter the quantities below to match what was shipped.</p>
        <PackingListQuantityDiscrepanciesForm
          materials={materials}
          shippedQuantity={shippedQuantity}
          packingListItemId={packingListItemId}
          unused={unused}
        />
      </Modal>
    )
  }
}

export default connect(mapStateToProps, { resetUpdatePackingListItems, submit })(
  ChangePackingListItemsModal
)
