import { Modal, Error } from '../../../common'
import { getSchoolNameAndAddress } from '../../../../selectors/settings'

const mapStateToProps = state => ({
  schoolNameAndAddress: getSchoolNameAndAddress(state),
})

const GenerateNARMultiSelect = connect(mapStateToProps)(
  ({
    modalProps,
    getFooterActions,
    courseName,
    schoolNameAndAddress,
    selectedEnrollments = [],
    filteredEnrollments = [],
    updated,
    setSuccessText,
    error,
  }) => {
    const attemptedChangesCount = selectedEnrollments?.length
    const actualChangesCount = filteredEnrollments.length

    useEffect(() => {
      if (updated) {
        setSuccessText(
          <>
            NAR forms are downloaded for {actualChangesCount} student
            {actualChangesCount > 1 ? 's' : ''} taking the <strong>{courseName}</strong> exam.
          </>
        )
      }
    }, [updated])

    return (
      <Modal
        {...modalProps}
        headerTitle="Generate NAR forms for Exams"
        footerActions={getFooterActions(
          filteredEnrollments,
          {
            schoolNameAndAddress,
          },
          actualChangesCount < 1
        )}
      >
        {actualChangesCount > 0 ? (
          <p style={{ marginTop: '2em' }}>
            You are generating NAR forms for {actualChangesCount} out of the {attemptedChangesCount}{' '}
            selected students taking the <strong>{courseName}</strong> exam.
          </p>
        ) : null}
        {actualChangesCount < 1 ? <p>The selected students do not qualify for NAR forms.</p> : null}
        {error ? <Error message={error} /> : null}
      </Modal>
    )
  }
)

export default GenerateNARMultiSelect
