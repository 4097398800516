import { clearDriftless, setDriftlessInterval, setDriftlessTimeout } from 'driftless'
import { formatDate } from '@myap/ui-library/esm/date'
import { iam } from '@myap/ui-library/esm/profile'

// Usage:
// function MyComponent(props) {
//   useTraceUpdate(props);
//   return <div>{props.children}</div>;
// }

export const useTraceUpdate = props => {
  const prev = useRef(props)
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v]
      }
      return ps
    }, {})
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps)
    }
    prev.current = props
  })
}

// Description:
// Custom hook for getting previous value
// Usage:
// const prevValue = usePrevious(value)
// Explained:
// https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/

export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

// Description:
// Custom hook that emits the ellapsed minutes
// Usage:
// const ellapsed = useMinutes()

let totalEllapsed = 0
export const useMinutes = startTimer => {
  const [ellapsedMinutes, setEllapsedMinutes] = useState(totalEllapsed)

  useEffect(() => {
    if (startTimer) {
      const now = new Date()
      const remainingSecondsUntilMinuteStart = 60 - parseInt(formatDate(now, 's'), 10)
      // first orient timer so it goes off at the beginning of the minute
      // then start one minute interval
      setDriftlessTimeout(() => {
        totalEllapsed += 1
        setDriftlessInterval(() => {
          totalEllapsed += 1
          setEllapsedMinutes(totalEllapsed)
        }, 60000)
      }, remainingSecondsUntilMinuteStart * 1000)
    }
    return () => clearDriftless()
  }, [startTimer])

  return ellapsedMinutes
}

// Description:
// Custom hook that emits the catapult aws status
// Usage:
// const auth = useCatapultAuth()

export const useCatapultAuth = () => {
  const { getEventBus, events, getTempAWSCreds, getAuthSession } = iam()
  const { isLoggedIn, basicProfile = {} } = getAuthSession()
  const bus = getEventBus()
  const { AWSLogin, AWSLoginFailed, Login, Logout } = events
  const hasAWSCreds = Boolean(getTempAWSCreds('apfym'))
  const [awsAuth, setAWSAuth] = useState({
    loading: !hasAWSCreds,
    loaded: hasAWSCreds,
    error: false,
  })
  const setAWSAuthLogin = () => setAWSAuth({ loaded: true, error: false, loading: false })
  const setAWSAuthLoginFailed = () => setAWSAuth({ loaded: false, error: true, loading: false })
  const [isAuthed, setAuth] = useState(isLoggedIn)
  const setLoggedIn = () => setAuth(true)
  const setLoggedOut = () => setAuth(false)

  useEffect(() => {
    bus.on(AWSLogin, setAWSAuthLogin)
    bus.on(AWSLoginFailed, setAWSAuthLoginFailed)
    bus.on(Logout, setLoggedOut)
    bus.on(Login, setLoggedIn)
    return () => {
      bus.off(AWSLogin, setAWSAuthLogin)
      bus.off(AWSLoginFailed, setAWSAuthLoginFailed)
      bus.off(Logout, setLoggedOut)
      bus.off(Login, setLoggedIn)
    }
  }, [])

  return { isAuthed, basicProfile, ...awsAuth }
}
