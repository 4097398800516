import memoize from 'memoizee'
import { BasicSelect } from '../../../../common'
import { fetchExamPricing } from '../../../../../actions/settingsGlobal'

const mapStateToProps = state => state.settingsPricing

const createLateReasonOptions = memoize(reasons => {
  return reasons.map(reason => ({
    label: reason.description,
    value: reason.cd,
  }))
})

const LateReason = ({
  fetchExamPricing,
  lateDayAdminPricing = [],
  fetching,
  fetched,
  isLateTestingWindow,
  updateLateReason,
  lateReasonCode,
}) => {
  const lateReasonOptions = createLateReasonOptions(lateDayAdminPricing)

  useEffect(() => {
    if (!fetching && !fetched) fetchExamPricing()
  }, [])

  if (isLateTestingWindow) {
    return (
      <div className="form-group">
        <BasicSelect
          label="Reason for late testing:"
          options={lateReasonOptions}
          input={{
            name: 'swapExamOptions',
            onChange: updateLateReason,
            value: lateReasonCode,
          }}
        />
      </div>
    )
  }
  return null
}

export default connect(
  mapStateToProps,
  { fetchExamPricing }
)(LateReason)
