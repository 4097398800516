import { isDateBefore } from '@myap/ui-library/esm/date'
import { Modal, Error, BasicSelect } from '../../../common'
import UpdateTable from '../../../orders/coordinator/UpdateTable'
import SelectNotTakingExamReasons from '../../common/SelectNotTakingExamReason'
import TestingLocation from './common/TestingLocation'
import LateReason from './common/LateReason'
import {
  STUDENT_ENROLLMENT_STATUS_YES,
  STUDENT_ENROLLMENT_STATUS_NO,
  UPDATE_TYPE_DATE_INTENT,
} from '../../../../constants/StudentConstants'
import { DIGITAL_EXAM, TEST_LOCATION_SCHOOL } from '../../../../constants/SettingsConstants'
import { getOrderDeadlineIsPast } from '../../../../selectors/section'
import {
  getFutureExamWindows,
  isDigitalExamInCutoffPeriod,
  getExamDay,
} from '../../../../selectors/examWindows'
import { isAdministeringDigitalExams } from '../../../../selectors/settings'

import style from '../../../../assets/style/scss/orders.scss'

const mapStateToProps = (state, { exam, type }) => {
  const {
    status: {
      data: { isSubmitted },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsDeadlines: { data: settingsDeadlinesData },
    studentsByOrg: { sectionMap },
  } = state
  const deadlinesData = settingsDeadlinesData?.[selectedEducationPeriod] ?? {}
  const section = sectionMap[exam.sectionId]
  const { examFormat, testCd, testWindows, testWindow } = exam
  const isDigital = examFormat === DIGITAL_EXAM
  const availableExamWindows = getFutureExamWindows(state, testCd, testWindows)
  const mustChangeExamWindow = type === UPDATE_TYPE_DATE_INTENT
  const isPastDigitalCutOff = isDigitalExamInCutoffPeriod(state, { testCd, testWindow })
  const isBeforeExamDay = isDateBefore(new Date(), getExamDay(state, testCd, testWindow))

  return {
    isSubmitted,
    isPastOrderDeadline: getOrderDeadlineIsPast(section, deadlinesData),
    isDigital,
    mustChangeExamWindow,
    isWithinDigitalWarningPeriod: isDigital && isPastDigitalCutOff && isBeforeExamDay,
    availableExamWindows,
    notAdministeringDigitalExams: !isAdministeringDigitalExams(state),
  }
}

const IntroText = ({
  examIntent,
  courseName,
  isPastOrderDeadline,
  mustChangeExamWindow,
  isWithinDigitalWarningPeriod,
  numberOfAvailableExamWindows,
}) => {
  const isExamIntentYes = examIntent === STUDENT_ENROLLMENT_STATUS_YES

  if (!isExamIntentYes)
    return (
      <>
        {isWithinDigitalWarningPeriod && (
          <>
            <div
              className="alert alert-warning cb-alert-heading"
              style={{ marginBottom: 0 }}
              role="alert"
              tabIndex="0"
            >
              <h2>Cannot Undo</h2>
            </div>
            <p>
              <strong>Important:</strong> If you cancel this student&#39;s exam now, they won&#39;t
              be able to test on this exam date. Click <strong>Continue</strong> to proceed with
              changing the student&#39;s Order Exam status to &quot;No&quot; to cancel their exam.
              Click <strong>Keep Order</strong> to keep the student&#39;s Order Exam status at
              &quot;Yes&quot;.
            </p>
          </>
        )}
        <p>
          You are indicating that the student below will not take the exam for{' '}
          <strong>{courseName}</strong>. The cost for this exam is noted below.
        </p>
      </>
    )

  if (mustChangeExamWindow) {
    if (numberOfAvailableExamWindows < 1) {
      return (
        <p>
          Call AP Services for Educators to confirm available administration dates for this exam
          (877-274-6474 or 212-632-1781).
        </p>
      )
    }
    return <p>Select the exam administration date for this student.</p>
  }

  if (isPastOrderDeadline)
    return (
      <p>
        You are ordering an additional exam for this student after the order deadline. The cost for
        this exam is noted below.
      </p>
    )

  return <p>You are ordering an additional exam for this student.</p>
}

const SelectExamWindow = ({ availableExamWindows, selectedWindow, updateExamWindow }) => (
  <>
    {availableExamWindows.length ? (
      <BasicSelect
        label="Select exam window"
        input={{
          name: 'testWindow',
          value: selectedWindow,
          onChange: e => updateExamWindow(e),
        }}
        options={availableExamWindows.map(({ adminWindow, displayName }) => ({
          label: displayName,
          value: adminWindow,
        }))}
        showEmptyOption={true}
      />
    ) : null}
  </>
)

const UpdateTableSection = ({ isSubmitted, exam, studentName }) => (
  <>
    <hr className={style['order-border-warning']} />
    {isSubmitted ? (
      <p>You will need to review and submit your change order to apply this update.</p>
    ) : null}
    <UpdateTable exam={exam} studentName={studentName} />
  </>
)

const ExamIntentUpdateOrder = ({
  exam: incomingExam,
  exam: { examIntent, lateReasonCode, testWindow },
  courseName,
  isDigital,
  mustChangeExamWindow,
  isWithinDigitalWarningPeriod,
  availableExamWindows,
  testLocation,
  studentName,
  modalStyles,
  isPastOrderDeadline,
  shouldCloseModal,
  error,
  isSubmitted,
  notAdministeringDigitalExams,
  onCloseAction,
  modalCloseFocusElem,
  getFooterActions,
}) => {
  const [notReasonObj, setNotReasonObj] = useState({})
  const [exam, setExam] = useState(incomingExam)
  const [attested, setAttested] = useState(isDigital || notAdministeringDigitalExams)
  const [updatedExam, setUpdatedExam] = useState(exam)
  const [selectedExamWindow, setSelectedExamWindow] = useState(null)
  const [selectedLateReasonCode, setSelectedLateReasonCode] = useState(lateReasonCode)
  const [selectedTestLocation, setSelectedTestLocation] = useState(
    isDigital ? TEST_LOCATION_SCHOOL : null
  )
  const updateTestLocation = e => setSelectedTestLocation(e.target.value)
  const updateExamWindow = e => setSelectedExamWindow(e.target.value)
  const updateLateReason = e => setSelectedLateReasonCode(e.target.value)
  const isExamIntentNo = examIntent === STUDENT_ENROLLMENT_STATUS_NO
  const isExamIntentYes = examIntent === STUDENT_ENROLLMENT_STATUS_YES
  const disableUpdateButton =
    isExamIntentYes &&
    ((!isDigital && !attested) ||
      (isDigital && !selectedTestLocation) ||
      (mustChangeExamWindow && !selectedLateReasonCode) ||
      (mustChangeExamWindow && !selectedExamWindow))

  useEffect(() => {
    setUpdatedExam({
      ...exam,
      ...notReasonObj,
      ...(selectedExamWindow ? { testWindow: selectedExamWindow } : { testWindow }),
      ...(selectedLateReasonCode ? { lateReasonCode: selectedLateReasonCode } : { lateReasonCode }),
      ...(selectedTestLocation ? { testLocation: selectedTestLocation } : { testLocation }),
    })
  }, [exam, notReasonObj, selectedExamWindow, selectedLateReasonCode, selectedTestLocation])

  return (
    <Modal
      modalStyles={modalStyles}
      headerTitle={isSubmitted ? 'Change Submitted Order' : 'Update Order'}
      shouldCloseModal={shouldCloseModal}
      onCloseAction={() => onCloseAction()}
      modalCloseFocusElem={modalCloseFocusElem}
      footerActions={getFooterActions(() => updatedExam, {
        disabled: disableUpdateButton,
        actionBtnLabel: isWithinDigitalWarningPeriod && 'Continue',
        cancelBtnLabel: isWithinDigitalWarningPeriod && 'Keep Order',
      })}
    >
      {error ? <Error title="Error Updating Student" message={error} /> : null}

      <IntroText
        examIntent={examIntent}
        courseName={courseName}
        isPastOrderDeadline={isPastOrderDeadline}
        mustChangeExamWindow={mustChangeExamWindow}
        isWithinDigitalWarningPeriod={isWithinDigitalWarningPeriod}
        numberOfAvailableExamWindows={availableExamWindows.length}
      />
      {isExamIntentNo ? (
        <SelectNotTakingExamReasons onChangeAction={reason => setNotReasonObj(reason)} />
      ) : null}
      {mustChangeExamWindow && isExamIntentYes ? (
        <>
          <SelectExamWindow
            availableExamWindows={availableExamWindows}
            selectedWindow={selectedExamWindow}
            updateExamWindow={updateExamWindow}
          />
          {selectedExamWindow ? (
            <>
              <p>
                You are changing the <strong>{courseName}</strong> exam date for this student.
                Select the reason below. Some reasons may incur a late-testing fee; any additional
                fee will be noted.
              </p>
              <LateReason
                isLateTestingWindow={true}
                updateLateReason={updateLateReason}
                lateReasonCode={selectedLateReasonCode}
              />
              {selectedLateReasonCode && (
                <TestingLocation
                  isDigital={true}
                  examIntent={examIntent}
                  testLocation={testLocation}
                  updateTestLocation={updateTestLocation}
                  attestTestLocation={e => setAttested(e.target.checked)}
                />
              )}
              {selectedTestLocation && (
                <UpdateTableSection
                  isSubmitted={isSubmitted}
                  exam={updatedExam}
                  studentName={studentName}
                />
              )}
            </>
          ) : null}
        </>
      ) : (
        <>
          <TestingLocation
            isDigital={isDigital}
            examIntent={examIntent}
            testLocation={testLocation}
            updateTestLocation={updateTestLocation}
            attestTestLocation={e => setAttested(e.target.checked)}
          />
          <UpdateTableSection
            isSubmitted={isSubmitted}
            exam={updatedExam}
            studentName={studentName}
          />
        </>
      )}
    </Modal>
  )
}

export default connect(mapStateToProps)(ExamIntentUpdateOrder)
