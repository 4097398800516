import stylesheet from '../../assets/style/scss/terms-and-conditions.scss'

export default ({ adminYear, academicYearDescr }) => (
  <div className={`${stylesheet['tac-container']}`}>
    <p>
      THIS AGREEMENT is by and between you (&quot;Client&quot; or &quot;you&quot;) and College Board
      and includes the Terms and Conditions set forth below (&quot;Agreement&quot;).
    </p>
    <p>
      College Board shall furnish Advanced Placement (AP) Exams to students at the selected schools
      designated by Client which order AP exams from College Board. In exchange, Client shall pay
      College Board for such tests in accordance with this Agreement.
    </p>
    <p>
      If your district or state, as applicable, has an existing signed written contract with College
      Board for the payment of AP exams, the terms and conditions of that contract will supersede
      any conflicting terms contained herein.
    </p>
    <p>
      <strong>Term and Termination.</strong> This Agreement commences when Client enters its Funding
      Plans and will expire on August 31, {adminYear} (&quot;Term&quot;). If either party breaches
      any of the provisions of this Agreement, the non-breaching party shall have the right to give
      the other party written notice to cure such breach within thirty (30) days and, if such breach
      is not cured within a thirty (30) day period, either party shall have the right to terminate
      this Agreement, without waiver of any other remedy, whether legal or equitable; provided,
      however, if Client breaches the Representations and Warranties, Ownership, or Confidentiality
      provisions, then College Board shall have the right to terminate this Agreement immediately.
    </p>
    <p>
      <strong>Partial Payment Upon Termination.</strong> Client will compensate College Board for
      all services performed, products furnished, including any costs associated with the initial
      deployment of resources in preparation for providing the services under this Agreement,
      through the effective date of any termination in accordance with invoices issued or to be
      issued by College Board.
    </p>
    <p>
      <strong>Fees and Payment.</strong> Client shall pay fees for the AP Exams furnished during the{' '}
      {academicYearDescr} implementation year. Unless otherwise indicated, payment terms are Net 30.
    </p>
    <p>
      If the Client is using federal funds to pay for all or a portion of the Deliverables furnished
      by College Board under this Agreement, Client acknowledges and agrees that College Board shall
      not be categorized as a &quot;subrecipient&quot; receiving a federal award as defined by OMB
      Circular Subpart A.210(c) of Circular No. A-133. College Board shall be defined as a
      &quot;vendor&quot; that provides good and services within normal business operations, provides
      similar goods or services to other purchasers and operates in a competitive environment.
      Client acknowledges and agrees that the substance of the relationship with College Board is
      that of a vendor not a subrecipient.
    </p>
    <p>
      <strong>Client Purchase Orders.</strong> Notwithstanding anything to the contrary, the parties
      hereby acknowledge and agree that the Client Purchase Order shall be subject to the terms and
      conditions of this Agreement and this Agreement shall override any terms and conditions
      included in the Client Purchase Order.
    </p>
    <p>
      <strong>Taxes.</strong> Client agrees to pay any sales, use, value added or other taxes or
      import duties (other than College Board's corporate income taxes) based on, or due as a result
      of any fees paid to College Board under this Agreement, unless Client is exempt from such
      taxes as the result of Client&#39;s corporate or government status and Client has furnished
      College Board with a valid tax exemption certificate.
    </p>
    <h3>Representations and Warranties.</h3>
    <p>
      <strong>(i) Authority.</strong> Client represents and warrants that it is empowered under
      applicable state laws to enter into and perform this Agreement and it has caused this
      Agreement to be duly authorized, executed, and delivered.
    </p>
    <p>
      <strong>(ii) College Board Services Warranty.</strong> College Board represents and warrants
      that it shall perform its obligations under this Agreement in a professional, workmanlike
      manner.
    </p>
    <p>
      <strong>(iii) College Board Disclaimer of Implied Warranties.</strong> EXCEPT AS PROVIDED
      ABOVE, COLLEGE BOARD MAKES NO WARRANTIES WHATSOEVER AND PROVIDES THE SERVICES AND
      DELIVERABLES, AS APPLICABLE, ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS.
      COLLEGE BOARD HEREBY DISCLAIMS ALL OTHER WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY,
      INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
      PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. COLLEGE BOARD DOES NOT WARRANT
      THE OPERATION OF THE DELIVERABLES TO BE UNINTERRUPTED OR ERROR-FREE OR THAT ALL DEFICIENCIES
      OR ERRORS ARE CAPABLE OF BEING CORRECTED. FURTHERMORE, COLLEGE BOARD DOES NOT WARRANT OR MAKE
      ANY REPRESENTATIONS REGARDING THE USE OF THE DELIVERABLES OR THE RESULTS OBTAINED THEREFROM OR
      THAT THE DELIVERABLES WILL SATISFY CLIENT&#39;S REQUIREMENTS.
    </p>
    <p>
      <strong>Ownership of Intellectual Property.</strong> College Board is the exclusive owner of
      all rights in and to the AP exam, all individual test items (questions) contained therein,
      including all copies thereof, all examination materials and all data collected therefrom,
      including but not limited to student scores derived from the exam, and collected under the
      registration and administration of the exam are at all times exclusively owned by College
      Board. In addition, College Board is the exclusive owner of all publications and reports
      associated with AP exams, including all copyrights, trademarks, trade secrets, patents, and
      other similar proprietary rights, and all renewals and extensions thereof (all exams
      referenced in this section are collectively referred to as &quot;College Board Intellectual
      Property&quot;). Client acknowledges and agrees that nothing in this Agreement shall be
      interpreted to indicate that College Board is passing its proprietary rights in and to College
      Board Intellectual Property to Client.
    </p>
    <p>
      <strong>Limitation of Liability.</strong> TO THE EXTENT PERMITTED BY LAW, AND NOTWITHSTANDING
      ANY OTHER PROVISION OF THIS AGREEMENT, THE TOTAL LIABILITY, IN THE AGGREGATE, OF COLLEGE BOARD
      AND ITS OFFICERS, TRUSTEES, PARTNERS, EMPLOYEES, AGENTS AND COLLEGE BOARD&#39;S SUBCONTRACTORS
      AND CONSULTANTS, AND ANY OF THEM, TO CLIENT AND ANYONE CLAIMING BY, THROUGH OR UNDER CLIENT,
      FOR ANY AND ALL CLAIMS, LOSSES, COSTS, OR DAMAGES WHATSOEVER ARISING OUT OF, RESULTING FROM OR
      IN ANY WAY RELATED TO THIS AGREEMENT OR THE WORK PERFORMED BY COLLEGE BOARD PURSUANT TO THIS
      AGREEMENT FROM ANY CAUSE OR CAUSES, INCLUDED BUT NOT LIMITED TO THE NEGLIGENCE, PROFESSIONAL
      ERRORS OR OMISSIONS, STRICT LIABILITY OR BREACH OF CONTRACT OR WARRANTY EXPRESS OR IMPLIED OF
      COLLEGE BOARD OR COLLEGE BOARD&#39;S OFFICERS, TRUSTEES, PARTNERS, EMPLOYEES, AGENTS,
      SUBCONTRACTORS OR CONSULTANTS OR ANY OF THEM, SHALL NOT EXCEED THE ACTUAL AMOUNT PAID TO
      COLLEGE BOARD UNDER THIS AGREEMENT FOR THE SPECIFIC DELIVERABLE SUBJECT TO THE DAMAGES CLAIM.
    </p>
    <p>
      IN NO EVENT SHALL EITHER PARTY, THEIR AFFILIATES OR THEIR SUBCONTRACTORS BE LIABLE FOR ANY
      SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES (INCLUDING, BUT
      NOT LIMITED TO, ANY DAMAGES FOR LOSS OF PROFITS OR SAVINGS, LOSS OF USE, BUSINESS INTERRUPTION
      OR THE LIKE), EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
    </p>
    <p>
      <strong>Force Majeure.</strong> A party may be excused from performance of an obligation under
      this Agreement in the event that such performance is prevented by an act of God, act of war,
      terrorism, riot, fire, explosion, flood, epidemic, pandemic, government action or inaction,
      curtailment of transportation facilities, weather event, labor strikes, environmental or other
      contamination, natural event or other circumstance (whether or not listed above) that is
      beyond the control of, and could not reasonably be avoided by, such party, whether or not the
      applicable event is foreseeable.
    </p>
    <p>
      <strong>Governing Law and Choice of Forum.</strong> Unless prohibited by the law of the State
      in which Client is located, this Agreement shall be construed in accordance with the terms and
      conditions set forth in this Agreement and the law of the State of New York without regard to
      choice or conflict of laws principles that would cause the application of any other laws. Any
      dispute or controversy arising out of or relating to this Agreement or otherwise shall be
      determined by a court of competent jurisdiction in New York County, New York State (or the
      Federal Court otherwise having territorial jurisdiction over such County and subject matter
      jurisdiction over the dispute), and not elsewhere, subject only to the authority of the Court
      in question to order changes of venue; provided, however, that prior to the instigation of any
      such action (other than an action for equitable relief) a meeting shall be held at a mutually
      agreed upon location, attended by individuals with decision-making authority to attempt in
      good faith to negotiate a resolution of the dispute. If within forty-five (45) days after such
      meeting the parties have not succeeded in resolving the dispute, either party may proceed at
      law, or in equity, in a court of competent jurisdiction. Client agrees not to demand a trial
      by jury in any action, proceeding or counterclaim.
    </p>
    <p>
      <strong>Notices.</strong> All notices or other communications hereunder shall be deemed to
      have been duly given and made if in writing and if served by personal delivery upon the party
      for whom it is intended on the day so delivered, if delivered by registered or certified mail,
      return receipt requested, or by courier service on the date of its receipt by the intended
      party (as indicated by the records of such of the U.S. Postal Service or the courier service),
      or if sent by e-mail, or if not a business day, the next succeeding business day, provided
      that the email sender retains confirmation of a &quot;read-receipt&quot; which acknowledges
      recipient&#39;s opening of such email, or if not available, promptly confirms by telephone
      confirmation thereof. Notices sent by Client to the College Board shall be sent to the
      addresses indicated below:
    </p>
    <table style={{ borderCollapse: 'collapse', marginBottom: '24px', width: '100%' }}>
      <tbody>
        <tr>
          <td style={{ border: '1px solid #000', padding: '24px' }}>
            <strong>To College Board:</strong>
            <br />
            AP Exams
            <br />
            College Board
            <br />
            250 Vesey Street
            <br />
            New York, NY 10281
            <br />
            Tel: (212) 713-8000
          </td>
          <td style={{ border: '1px solid #000', padding: '24px' }}>
            <strong>With a copy to:</strong>
            <br />
            Legal Department
            <br />
            College Board
            <br />
            250 Vesey Street
            <br />
            New York, NY 10281
            <br />
            Tel: (212) 713-8000
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <strong>Relationship of the Parties.</strong> The relationship of the Client and College Board
      is that of independent contractors. Neither party nor their employees are partners, agents,
      employees, or joint ventures of the other party. Neither party shall have any authority to
      bind the other party to any obligation by contract or otherwise. College Board, its employees,
      and agents shall not be considered employees of the Client while performing these services and
      will not be entitled to fringe benefits normally accruing to employees of the Client. Client
      and College Board recognize and agree that College Board is an independent contractor.
    </p>
    <p>
      <strong>Third-Party Rights.</strong> Nothing contained in this Agreement, express or implied,
      establishes or creates, or is intended or will be construed to establish or create any right
      in or remedy of, or any duty or obligation to, any third party.
    </p>
    <p>
      <strong>Survival.</strong> It is agreed that certain obligations under this Agreement, which,
      by their nature would continue beyond the termination, cancellation, or expiration of this
      Agreement, shall survive termination, cancellation, or expiration of this Agreement.
    </p>
    <p>
      <strong>Severability.</strong> The provisions of this Agreement shall be deemed severable and
      the invalidity or unenforceability of any provision shall not affect the validity or
      enforceability of the other provisions hereof. If any provision of this Agreement, or the
      application thereof to any person or entity or any circumstance, is invalid or unenforceable,
      then (a) a suitable and equitable provision shall be substituted therefore in order to carry
      out so far as may be a valid and enforceable provision and (b) the remainder of this Agreement
      and the application of such provision to other persons, entities, or circumstances shall not
      be affected by such invalidity or unenforceability, nor shall such invalidity or
      unenforceability affect the validity or enforceability of such provision, or the application
      thereof, in any other jurisdiction.
    </p>
    <p>
      <strong>Integration, Execution and Delivery.</strong> Except if your school, district or
      state, as applicable, has an existing signed written contract with College Board, in which
      case the terms and conditions of that contract will supersede any conflicting terms contained
      herein, this Agreement includes constitutes the entire agreement between College Board and
      Client and supersedes all prior written or oral understandings, bids, offers, negotiations, or
      communications of every kind concerning the subject matter of this Agreement, including any
      Client Purchase Order. No course of dealing between parties and no usage of trade shall be
      relevant to supplement any term used in the Agreement. Acceptance or acquiescence in a course
      of performance rendered under the Agreement shall not be relevant to determine the meaning of
      the Agreement and no waiver by a party of any right under the Agreement shall prejudice that
      party's exercise of that right in the future.
    </p>
  </div>
)
