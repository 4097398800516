import { TableTemplate } from '.'
import { DisplayExamDate } from '../../common'
import { numberFormat } from '../../../utils/numbers'
import styles from '../../../assets/style/scss/table-utils.scss'

const headerMainOrderColumns = id => [
  {
    headerText: { title: 'Course' },
    key: 'course',
    sorting: 'asc',
    headerAttributes: { id: `${id}-Course` },
    defaultSort: true,
  },
  {
    headerText: { title: 'Exam Date' },
    key: 'examDate',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ExamDate` },
  },
  {
    headerText: { title: 'SSD Materials' },
    key: 'ssdMaterials',
    sorting: 'asc',
    headerAttributes: { id: `${id}-SSDMaterials` },
  },
  {
    headerText: { title: 'Total Exams' },
    key: 'exams',
    sorting: 'asc',
    headerAttributes: { id: `${id}-TotalExams` },
  },
]

const renderMainOrderCourses = data =>
  data.map((c, i) => (
    <tr key={i} className={c.isAggregate ? styles['top-aggregate-row'] : ''}>
      <th scope="row">{c.course}</th>
      <td className={styles['with-icon']}>
        <div className={`${styles['exam-date-wrapper']} ${styles.flexible}`}>
          <DisplayExamDate course={c} />
        </div>
      </td>
      <td className={styles['numeric-column']}>{numberFormat(c.ssdMaterials)}</td>
      <td className={styles['numeric-column']}>{numberFormat(c.exams)}</td>
    </tr>
  ))

export default ({
  data = [],
  aggregateRow,
  id,
  headerStyle = { marginTop: '10px', marginBottom: '5px', fontSize: '1em' },
  emptyText = 'You do not have any exam orders.',
  caption = 'A view of the initial order submission',
  summary = 'Orders details are listed by course name in ascending order. Additional sorting options are available by clicking on the column headers.',
}) => (
  <TableTemplate
    header="Exam Orders"
    headerStyle={headerStyle}
    emptyText={emptyText}
    tableAttrs={{
      id,
      caption,
      summary,
      data,
      aggregateRow,
      rowBuilder: data => renderMainOrderCourses(data),
      columns: headerMainOrderColumns(id),
    }}
  />
)
