import { SubmitOrderPanel, OrdersShipTo } from '.'
import style from '../../../assets/style/scss/orders.scss'

const OrderDetails = ({ isAllAccess }) => (
  <div className={`row ${style['order-details-cont']}`}>
    <div className="col-md-4" />
    <div
      className={`col-md-${isAllAccess ? '4' : '12'} col-sm-${isAllAccess ? '6' : '12'} col-xs-12 ${
        style['ship-to-cont']
      }`}
    >
      <OrdersShipTo />
    </div>
    {isAllAccess ? (
      <div className={`col-md-4 col-sm-6 col-xs-12 ${style['orders-submit-cont']}`}>
        <SubmitOrderPanel />
      </div>
    ) : null}
  </div>
)

export default OrderDetails
