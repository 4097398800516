import { Textarea } from '../common'
import { INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS, INCIDENT_REPORT_PAPER_FIELD_NAMES as PAPER_FIELDS, } from '../../constants/IncidentReportConstants'

function IncidentReportIncidentDetailFields({ isPaper, isDraft, currentValues }) {
  const [onlyPaperSelected, setOnlyPaperSelected] = useState(false)
  
  useEffect(() => {
      if (
        isPaper &&
        currentValues[PAPER_FIELDS.usedExtraPaperInd.name] &&
        !currentValues[PAPER_FIELDS.defectiveMatsInd.name] &&
        !currentValues[PAPER_FIELDS.multipleChoiceInd.name] &&
        !currentValues[PAPER_FIELDS.freeResponseInd.name] &&
        !currentValues[PAPER_FIELDS.shortAnswerResponseInd.name] &&
        !currentValues[PAPER_FIELDS.orangeBookletInd.name] &&
        !currentValues[PAPER_FIELDS.masterAudioCDsInd.name] &&
        !currentValues[PAPER_FIELDS.equipProbsInd.name] &&
        !currentValues[PAPER_FIELDS.shortAnsrWrngBookInd.name] &&
        !currentValues[PAPER_FIELDS.earlyOpeningSectionInd.name] &&
        !currentValues[PAPER_FIELDS.mixUpFreeResponseBooksInd.name] &&
        !currentValues[PAPER_FIELDS.wrngColorInkInd.name] &&
        !currentValues[PAPER_FIELDS.misplacedAnswersInd.name] &&
        !currentValues[PAPER_FIELDS.missingExamMaterialsInd.name] &&
        !currentValues[PAPER_FIELDS.overtimingMinutesInd.name] &&
        !currentValues[PAPER_FIELDS.undertimingMinutesInd.name] &&
        !currentValues[PAPER_FIELDS.usedInkOnAnswerSheetInd.name] &&
        !currentValues[PAPER_FIELDS.sharedSameAPIDInd.name] &&
        !currentValues[PAPER_FIELDS.testingOffScheduleInd.name] &&
        !currentValues[SHARED_FIELDS.interruptionMinutesInd.name] &&
        !currentValues[SHARED_FIELDS.disturbanceMinutesInd.name] &&
        !currentValues[SHARED_FIELDS.misconductInd.name] &&
        !currentValues[SHARED_FIELDS.studentDismissedInd.name] &&
        !currentValues[SHARED_FIELDS.usedProhibitedDeviceInd.name] &&
        !currentValues[SHARED_FIELDS.removeExamMaterialsInd.name] &&
        !currentValues[SHARED_FIELDS.illnessInd.name] &&
        !currentValues[SHARED_FIELDS.otherInd.name] &&
        !currentValues[SHARED_FIELDS.lateTestingRequiredInd.name] &&
        !currentValues[SHARED_FIELDS.altExamOrderedInd.name]
      ) {
        setOnlyPaperSelected(true)
      } else {
        setOnlyPaperSelected(false)
      }
    }, [isPaper, currentValues])

  return (
    <div className="row">
      <div className="col-xs-12">
        <h3>Incident Detail</h3>
        <fieldset>
          <div className="row">
            <div className="col-xs-12">
              <Textarea
                name={SHARED_FIELDS.incidentDetail.name}
                label="Explanation (Describe all events and actions taken)"
                rows="5"
                isRequired={!onlyPaperSelected && !isDraft}
                maxlength={20000}
                placeholder="Enter explanation..."
                formGroupStyle={{ marginBottom: 0 }}
                errorOnPristine={!isDraft}
              />
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  )
}

export default IncidentReportIncidentDetailFields
