import * as c from '../constants/IncidentReportConstants'

const incidentReportInitialState = {
  fetching: false,
  fetched: false,
  fetchError: null,
  draft: {},
  submitting: false,
  submitted: false,
  error: null,
}

export const incidentReport = (state = incidentReportInitialState, { type, payload }) => {
  switch (type) {
    case c.FETCH_INCIDENT_REPORT_DRAFT_PENDING:
      return { ...state, fetching: true, fetched: false, fetchError: null }
    case c.FETCH_INCIDENT_REPORT_DRAFT_FULFILLED:
      return { ...state, fetching: false, fetched: true, draft: payload }
    case c.FETCH_INCIDENT_REPORT_DRAFT_REJECTED:
      return { ...state, fetching: false, fetched: false, fetchError: payload }
    case c.SUBMIT_INCIDENT_REPORT_PENDING:
      return { ...state, submitting: true, submitted: false, error: null }
    case c.SUBMIT_INCIDENT_REPORT_FULFILLED:
      return { ...state, submitting: false, submitted: true }
    case c.SUBMIT_INCIDENT_REPORT_REJECTED:
      return { ...state, submitting: false, submitted: false, error: payload }
    case c.RESET_DRAFT_INCIDENT_REPORT:
      return { ...state, draft: {} }
    case c.RESET_INCIDENT_REPORT:
      return incidentReportInitialState
    default:
      return state
  }
}

const incidentReportHistoryInitialState = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
}

export const incidentReportHistory = (
  state = incidentReportHistoryInitialState,
  { type, payload }
) => {
  switch (type) {
    case c.FETCH_INCIDENT_REPORT_HISTORY_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      }

    case c.FETCH_INCIDENT_REPORT_HISTORY_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: payload }

    case c.FETCH_INCIDENT_REPORT_HISTORY_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    default:
      return state
  }
}
