import * as c from '../constants/SettingsConstants'

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case c.FETCH_EXAM_WINDOWS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }

    case c.FETCH_EXAM_WINDOWS_FULFILLED: {
      const { orgId, educationPeriodCd, data, examWindowRefData } = payload
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: {
          ...state.data,
          [orgId]: {
            ...state.data?.[orgId],
            [educationPeriodCd]: {
              ...state.data?.[orgId]?.[educationPeriodCd],
              ...data,
            },
          },
        },
        examWindowRefData: {
          ...state.examWindowRefData,
          [orgId]: {
            ...state.examWindowRefData?.[orgId],
            [educationPeriodCd]: {
              ...state.examWindowRefData?.[orgId]?.[educationPeriodCd],
              ...examWindowRefData,
            },
          },
        },
      }
    }

    case c.FETCH_EXAM_WINDOWS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    default:
      return state
  }
}
