import { isValid, isDirty, submit } from 'redux-form'
import { Modal } from '../../components/common'
import { openModal } from '../../actions/app'
import { SELECT_SSD_MATERIALS_FORM, UPDATE_TYPE_SSD } from '../../constants/StudentConstants'
import SelectSSDMaterialsToBeOrderedForm from '../forms/SelectSSDMaterialsToBeOrderedForm'
import { resetStudentUpdate } from '../../actions/studentsCommon'

const mapStateToProps = (state, { examId }) => {
  const {
    studentsByOrg: {
      update: { type, updating, updated, error, id },
      courseMap,
      studentMap,
      exams,
    },
  } = state
  const exam = exams.find(e => e.examId === examId)
  const isActive = type === UPDATE_TYPE_SSD && exam.examId === id
  const { lastName, firstName, orderChanged } = studentMap[exam.studentId]

  return {
    updating: isActive && updating,
    updated,
    error: isActive && error,
    valid: isValid(SELECT_SSD_MATERIALS_FORM)(state),
    dirty: isDirty(SELECT_SSD_MATERIALS_FORM)(state),
    exam,
    courseName: courseMap[exam.testCd].name,
    studentName: `${firstName} ${lastName}`,
    orderChanged,
  }
}

function footerActions({ valid, updating, dirty, submitForm }) {
  return [
    {
      buttonLabel: 'Cancel',
      isDismissable: true,
      isPrimary: false,
      isDisabled: updating,
    },
    {
      buttonLabel: 'Update',
      isPrimary: true,
      onClick: submitForm,
      isDisabled: !valid || updating || !dirty,
      busy: updating,
    },
  ]
}

function OrderSSDMaterialsModal({
  updating,
  updated,
  valid,
  dirty,
  exam,
  courseName,
  studentName,
  orderChanged,
  modalCloseFocusElem,
  openModal,
  submit,
  resetStudentUpdate,
}) {
  const [shouldCloseModal, setShouldCloseModal] = useState(false)
  const [onCloseAction, setOnCloseAction] = useState(null)

  useEffect(() => {
    if (updated) {
      if (orderChanged) {
        setOnCloseAction(() => {
          openModal('UpdateStudentExamSuccessModal', {
            exam,
            type: UPDATE_TYPE_SSD,
            courseName,
            studentName,
            modalCloseFocusElem,
          })
        })
      }
      setShouldCloseModal(true)
    }
    return () => resetStudentUpdate()
  }, [updated, orderChanged, exam, courseName, studentName, modalCloseFocusElem])

  return (
    <Modal
      shouldCloseModal={shouldCloseModal}
      onCloseAction={onCloseAction}
      modalCloseFocusElem={modalCloseFocusElem}
      headerTitle={`${courseName}: Special Exam Materials for Students with Accommodations`}
      footerActions={footerActions({
        valid,
        updating,
        dirty,
        submitForm: () => submit(SELECT_SSD_MATERIALS_FORM),
      })}
    >
      <p>
        Indicate special exam materials based on approved or expected accommodations. (If this
        student will use a <strong>regular-format exam</strong>, don&#39;t indicate any special exam
        materials.){' '}
        <strong>
          Indicating materials below is not a formal request for accommodations; accommodations
          requests must be submitted to and approved by College Board SSD.
        </strong>
      </p>
      <SelectSSDMaterialsToBeOrderedForm exam={exam} />
    </Modal>
  )
}

export default connect(mapStateToProps, {
  submit,
  openModal,
  resetStudentUpdate,
})(OrderSSDMaterialsModal)
