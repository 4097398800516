import { useCallback } from 'react'
import { formatDateTime } from '@myap/ui-library/esm/date'
import { Error } from '../common'
import { fetchAvailableCourses } from '../../actions/course'
import { getAllCourses } from '../../selectors/course'
import {
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
  INCIDENT_REPORT_PAPER_FIELD_NAMES as PAPER_FIELDS,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES as DIGITAL_FIELDS,
  COURSES_WITH_HIDDEN_EXAM_FORM_SECTION,
} from '../../constants/IncidentReportConstants'
import { DIGITAL_EXAM, HYBRID_EXAM } from '../../constants/SettingsConstants'
import { formatPhoneNumber } from '../../utils/numbers'
import { NAV_LINKS } from '../../constants/NavConstants'
import styles from '../../assets/style/scss/incident-report-history.scss'
import { getSelectedEducationPeriod } from '../../selectors/settings'

const mapStateToProps = (state, { report }) => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    availableCourses: { fetching: fetchingCourses, fetched: fetchedCourses, error: errorCourses },
  } = state

  return {
    orgId,
    educationPeriodCd: getSelectedEducationPeriod(state),
    allCourses: getAllCourses({ state, apOnly: true }),
    fetchingCourses,
    fetchedCourses,
    errorCourses,
    selectedExamDate: report?.exam?.examDate,
    testCd: report?.exam?.[SHARED_FIELDS.testCode.name] ?? null,
    examFormat: report?.exam?.examFormat ?? null,
  }
}

const IncidentTypeField = ({ incident, additionalValue, children }) =>
  incident ? (
    <li className={styles['field-value']}>
      {children}
      {additionalValue ? `: ${additionalValue}` : null}
    </li>
  ) : null

export default connect(mapStateToProps, { fetchAvailableCourses })(({
  report,
  courseName,
  orgId,
  educationPeriodCd,
  fetchingCourses,
  fetchedCourses,
  errorCourses,
  allCourses,
  selectedExamDate,
  testCd,
  examFormat,
  print = false,
  fetchAvailableCourses,
}) => {
  const allCoursesLength = allCourses?.length ?? 0
  const fetchCourses = useCallback(() => {
    if (orgId && educationPeriodCd && !fetchingCourses && !fetchedCourses && !allCoursesLength) {
      fetchAvailableCourses({ orgId, educationPeriodCd })
    }
  }, [orgId, educationPeriodCd, fetchingCourses, fetchedCourses, allCoursesLength])
  // fetch available courses
  useEffect(() => {
    fetchCourses()
  }, [fetchCourses])

  if (!report) {
    return <Error title="Error" message="Incident Report not available" />
  }

  return (
    <div className={`row ${styles['report-content']}`}>
      <div className="col-xs-12">
        <div className={styles['report-heading']}>
          <div className={styles['report-id']}>Report ID: {report?.reportId}</div>
          <button
            type="button"
            className={`btn btn-sm btn-primary ${styles['print-button']}`}
            onClick={() => {
              if (print) {
                window.print()
              } else {
                window.open(
                  `${NAV_LINKS.printIncidentReport}?coursename=${encodeURIComponent(
                    courseName
                  )}&reportid=${encodeURIComponent(report?.reportId)}&epc=${encodeURIComponent(
                    educationPeriodCd
                  )}`
                )
              }
              return null
            }}
          >
            Print Incident
          </button>
        </div>
        <div className={styles['report-body']}>
          <div className={styles['report-section']}>
            <h4>AP Coordinator Contact Information</h4>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className={styles['field-name']}>First Name:</div>
                <div className={styles['field-value']}>
                  {report?.coordinator?.[SHARED_FIELDS.firstName.name]}
                </div>
              </div>
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className={styles['field-name']}>Last Name:</div>
                <div className={styles['field-value']}>
                  {report?.coordinator?.[SHARED_FIELDS.lastName.name]}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className={styles['field-name']}>Summer Phone Number:</div>
                <div className={styles['field-value']}>
                  {formatPhoneNumber(report?.coordinator?.[SHARED_FIELDS.phone.name])}
                </div>
              </div>
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className={styles['field-name']}>Email:</div>
                <div className={styles['field-value']}>
                  {report?.coordinator?.[SHARED_FIELDS.email.name]}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className={styles['report-section']}>
                <h4>Exam Information</h4>
                <div className="row">
                  <div className="col-xs-12">
                    <div className={styles['field-name']}>Exam Section:</div>
                    <div className={styles['field-value']}>
                      {report?.exam?.[SHARED_FIELDS.section.name]}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className={styles['field-name']}>Exam Title:</div>
                    <div className={styles['field-value']}>
                      {errorCourses ? (
                        <span className="cb-error-msg" aria-live="polite">
                          Error retrieving course names
                        </span>
                      ) : (
                        allCourses.find(
                          ({ testCd }) => testCd === report?.exam?.[SHARED_FIELDS.testCode.name]
                        )?.courseName
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className={styles['field-name']}>Exam Date:</div>
                    <div className={styles['field-value']}>
                      {formatDateTime(selectedExamDate, 'MMMM d')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(examFormat !== DIGITAL_EXAM &&
              examFormat !== HYBRID_EXAM &&
              !COURSES_WITH_HIDDEN_EXAM_FORM_SECTION.includes(testCd)) ||
            examFormat === null ? (
              <div className="col-md-8 col-sm-6 col-xs-12">
                <div className={styles['report-section']}>
                  <h4>Exam Form</h4>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className={styles['field-name']}>Form:</div>
                      <div className={styles['field-value']}>
                        {report?.exam?.[SHARED_FIELDS.examForm.name]}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className={styles['field-name']}>Form Code:</div>
                      <div className={styles['field-value']}>
                        {report?.exam?.[SHARED_FIELDS.formCode.name]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles['report-section']}>
            <div className="row">
              <div className="col-xs-12">
                <h4>Type of Incident</h4>
                <ul role="list">
                  {report?.incidentType?.studentTechIssue?.[DIGITAL_FIELDS.batteryDiedInd.name] ||
                  report?.incidentType?.studentTechIssue?.[
                    DIGITAL_FIELDS.connectionDroppedInd.name
                  ] ||
                  report?.incidentType?.studentTechIssue?.[
                    DIGITAL_FIELDS.deviceFrozeOrCrashInd.name
                  ] ||
                  report?.incidentType?.studentTechIssue?.[
                    DIGITAL_FIELDS.deviceDidNotMeetReqInd.name
                  ] ||
                  report?.incidentType?.studentTechIssue?.[
                    DIGITAL_FIELDS.loanerDeviceIssueInd.name
                  ] ||
                  report?.incidentType?.studentTechIssue?.[
                    DIGITAL_FIELDS.anotherTestAppInstalledInd.name
                  ] ||
                  report?.incidentType?.studentTechIssue?.[
                    DIGITAL_FIELDS.blueBookNotInstalledInd.name
                  ] ||
                  report?.incidentType?.studentTechIssue?.[
                    DIGITAL_FIELDS.examSetUpNotCompletedInd.name
                  ] ||
                  report?.incidentType?.studentTechIssue?.[
                    DIGITAL_FIELDS.noAdmissionTicketInd.name
                  ] ||
                  report?.incidentType?.studentTechIssue?.otherInd ? (
                    <li className={styles['report-sub-section']}>
                      <h5>Student Tech Issue</h5>
                      <ul role="list">
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.studentTechIssue?.[
                              DIGITAL_FIELDS.batteryDiedInd.name
                            ]
                          }
                        >
                          Battery died
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.studentTechIssue?.[
                              DIGITAL_FIELDS.connectionDroppedInd.name
                            ]
                          }
                        >
                          Connection dropped
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.studentTechIssue?.[
                              DIGITAL_FIELDS.deviceFrozeOrCrashInd.name
                            ]
                          }
                        >
                          Device froze or crashed
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.studentTechIssue?.[
                              DIGITAL_FIELDS.deviceDidNotMeetReqInd.name
                            ]
                          }
                        >
                          Student device didn&#39;t meet requirements
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.studentTechIssue?.[
                              DIGITAL_FIELDS.loanerDeviceIssueInd.name
                            ]
                          }
                        >
                          Loaner device unavailable or not working
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.studentTechIssue?.[
                              DIGITAL_FIELDS.anotherTestAppInstalledInd.name
                            ]
                          }
                        >
                          Another testing application was installed instead of Bluebook
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.studentTechIssue?.[
                              DIGITAL_FIELDS.blueBookNotInstalledInd.name
                            ]
                          }
                        >
                          Bluebook was not installed on the student&#39;s device
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.studentTechIssue?.[
                              DIGITAL_FIELDS.examSetUpNotCompletedInd.name
                            ]
                          }
                        >
                          Student didn&#39;t complete exam setup before arrival
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.studentTechIssue?.[
                              DIGITAL_FIELDS.noAdmissionTicketInd.name
                            ]
                          }
                        >
                          Student didn&#39;t have admission ticket
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={report?.incidentType?.studentTechIssue?.otherInd}
                        >
                          Other
                        </IncidentTypeField>
                      </ul>
                    </li>
                  ) : null}
                  {report?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.couldNotOpenInd.name] ||
                  report?.incidentType?.blueBookProblem?.[DIGITAL_FIELDS.couldNotSignInd.name] ||
                  report?.incidentType?.blueBookProblem?.[
                    DIGITAL_FIELDS.testNotAvailableInd.name
                  ] ||
                  report?.incidentType?.blueBookProblem?.[
                    DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name
                  ] ||
                  report?.incidentType?.blueBookProblem?.[
                    DIGITAL_FIELDS.inAccurateTimingInd.name
                  ] ||
                  report?.incidentType?.blueBookProblem?.[
                    DIGITAL_FIELDS.contentDisplayIssueInd.name
                  ] ||
                  report?.incidentType?.blueBookProblem?.[
                    DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name
                  ] ||
                  report?.incidentType?.blueBookProblem?.[
                    DIGITAL_FIELDS.answerNotSubmittedInd.name
                  ] ||
                  report?.incidentType?.blueBookProblem?.otherInd ? (
                    <li className={styles['report-sub-section']}>
                      <h5>Bluebook application problem</h5>
                      <ul role="list">
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.blueBookProblem?.[
                              DIGITAL_FIELDS.couldNotOpenInd.name
                            ]
                          }
                        >
                          Student couldn&#39;t open Bluebook
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.blueBookProblem?.[
                              DIGITAL_FIELDS.couldNotSignInd.name
                            ]
                          }
                        >
                          Student couldn&#39;t sign in
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.blueBookProblem?.[
                              DIGITAL_FIELDS.testNotAvailableInd.name
                            ]
                          }
                        >
                          The test was not available on the student&#39;s Bluebook homepage
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.blueBookProblem?.[
                              DIGITAL_FIELDS.couldNotStartAfterTheStartCodeInd.name
                            ]
                          }
                        >
                          Student couldn&#39;t start the test after entering the start code
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.blueBookProblem?.[
                              DIGITAL_FIELDS.inAccurateTimingInd.name
                            ]
                          }
                        >
                          Inaccurate timing
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.blueBookProblem?.[
                              DIGITAL_FIELDS.contentDisplayIssueInd.name
                            ]
                          }
                        >
                          Content didn&#39;t display or was hard to read
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.blueBookProblem?.[
                              DIGITAL_FIELDS.calculatorClockOrToolIssueInd.name
                            ]
                          }
                        >
                          Embedded calculator, countdown clock, or other tools didn&#39;t work
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.blueBookProblem?.[
                              DIGITAL_FIELDS.answerNotSubmittedInd.name
                            ]
                          }
                        >
                          Answers were not submitted
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={report?.incidentType?.blueBookProblem?.otherInd}
                        >
                          Other
                        </IncidentTypeField>
                      </ul>
                    </li>
                  ) : null}
                  {report?.incidentType?.testDayToolKitProblem?.[
                    DIGITAL_FIELDS.couldNotAccessTDTKInd.name
                  ] ||
                  report?.incidentType?.testDayToolKitProblem?.[
                    DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name
                  ] ||
                  report?.incidentType?.testDayToolKitProblem?.[
                    DIGITAL_FIELDS.studentCheckInIssueInd.name
                  ] ||
                  report?.incidentType?.testDayToolKitProblem?.[
                    DIGITAL_FIELDS.inAccurateStudentStatusInd.name
                  ] ||
                  report?.incidentType?.testDayToolKitProblem?.otherInd ? (
                    <li className={styles['report-sub-section']}>
                      <h5>Test Day Toolkit problem</h5>
                      <ul role="list">
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.testDayToolKitProblem?.[
                              DIGITAL_FIELDS.couldNotAccessTDTKInd.name
                            ]
                          }
                        >
                          Couldn&#39;t access Test Day Toolkit
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.testDayToolKitProblem?.[
                              DIGITAL_FIELDS.inAccurateStudentRosterDataInd.name
                            ]
                          }
                        >
                          Inaccurate student roster data
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.testDayToolKitProblem?.[
                              DIGITAL_FIELDS.studentCheckInIssueInd.name
                            ]
                          }
                        >
                          Couldn&#39;t check students in
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.testDayToolKitProblem?.[
                              DIGITAL_FIELDS.inAccurateStudentStatusInd.name
                            ]
                          }
                        >
                          Inaccurate student statuses in dashboard
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={report?.incidentType?.testDayToolKitProblem?.otherInd}
                        >
                          Other
                        </IncidentTypeField>
                      </ul>
                    </li>
                  ) : null}
                  {report?.incidentType?.facilityTechIssue?.[
                    DIGITAL_FIELDS.networkOutageInd.name
                  ] ||
                  report?.incidentType?.facilityTechIssue?.[DIGITAL_FIELDS.powerOutageInd.name] ||
                  report?.incidentType?.facilityTechIssue?.otherInd ? (
                    <li className={styles['report-sub-section']}>
                      <h5>Facility Technology Issue</h5>
                      <ul role="list">
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.facilityTechIssue?.[
                              DIGITAL_FIELDS.networkOutageInd.name
                            ]
                          }
                        >
                          Network outage
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.facilityTechIssue?.[
                              DIGITAL_FIELDS.powerOutageInd.name
                            ]
                          }
                        >
                          Power outage
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={report?.incidentType?.facilityTechIssue?.otherInd}
                        >
                          Other
                        </IncidentTypeField>
                      </ul>
                    </li>
                  ) : null}
                  {report?.incidentType?.misconduct?.[SHARED_FIELDS.misconductInd.name] ||
                  report?.incidentType?.misconduct?.[SHARED_FIELDS.studentDismissedInd.name] ||
                  report?.incidentType?.misconduct?.[SHARED_FIELDS.usedProhibitedDeviceInd.name] ||
                  report?.incidentType?.misconduct?.[SHARED_FIELDS.removeExamMaterialsInd.name] ? (
                    <li className={styles['report-sub-section']}>
                      <h5>Rule Violation</h5>
                      <ul role="list">
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.misconduct?.[SHARED_FIELDS.misconductInd.name]
                          }
                        >
                          Rule Violation
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.misconduct?.[
                              SHARED_FIELDS.studentDismissedInd.name
                            ]
                          }
                        >
                          Student Dismissed
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.misconduct?.type?.[
                              SHARED_FIELDS.usedProhibitedDeviceInd.name
                            ]
                          }
                        >
                          Student Observed with a Prohibited Device
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.misconduct?.type?.[
                              SHARED_FIELDS.removeExamMaterialsInd.name
                            ]
                          }
                        >
                          Student Removed/Attempted to Remove Exam Materials
                        </IncidentTypeField>
                      </ul>
                    </li>
                  ) : null}
                  {report?.incidentType?.staffTechIssue?.[
                    DIGITAL_FIELDS.staffBatteryDiedInd.propName
                  ] ||
                  report?.incidentType?.staffTechIssue?.[
                    DIGITAL_FIELDS.staffConnectionDroppedInd.propName
                  ] ||
                  report?.incidentType?.staffTechIssue?.[
                    DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.propName
                  ] ||
                  report?.incidentType?.staffTechIssue?.[
                    DIGITAL_FIELDS.staffDeviceIssueInd.propName
                  ] ||
                  report?.incidentType?.staffTechIssue?.[
                    DIGITAL_FIELDS.staffTechIssueOther.propName
                  ] ? (
                    <li className={styles['report-sub-section']}>
                      <h5>Staff Technology Issue</h5>
                      <ul role="list">
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.staffTechIssue?.[
                              DIGITAL_FIELDS.staffBatteryDiedInd.propName
                            ]
                          }
                        >
                          Battery died
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.staffTechIssue?.[
                              DIGITAL_FIELDS.staffConnectionDroppedInd.propName
                            ]
                          }
                        >
                          Connection dropped
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.staffTechIssue?.[
                              DIGITAL_FIELDS.staffDeviceFrozeOrCrashInd.propName
                            ]
                          }
                        >
                          Device froze or crashed
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.staffTechIssue?.[
                              DIGITAL_FIELDS.staffDeviceIssueInd.propName
                            ]
                          }
                        >
                          Staff device issue
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.staffTechIssue?.[
                              DIGITAL_FIELDS.staffTechIssueOther.propName
                            ]
                          }
                        >
                          Other
                        </IncidentTypeField>
                      </ul>
                    </li>
                  ) : null}
                  {/* DEPRECATED START */}
                  <IncidentTypeField
                    incident={report?.incidentType?.[DIGITAL_FIELDS.staffTechIssue.propName]}
                  >
                    Staff technology issue that caused student testing disruption
                  </IncidentTypeField>
                  {/* DEPRECATED END */}
                  {report?.incidentType?.defectiveMaterials?.[
                    PAPER_FIELDS.multipleChoiceInd.name
                  ] ||
                  report?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.freeResponseInd.name] ||
                  report?.incidentType?.defectiveMaterials?.[
                    PAPER_FIELDS.shortAnswerResponseInd.name
                  ] ||
                  report?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.orangeBookletInd.name] ||
                  report?.incidentType?.defectiveMaterials?.[PAPER_FIELDS.masterAudioCDs.name] ? (
                    <li className={styles['report-sub-section']}>
                      <h5>Defective Materials</h5>
                      <ul role="list">
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.defectiveMaterials?.[
                              PAPER_FIELDS.multipleChoiceInd.name
                            ]
                          }
                        >
                          Multiple Choice
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.defectiveMaterials?.[
                              PAPER_FIELDS.freeResponseInd.name
                            ]
                          }
                        >
                          Free-Response
                        </IncidentTypeField>
                        {/* DEPRECATED START */}
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.defectiveMaterials?.[
                              PAPER_FIELDS.shortAnswerResponseInd.name
                            ]
                          }
                        >
                          Short-Answer Response
                        </IncidentTypeField>
                        {/* DEPRECATED END */}
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.defectiveMaterials?.[
                              PAPER_FIELDS.orangeBookletInd.name
                            ]
                          }
                        >
                          Orange Booklet
                        </IncidentTypeField>
                        <IncidentTypeField
                          incident={
                            report?.incidentType?.defectiveMaterials?.[
                              PAPER_FIELDS.masterAudioCDs.name
                            ]
                          }
                          additionalValue={
                            report?.incidentType?.defectiveMaterials?.[
                              PAPER_FIELDS.masterAudioCDs.name
                            ]
                          }
                        >
                          Master Audio CDs
                        </IncidentTypeField>
                      </ul>
                    </li>
                  ) : null}
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.equipProbsInd.name]}
                  >
                    Equipment Problems
                  </IncidentTypeField>
                  {/* DEPRECATED START */}
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.shortAnsrWrngBookInd.name]}
                  >
                    Short-answer responses and/or free-responses written in wrong book
                  </IncidentTypeField>
                  {/* DEPRECATED END */}
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.earlyOpeningSectionInd.name]}
                  >
                    Student opened exam booklet early
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.mixUpFreeResponseBooksInd.name]}
                  >
                    Student is given the wrong exam booklet
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.wrngColorInkInd.name]}
                  >
                    Student used inappropriate ink color in the free-response booklet
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.misplacedAnswersInd.name]}
                  >
                    Misplaced Answers
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[SHARED_FIELDS.illnessInd.name]}
                  >
                    Illness
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.missingExamMaterialsInd.name]}
                  >
                    Missing Exam Materials
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.overtimingMinutes.name]}
                    additionalValue={report?.incidentType?.[PAPER_FIELDS.overtimingMinutes.name]}
                  >
                    Overtiming-Number of Minutes
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.undertimingMinutes.name]}
                    additionalValue={report?.incidentType?.[PAPER_FIELDS.undertimingMinutes.name]}
                  >
                    Undertiming-Number of Minutes
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[SHARED_FIELDS.interruptionMinutes.name]}
                    additionalValue={report?.incidentType?.[SHARED_FIELDS.interruptionMinutes.name]}
                  >
                    Interruption-Number of Minutes
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[SHARED_FIELDS.disturbanceMinutes.name]}
                    additionalValue={report?.incidentType?.[SHARED_FIELDS.disturbanceMinutes.name]}
                  >
                    Disturbance-Number of Minutes
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.usedInkOnAnswerSheetInd.name]}
                  >
                    Student Used Ink on Answer Sheet
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.usedExtraPaperInd.name]}
                  >
                    Student Used Extra Paper
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.sharedSameAPIDInd.name]}
                  >
                    Students Shared Same AP ID
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[PAPER_FIELDS.testingOffScheduleInd.name]}
                  >
                    Testing Off-Schedule
                  </IncidentTypeField>
                  <IncidentTypeField incident={report?.incidentType?.[SHARED_FIELDS.otherInd.name]}>
                    Other
                  </IncidentTypeField>
                  <IncidentTypeField
                    incident={report?.incidentType?.[SHARED_FIELDS.other.name]}
                    additionalValue={report?.incidentType?.[SHARED_FIELDS.other.name]}
                  >
                    Other
                  </IncidentTypeField>
                </ul>
                {report?.[SHARED_FIELDS.apServicesCaseNumber.name] ||
                report?.[SHARED_FIELDS.lateTestingRequiredInd.name] ||
                report?.[SHARED_FIELDS.altExamOrderedInd.name] ? (
                  <div className={styles['report-section']}>
                    <div className="row">
                      <div className="col-xs-12">
                        <h4>Additional Information</h4>
                        <ul role="list">
                          <IncidentTypeField
                            incident={report?.[SHARED_FIELDS.apServicesCaseNumber.name]}
                            additionalValue={report?.[SHARED_FIELDS.apServicesCaseNumber.name]}
                          >
                            AP Services Case Number
                          </IncidentTypeField>
                          <IncidentTypeField
                            incident={report?.[SHARED_FIELDS.lateTestingRequiredInd.name]}
                          >
                            The incident compromise the student&#39;s ability to test enough to
                            require late testing
                          </IncidentTypeField>
                          <IncidentTypeField
                            incident={report?.[SHARED_FIELDS.altExamOrderedInd.name]}
                          >
                            An exam for a subsequent administration has been ordered
                          </IncidentTypeField>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className={styles['report-section']}>
                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Students Involved</h4>
                      {report?.[SHARED_FIELDS.fullTestCenterImpactedInd.name] ? (
                        <p className={styles['field-value']}>
                          All students taking the exam were impacted
                        </p>
                      ) : null}
                      {report?.[SHARED_FIELDS.students.name]?.length > 0 ? (
                        <div className="row">
                          <div className="col-sm-8 col-xs-12">
                            <table className="table cb-no-table-border">
                              <thead>
                                <tr>
                                  <th>First Name</th>
                                  <th>Last Name</th>
                                  <th>AP ID</th>
                                </tr>
                              </thead>
                              <tbody>
                                {report?.[SHARED_FIELDS.students.name]?.map(
                                  ({
                                    [SHARED_FIELDS.firstName.name]: firstName,
                                    [SHARED_FIELDS.lastName.name]: lastName,
                                    [SHARED_FIELDS.studentApId.name]: studentApId,
                                  }) => (
                                    <tr key={studentApId}>
                                      <td>{firstName}</td>
                                      <td>{lastName}</td>
                                      <td>{studentApId}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={styles['report-section']}>
                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Incident Detail</h4>
                      <div className={styles['incident-details']}>
                        {report?.[SHARED_FIELDS.incidentDetail.name]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
