import { isDirty } from './utils'
import styles from '../../assets/style/scss/invoices.scss'


const InvoiceChangeMessage = data => {
  return isDirty(data) ? (
    <div className={styles['invoice-change-message']}>
      Changes that have affected your PDF invoice are highlighted in gray.
    </div>
  ) : null
}

export default InvoiceChangeMessage
