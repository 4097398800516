import { useParams } from 'react-router-dom'
import { Error, Loader } from '@myap/ui-library'
import {
  fetchExamRoomSchedule,
  fetchExamRoomStudents,
  resetSelectedStudents,
} from '../../actions/examRoom'
import { setNavLink, unsetNavLink } from '../../actions/nav'
import ExamRoomDetails from '../../components/examRoom/ExamRoomDetails'
import ExamRoomStudents from '../../components/examRoom/ExamRoomStudents'
import { getRoom } from '../../components/examRoom/utils'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = state => {
  const { user, settingsEducationPeriod, examRoomSchedule, examRoomStudents } = state
  const { data = [] } = examRoomSchedule

  return {
    orgId: user.data.selectedOrgId,
    educationPeriod: parseInt(settingsEducationPeriod.selectedEducationPeriod, 10),
    scheduleFetching: examRoomSchedule.fetching,
    studentFetching: examRoomStudents.fetching,
    scheduleError: examRoomSchedule.error,
    studentError: examRoomStudents.error,
    scheduleFetched: examRoomSchedule.fetched,
  }
}

const ExamRoomDetailsPage = props => {
  const {
    orgId,
    educationPeriod,
    scheduleFetching,
    studentFetching,
    scheduleFetched,
    studentError,
    scheduleError,
    fetchExamRoomSchedule,
    fetchExamRoomStudents,
    resetSelectedStudents,
    setNavLink,
    unsetNavLink,
  } = props
  const { roomId } = useParams()
  const room = getRoom(data, roomId) || {}

  useEffect(() => {
    if (!room.id) fetchExamRoomSchedule(orgId, educationPeriod)
    setNavLink(NAV_LINKS.examroom, 'Back to exam room schedule')
    return () => {
      unsetNavLink()
      resetSelectedStudents()
    }
  }, [])

  useEffect(() => {
    if (scheduleFetched) fetchExamRoomStudents(orgId, educationPeriod, room)
  }, [scheduleFetched])

  if (scheduleFetching) return <Loader />

  if (scheduleError)
    return <Error title="Error loading exam room details" message={error} useWrapper={true} />

  if (scheduleFetched)
    return (
      <CSSTransition
        classNames="animate-fade"
        in={true}
        timeout={500}
        appear={true}
        enter={false}
        exit={false}
      >
        <div className="container">
          <ExamRoomDetails roomId={room.id} />
          {studentFetching ? (
            <Loader />
          ) : studentError ? (
            <Error title="Error loading students" message={error} />
          ) : (
            <ExamRoomStudents roomId={room.id} />
          )}
        </div>
      </CSSTransition>
    )

  return null
}

export default connect(
  mapStateToProps,
  {
    fetchExamRoomSchedule,
    fetchExamRoomStudents,
    resetSelectedStudents,
    setNavLink,
    unsetNavLink,
  }
)(ExamRoomDetailsPage)
