const mapStateToProps = state => {
  const { roles, selectedOrgId, selectedRole } = state.user.data
  const { setupStep, [selectedOrgId]: settings = {} } = state.settingsOrg
  const isLevelOne =
    roles[selectedRole].level === 1 || settings?.data?.examAdministration?.orderByAnotherSchool
  return {
    step: setupStep,
    isLevelOne,
    totalSteps: isLevelOne ? 4 : 5,
  }
}

const StepComplete = ({ step, title, totalSteps }) => (
  <li className="cb-stepper-complete">
    <span className="cb-glyph cb-glyph-circular cb-check" aria-hidden="true" />
    <p>{title}</p>
    <span className="sr-only cb-stepper-step">
      step {step} of {totalSteps} completed
    </span>
  </li>
)

const StepActive = ({ step, title, totalSteps }) => (
  <li className="cb-stepper-active">
    <span
      className="cb-glyph cb-disc"
      aria-hidden="true"
      style={{ fontSize: '2em', color: '#0077C8' }}
    />
    <p style={{ marginTop: 3 }}>{title}</p>
    <span className="sr-only cb-stepper-step">
      step {step} of {totalSteps} active
    </span>
  </li>
)

const StepIncomplete = ({ step, title, totalSteps }) => (
  <li>
    <span className="cb-glyph cb-glyph-circular" aria-hidden="true" />
    <p>{title}</p>
    <span className="sr-only cb-stepper-step">
      step {step} of {totalSteps} pending
    </span>
  </li>
)

const getStep = (current, totalSteps, active, title) => {
  if (current < active) return <StepComplete totalSteps={totalSteps} step={current} title={title} />
  if (current > active)
    return <StepIncomplete totalSteps={totalSteps} step={current} title={title} />
  return <StepActive totalSteps={totalSteps} step={current} title={title} />
}

export default connect(mapStateToProps)(({ isLevelOne, step, totalSteps }) => (
  <div>
    <h2>AP Registration and Ordering Setup</h2>
    <div className="cb-stepper" role="region" aria-label="Steps for coordinator setup">
      <ol>
        {isLevelOne ? (
          <>
            {getStep(1, totalSteps, step, 'School Information')}
            {getStep(2, totalSteps, step, 'Exam Administration')}
            {getStep(3, totalSteps, step, 'Roster Management')}
            {getStep(4, totalSteps, step, 'Participation Form')}
          </>
        ) : (
          <>
            {getStep(1, totalSteps, step, 'School Information')}
            {getStep(2, totalSteps, step, 'Exam Administration')}
            {getStep(3, totalSteps, step, 'Exam Decision Indicator')}
            {getStep(4, totalSteps, step, 'Roster Management')}
            {getStep(5, totalSteps, step, 'Participation Form')}
          </>
        )}
      </ol>
    </div>
  </div>
))
