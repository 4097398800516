import { DropdownPortal } from '../../common'
import { MAKEUP_STATUSES, STATUS_NOT_APPLICABLE } from '../../../constants/ExamReadinessConstants'
import { updateMakeupStatus } from '../../../actions/examReadiness'
import {
  getAvailableDigitalExamWindows,
  isDigitalExamInCutoffPeriod,
} from '../../../selectors/examWindows'
import { sortColumnByKey } from '../../../utils/sort'

const mapStateToProps = (state, { rowData }) => {
  const { testCode, examDateTime, makeupStatus } = rowData
  const availableDigitalExams = sortColumnByKey(
    getAvailableDigitalExamWindows(state, testCode).filter(e => !e.requiresUnlocking),
    'examDateTime',
    'asc'
  )
  const currentIndex = availableDigitalExams.findIndex(a => a.examDateTime === examDateTime)
  const nextExam = availableDigitalExams[currentIndex + 1]
  return {
    canEditStatus:
      makeupStatus &&
      makeupStatus !== STATUS_NOT_APPLICABLE &&
      nextExam &&
      !isDigitalExamInCutoffPeriod(state, {
        testCd: testCode,
        testWindow: nextExam?.adminWindow,
      }),
  }
}

export const CoordinatorMakeupColumn = ({ rowData, updateMakeupStatus, canEditStatus }) => {
  const { makeupStatusFormatted, studentExamId, makeupStatus } = rowData
  const dropdownOptions = {
    label: makeupStatusFormatted,
    id: `makeupdropdown${studentExamId}`,
    menuItems: Object.keys(MAKEUP_STATUSES).reduce((arr, key) => {
      const label = MAKEUP_STATUSES[key]
      const remove = key === makeupStatus || key === STATUS_NOT_APPLICABLE
      return remove
        ? arr
        : [...arr, { label, clickEvent: () => updateMakeupStatus(studentExamId, key) }]
    }, []),
  }

  return canEditStatus ? <DropdownPortal {...dropdownOptions} /> : makeupStatusFormatted
}

export default connect(
  mapStateToProps,
  { updateMakeupStatus }
)(CoordinatorMakeupColumn)
