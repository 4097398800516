import { change } from 'redux-form'
import { Modal, Error } from '../../components/common'
import { updateExamDecisionsSettings } from '../../actions/settingsOrg'
import {
  EXAM_DECISIONS_FORM_NAME,
  EXAM_DECISIONS_FORM_FIELDS,
} from '../../constants/SettingsConstants'

const mapStateToProps = state => {
  const { selectedOrgId } = state.user.data
  const orgSettings = state.settingsExamDecisions[selectedOrgId] || {}

  return {
    ...orgSettings,
    selectedOrgId,
  }
}

export default connect(
  mapStateToProps,
  { updateExamDecisionsSettings, change }
)(
  ({
    modalCloseFocusElem,
    updateExamDecisionsSettings,
    data,
    updating,
    updated,
    errored,
    selectedOrgId,
    change,
  }) => (
    <div>
      <Modal
        modalCloseFocusElem={document.getElementById(
          `${EXAM_DECISIONS_FORM_FIELDS.enableDecisions}-button`
        )}
        shouldCloseModal={updated}
        headerTitle="Confirm Exam Decisions"
        footerActions={[
          {
            buttonLabel: 'Cancel',
            isDismissable: true,
            onClick: () =>
              change(EXAM_DECISIONS_FORM_NAME, EXAM_DECISIONS_FORM_FIELDS.enableDecisions, false),
          },
          {
            buttonLabel: 'Save',
            isPrimary: true,
            busy: updating,
            isDisabled: updating,
            onClick: () =>
              updateExamDecisionsSettings(
                { ...data, [EXAM_DECISIONS_FORM_FIELDS.enableDecisions]: true },
                selectedOrgId
              ),
          },
        ]}
      >
        {errored ? <Error title="Error Saving Exam Decision" message={errored} /> : null}
        <p>
          Are you sure you want to enable Student Decisions? You will not be able to undo your
          selection after you save it.
        </p>
      </Modal>
    </div>
  )
)
