import * as c from '../constants/SettingsConstants'
import template from './template'

export default (state, { type, payload }) => {
  switch (type) {
    case c.SET_SELECTED_EDUCATION_PERIOD:
      return { ...state, selectedEducationPeriod: payload }

    case c.SET_SELECTED_IS_CURRENT_EDUCATION_PERIOD:
      return { ...state, selectedIsCurrent: payload }

    case c.CLEAR_SELECTED_EDUCATION_PERIOD:
      return { ...state, selectedEducationPeriod: null }

    default:
      return template(state, { type, payload }, c, 'EDUCATION_PERIOD')
  }
}
