import { STUDENT_EXAM_URLS } from '@myap/ui-library/esm/profile/content'
import { Dropdown } from '../../common'
import AvailableCourses from '../common/AvailableCourses'
import { openModal } from '../../../actions/app'

import styles from '../../../assets/style/scss/exam-readiness.scss'

const CoordinatorPractice = ({ openModal }) => {
  const triggerRef = useRef(null)

  return (
    <AvailableCourses
      showLoading={false}
      showError={false}
      component={({ courses = [] }) => (
        <div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() =>
              openModal('ProvisionPracticeModal', { modalCloseFocusElem: triggerRef.current })
            }
          >
            Take Digital Practice
          </button>
          <div className={styles['coordinator-scoring-materials-picker']}>
            {courses.length > 1 ? (
              <Dropdown
                label="Scoring Materials"
                id="selectScoringMaterialsDropdown"
                menuItems={courses.map(c => ({
                  label: c.courseName,
                  href: STUDENT_EXAM_URLS[c.testCode],
                  title: `${c.courseName} Scoring Materials (Opens in a new window)`,
                  target: '_blank',
                }))}
              />
            ) : (
              <a
                href={STUDENT_EXAM_URLS[(courses[0]?.testCode)]}
                title={`${courses[0]?.courseName} Scoring Materials (Opens in a new window)`}
              >
                {courses[0]?.courseName}
              </a>
            )}
          </div>
        </div>
      )}
    />
  )
}

export default connect(
  null,
  { openModal }
)(CoordinatorPractice)
