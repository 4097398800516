import { isExamOnly } from '../section'
import * as c from '../../constants/FilterConstants'
import { filterByKey } from './utils'

/** *********************************************************** */
/** ***************** DATA FILTERING FUNCTIONS **************** */
/** *********************************************************** */
const filterByTeacher = (dataElem, valuesToFilterBy) => {
  const { sectionTeachers = [] } = dataElem
  return valuesToFilterBy.find(({ value }) =>
    sectionTeachers.find(t => t.teacherId.toString() === value)
  )
}

const filterByTeacherConfirm = (dataElem, valuesToFilterBy) => {
  // check for sectionId to make sure this "section" is really a section and not a course without sections
  return (
    dataElem.sectionId &&
    !isExamOnly(dataElem.sectionType) &&
    valuesToFilterBy.find(({ value }) => {
      return (
        (value === c.FILTER_YES && dataElem.teacherConfirmedExamDecisionsDate) ||
        (value === c.FILTER_NO && !dataElem.teacherConfirmedExamDecisionsDate)
      )
    })
  )
}

const filterByEnrollment = (dataElem, valuesToFilterBy) => {
  const { sectionId, maxNumberOfStudents, enrollmentCount } = dataElem
  return valuesToFilterBy.find(
    ({ value }) =>
      (value === c.NO_SECTIONS && !sectionId) ||
      (value === c.PARTIAL && enrollmentCount < maxNumberOfStudents) ||
      (value === c.FULL && enrollmentCount === maxNumberOfStudents)
  )
}

export const COURSE_FILTER_FUNCTIONS = {
  [c.COURSE_FILTER.filter]: (d, v) => filterByKey(d, v, 'testCd'),
  [c.COURSE_TYPE_FILTER.filter]: (d, v) => filterByKey(d, v, 'testProgramCd'),
  [c.TEACHER_FILTER.filter]: filterByTeacher,
  [c.TEACHER_CONFIRM_FILTER.filter]: filterByTeacherConfirm,
  [c.ENROLLMENT_FILTER.filter]: filterByEnrollment,
}

/** *********************************************************** */
/** ******************* FILTER TAG FUNCTIONS ****************** */
/** *********************************************************** */
export const getCourseFilterTagData = (state, filter, value) => {
  const { courses, teachers = [] } = state
  let filterProps
  let displayValue

  switch (filter) {
    case c.COURSE_FILTER.filter:
      filterProps = c.COURSE_FILTER
      displayValue = courses[value]?.courseName
      break
    case c.COURSE_TYPE_FILTER.filter:
      filterProps = c.COURSE_TYPE_FILTER
      displayValue = c.COURSE_TYPE_FILTER_VALUES[value]
      break
    case c.ENROLLMENT_FILTER.filter:
      filterProps = c.ENROLLMENT_FILTER
      displayValue = c.ENROLLMENT_FILTER_VALUES[value]
      break
    case c.TEACHER_CONFIRM_FILTER.filter:
      filterProps = c.TEACHER_CONFIRM_FILTER
      displayValue = c.TEACHER_CONFIRM_FILTER_VALUES[value]
      break
    case c.TEACHER_FILTER.filter: {
      const { lastName, firstName } = teachers.find(t => t.proId.toString() === value) || {}
      filterProps = c.TEACHER_FILTER
      displayValue = `${firstName} ${lastName}`
      break
    }
    default:
      filterProps = {}
      displayValue = ''
  }

  return { ...filterProps, displayValue }
}
