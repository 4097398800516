import { Route, Switch } from 'react-router-dom'
import FundingRoute from '../routes/FundingRoute'
import * as pages from '../../containers'
import { NAV_LINKS } from '../../constants/NavConstants'

export default () => (
  <Switch>
    <FundingRoute path={NAV_LINKS.districtfunding} />
    <Route component={pages.NotFoundPage} />
  </Switch>
)
