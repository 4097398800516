import { reduxForm } from 'redux-form'
import {
  EXAM_DECISION_INDICATOR_FORM,
  EXAM_DECISION_INDICATOR_FIELDS as FIELDS,
} from '../../../constants/SetupConstants'
import { StepTracker, StepButtons } from '..'
import { Input } from '../../common'
import { changeStep } from '../../../actions/settingsOrg'

// const mapStateToProps = state => ({ orgSettings: state.settingsOrg[state.user.data.selectedOrgId].data, selectedOrgId: state.user.data.selectedOrgId, educationPeriod: state.settingsEducationPeriod.current.descr })

const ExamDecisionIndicatorForm = ({ backStep }) => (
  <>
    <StepTracker />
    <h3 className="h1" style={{ marginTop: '40px' }}>
      Student Exam Decision Indicator: Review this Information
    </h3>
    <p>
      By default, students who enter a join code are included in your exam roster and their exam
      order status will be set to &ldquo;<strong>Yes</strong>&rdquo; unless you modify it.
    </p>
    <p>
      If you&#39;d prefer for students to indicate their exam order status themselves, you may
      change the <strong>Student Exam Decision Indicator</strong> to the &ldquo;Advanced&rdquo;
      setting. By default, the Advanced setting sets students&#39; exam order status to
      &ldquo;Undecided&rdquo; for all course-takers/enrollees. If you choose to use the Advanced
      setting, each student will be responsible for selecting an exam order status of &ldquo;
      <strong>Yes</strong>&rdquo; or &ldquo;<strong>No</strong>&rdquo; by the deadline you specify.
    </p>
    <p>
      You will possess the ability to directly modify students&#39; exam order status before you
      submit your order, irrespective to what students select.
    </p>
    <p>
      To review or modify the Student Exam Decision Indicator, go to the <strong>Settings</strong>{' '}
      page after you complete setup.{' '}
      <strong>
        You have <em style={{ fontStyle: 'normal', textDecoration: 'underline' }}>7 days</em> to
        change the student exam decision indicator after completing setup.
      </strong>{' '}
      After 7 days, you won&#39;t be able to change the indicator.
    </p>
    <Input
      type="checkbox"
      name={FIELDS.DECISION_INDICATOR.name}
      value={true}
      label="I have read and understand how I can change the Student Exam Decision Indicator for my school this year."
      isRequired={FIELDS.DECISION_INDICATOR.required}
    />

    <div style={{ marginTop: '50px' }}>
      <StepButtons backStep={backStep} form={EXAM_DECISION_INDICATOR_FORM} />
    </div>
  </>
)

export default reduxForm({
  form: EXAM_DECISION_INDICATOR_FORM,
  onSubmit: (data, dispatch) => {
    dispatch(changeStep(4))
    window.scrollTo(0, 0)
  },
})(ExamDecisionIndicatorForm)
