import axios, { getServerErrorMessage } from '../utils/axios'
import * as c from '../constants/NotificationConstants'

export const getHoldNotification =
  ({ orgId }) =>
  async dispatch => {
    let response
    dispatch({ type: c.FETCH_NOTIFICATION_PENDING, payload: { type: c.HOLD_NOTIFICATION } })
    try {
      response = await axios.get(
        `${Config.API_URL}/paapro/v1/organizations/${orgId}/exam-order-hold`
      )
      dispatch({
        type: c.FETCH_NOTIFICATION_FULFILLED,
        payload: { type: c.HOLD_NOTIFICATION, data: response.data },
      })
    } catch (err) {
      let error
      if (Object.keys(err).length) {
        error = getServerErrorMessage(err)
      } else {
        error = err
      }
      dispatch({
        type: c.FETCH_NOTIFICATION_REJECTED,
        payload: { type: c.HOLD_NOTIFICATION, error },
      })
    }
  }

export const getNotifications =
  ({ orgId, educationPeriodCd }) =>
  async dispatch => {
    let response
    dispatch({ type: c.FETCH_NOTIFICATION_PENDING, payload: { type: c.AP_NOTIFICATIONS } })
    try {
      response = await axios.get(
        `${Config.API_URL}/notifications/organizations/${orgId}/education-periods/${educationPeriodCd}`
      )
      dispatch({
        type: c.FETCH_NOTIFICATION_FULFILLED,
        payload: { type: c.AP_NOTIFICATIONS, data: response.data },
      })
    } catch (err) {
      let error
      if (Object.keys(err).length) {
        error = getServerErrorMessage(err)
      } else {
        error = err
      }
      dispatch({
        type: c.FETCH_NOTIFICATION_REJECTED,
        payload: { type: c.AP_NOTIFICATIONS, error },
      })
    }
  }

export const dismissNotification =
  ({ orgId, educationPeriodCd, notificationType, messageIds }) =>
  async dispatch => {
    dispatch({ type: c.DISMISS_NOTIFICATION_PENDING, payload: { type: notificationType } })
    try {
      const response = await axios.post(
        `${Config.API_URL}/notifications/organizations/${orgId}/education-periods/${educationPeriodCd}`,
        {
          messageIds,
        }
      )
      dispatch({
        type: c.DISMISS_NOTIFICATION_FULFILLED,
        payload: { type: notificationType, messageIds },
      })
    } catch (err) {
      dispatch({
        type: c.DISMISS_NOTIFICATION_REJECTED,
        payload: { type: notificationType, error: getServerErrorMessage(err) },
      })
    }
  }
