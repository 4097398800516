import { reduxForm, change, formValueSelector, isDirty } from 'redux-form'
import {
  manipulateMonths,
  manipulateDays,
  getEndOfWeek,
  formatDate,
  parse,
  isValidDate,
  DATETIME_FORMATS,
} from '@myap/ui-library/esm/date'
import {
  changeStep,
  fetchStateFundingAndPricing,
  setDistrictFundingSubsidyData,
} from '../../actions/funding'
import { StepButtons, DistrictFundsControls, DistrictFundingOtherFees } from '.'
import { Text, Date as DateSelector, OpenNewWindow } from '../common'
import {
  TOTAL_FUNDING_STEPS,
  FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME,
  FUNDING_SUBSIDY_CONFIGURATION_FORM_FIELDS as FIELDS,
} from '../../constants/FundingConstants'
import { isEmpty } from '../../utils/common'
import { safeNumber } from '../../utils/numbers'
import styles from '../../assets/style/scss/funding.scss'

const FEDERAL_ASSIST_URL = 'https://apcentral.collegeboard.org/exam-administration-ordering-scores/ordering-fees/exam-fees/federal-state-assistance'
const EXAM_FEES_URL = 'https://apcentral.collegeboard.org/exam-administration-ordering-scores/ordering-fees/exam-fees'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    funding: {
      data: { subsidies },
    },
    stateFundingAndPricing: { fetched: fetchedStateFunding, data: stateFundingAndPricingData = {} },
    settingsEducationPeriod: { selectedEducationPeriod, availableEducationPeriods },
    settingsDeadlines: { data: deadlinesData },
  } = state
  const districtFundingLockDate = deadlinesData[selectedEducationPeriod]?.districtFundingLockDate
  const invoiceAvailableDate = deadlinesData[selectedEducationPeriod]?.invoiceAvailableDate
  const { examPricing = {}, stateCode = '' } = stateFundingAndPricingData
  const selector = formValueSelector(FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME)
  const regularFeeReducedTestPrice = safeNumber(examPricing?.reducedFee?.nonCapstone?.testPrice)
  const regularFeeReducedStateFunding = safeNumber(
    examPricing?.reducedFee?.nonCapstone?.stateFunding
  )
  const capstoneFeeReducedTestPrice = safeNumber(examPricing?.reducedFee?.capstone?.testPrice)
  const capstoneFeeReducedStateFunding = safeNumber(examPricing?.reducedFee?.capstone?.stateFunding)
  const regularStandardFeeTestPrice = safeNumber(examPricing?.standardFee?.nonCapstone?.testPrice)
  const regularStandardFeeStateFunding = safeNumber(
    examPricing?.standardFee?.nonCapstone?.stateFunding
  )
  const capstoneStandardFeeTestPrice = safeNumber(examPricing?.standardFee?.capstone?.testPrice)
  const capstoneStandardFeeStateFunding = safeNumber(
    examPricing?.standardFee?.capstone?.stateFunding
  )
  const hasStateFunding =
    !isEmpty(examPricing?.reducedFee?.capstone?.stateFunding) ||
    !isEmpty(examPricing?.reducedFee?.nonCapstone?.stateFunding) ||
    !isEmpty(examPricing?.standardFee?.capstone?.stateFunding) ||
    !isEmpty(examPricing?.standardFee?.nonCapstone?.stateFunding)

  const apAdminYear = availableEducationPeriods[selectedEducationPeriod]?.apAdminYear

  return {
    initialValues: {
      [FIELDS.regularFeeReducedAmount.name]: safeNumber(subsidies?.reducedFee?.nonCapstone?.amount),
      [FIELDS.capstoneFeeReducedAmount.name]: safeNumber(subsidies?.reducedFee?.capstone?.amount),
      [FIELDS.regularStandardFeeAmount.name]: safeNumber(
        subsidies?.standardFee?.nonCapstone?.amount
      ),
      [FIELDS.capstoneStandardFeeAmount.name]: safeNumber(subsidies?.standardFee?.capstone?.amount),
      [FIELDS.regularFeeReducedId.name]: subsidies?.reducedFee?.nonCapstone?.id,
      [FIELDS.capstoneFeeReducedId.name]: subsidies?.reducedFee?.capstone?.id,
      [FIELDS.regularStandardFeeId.name]: subsidies?.standardFee?.nonCapstone?.id,
      [FIELDS.capstoneStandardFeeId.name]: subsidies?.standardFee?.capstone?.id,
      [FIELDS.regularFeeReducedName.name]: subsidies?.reducedFee?.nonCapstone?.name,
      [FIELDS.regularFeeReducedMustTake.name]:
        subsidies?.reducedFee?.nonCapstone?.courseTaken ?? false,
      [FIELDS.regularFeeReducedCancellationFeesActive.name]:
        subsidies?.reducedFee?.nonCapstone?.feeTypeSubsidies?.cancellation?.active ?? false,
      [FIELDS.regularFeeReducedCancellationFeesId.name]:
        subsidies?.reducedFee?.nonCapstone?.feeTypeSubsidies?.cancellation?.id ?? null,
      [FIELDS.regularFeeReducedLateOrderFeesActive.name]:
        subsidies?.reducedFee?.nonCapstone?.feeTypeSubsidies?.lateOrder?.active ?? false,
      [FIELDS.regularFeeReducedLateOrderFeesId.name]:
        subsidies?.reducedFee?.nonCapstone?.feeTypeSubsidies?.lateOrder?.id ?? null,
      [FIELDS.regularFeeReducedLateTestingFeesActive.name]:
        subsidies?.reducedFee?.nonCapstone?.feeTypeSubsidies?.lateTesting?.active ?? false,
      [FIELDS.regularFeeReducedLateTestingFeesId.name]:
        subsidies?.reducedFee?.nonCapstone?.feeTypeSubsidies?.lateTesting?.id ?? null,
      [FIELDS.capstoneFeeReducedName.name]: subsidies?.reducedFee?.capstone?.name,
      [FIELDS.capstoneFeeReducedMustTake.name]:
        subsidies?.reducedFee?.capstone?.courseTaken ?? false,
      [FIELDS.capstoneFeeReducedCancellationFeesActive.name]:
        subsidies?.reducedFee?.capstone?.feeTypeSubsidies?.cancellation?.active ?? false,
      [FIELDS.capstoneFeeReducedCancellationFeesId.name]:
        subsidies?.reducedFee?.capstone?.feeTypeSubsidies?.cancellation?.id ?? null,
      [FIELDS.capstoneFeeReducedLateOrderFeesActive.name]:
        subsidies?.reducedFee?.capstone?.feeTypeSubsidies?.lateOrder?.active ?? false,
      [FIELDS.capstoneFeeReducedLateOrderFeesId.name]:
        subsidies?.reducedFee?.capstone?.feeTypeSubsidies?.lateOrder?.id ?? null,
      [FIELDS.capstoneFeeReducedLateTestingFeesActive.name]:
        subsidies?.reducedFee?.capstone?.feeTypeSubsidies?.lateTesting?.active ?? false,
      [FIELDS.capstoneFeeReducedLateTestingFeesId.name]:
        subsidies?.reducedFee?.capstone?.feeTypeSubsidies?.lateTesting?.id ?? null,
      [FIELDS.regularStandardFeeName.name]: subsidies?.standardFee?.nonCapstone?.name,
      [FIELDS.regularStandardFeeMustTake.name]:
        subsidies?.standardFee?.nonCapstone?.courseTaken ?? false,
      [FIELDS.regularStandardFeeCancellationFeesActive.name]:
        subsidies?.standardFee?.nonCapstone?.feeTypeSubsidies?.cancellation?.active ?? false,
      [FIELDS.regularStandardFeeCancellationFeesId.name]:
        subsidies?.standardFee?.nonCapstone?.feeTypeSubsidies?.cancellation?.id ?? null,
      [FIELDS.regularStandardFeeLateOrderFeesActive.name]:
        subsidies?.standardFee?.nonCapstone?.feeTypeSubsidies?.lateOrder?.active ?? false,
      [FIELDS.regularStandardFeeLateOrderFeesId.name]:
        subsidies?.standardFee?.nonCapstone?.feeTypeSubsidies?.lateOrder?.id ?? null,
      [FIELDS.regularStandardFeeLateTestingFeesActive.name]:
        subsidies?.standardFee?.nonCapstone?.feeTypeSubsidies?.lateTesting?.active ?? false,
      [FIELDS.regularStandardFeeLateTestingFeesId.name]:
        subsidies?.standardFee?.nonCapstone?.feeTypeSubsidies?.lateTesting?.id ?? null,
      [FIELDS.capstoneStandardFeeName.name]: subsidies?.standardFee?.capstone?.name,
      [FIELDS.capstoneStandardFeeMustTake.name]:
        subsidies?.standardFee?.capstone?.courseTaken ?? false,
      [FIELDS.capstoneStandardFeeCancellationFeesActive.name]:
        subsidies?.standardFee?.capstone?.feeTypeSubsidies?.cancellation?.active ?? false,
      [FIELDS.capstoneStandardFeeCancellationFeesId.name]:
        subsidies?.standardFee?.capstone?.feeTypeSubsidies?.cancellation?.id ?? null,
      [FIELDS.capstoneStandardFeeLateTestingFeesActive.name]:
        subsidies?.standardFee?.capstone?.feeTypeSubsidies?.lateTesting?.active ?? false,
      [FIELDS.capstoneStandardFeeLateTestingFeesId.name]:
        subsidies?.standardFee?.capstone?.feeTypeSubsidies?.lateTesting?.id ?? null,
      [FIELDS.capstoneStandardFeeLateOrderFeesActive.name]:
        subsidies?.standardFee?.capstone?.feeTypeSubsidies?.lateOrder?.active ?? false,
      [FIELDS.capstoneStandardFeeLateOrderFeesId.name]:
        subsidies?.standardFee?.capstone?.feeTypeSubsidies?.lateOrder?.id ?? null,
      [FIELDS.invoiceDate.name]:
        subsidies?.invoiceAvailableDate && isValidDate(parse(subsidies?.invoiceAvailableDate))
          ? subsidies?.invoiceAvailableDate
          : `${apAdminYear}-06-30`,
    },
    currentValues: {
      [FIELDS.regularFeeReducedAmount.name]: selector(state, FIELDS.regularFeeReducedAmount.name),
      [FIELDS.capstoneFeeReducedAmount.name]: selector(state, FIELDS.capstoneFeeReducedAmount.name),
      [FIELDS.regularStandardFeeAmount.name]: selector(state, FIELDS.regularStandardFeeAmount.name),
      [FIELDS.capstoneStandardFeeAmount.name]: selector(
        state,
        FIELDS.capstoneStandardFeeAmount.name
      ),
      [FIELDS.regularFeeReducedName.name]: selector(state, FIELDS.regularFeeReducedName.name),
      [FIELDS.regularFeeReducedMustTake.name]: selector(
        state,
        FIELDS.regularFeeReducedMustTake.name
      ),
      [FIELDS.regularFeeReducedCancellationFeesActive.name]: selector(
        state,
        FIELDS.regularFeeReducedCancellationFeesActive.name
      ),
      [FIELDS.regularFeeReducedLateOrderFeesActive.name]: selector(
        state,
        FIELDS.regularFeeReducedLateOrderFeesActive.name
      ),
      [FIELDS.regularFeeReducedLateTestingFeesActive.name]: selector(
        state,
        FIELDS.regularFeeReducedLateTestingFeesActive.name
      ),
      [FIELDS.capstoneFeeReducedName.name]: selector(state, FIELDS.capstoneFeeReducedName.name),
      [FIELDS.capstoneFeeReducedMustTake.name]: selector(
        state,
        FIELDS.capstoneFeeReducedMustTake.name
      ),
      [FIELDS.capstoneFeeReducedCancellationFeesActive.name]: selector(
        state,
        FIELDS.capstoneFeeReducedCancellationFeesActive.name
      ),
      [FIELDS.capstoneFeeReducedLateOrderFeesActive.name]: selector(
        state,
        FIELDS.capstoneFeeReducedLateOrderFeesActive.name
      ),
      [FIELDS.capstoneFeeReducedLateTestingFeesActive.name]: selector(
        state,
        FIELDS.capstoneFeeReducedLateTestingFeesActive.name
      ),
      [FIELDS.regularStandardFeeName.name]: selector(state, FIELDS.regularStandardFeeName.name),
      [FIELDS.regularStandardFeeMustTake.name]: selector(
        state,
        FIELDS.regularStandardFeeMustTake.name
      ),
      [FIELDS.regularStandardFeeCancellationFeesActive.name]: selector(
        state,
        FIELDS.regularStandardFeeCancellationFeesActive.name
      ),
      [FIELDS.regularStandardFeeLateOrderFeesActive.name]: selector(
        state,
        FIELDS.regularStandardFeeLateOrderFeesActive.name
      ),
      [FIELDS.regularStandardFeeLateTestingFeesActive.name]: selector(
        state,
        FIELDS.regularStandardFeeLateTestingFeesActive.name
      ),
      [FIELDS.capstoneStandardFeeName.name]: selector(state, FIELDS.capstoneStandardFeeName.name),
      [FIELDS.capstoneStandardFeeMustTake.name]: selector(
        state,
        FIELDS.capstoneStandardFeeMustTake.name
      ),
      [FIELDS.capstoneStandardFeeCancellationFeesActive.name]: selector(
        state,
        FIELDS.capstoneStandardFeeCancellationFeesActive.name
      ),
      [FIELDS.capstoneStandardFeeLateOrderFeesActive.name]: selector(
        state,
        FIELDS.capstoneStandardFeeLateOrderFeesActive.name
      ),
      [FIELDS.capstoneStandardFeeLateOrderFeesId.name]: selector(
        state,
        FIELDS.capstoneStandardFeeLateOrderFeesId.name
      ),
      [FIELDS.invoiceDate.name]: selector(state, FIELDS.invoiceDate.name),
    },
    [`minimum${FIELDS.regularFeeReducedAmount.name}`]: 0,
    [`minimum${FIELDS.capstoneFeeReducedAmount.name}`]: 0,
    [`minimum${FIELDS.regularStandardFeeAmount.name}`]: 0,
    [`minimum${FIELDS.capstoneStandardFeeAmount.name}`]: 0,
    orgId,
    hasStateFunding, // based on whether stateFunding is populated in any examPricing subsidies
    regularFeeReducedTestPrice,
    regularFeeReducedStateFunding,
    capstoneFeeReducedTestPrice,
    capstoneFeeReducedStateFunding,
    regularStandardFeeTestPrice,
    regularStandardFeeStateFunding,
    capstoneStandardFeeTestPrice,
    capstoneStandardFeeStateFunding,
    fetchedStateFunding,
    stateCode,
    academicYear: availableEducationPeriods[selectedEducationPeriod]?.descr,
    finalInvoiceDate: formatDate(
      manipulateMonths(availableEducationPeriods[selectedEducationPeriod]?.academicYrEndDate, 3)
    ),
    educationPeriodCd: selectedEducationPeriod,
    districtFundingLockDate,
    invoiceAvailableDate,
    dirty: isDirty(FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME)(state),
  }
}

const ConfigureDistrictSubsidyFunding = ({
  orgId,
  educationPeriodCd,
  fetchedStateFunding,
  regularFeeReducedTestPrice,
  regularFeeReducedStateFunding,
  capstoneFeeReducedTestPrice,
  capstoneFeeReducedStateFunding,
  regularStandardFeeTestPrice,
  regularStandardFeeStateFunding,
  capstoneStandardFeeTestPrice,
  capstoneStandardFeeStateFunding,
  finalInvoiceDate,
  invoiceAvailableDate,
  initialValues,
  currentValues,
  hasStateFunding,
  stateCode,
  academicYear,
  districtFundingLockDate,
  dirty,
  handleSubmit,
  fetchStateFundingAndPricing,
}) => {
  const [regularFeeReducedCost, setRegularFeeReducedCost] = useState(null)
  const [capstoneFeeReducedCost, setCapstoneFeeReducedCost] = useState(null)
  const [regularStandardFeeCost, setRegularStandardFeeCost] = useState(null)
  const [capstoneStandardFeeCost, setCapstoneStandardFeeCost] = useState(null)
  const [stateFullName, setStateFullName] = useState('')

  const subsidyNameStyles = {
    display: 'inline-block',
    marginLeft: '20px',
    width: '500px',
  }

  const calcSchoolCost = (examCost = 0, stateFunds = 0, districtFunds = 0) =>
    examCost - stateFunds - safeNumber(districtFunds)

  const alertBeforeLeaving = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    if (dirty) {
      window.addEventListener('beforeunload', alertBeforeLeaving)
    }
    return () => {
      if (dirty) {
        window.removeEventListener('beforeunload', alertBeforeLeaving)
      }
    }
  }, [])

  useEffect(() => {
    if (orgId && educationPeriodCd && !fetchedStateFunding) {
      fetchStateFundingAndPricing(orgId, educationPeriodCd)
    }
  }, [orgId, educationPeriodCd, fetchedStateFunding])

  useEffect(() => {
    const { name = '' } = APRICOT.data.states.find(s => s.value === stateCode) ?? {}
    setStateFullName(name === 'Choose a State' ? '' : name)
  }, [stateCode])

  // Calculate initial costs
  useEffect(() => {
    if (fetchedStateFunding) {
      setRegularFeeReducedCost(
        calcSchoolCost(
          regularFeeReducedTestPrice,
          regularFeeReducedStateFunding,
          safeNumber(initialValues[FIELDS.regularFeeReducedAmount.name])
        )
      )
      setCapstoneFeeReducedCost(
        calcSchoolCost(
          capstoneFeeReducedTestPrice,
          capstoneFeeReducedStateFunding,
          safeNumber(initialValues[FIELDS.capstoneFeeReducedAmount.name])
        )
      )
      setRegularStandardFeeCost(
        calcSchoolCost(
          regularStandardFeeTestPrice,
          regularStandardFeeStateFunding,
          safeNumber(initialValues[FIELDS.regularStandardFeeAmount.name])
        )
      )
      setCapstoneStandardFeeCost(
        calcSchoolCost(
          capstoneStandardFeeTestPrice,
          capstoneStandardFeeStateFunding,
          safeNumber(initialValues[FIELDS.capstoneStandardFeeAmount.name])
        )
      )
    }
  }, [fetchedStateFunding])

  if (fetchedStateFunding) {
    return (
      <>
        {hasStateFunding ? (
          <div className={styles.intro}>
            <p>
              The state of {stateFullName} is providing funding for AP Exams. For full details on
              state funding, visit{' '}
              <OpenNewWindow url={FEDERAL_ASSIST_URL}>
                Federal and State AP Exam Fee Assistance
              </OpenNewWindow> on AP Central.
            </p>
            <p>
              Below is a summary of your state&#39;s funding. Please indicate the amount your
              district will supplement for the following test taker types.
            </p>
            <p>
              For details on <OpenNewWindow url={EXAM_FEES_URL}>exam costs and fees</OpenNewWindow>,
              visit AP Central.
            </p>
          </div>
        ) : (
          <div className={`${styles.intro} ${styles['no-state-funding']}`}>
            <p>
              The state of {stateFullName} has not indicated funding for the {academicYear} school
              year yet. Please note: If you add funding now and it conflicts with your state&#39;s
              funding selection, you&#39;ll be contacted and required to adjust prior to{' '}
              {formatDate(districtFundingLockDate, DATETIME_FORMATS.longMonthDayYear)}. For full
              details on state funding, visit{' '}
              <OpenNewWindow url={FEDERAL_ASSIST_URL}>
                Federal and State AP Exam Fee Assistance
              </OpenNewWindow>{' '}
              on AP Central.
            </p>
            <p>
              For details on <OpenNewWindow url={EXAM_FEES_URL}>exam costs and fees</OpenNewWindow>,
              visit AP Central.
            </p>
          </div>
        )}
        <form className={styles['subsidy-configuration']} onSubmit={handleSubmit}>
          <div className={styles['fee-type']}>
            <h2 className={styles.header}>Fee-Reduced Students</h2>
            <div className={styles.content}>
              <div className={styles['exam-type']}>
                <h3 className={styles.header}>Regular AP Exams</h3>
                <div className={styles.content}>
                  {!isEmpty(initialValues[FIELDS.regularFeeReducedName.name]) ? (
                    <Text
                      name={FIELDS.regularFeeReducedName.name}
                      isRequired={FIELDS.regularFeeReducedName.required}
                      label="Name"
                      placeholder="Enter subsidy name"
                      inputStyle={subsidyNameStyles}
                    />
                  ) : null}
                  <div className={styles.pricing}>Exam Cost: ${regularFeeReducedTestPrice}</div>
                  <div className={styles.pricing}>
                    State funds: ${regularFeeReducedStateFunding}
                  </div>
                  <div className={styles.pricing}>
                    <DistrictFundsControls
                      amountField={FIELDS.regularFeeReducedAmount.name}
                      mustTakeField={FIELDS.regularFeeReducedMustTake.name}
                      formName={FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME}
                      testPrice={regularFeeReducedTestPrice}
                      stateFunding={regularFeeReducedStateFunding}
                      updateCost={setRegularFeeReducedCost}
                      calcNewCost={calcSchoolCost}
                    />
                  </div>
                  <div className={styles['pricing-total']}>
                    School pays: ${regularFeeReducedCost} per qualifying exam
                  </div>
                  <DistrictFundingOtherFees
                    mustTakeField={FIELDS.regularFeeReducedMustTake.name}
                    cancellationFeesField={FIELDS.regularFeeReducedCancellationFeesActive.name}
                    lateOrderFeesField={FIELDS.regularFeeReducedLateOrderFeesActive.name}
                    lateTestingFeesField={FIELDS.regularFeeReducedLateTestingFeesActive.name}
                    hasStateFunding={hasStateFunding}
                    disabled={safeNumber(currentValues[FIELDS.regularFeeReducedAmount.name]) < 1}
                    openByDefault={true}
                  />
                </div>
              </div>
              <div className={styles['exam-type']}>
                <h3 className={styles.header}>AP Capstone Exams</h3>
                <div className={styles.content}>
                  {!isEmpty(initialValues[FIELDS.capstoneFeeReducedName.name]) ? (
                    <Text
                      name={FIELDS.capstoneFeeReducedName.name}
                      isRequired={FIELDS.capstoneFeeReducedName.required}
                      label="Name"
                      placeholder="Enter subsidy name"
                      inputStyle={subsidyNameStyles}
                    />
                  ) : null}
                  <div className={styles.pricing}>Exam Cost: ${capstoneFeeReducedTestPrice}</div>
                  <div className={styles.pricing}>
                    State funds: ${capstoneFeeReducedStateFunding}
                  </div>
                  <div className={styles.pricing}>
                    <DistrictFundsControls
                      amountField={FIELDS.capstoneFeeReducedAmount.name}
                      mustTakeField={FIELDS.capstoneFeeReducedMustTake.name}
                      formName={FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME}
                      testPrice={capstoneFeeReducedTestPrice}
                      stateFunding={capstoneFeeReducedStateFunding}
                      updateCost={setCapstoneFeeReducedCost}
                      calcNewCost={calcSchoolCost}
                    />
                  </div>
                  <div className={styles['pricing-total']}>
                    School pays: ${capstoneFeeReducedCost} per qualifying exam
                  </div>
                  <DistrictFundingOtherFees
                    mustTakeField={FIELDS.capstoneFeeReducedMustTake.name}
                    cancellationFeesField={FIELDS.capstoneFeeReducedCancellationFeesActive.name}
                    lateOrderFeesField={FIELDS.capstoneFeeReducedLateOrderFeesActive.name}
                    lateTestingFeesField={FIELDS.capstoneFeeReducedLateTestingFeesActive.name}
                    hasStateFunding={hasStateFunding}
                    disabled={safeNumber(currentValues[FIELDS.capstoneFeeReducedAmount.name]) < 1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles['fee-type']}>
            <h2 className={styles.header}>Standard Fee Students</h2>
            <div className={styles.content}>
              <div className={styles['exam-type']}>
                <h3 className={styles.header}>Regular AP Exams</h3>
                <div className={styles.content}>
                  {!isEmpty(initialValues[FIELDS.regularStandardFeeName.name]) ? (
                    <Text
                      name={FIELDS.regularStandardFeeName.name}
                      isRequired={FIELDS.regularStandardFeeName.required}
                      label="Name"
                      placeholder="Enter subsidy name"
                      inputStyle={subsidyNameStyles}
                    />
                  ) : null}
                  <div className={styles.pricing}>Exam Cost: ${regularStandardFeeTestPrice}</div>
                  <div className={styles.pricing}>
                    State funds: ${regularStandardFeeStateFunding}
                  </div>
                  <div className={styles.pricing}>
                    <DistrictFundsControls
                      amountField={FIELDS.regularStandardFeeAmount.name}
                      mustTakeField={FIELDS.regularStandardFeeMustTake.name}
                      formName={FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME}
                      testPrice={regularStandardFeeTestPrice}
                      stateFunding={regularStandardFeeStateFunding}
                      updateCost={setRegularStandardFeeCost}
                      calcNewCost={calcSchoolCost}
                    />
                  </div>
                  <div className={styles['pricing-total']}>
                    School pays: ${regularStandardFeeCost} per qualifying exam
                  </div>
                  <DistrictFundingOtherFees
                    mustTakeField={FIELDS.regularStandardFeeMustTake.name}
                    cancellationFeesField={FIELDS.regularStandardFeeCancellationFeesActive.name}
                    lateOrderFeesField={FIELDS.regularStandardFeeLateOrderFeesActive.name}
                    lateTestingFeesField={FIELDS.regularStandardFeeLateTestingFeesActive.name}
                    hasStateFunding={hasStateFunding}
                    disabled={safeNumber(currentValues[FIELDS.regularStandardFeeAmount.name]) < 1}
                  />
                </div>
              </div>
              <div className={styles['exam-type']}>
                <h3 className={styles.header}>AP Capstone Exams</h3>
                <div className={styles.content}>
                  {!isEmpty(initialValues[FIELDS.capstoneStandardFeeName.name]) ? (
                    <Text
                      name={FIELDS.capstoneStandardFeeName.name}
                      isRequired={FIELDS.capstoneStandardFeeName.required}
                      label="Name"
                      placeholder="Enter subsidy name"
                      inputStyle={subsidyNameStyles}
                    />
                  ) : null}
                  <div className={styles.pricing}>Exam Cost: ${capstoneStandardFeeTestPrice}</div>
                  <div className={styles.pricing}>
                    State funds: ${capstoneStandardFeeStateFunding}
                  </div>
                  <div className={styles.pricing}>
                    <DistrictFundsControls
                      amountField={FIELDS.capstoneStandardFeeAmount.name}
                      mustTakeField={FIELDS.capstoneStandardFeeMustTake.name}
                      formName={FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME}
                      testPrice={capstoneStandardFeeTestPrice}
                      stateFunding={capstoneStandardFeeStateFunding}
                      updateCost={setCapstoneStandardFeeCost}
                      calcNewCost={calcSchoolCost}
                    />
                  </div>
                  <div className={styles['pricing-total']}>
                    School pays: ${capstoneStandardFeeCost} per qualifying exam
                  </div>
                  <DistrictFundingOtherFees
                    mustTakeField={FIELDS.capstoneStandardFeeMustTake.name}
                    cancellationFeesField={FIELDS.capstoneStandardFeeCancellationFeesActive.name}
                    lateOrderFeesField={FIELDS.capstoneStandardFeeLateOrderFeesActive.name}
                    lateTestingFeesField={FIELDS.capstoneStandardFeeLateTestingFeesActive.name}
                    hasStateFunding={hasStateFunding}
                    disabled={safeNumber(currentValues[FIELDS.capstoneStandardFeeAmount.name]) < 1}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-xs-6">
              <DateSelector
                name={FIELDS.invoiceDate.name}
                isRequired={FIELDS.invoiceDate.required}
                form={FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME}
                showLabel={true}
                label="When would you like to receive your invoice?"
                wrapperStyle={{ width: '175px' }}
                minDate={manipulateDays(getEndOfWeek(invoiceAvailableDate), 1)}
                maxDate={finalInvoiceDate}
                openToDate={currentValues[FIELDS.invoiceDate.name]}
              />
            </div>
          </div>
        </form>
        <StepButtons
          form={FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME}
          previousStep={3}
          totalSteps={TOTAL_FUNDING_STEPS}
          formComplete={
            (currentValues[FIELDS.regularFeeReducedAmount.name] > 0 ||
              currentValues[FIELDS.capstoneFeeReducedAmount.name] > 0 ||
              currentValues[FIELDS.regularStandardFeeAmount.name] > 0 ||
              currentValues[FIELDS.capstoneStandardFeeAmount.name] > 0) &&
            !isEmpty(currentValues[FIELDS.invoiceDate.name]) &&
            fetchedStateFunding
          }
        />
      </>
    )
  }
  return null
}

export default connect(mapStateToProps, {
  changeStep,
  change,
  fetchStateFundingAndPricing,
  setDistrictFundingSubsidyData,
})(
  reduxForm({
    form: FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME,
    enableReinitialize: true,
    validate: (
      vals,
      {
        regularFeeReducedTestPrice,
        regularFeeReducedStateFunding,
        capstoneFeeReducedTestPrice,
        capstoneFeeReducedStateFunding,
        regularStandardFeeTestPrice,
        regularStandardFeeStateFunding,
        capstoneStandardFeeTestPrice,
        capstoneStandardFeeStateFunding,
      }
    ) => {
      const belowZeroErrorMsg = 'Amount that school pays cannot be less than zero'
      const errors = {}
      const nameIsUnique = (nameToCheck, namesToCheckAgainst) => {
        // This filter removes any undefined values from the array
        const filteredNamesToCheckAgainst = namesToCheckAgainst.filter(Boolean)
        if (!isEmpty(nameToCheck)) {
          return (
            filteredNamesToCheckAgainst.find(
              name => name.toUpperCase() !== nameToCheck.toUpperCase()
            ) ?? true
          )
        }
        return true
      }

      // Validate no resulting school costs are less than 0
      if (
        regularFeeReducedTestPrice -
          regularFeeReducedStateFunding -
          safeNumber(vals[FIELDS.regularFeeReducedAmount.name]) <
        0
      ) {
        errors[FIELDS.regularFeeReducedAmount.name] = belowZeroErrorMsg
      }
      if (
        capstoneFeeReducedTestPrice -
          capstoneFeeReducedStateFunding -
          safeNumber(vals[FIELDS.capstoneFeeReducedAmount.name]) <
        0
      ) {
        errors[FIELDS.capstoneFeeReducedAmount.name] = belowZeroErrorMsg
      }
      if (
        regularStandardFeeTestPrice -
          regularStandardFeeStateFunding -
          safeNumber(vals[FIELDS.regularStandardFeeAmount.name]) <
        0
      ) {
        errors[FIELDS.regularStandardFeeAmount.name] = belowZeroErrorMsg
      }
      if (
        capstoneStandardFeeTestPrice -
          capstoneStandardFeeStateFunding -
          safeNumber(vals[FIELDS.capstoneStandardFeeAmount.name]) <
        0
      ) {
        errors[FIELDS.capstoneStandardFeeAmount.name] = belowZeroErrorMsg
      }
      // Validates subsidy names are unique
      if (
        !nameIsUnique(vals[FIELDS.regularFeeReducedName.name], [
          vals[FIELDS.capstoneFeeReducedName.name],
          vals[FIELDS.regularStandardFeeName.name],
          vals[FIELDS.capstoneStandardFeeName.name],
        ])
      ) {
        errors[FIELDS.regularFeeReducedName.name] = 'Subsidy name must be unique.'
      }
      if (
        !nameIsUnique(vals[FIELDS.capstoneFeeReducedName.name], [
          vals[FIELDS.regularFeeReducedName.name],
          vals[FIELDS.regularStandardFeeName.name],
          vals[FIELDS.capstoneStandardFeeName.name],
        ])
      ) {
        errors[FIELDS.capstoneFeeReducedName.name] = 'Subsidy name must be unique.'
      }
      if (
        !nameIsUnique(vals[FIELDS.regularStandardFeeName.name], [
          vals[FIELDS.regularFeeReducedName.name],
          vals[FIELDS.capstoneFeeReducedName.name],
          vals[FIELDS.capstoneStandardFeeName.name],
        ])
      ) {
        errors[FIELDS.regularStandardFeeName.name] = 'Subsidy name must be unique.'
      }
      if (
        !nameIsUnique(vals[FIELDS.capstoneStandardFeeName.name], [
          vals[FIELDS.regularFeeReducedName.name],
          vals[FIELDS.capstoneFeeReducedName.name],
          vals[FIELDS.regularStandardFeeName.name],
        ])
      ) {
        errors[FIELDS.capstoneStandardFeeName.name] = 'Subsidy name must be unique.'
      }
      if (
        safeNumber(vals[FIELDS.regularFeeReducedAmount.name]) === 0 &&
        safeNumber(vals[FIELDS.capstoneFeeReducedAmount.name]) === 0 &&
        safeNumber(vals[FIELDS.regularStandardFeeAmount.name]) === 0 &&
        safeNumber(vals[FIELDS.capstoneStandardFeeAmount.name]) === 0
      ) {
        errors._error = 'At least one subsidy must be funded.'
      }
      return errors
    },
    onSubmit: (vals, dispatch, { changeStep, setDistrictFundingSubsidyData }) => {
      setDistrictFundingSubsidyData({
        reducedFee: {
          capstone: {
            ...(vals[FIELDS.capstoneFeeReducedName.name]
              ? { name: vals[FIELDS.capstoneFeeReducedName.name] }
              : {}),
            ...(vals[FIELDS.capstoneFeeReducedId.name]
              ? { id: vals[FIELDS.capstoneFeeReducedId.name] }
              : {}),
            ...(vals[FIELDS.capstoneFeeReducedAmount.name]
              ? { amount: safeNumber(vals[FIELDS.capstoneFeeReducedAmount.name]) }
              : {}),
            feeTypeSubsidies: {
              cancellation: {
                id: vals[FIELDS.capstoneFeeReducedCancellationFeesId.name],
                active: vals[FIELDS.capstoneFeeReducedCancellationFeesActive.name],
              },
              lateTesting: {
                id: vals[FIELDS.capstoneFeeReducedLateTestingFeesId.name],
                active: vals[FIELDS.capstoneFeeReducedLateTestingFeesActive.name],
              },
              lateOrder: {
                id: vals[FIELDS.capstoneFeeReducedLateOrderFeesId.name],
                active: vals[FIELDS.capstoneFeeReducedLateOrderFeesActive.name],
              },
            },
            ...(vals[FIELDS.capstoneFeeReducedMustTake.name]
              ? { courseTaken: vals[FIELDS.capstoneFeeReducedMustTake.name] }
              : {}),
          },
          nonCapstone: {
            ...(vals[FIELDS.regularFeeReducedName.name]
              ? { name: vals[FIELDS.regularFeeReducedName.name] }
              : {}),
            ...(vals[FIELDS.regularFeeReducedId.name]
              ? { id: vals[FIELDS.regularFeeReducedId.name] }
              : {}),
            ...(vals[FIELDS.regularFeeReducedAmount.name]
              ? { amount: safeNumber(vals[FIELDS.regularFeeReducedAmount.name]) }
              : {}),
            feeTypeSubsidies: {
              cancellation: {
                id: vals[FIELDS.regularFeeReducedCancellationFeesId.name],
                active: vals[FIELDS.regularFeeReducedCancellationFeesActive.name],
              },
              lateTesting: {
                id: vals[FIELDS.regularFeeReducedLateTestingFeesId.name],
                active: vals[FIELDS.regularFeeReducedLateTestingFeesActive.name],
              },
              lateOrder: {
                id: vals[FIELDS.regularFeeReducedLateOrderFeesId.name],
                active: vals[FIELDS.regularFeeReducedLateOrderFeesActive.name],
              },
            },
            ...(vals[FIELDS.regularFeeReducedMustTake.name]
              ? { courseTaken: vals[FIELDS.regularFeeReducedMustTake.name] }
              : {}),
          },
        },
        standardFee: {
          capstone: {
            ...(vals[FIELDS.capstoneStandardFeeName.name]
              ? { name: vals[FIELDS.capstoneStandardFeeName.name] }
              : {}),
            ...(vals[FIELDS.capstoneStandardFeeId.name]
              ? { id: vals[FIELDS.capstoneStandardFeeId.name] }
              : {}),
            ...(vals[FIELDS.capstoneStandardFeeAmount.name]
              ? { amount: safeNumber(vals[FIELDS.capstoneStandardFeeAmount.name]) }
              : {}),
            feeTypeSubsidies: {
              cancellation: {
                id: vals[FIELDS.capstoneStandardFeeCancellationFeesId.name],
                active: vals[FIELDS.capstoneStandardFeeCancellationFeesActive.name],
              },
              lateTesting: {
                id: vals[FIELDS.capstoneStandardFeeLateTestingFeesId.name],
                active: vals[FIELDS.capstoneStandardFeeLateTestingFeesActive.name],
              },
              lateOrder: {
                id: vals[FIELDS.capstoneStandardFeeLateOrderFeesId.name],
                active: vals[FIELDS.capstoneStandardFeeLateOrderFeesActive.name],
              },
            },
            ...(vals[FIELDS.capstoneStandardFeeMustTake.name]
              ? { courseTaken: vals[FIELDS.capstoneStandardFeeMustTake.name] }
              : {}),
          },
          nonCapstone: {
            ...(vals[FIELDS.regularStandardFeeName.name]
              ? { name: vals[FIELDS.regularStandardFeeName.name] }
              : {}),
            ...(vals[FIELDS.regularStandardFeeId.name]
              ? { id: vals[FIELDS.regularStandardFeeId.name] }
              : {}),
            ...(vals[FIELDS.regularStandardFeeAmount.name]
              ? { amount: safeNumber(vals[FIELDS.regularStandardFeeAmount.name]) }
              : {}),
            feeTypeSubsidies: {
              cancellation: {
                id: vals[FIELDS.regularStandardFeeCancellationFeesId.name],
                active: vals[FIELDS.regularStandardFeeCancellationFeesActive.name],
              },
              lateTesting: {
                id: vals[FIELDS.regularStandardFeeLateTestingFeesId.name],
                active: vals[FIELDS.regularStandardFeeLateTestingFeesActive.name],
              },
              lateOrder: {
                id: vals[FIELDS.regularStandardFeeLateOrderFeesId.name],
                active: vals[FIELDS.regularStandardFeeLateOrderFeesActive.name],
              },
            },
            ...(vals[FIELDS.regularStandardFeeMustTake.name]
              ? { courseTaken: vals[FIELDS.regularStandardFeeMustTake.name] }
              : {}),
          },
        },
        invoiceAvailableDate: vals[FIELDS.invoiceDate.name],
      })
      changeStep(5)
    },
  })(ConfigureDistrictSubsidyFunding)
)
