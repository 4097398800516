import { Modal } from '../../components/common';

export default ({videoLink, videoTitle, modalCloseFocusElem}) => (
  <Modal
  	noHeader={true}
  	headerTitle={videoTitle}
  	isVideoModal={true}
    modalCloseFocusElem={modalCloseFocusElem}>
    <div className="cb-video">
      <iframe src={videoLink}
              title={videoTitle}
              frameBorder="0" allowFullScreen></iframe>
    </div>
  </Modal>
)
