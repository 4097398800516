import { SubmissionError } from 'redux-form'
import { iam } from '@myap/ui-library/esm/profile'
import axios, { getServerErrorMessage } from '../utils/axios'
import * as c from '../constants/SurveyConstants'

const { getJWTToken, getAuthSession } = iam()

export const fetchSurveyData = (orgId, educationPeriodCd) => async dispatch => {
  try {
    dispatch({ type: c.FETCH_SURVEY_DATA_PENDING })
    const config = {
      headers: {
        'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
        'X-CB-Catapult-Authorization-Token': getJWTToken(),
      },
    }
    const { data } = await axios.get(
      `${Config.API_GATE_URL}/surveys/organizations/${orgId}/education-periods/${educationPeriodCd}`,
      config
    )
    dispatch({
      type: c.FETCH_SURVEY_DATA_FULFILLED,
      payload: data?.surveys,
    })
  } catch (err) {
    dispatch({ type: c.FETCH_SURVEY_DATA_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const saveSurveyData =
  ({ orgId, educationPeriodCd, data }) =>
  async dispatch => {
    try {
      dispatch({ type: c.SAVE_SURVEY_DATA_PENDING })
      const config = {
        headers: {
          'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
          'X-CB-Catapult-Authorization-Token': getJWTToken(),
        },
      }
      await axios.post(
        `${Config.API_GATE_URL}/surveys/organizations/${orgId}/education-periods/${educationPeriodCd}`,
        data,
        config
      )
      dispatch({
        type: c.SAVE_SURVEY_DATA_FULFILLED,
        payload: data.survey?.surveyId,
      })
    } catch (err) {
      const errMsg = getServerErrorMessage(err)
      dispatch({ type: c.SAVE_SURVEY_DATA_REJECTED, payload: errMsg })
      throw new SubmissionError({
        _error: errMsg,
      })
    }
  }
