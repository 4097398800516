import { ExamIntentDropdown, Dropdown } from '../../common'
import { STUDENT_ENROLLMENT_STATUS_NO } from '../../../constants/StudentConstants'
import { updateStudentSectionExamIntent } from '../../../actions/studentsBySection'
import { openModal } from '../../../actions/app'

const mapStateToProps = (state, props) => {
  const { student: { sectionId, studentId, allowableExamIntents, examIntent }} = props
  const { courses: { entities: { sections }}, studentsBySection: { update: { id, updating }}} = state
  const intents = allowableExamIntents
  const filteredOptions = intents.filter(intent => intent !== examIntent)


  return {
    availableExamIntentOptions: filteredOptions.length > 0 ? intents : [],
    section: sections[sectionId] || {},
    updating: id === studentId && updating,
  }
}

export class TakingExamDecisionMenu extends Component {
  constructor(props) {
    super(props)
    this.handleUpdateAction = this.handleUpdateAction.bind(this)
    this.conflictModal = this.conflictModal.bind(this)
    this.modalCloseFocusElem = createRef()
  }

  handleUpdateAction(intent) {
    const { student, section: { courseName }, updateStudentSectionExamIntent, openModal } = this.props
    const updated = { ...student, examIntent: intent }

    if (intent === STUDENT_ENROLLMENT_STATUS_NO)
      openModal('ExamIntentNotTakingReasonModal', {
        exam: updated,
        courseName,
        action: updateStudentSectionExamIntent,
        updateId: student.studentId,
        storeLocation: 'studentsBySection',
        modalCloseFocusElem: this.modalCloseFocusElem.current.querySelectorAll('.dropdown-toggle')[0],
      })
    else
      updateStudentSectionExamIntent(updated)
  }

  conflictModal() {
    const { openModal, student: { examConflictCourseName }} = this.props

    openModal('TextModal', {
      title: "Why Can't This Be Changed?",
      modalCloseFocusElem: this.modalCloseFocusElem.current.querySelectorAll('button')[0],
      BodyComponent: () => <p>This student is registered for the <strong>{examConflictCourseName}</strong> exam. Students may not take both exams in the same year. Contact your school&#39;s AP coordinator if the student&#39;s exam registration needs to be switched.</p>,
    })
  }

  render() {
    const { student, updating, section: { courseName, studentOrTeacherCanChangeExamIntent }, availableExamIntentOptions } = this.props

    return (
      <ExamIntentDropdown
        ref={this.modalCloseFocusElem}
        handleUpdateAction={this.handleUpdateAction}
        availableExamIntentOptions={availableExamIntentOptions}
        exam={student}
        courseName={courseName}
        updating={updating}
        DropdownComponent={Dropdown}
        conflictModal={this.conflictModal}
        isEditable={studentOrTeacherCanChangeExamIntent}
      />
    )
  }
}

export default connect(mapStateToProps, { updateStudentSectionExamIntent, openModal })(TakingExamDecisionMenu)
