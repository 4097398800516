import { PACKING_LIST_LABEL_MAPPING, RETURNED_RESPONSE } from '../../constants/PackingListConstants'
import { BasicInput } from '../common'
import { updatePackingListItems } from '../../actions/packingList'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    packingListDetails,
  } = state
  return { orgId, educationPeriodCd, packingListDetails }
}

function handleChange(e, props) {
  const {
    orgId,
    educationPeriodCd,
    readOnly,
    updatePackingListItems,
    packingListDetails,
    packingListItemId,
    id,
  } = props
  if (!readOnly) {
    const payload = { [id]: e.target.value }
    updatePackingListItems({
      orgId,
      educationPeriodCd,
      packingListDetails,
      packingListItemId,
      payload,
    })
  }
}

const ResponseReturned = props => {
  const { id, readOnly, value, packingListItemId } = props
  return (
    <>
      <h4>
        {`${PACKING_LIST_LABEL_MAPPING[id]}`}{' '}
        <span style={{ fontSize: '.8em', color: 'rgb(193, 49, 69)' }}>(Required)</span>
      </h4>
      {RETURNED_RESPONSE.map(r => (
        <div key={r}>
          <BasicInput
            type="radio"
            label={r}
            input={{
              name: `${r}${packingListItemId}`,
              value: r,
              onChange: e => handleChange(e, props),
            }}
            disabled={readOnly}
            checked={r === value}
          />
        </div>
      ))}
    </>
  )
}

export default connect(mapStateToProps, { updatePackingListItems })(ResponseReturned)
