import { BasicInput } from '../common'
import { updatePackingListItems } from '../../actions/packingList'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    packingListDetails,
  } = state
  return { orgId, educationPeriodCd, packingListDetails }
}

const SubmissionComponent = ({
  orgId,
  educationPeriodCd,
  id,
  readOnly,
  value,
  title,
  label,
  packingListDetails,
  packingListItemId,
  updatePackingListItems,
}) => {
  const handleChange = e => {
    if (!readOnly) {
      const payload = { [id]: e.target.checked }
      updatePackingListItems({
        orgId,
        educationPeriodCd,
        packingListDetails,
        packingListItemId,
        payload,
      })
    }
  }

  return (
    <div style={{ marginBottom: '24px' }}>
      <h4>
        {`${title}`} <span style={{ fontSize: '.8em', color: 'rgb(193, 49, 69)' }}>(Required)</span>
      </h4>
      <BasicInput
        type="checkbox"
        label={label}
        input={{
          name: `${id}_${packingListItemId}`,
          value,
          onChange: handleChange,
        }}
        isRequired={true}
        disabled={readOnly}
        checked={value}
      />
    </div>
  )
}

export default connect(mapStateToProps, { updatePackingListItems })(SubmissionComponent)
