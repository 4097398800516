import {
  confirmExamOrderSubmissionSubmitted,
  confirmExamOrderSubmissionCanceled,
  submitExamOrders,
  confirmExamOrderSubmissionPending,
} from '../../actions/orders'
import { openModal } from '../../actions/app'
import { Modal, Error, Loader } from '../../components/common'

const mapStateToProps = state => {
  const { ordersSubmission } = state
  return {
    ...ordersSubmission,
  }
}

class ConfirmExamOrderSubmissionModal extends Component {
  constructor() {
    super()
    this.state = { checked: false }
  }

  componentDidMount() {
    this.props.confirmExamOrderSubmissionPending()
  }

  openOrderSubmittedModal() {
    const {
      submitted,
      openModal,
      isMainOrder,
      redirectOnSuccess,
      confirmExamOrderSubmissionSubmitted,
      confirmExamOrderSubmissionCanceled,
    } = this.props

    if (submitted) {
      openModal('OrderSubmittedModal', { isMainOrder, redirectOnSuccess })
      confirmExamOrderSubmissionSubmitted()
    } else {
      confirmExamOrderSubmissionCanceled()
    }
  }

  footerActions() {
    const { submitExamOrders, error, submitting } = this.props
    const { checked } = this.state

    return [
      {
        buttonLabel: 'Cancel',
        isDismissable: true,
        isPrimary: false,
      },
      {
        buttonLabel: 'Submit',
        isPrimary: true,
        onClick: () => submitExamOrders(),
        isDisabled: error || submitting || !checked,
        busy: submitting,
      },
    ]
  }

  render() {
    const { error, submitting, submitted, isMainOrder } = this.props
    const { checked } = this.state

    return (
      <Modal
        shouldCloseModal={submitted}
        onCloseAction={this.openOrderSubmittedModal.bind(this)}
        headerTitle={isMainOrder ? 'Submit Main Order' : 'Submit Order Changes'}
        footerActions={this.footerActions()}
      >
        {error ? <Error title="Error Submitting Order" message={error} /> : null}
        <p>Are you sure you want to submit your order{!isMainOrder && ' changes'}?</p>
        <div className="checkbox">
          <label htmlFor="confirmOrderSubmit">
            <input
              type="checkbox"
              name="confirmOrderSubmit"
              id="confirmOrderSubmit"
              className="cb-checkbox"
              value={checked}
              checked={checked}
              aria-label="Confirm order submission"
              onChange={() => this.setState({ checked: !checked })}
            />
            <span className="cb-span" />I attest that none of the exams in my order are intended for
            use by persons prohibited from taking or reviewing AP Exams, including teachers,
            department chairs, administrators, tutors, individuals involved in test preparation
            services, and educators of any kind.
          </label>
        </div>
        {submitting ? (
          <div>
            <Loader />
            <p>
              We are processing your order now. Please do not close the browser or reload this page.
            </p>
          </div>
        ) : null}
      </Modal>
    )
  }
}

export default connect(mapStateToProps, {
  confirmExamOrderSubmissionSubmitted,
  confirmExamOrderSubmissionCanceled,
  confirmExamOrderSubmissionPending,
  submitExamOrders,
  openModal,
})(ConfirmExamOrderSubmissionModal)
