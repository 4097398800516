import isEqual from 'lodash-es/isEqual'
import { formatDate, DATETIME_FORMATS, isDateAfter } from '@myap/ui-library/esm/date'
import { StepButtons, StepTracker, SelectedSubsidiesTable } from '.'
import { Error, Loader } from '../common'
import { changeStep, fetchChildOrgs, fetchStateFundingAndPricing } from '../../actions/funding'
import { isEmpty } from '../../utils/common'
import { sortColumnByKey } from '../../utils/sort'
import { formatPhoneNumber, formatZip } from '../../utils/numbers'
import {
  TOTAL_FUNDING_STEPS,
  CONTACT_INFORMATION_FORM_FIELDS as CONTACT_FIELDS,
  FUNDING_CENTRAL_BILL,
  FUNDING_SUBSIDY,
} from '../../constants/FundingConstants'
import styles from '../../assets/style/scss/funding.scss'

const getNextButtonText = (hasSavedData, doChangesExist) => {
  if (hasSavedData && doChangesExist) return 'Update'
  if (!hasSavedData && doChangesExist) return 'Complete'
  if (hasSavedData && !doChangesExist) return 'Delete'
}

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: {
      selectedEducationPeriod: educationPeriodCd,
      availableEducationPeriods,
    },
    childOrgs: {
      fetching: fetchingChildOrgs,
      fetched: fetchedChildOrgs,
      error: errorChildOrgs,
      data: childOrgsData,
    },
    districtFundingLockStatus: { data: districtLockStatus },
    funding: { error: errorFunding, saving, saved, fundingType, data = {}, savedData },
    stateFundingAndPricing: { data: stateFundingAndPricing },
    settingsDeadlines: { data: deadlinesData },
  } = state
  const {
    grantor = {},
    excludeOrgs = [],
    includeOrgs = [],
    purchaseOrderNumber,
    excludeCharterSchools,
    districtUserCreated,
    disableDistrictFunding,
    subsidies,
  } = data || {}
  const { examPricing = {} } = stateFundingAndPricing

  let includeOrgsData = []
  if (includeOrgs.length > 0) {
    includeOrgsData = includeOrgs
  } else {
    childOrgsData.forEach(({ orgId, orgName }) => {
      if (!excludeOrgs.find(excludedOrg => orgId === excludedOrg.orgId)) {
        includeOrgsData.push({
          orgId,
          orgName,
        })
      }
    })
  }

  const districtFundingLockDate = deadlinesData[educationPeriodCd]?.districtFundingLockDate
  const unlockExpiryDate = districtLockStatus?.unlockExpiryDate
  const fundingLockDate = isDateAfter(unlockExpiryDate, districtFundingLockDate)
    ? unlockExpiryDate
    : districtFundingLockDate
  const today = formatDate(new Date())
  const fundingLocked = isDateAfter(today, fundingLockDate)

  return {
    orgId,
    educationPeriodCd,
    fetchingChildOrgs,
    fetchedChildOrgs,
    errorChildOrgs,
    errorFunding,
    saving,
    saved,
    fundingType,
    grantor,
    includeOrgs: sortColumnByKey(includeOrgsData, ['orgName'], ['asc']),
    purchaseOrderNumber,
    excludeCharterSchools,
    subsidies,
    academicYear: availableEducationPeriods[educationPeriodCd]?.descr,
    hasSavedData: !isEmpty(savedData),
    dataHasChanged: !isEqual(savedData, {
      grantor: {
        grantorName: grantor?.grantorName,
        [CONTACT_FIELDS.fname.name]: grantor?.[CONTACT_FIELDS.fname.name],
        [CONTACT_FIELDS.lname.name]: grantor?.[CONTACT_FIELDS.lname.name],
        [CONTACT_FIELDS.phone.name]: grantor?.[CONTACT_FIELDS.phone.name],
        [CONTACT_FIELDS.email.name]: grantor?.[CONTACT_FIELDS.email.name],
        [CONTACT_FIELDS.addr1.name]: grantor?.[CONTACT_FIELDS.addr1.name],
        ...(!isEmpty(grantor?.[CONTACT_FIELDS.addr2.name])
          ? { [CONTACT_FIELDS.addr2.name]: grantor?.[CONTACT_FIELDS.addr2.name] }
          : {}),
        [CONTACT_FIELDS.city.name]: grantor?.[CONTACT_FIELDS.city.name],
        [CONTACT_FIELDS.state.name]: grantor?.[CONTACT_FIELDS.state.name],
        [CONTACT_FIELDS.country.name]: grantor?.[CONTACT_FIELDS.country.name],
        [CONTACT_FIELDS.zip.name]: grantor?.[CONTACT_FIELDS.zip.name],
      },
      excludeOrgs,
      ...(!isEmpty(purchaseOrderNumber) ? { purchaseOrderNumber } : {}),
      excludeCharterSchools,
      districtUserCreated,
      ...(!isEmpty(subsidies) ? { subsidies } : {}),
      disableDistrictFunding,
    }),
    canEdit: !fundingLocked,
    examPricing,
  }
}

const FundingConfiguration = ({
  orgId,
  educationPeriodCd,
  fetchingChildOrgs,
  fetchedChildOrgs,
  errorChildOrgs,
  errorFunding,
  saving,
  saved,
  fundingType,
  grantor,
  includeOrgs,
  purchaseOrderNumber,
  excludeCharterSchools,
  subsidies,
  academicYear,
  hasSavedData,
  dataHasChanged,
  canEdit,
  examPricing,
  changeStep,
  fetchChildOrgs,
  fetchStateFundingAndPricing,
}) => {
  const alertBeforeLeaving = e => {
    e.preventDefault()
    e.returnValue = ''
  }
  useEffect(() => {
    if (!saving) window.scroll(0, 0)
    if (!saved && dataHasChanged) {
      window.addEventListener('beforeunload', alertBeforeLeaving)
    }
    return () => {
      if (!saved && dataHasChanged) {
        window.removeEventListener('beforeunload', alertBeforeLeaving)
      }
    }
  }, [saved, dataHasChanged])

  useEffect(() => {
    if (isEmpty(includeOrgs) && !fetchingChildOrgs && !fetchedChildOrgs) {
      fetchChildOrgs(orgId)
    }
  }, [])

  useEffect(() => {
    if (fundingType === FUNDING_SUBSIDY && isEmpty(examPricing)) {
      fetchStateFundingAndPricing(orgId, educationPeriodCd)
    }
  }, [])

  const Heading = ({ title, step }) => (
    <div className="row">
      <div className="col-xs-12">
        <h2 className={`h3 ${styles['section-heading']}`}>{title}</h2>
        {canEdit ? (
          <button type="button" className="btn-link" onClick={() => changeStep(step)}>
            edit
          </button>
        ) : null}
      </div>
    </div>
  )

  return (
    <>
      {!hasSavedData || (hasSavedData && dataHasChanged) ? (
        <>
          <StepTracker />
          <p>
            Use this page to confirm the information captured around your funding is accurate. If
            you need to edit any information, do so now before finalization.
          </p>
        </>
      ) : (
        <p>You have completed your district funding plan for {academicYear} </p>
      )}
      <Heading title="Contact Information" step={1} />
      <dl>
        <div className={`row ${styles.section}`}>
          <div className="col-xs-2">
            <dt>First Name</dt>
            <dd>{grantor?.[CONTACT_FIELDS.fname.name]}</dd>
          </div>
          <div className="col-xs-4">
            <dt>Last Name</dt>
            <dd>{grantor?.[CONTACT_FIELDS.lname.name]}</dd>
          </div>
        </div>
        <div className={`row ${styles.section}`}>
          <div className="col-xs-2">
            <dt>Phone Number</dt>
            <dd>{formatPhoneNumber(grantor?.[CONTACT_FIELDS.phone.name])}</dd>
          </div>
          <div className="col-xs-4">
            <dt>Email</dt>
            <dd>{grantor?.[CONTACT_FIELDS.email.name]}</dd>
          </div>
        </div>
        {!isEmpty(purchaseOrderNumber) ? (
          <div className={`row ${styles.section}`}>
            <div className="col-xs-6">
              <dt>Purchase Order Number</dt>
              <dd>{purchaseOrderNumber}</dd>
            </div>
          </div>
        ) : null}
        <Heading title="Billing Address" step={1} />
        <div className={`row ${styles.section}`}>
          <div className="col-xs-6">
            <dt>Country</dt>
            <dd>{grantor?.[CONTACT_FIELDS.country.name]}</dd>
          </div>
        </div>
        <div className={`row ${styles.section}`}>
          <div className="col-xs-6">
            <dt>Address Line 1</dt>
            <dd>{grantor?.[CONTACT_FIELDS.addr1.name]}</dd>
          </div>
        </div>
        {!isEmpty(grantor?.[CONTACT_FIELDS.addr2.name]) ? (
          <div className={`row ${styles.section}`}>
            <div className="col-xs-6">
              <dt>Address Line 2</dt>
              <dd>{grantor?.[CONTACT_FIELDS.addr2.name]}</dd>
            </div>
          </div>
        ) : null}
        <div className={`row ${styles.section}`}>
          <div className="col-xs-6">
            <dt>City</dt>
            <dd>{grantor?.[CONTACT_FIELDS.city.name]}</dd>
          </div>
        </div>
        <div className={`row ${styles.section}`}>
          <div className="col-xs-2">
            <dt>State</dt>
            <dd>{grantor?.[CONTACT_FIELDS.state.name]}</dd>
          </div>
          <div className="col-xs-4">
            <dt>Zip</dt>
            <dd>{formatZip(grantor?.[CONTACT_FIELDS.zip.name])}</dd>
          </div>
        </div>
      </dl>
      <Heading title="Funded Schools" step={2} />
      <div className={`row ${styles.section}`}>
        {errorChildOrgs ? (
          <Error message={errorChildOrgs} />
        ) : (
          <div className={`col-xs-6 ${styles.schools}`}>
            {fetchingChildOrgs ? (
              <Loader />
            ) : (
              <ul>
                {includeOrgs.map(({ orgName, orgId }) => (
                  <li key={orgId}>{orgName}</li>
                ))}
              </ul>
            )}
            <dt>Exclude Charter Schools</dt>
            <dd>{excludeCharterSchools ? 'Yes' : 'No'}</dd>
          </div>
        )}
      </div>
      <Heading title="Funding Details" step={3} />
      <div className={`row ${styles.section}`} style={{ marginBottom: '24px' }}>
        <div className="col-xs-12">
          {fundingType === FUNDING_CENTRAL_BILL ? (
            <p>
              You have chosen <strong>Central Billing</strong> for {academicYear}.
            </p>
          ) : (
            <>
              <p>You have chosen to create one or more district subsidies for {academicYear}.</p>
              <dl>
                <SelectedSubsidiesTable
                  title="Reduced Fee Students"
                  examPricing={examPricing?.reducedFee}
                  subsidies={subsidies?.reducedFee}
                />
                <SelectedSubsidiesTable
                  title="Standard Fee Students"
                  examPricing={examPricing?.standardFee}
                  subsidies={subsidies?.standardFee}
                />
              </dl>
              <p>
                You have chosen to receive your invoice on{' '}
                {formatDate(subsidies?.invoiceAvailableDate, DATETIME_FORMATS.longMonthDayYear)}.
              </p>
            </>
          )}
        </div>
      </div>
      {errorFunding ? <Error message={errorFunding} /> : null}
      <StepButtons
        hideNextStep={!canEdit}
        deleteDistrictFunding={hasSavedData && !dataHasChanged && canEdit}
        nextButtonText={getNextButtonText(hasSavedData, dataHasChanged)}
        previousButtonText={hasSavedData && !dataHasChanged ? 'Back to Dashboard' : 'Previous'}
        previousStep={hasSavedData && !dataHasChanged ? 0 : 4}
        totalSteps={TOTAL_FUNDING_STEPS}
        formComplete={true}
      />
    </>
  )
}

export default connect(mapStateToProps, {
  changeStep,
  fetchChildOrgs,
  fetchStateFundingAndPricing,
})(FundingConfiguration)
