import { Link } from 'react-router-dom'
import { SETTINGS_PANELS } from '../../../constants/SettingsConstants'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId, isCoordinator, isSchoolAdmin, isPreAPOnly },
    },
    settingsOrg,
  } = state
  const { data = {}, fetched } = settingsOrg[selectedOrgId] || {}

  return {
    showNotification:
      (isCoordinator || isSchoolAdmin) &&
      fetched &&
      !data.participationForm?.coordinatorSigned &&
      !isPreAPOnly,
  }
}

const PartFormNotification = ({ showNotification = false }) =>
  showNotification ? (
    <div
      role="alert"
      className="alert alert-warning cb-alert-heading"
      style={{ marginTop: -21, border: 0, backgroundColor: 'rgb(250, 227, 200)' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <h2>Participation Form Required</h2>
            <p style={{ marginBottom: 0 }}>
              You have not completed your school&#39;s participation form. You will not be able to
              order exams until the participation form is complete.
            </p>
          </div>
          <div className="col-sm-4 text-right">
            <Link to={`/settings?scroll=${SETTINGS_PANELS.partForm}`} className="cb-base-font">
              <strong>Complete Participation Form</strong>
              <i
                className="cb-glyph cb-east"
                aria-hidden={true}
                style={{ marginLeft: 5, fontSize: '.9em' }}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : null

export default connect(mapStateToProps)(PartFormNotification)
