import PackingListStep2PanelBody from './PackingListStep2PanelBody'
import PackingListStep2Title from './PackingListStep2Title'
import { Accordions } from '../common'
import { sortColumnByKey, sortColumnByDate } from '../../utils/sort'

import packingStyles from '../../assets/style/scss/packing-details.scss'

const mapStateToProps = state => {
  const { packingListDetails } = state
  return {
    packingListItems: packingListDetails?.packingListItems || [],
  }
}

/**
 * Create accordions for packing list step 2.
 */
const panel = (
  courseName,
  courseDateTime,
  status,
  packingListId,
  packingListItemId,
  forPrinting
) => ({
  PanelBody: () => (
    <PackingListStep2PanelBody
      packingListItemId={packingListItemId}
      packingListId={packingListId}
    />
  ),
  id: packingListItemId,
  title: () => (
    <PackingListStep2Title
      courseName={courseName}
      courseDateTime={courseDateTime}
      status={status}
    />
  ),
  panelId: `PANEL_ID_${packingListItemId}`,
  openByDefault: forPrinting,
})

const PackingListStep2 = ({ packingListItems, packingListId, forPrinting = false }) => {
  // Sort packing list items by course date and then course name.
  return (
    <>
      <h4>Step 2 of 2: Confirm Administered Exams</h4>
      <div id={packingStyles.packingShipmentAccordionContainer}>
        <Accordions
          id={packingStyles.packingShipmentAccordion}
          panels={[
            ...sortColumnByDate(
              sortColumnByKey(packingListItems, 'courseName', 'asc'),
              'courseDateTime',
              'asc'
            ).map(({ courseName, courseDateTime, status, packingListItemId }) =>
              panel(
                courseName,
                courseDateTime,
                status,
                packingListId,
                packingListItemId,
                forPrinting
              )
            ),
          ]}
        />
      </div>
    </>
  )
}

export default connect(mapStateToProps)(PackingListStep2)
