import { Redirect } from 'react-router-dom'
import CoordinatorDashboard from '../../components/dashboard/CoordinatorDashboard'
import CSRDashboard from '../../components/dashboard/CSRDashboard'
import { NAV_LINKS } from '../../constants/NavConstants'
import { getDefaultRoute } from '../../components/routes/utils'

const mapStateToProps = state => {
  const { data } = state.user

  return {
    ...data,
    showCSRDashboard: data.isCSR && !data.selectedOrgId,
  }
}

export default connect(mapStateToProps)(props => {
  const { showCSRDashboard, isCoordinator, isCSR } = props
  const defaultRoute = getDefaultRoute(props)

  if (defaultRoute !== NAV_LINKS.dashboard)
    return <Redirect to={{ pathname: defaultRoute, search: window.location.search }} />

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      {showCSRDashboard ? (
        <CSRDashboard />
      ) : isCoordinator && !showCSRDashboard ? (
        <CoordinatorDashboard isCSR={isCSR} />
      ) : null}
    </CSSTransition>
  )
})
