import { getFormInitialValues, change } from 'redux-form'
import { Text, Select, ConfirmEmailFields } from '../../common'
import {
  AlphanumericValidation,
  LimitedStringValidation,
  AddressValidation,
  CityValidation,
  LetterValidation,
  USZipValidation,
} from '../../common/forms/Validations'
import {
  SCHOOL_INFORMATION_FIELDS as FIELDS,
  SCHOOL_INFORMATION_FORM,
} from '../../../constants/SetupConstants'

const states = APRICOT.data.states.map(s => ({
  value: s.value.toUpperCase(),
  label: s.name,
}))
const countries = APRICOT.data.countries.map(c => ({
  value: c.iso.toUpperCase(),
  label: c.name,
}))

const mapStateToProps = state => {
  const initialValues = getFormInitialValues(SCHOOL_INFORMATION_FORM)(state)
  return {
    initialValues,
  }
}

const BillingContactForm = props => {
  const { email, initialValues, change } = props
  const [isInternational, setIsInternational] = useState(
    initialValues.schoolInformation.billing.contactInformation.address.countryCode !== 'US'
  )
  const [postalCodeMaxLength, setPostalCodeMaxLength] = useState(isInternational ? 10 : 5)
  const [postalCodeValidation, setPostalCodeValidation] = useState(
    isInternational ? [AlphanumericValidation] : [USZipValidation]
  )
  useEffect(() => {
    // Determine the new validation and maxlength based on the selected country
    setPostalCodeValidation(isInternational ? [AlphanumericValidation] : [USZipValidation])
    setPostalCodeMaxLength(isInternational ? 10 : 5)
  }, [isInternational])

  const handleCountryChange = e => {
    const selectedCountry = e.target.value
    const zipCodeFieldName = FIELDS.BADDR_CODE.name
    setIsInternational(selectedCountry !== 'US')
    // Update the field's validation and maxlength
    change(SCHOOL_INFORMATION_FORM, FIELDS.BADDR_PROVINCE.name, null)
    change(SCHOOL_INFORMATION_FORM, FIELDS.BADDR_STATE.name, null)
    change(SCHOOL_INFORMATION_FORM, FIELDS.BADDR_COUNTRY.name, selectedCountry)
    change(SCHOOL_INFORMATION_FORM, zipCodeFieldName, '') // to clear the field
  }
  return (
    <fieldset>
      <div className="row">
        <div className="col-xs-5">
          <Text
            label="First Name"
            name={FIELDS.BILL_FNAME.name}
            placeholder="Enter first name"
            maxlength={20}
            isRequired={FIELDS.BILL_FNAME.required}
            validate={[LimitedStringValidation]}
          />
        </div>
        <div className="col-xs-2">
          <Text
            label="MI"
            name={FIELDS.BILL_MI.name}
            placeholder=" "
            maxlength={1}
            isRequired={FIELDS.BILL_MI.required}
            validate={[LetterValidation]}
          />
        </div>
        <div className="col-xs-5">
          <Text
            label="Last Name"
            name={FIELDS.BILL_LNAME.name}
            placeholder="Enter last name"
            maxlength={30}
            isRequired={FIELDS.BILL_LNAME.required}
            validate={[LimitedStringValidation]}
          />
        </div>
      </div>
      <ConfirmEmailFields
        label="Email"
        emailProps={FIELDS.BILL_EMAIL}
        commonPlaceholder="email address"
        showConfirmErrorOnLoad={Boolean(email)}
        maxlength={50}
      />
      <Text
        label="Street Address 1"
        name={FIELDS.BADDR_STR1.name}
        placeholder="Enter street address"
        maxlength={50}
        isRequired={FIELDS.BADDR_STR1.required}
        validate={[AddressValidation]}
      />
      <Text
        label="Street Address 2"
        name={FIELDS.BADDR_STR2.name}
        placeholder="Enter street address"
        maxlength={50}
        isRequired={FIELDS.BADDR_STR2.required}
        validate={[AddressValidation]}
      />
      <Text
        label="Street Address 3"
        name={FIELDS.BADDR_STR3.name}
        placeholder="Enter street address"
        maxlength={50}
        isRequired={FIELDS.BADDR_STR3.required}
        validate={[AddressValidation]}
      />
      <Text
        label="City"
        name={FIELDS.BADDR_CITY.name}
        placeholder="Enter city"
        maxlength={50}
        isRequired={FIELDS.BADDR_CITY.required}
        validate={[CityValidation]}
      />
      {isInternational ? (
        <Text
          label="Province"
          name={FIELDS.BADDR_PROVINCE.name}
          placeholder="Enter province"
          maxlength={32}
          isRequired={false}
          validate={[LimitedStringValidation]}
        />
      ) : (
        <Select
          name={FIELDS.BADDR_STATE.name}
          options={states}
          showEmptyOption={false}
          label="State"
          isRequired={true}
        />
      )}
      <Text
        label={isInternational ? 'Postal Code' : 'Zip Code'}
        name={FIELDS.BADDR_CODE.name}
        placeholder="Enter code"
        maxlength={postalCodeMaxLength}
        validate={postalCodeValidation}
        isRequired={FIELDS.BADDR_CODE.required}
      />
      <Select
        name={FIELDS.BADDR_COUNTRYCODE.name}
        options={countries}
        label="Country"
        isRequired={FIELDS.BADDR_COUNTRYCODE.required}
        onChange={handleCountryChange}
      />
    </fieldset>
  )
}

export default connect(mapStateToProps, { change })(BillingContactForm)
