import { Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import APLogo from '../../../../assets/images/CB_AP_Logo.png'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://atlas.collegeboard.org/apricot/release/3.4.0/fonts/8b97101b6c2bd9c2c34e85419d4ec253-roboto-regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: 'https://atlas.collegeboard.org/apricot/release/3.4.0/fonts/1585bb4125f8c3e1c7ae465ba7a6f27a-roboto-italic.ttf',
      fontStyle: 'italic',
    },
    {
      src: 'https://atlas.collegeboard.org/apricot/release/3.4.0/fonts/8894117a7309dcfe3540ab877b0764d8-roboto-bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  ],
})

const PAGE_TITLE_FONT_SIZE = 16
const GENERAL_FONT_SIZE = 12

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },
  imageContainer: {
    display: 'block',
  },
  logoImage: {
    width: 59,
    height: 26,
    marginBottom: 16,
  },
  titleContainer: {
    display: 'block',
  },
  formTitle: {
    fontSize: PAGE_TITLE_FONT_SIZE,
    fontWeight: 'bold',
    marginBottom: 14,
  },
  identifyingColumns: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    fontSize: GENERAL_FONT_SIZE,
    fontWeight: 'normal',
    marginBottom: 4,
  },
  column: {
    width: '50%',
  },
  columnItem: {
    marginBottom: 4,
  },
  columnItemLabel: {
    fontWeight: 'bold',
  },
  ssdDescription: {
    display: 'block',
    fontSize: GENERAL_FONT_SIZE,
    fontWeight: 'normal',
    marginBottom: 8,
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 'bold',
  },
  accommodations: {
    display: 'block',
    border: '2px solid #000000',
    fontSize: GENERAL_FONT_SIZE,
    marginBottom: 8,
    padding: 6,
  },
  accommodationsHeader: {
    fontWeight: 'bold',
    marginBottom: 6,
  },
  accommodationsList: {
    width: '100%',
  },
  accommodationsListColumns: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  accommodationItem: {
    width: '100%',
  },
  accommodationItemHalf: {
    width: '50%',
  },
  timingRecord: {
    display: 'block',
    border: '2px solid #000000',
    fontSize: GENERAL_FONT_SIZE,
    marginBottom: 20,
    padding: 6,
  },
  timingRecordHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  timingRecordHeader: {
    fontWeight: 'bold',
    marginBottom: 6,
  },
  timingRecordColumnHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  placeholderColumn: {
    width: '10%',
  },
  timingRecordColumnHeader: {
    width: '23%',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
  },
  tableColumn: {
    width: '11.5%',
    textAlign: 'center',
  },
  tableColumnLeft: {
    borderRight: '1px solid black',
  },
  tableRowHeader: {
    width: '10%',
    paddingRight: 2,
    textAlign: 'right',
  },
  tableCell: {
    width: '11.5%',
    borderBottom: '1px solid black',
    padding: 6,
  },
  noBottomBorder: {
    borderBottom: 'none',
  },
  asteriskNote: {
    marginTop: 8,
    marginLeft: 239,
    fontSize: 10,
  },
  signatureRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    fontSize: GENERAL_FONT_SIZE,
  },
  pageNumbers: {
    fontSize: 8,
    position: 'absolute',
    textAlign: 'center',
    left: 0,
    right: 0,
    bottom: 10,
  },
})

const NORMAL_AMOUNT = 20

function ExamNARForm({ data }) {
  const {
    studentId,
    firstName,
    lastName,
    aiCode,
    courseName,
    approvedSSDAccommodations,
    schoolNameAndAddress,
    examDate,
  } = data
  const { schoolName } = schoolNameAndAddress
  const dateGenerated = formatDate(new Date(), DATETIME_FORMATS.shortMonthDayYear)

  return (
    <Page size={[612.0, 792.0]} orientation="portrait" wrap style={styles.page}>
      <View style={styles.imageContainer}>
        <Image src={APLogo} style={styles.logoImage} />
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.formTitle}>2024 AP Nonstandard Administration Report (NAR)</Text>
      </View>
      <View style={styles.identifyingColumns}>
        <View style={styles.column}>
          <Text style={styles.columnItem}>
            <Text style={styles.columnItemLabel}>Date Generated:</Text> {dateGenerated}
          </Text>
          <Text style={styles.columnItem}>
            <Text style={styles.columnItemLabel}>School Code:</Text> {aiCode}
          </Text>
          <Text style={styles.columnItem}>
            <Text style={styles.columnItemLabel}>School:</Text> {schoolName}
          </Text>
        </View>
        <View style={styles.column}>
          <Text style={styles.columnItem}>
            <Text style={styles.columnItemLabel}>AP ID:</Text> {studentId}
          </Text>
          <Text style={styles.columnItem}>
            <Text style={styles.columnItemLabel}>Student Name:</Text> {firstName} {lastName}
          </Text>
          <Text style={styles.columnItem}>
            <Text style={styles.columnItemLabel}>Exam Date:</Text>{' '}
            {formatDate(examDate, 'MMM dd, yyyy')}
          </Text>
          <Text style={styles.columnItem}>
            <Text style={styles.columnItemLabel}>Exam Title:</Text> {courseName}
          </Text>
        </View>
      </View>
      <View style={styles.ssdDescription}>
        <Text>
          Services for Students with Disabilities (SSD) Coordinator or AP Coordinator: Please
          complete this report for each AP Exam taken by this student (paper exam, AP Chinese, or AP
          Japanese). The timing record only needs to be completed for students approved for extended
          time (refer to the extended time tables in the current{' '}
          <Text style={styles.italic}>AP Accommodations Guide</Text> to determine specific timing
          for each exam).
        </Text>
      </View>
      <View style={styles.accommodations}>
        <Text style={styles.accommodationsHeader}>Approved Accommodations</Text>
        {approvedSSDAccommodations.length > NORMAL_AMOUNT ? (
          <View style={styles.accommodationsListColumns}>
            {approvedSSDAccommodations.map((accommodation, index) => (
              <View key={index} style={styles.accommodationItemHalf}>
                <Text>&bull; {accommodation}</Text>
              </View>
            ))}
          </View>
        ) : (
          <View style={styles.accommodationsList}>
            {approvedSSDAccommodations.map((accommodation, index) => (
              <View key={index} style={styles.accommodationItem}>
                <Text>&bull; {accommodation}</Text>
              </View>
            ))}
          </View>
        )}
      </View>
      <View wrap={false} style={styles.timingRecord}>
        <View style={styles.timingRecordHeaderRow}>
          <View>
            <Text style={styles.timingRecordHeader}>Timing Record</Text>
          </View>
          <View>
            <Text style={styles.timingRecordHeader}>
              College Board-approved Total Testing Time: ______________
            </Text>
          </View>
        </View>
        <View style={styles.timingRecordColumnHeaderWrapper}>
          <View style={styles.placeholderColumn} />
          <View style={styles.timingRecordColumnHeader}>
            <Text>Section I Time</Text>
            <Text>(hours and minutes)</Text>
          </View>
          <View style={styles.placeholderColumn} />
          <View style={styles.timingRecordColumnHeader}>
            <Text>Section II Time</Text>
            <Text>(hours and minutes)</Text>
          </View>
          <View style={styles.placeholderColumn} />
          <View style={styles.timingRecordColumnHeader}>
            <Text>Extra or Extended</Text>
            <Text>Break Time</Text>
            <Text>(in minutes)</Text>
          </View>
        </View>
        <View style={styles.timingRecordColumnHeaderWrapper}>
          <View style={styles.placeholderColumn} />
          <View style={[styles.tableColumn, styles.tableColumnLeft]}>
            <Text style={styles.bold}>Approved</Text>
          </View>
          <View style={styles.tableColumn}>
            <Text style={styles.bold}>Used</Text>
          </View>
          <View style={styles.placeholderColumn} />
          <View style={[styles.tableColumn, styles.tableColumnLeft]}>
            <Text style={styles.bold}>Approved</Text>
          </View>
          <View style={styles.tableColumn}>
            <Text style={styles.bold}>Used</Text>
          </View>
          <View style={styles.placeholderColumn} />
          <View style={[styles.tableColumn, styles.tableColumnLeft]}>
            <Text style={styles.bold}>Approved</Text>
          </View>
          <View style={styles.tableColumn}>
            <Text style={styles.bold}>Used</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableRowHeader} />
          <View style={[styles.tableCell, styles.tableColumnLeft, styles.noBottomBorder]} />
          <View style={[styles.tableCell, styles.noBottomBorder]} />
          <View style={styles.tableRowHeader}>
            <Text>Reading</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft, styles.noBottomBorder]} />
          <View style={[styles.tableCell, styles.noBottomBorder]} />
          <View style={styles.tableRowHeader} />
          <View style={[styles.tableCell, styles.tableColumnLeft, styles.noBottomBorder]} />
          <View style={[styles.tableCell, styles.noBottomBorder]} />
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableRowHeader}>
            <Text>Part A:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
          <View style={styles.tableRowHeader}>
            <Text>Period*:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
          <View style={styles.tableRowHeader}>
            <Text>Extra 1:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableRowHeader}>
            <Text>Part B:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
          <View style={styles.tableRowHeader}>
            <Text>Part A:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
          <View style={styles.tableRowHeader}>
            <Text>Extra 2:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableRowHeader} />
          <View style={[styles.tableCell, styles.tableColumnLeft, styles.noBottomBorder]} />
          <View style={[styles.tableCell, styles.noBottomBorder]} />
          <View style={styles.tableRowHeader}>
            <Text>Part B:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
          <View style={styles.tableRowHeader}>
            <Text>Extra 3:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableRowHeader}>
            <Text>Total:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
          <View style={styles.tableRowHeader}>
            <Text>Total:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
          <View style={styles.tableRowHeader}>
            <Text>Total:</Text>
          </View>
          <View style={[styles.tableCell, styles.tableColumnLeft]} />
          <View style={styles.tableCell} />
        </View>
        <View style={styles.asteriskNote}>
          <Text>*if applicable</Text>
        </View>
      </View>
      <View style={styles.signatureRow}>
        <View>
          <Text>
            Proctor&#39;s Signature: __________________________________________________________
          </Text>
        </View>
        <View style={{ marginLeft: 20 }}>
          <Text>Date: __________________</Text>
        </View>
      </View>
      <Text
        style={styles.pageNumbers}
        render={({ subPageNumber, subPageTotalPages }) =>
          `${subPageNumber} of ${subPageTotalPages}`
        }
        fixed
      />
    </Page>
  )
}

export default ExamNARForm
