export const SET_FUNDING_STEP = 'SET_FUNDING_STEP'

export const FETCH_DISTRICT_FUNDING_DATA_PENDING = 'FETCH_DISTRICT_FUNDING_DATA_PENDING'
export const FETCH_DISTRICT_FUNDING_DATA_FULFILLED = 'FETCH_DISTRICT_FUNDING_DATA_FULFILLED'
export const FETCH_DISTRICT_FUNDING_DATA_REJECTED = 'FETCH_DISTRICT_FUNDING_DATA_REJECTED'

export const SAVE_DISTRICT_FUNDING_DATA_PENDING = 'SAVE_DISTRICT_FUNDING_DATA_PENDING'
export const SAVE_DISTRICT_FUNDING_DATA_FULFILLED = 'SAVE_DISTRICT_FUNDING_DATA_FULFILLED'
export const SAVE_DISTRICT_FUNDING_DATA_REJECTED = 'SAVE_DISTRICT_FUNDING_DATA_REJECTED'

export const SET_SAVED_DISTRICT_FUNDING_DATA = 'SET_SAVED_DISTRICT_FUNDING_DATA'

export const FETCH_CHILD_ORGS_PENDING = 'FETCH_CHILD_ORGS_PENDING'
export const FETCH_CHILD_ORGS_FULFILLED = 'FETCH_CHILD_ORGS_FULFILLED'
export const FETCH_CHILD_ORGS_REJECTED = 'FETCH_CHILD_ORGS_REJECTED'

export const FETCH_STATE_FUNDING_AND_PRICING_PENDING = 'FETCH_STATE_FUNDING_AND_PRICING_PENDING'
export const FETCH_STATE_FUNDING_AND_PRICING_FULFILLED = 'FETCH_STATE_FUNDING_AND_PRICING_FULFILLED'
export const FETCH_STATE_FUNDING_AND_PRICING_REJECTED = 'FETCH_STATE_FUNDING_AND_PRICING_REJECTED'

export const FETCH_DISTRICT_FUNDING_LOCK_DATE_PENDING = 'FETCH_DISTRICT_FUNDING_LOCK_DATE_PENDING'
export const FETCH_DISTRICT_FUNDING_LOCK_DATE_FULFILLED =
  'FETCH_DISTRICT_FUNDING_LOCK_DATE_FULFILLED'
export const FETCH_DISTRICT_FUNDING_LOCK_DATE_REJECTED = 'FETCH_DISTRICT_FUNDING_LOCK_DATE_REJECTED'

export const RESET_DISTRICT_FUNDING_DATA = 'RESET_DISTRICT_FUNDING_DATA'
export const SET_DISTRICT_FUNDING_CONTACT_INFO_DATA = 'SET_DISTRICT_FUNDING_CONTACT_INFO_DATA'
export const SET_DISTRICT_FUNDING_SCHOOL_SELECTION_DATA =
  'SET_DISTRICT_FUNDING_SCHOOL_SELECTION_DATA'
export const SET_DISTRICT_FUNDING_TYPE_DATA = 'SET_DISTRICT_FUNDING_TYPE_DATA'
export const SET_DISTRICT_FUNDING_SUBSIDY_DATA = 'SET_DISTRICT_FUNDING_SUBSIDY_DATA'

export const DELETE_DISTRICT_FUNDING_PENDING = 'DELETE_DISTRICT_FUNDING_PENDING'
export const DELETE_DISTRICT_FUNDING_FULFILLED = 'DELETE_DISTRICT_FUNDING_FULFILLED'
export const DELETE_DISTRICT_FUNDING_REJECTED = 'DELETE_DISTRICT_FUNDING_REJECTED'

export const TOTAL_FUNDING_STEPS = 5

export const FUNDING_CENTRAL_BILL = 'CENTRAL_BILLING'
export const FUNDING_SUBSIDY = 'SUBSIDIES'

export const CONTACT_INFORMATION_FORM_NAME = 'ContactInformationFundingForm'
export const CONTACT_INFORMATION_FORM_FIELDS = {
  fname: { name: 'firstName', required: true },
  lname: { name: 'lastName', required: true },
  phone: { name: 'phone', required: true },
  email: { name: 'email', required: true },
  ponumber: { name: 'purchaseOrderNumber', required: false },
  country: { name: 'country', required: true },
  addr1: { name: 'addr1', required: true },
  addr2: { name: 'addr2', required: false },
  city: { name: 'city', required: true },
  state: { name: 'state', required: true },
  zip: { name: 'zip', required: true },
}

export const SCHOOL_SELECTION_FORM_NAME = 'SchoolSelectionFundingForm'
export const SCHOOL_SELECTION_FORM_FIELDS = {
  excludeOrgs: { name: 'excludeOrgs', required: false },
  includeOrgs: { name: 'includeOrgs', required: true },
  excludeCharterSchools: { name: 'excludeCharterSchools', required: false },
}

export const FUNDING_CENTRAL_BILL_CONFIRMATION_FORM_NAME = 'confirmCentralBillFundingForm'
export const FUNDING_CENTRAL_BILL_CONFIRMATION_FORM_FIELDS = {
  confirmCentralBilling: { name: 'confirmCentralBilling', required: true },
}

export const FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME = 'districtFundingSubsidyConfigurationForm'
export const FUNDING_SUBSIDY_CONFIGURATION_FORM_FIELDS = {
  regularFeeReducedName: { name: 'regularFeeReducedName', required: true },
  regularFeeReducedId: { name: 'regularFeeReducedId', required: false },
  regularFeeReducedAmount: { name: 'regularFeeReducedAmount', required: false },
  regularFeeReducedMustTake: { name: 'regularFeeReducedMustTake', required: false },
  regularFeeReducedCancellationFeesActive: { name: 'regularFeeReducedCancellationFeesActive', required: false },
  regularFeeReducedCancellationFeesId: { name: 'regularFeeReducedCancellationFeesId', required: false },
  regularFeeReducedLateOrderFeesActive: { name: 'regularFeeReducedLateOrderFeesActive', required: false },
  regularFeeReducedLateOrderFeesId: { name: 'regularFeeReducedLateOrderFeesId', required: false },
  regularFeeReducedLateTestingFeesActive: { name: 'regularFeeReducedLateTestingFeesActive', required: false },
  regularFeeReducedLateTestingFeesId: { name: 'regularFeeReducedLateTestingFeesId', required: false },
  capstoneFeeReducedName: { name: 'capstoneFeeReducedName', required: true },
  capstoneFeeReducedId: { name: 'capstoneFeeReducedId', required: false },
  capstoneFeeReducedAmount: { name: 'capstoneFeeReducedAmount', required: false },
  capstoneFeeReducedMustTake: { name: 'capstoneFeeReducedMustTake', required: false },
  capstoneFeeReducedCancellationFeesActive: { name: 'capstoneFeeReducedCancellationFeesActive', required: false },
  capstoneFeeReducedCancellationFeesId: { name: 'capstoneFeeReducedCancellationFeesId', required: false },
  capstoneFeeReducedLateOrderFeesActive: { name: 'capstoneFeeReducedLateOrderFeesActive', required: false },
  capstoneFeeReducedLateOrderFeesId: { name: 'capstoneFeeReducedLateOrderFeesId', required: false },
  capstoneFeeReducedLateTestingFeesActive: { name: 'capstoneFeeReducedLateTestingFeesActive', required: false },
  capstoneFeeReducedLateTestingFeesId: { name: 'capstoneFeeReducedLateTestingFeesId', required: false },
  regularStandardFeeName: { name: 'regularStandardFeeName', required: true },
  regularStandardFeeId: { name: 'regularStandardFeeId', required: false },
  regularStandardFeeAmount: { name: 'regularStandardFeeAmount', required: false },
  regularStandardFeeMustTake: { name: 'regularStandardFeeMustTake', required: false },
  regularStandardFeeCancellationFeesActive: { name: 'regularStandardFeeCancellationFeesActive', required: false },
  regularStandardFeeCancellationFeesId: { name: 'regularStandardFeeCancellationFeesId', required: false },
  regularStandardFeeLateOrderFeesActive: { name: 'regularStandardFeeLateOrderFeesActive', required: false },
  regularStandardFeeLateOrderFeesId: { name: 'regularStandardFeeLateOrderFeesId', required: false },
  regularStandardFeeLateTestingFeesActive: { name: 'regularStandardFeeLateTestingFeesActive', required: false },
  regularStandardFeeLateTestingFeesId: { name: 'regularStandardFeeLateTestingFeesId', required: false },
  capstoneStandardFeeName: { name: 'capstoneStandardFeeName', required: true },
  capstoneStandardFeeId: { name: 'capstoneStandardFeeId', required: false },
  capstoneStandardFeeAmount: { name: 'capstoneStandardFeeAmount', required: false },
  capstoneStandardFeeMustTake: { name: 'capstoneStandardFeeMustTake', required: false },
  capstoneStandardFeeCancellationFeesActive: { name: 'capstoneStandardFeeCancellationFeesActive', required: false },
  capstoneStandardFeeCancellationFeesId: { name: 'capstoneStandardFeeCancellationFeesId', required: false },
  capstoneStandardFeeLateOrderFeesActive: { name: 'capstoneStandardFeeLateOrderFeesActive', required: false },
  capstoneStandardFeeLateOrderFeesId: { name: 'capstoneStandardFeeLateOrderFeesId', required: false },
  capstoneStandardFeeLateTestingFeesActive: { name: 'capstoneStandardFeeLateTestingFeesActive', required: false },
  capstoneStandardFeeLateTestingFeesId: { name: 'capstoneStandardFeeLateTestingFeesId', required: false },
  invoiceDate: { name: 'invoiceAvailableDate', required: true },
}

export const OTHER_NONE = 'NONE'
export const LATE_ORDER = 'LATE_ORDER'
export const LATE_TESTING = 'LATE_TESTING'
export const CANCELLATION = 'CANCELLATION'

export const OTHER_FEES = {
  [LATE_ORDER]: 'Late Ordering Fees',
  [LATE_TESTING]: 'Late-Testing Fees',
  [CANCELLATION]: 'Cancellations Fees',
}
