import { Link } from 'react-router-dom'
import { numberFormat } from '../../utils/numbers'
import { formatter, getQuantityLink, suppressQuantity } from './utils'

import styles from '../../assets/style/scss/invoices.scss'

export default ({ data }) =>
  data.map((d, i) => {
    const quantity = d.quantity ? numberFormat(d.quantity) : ''
    const link = getQuantityLink(d)

    return (
      <tr key={`${d.product}_${i}`} className={d.dirty ? styles.highlight : ''}>
        <th scope="row">
          {d.description}
          {d.dirty ? (
            <span className="sr-only">
              You made changes that impacted {d.description}. These changed will be reflected in a
              new PDF invoice.
            </span>
          ) : null}
        </th>
        <td>{link ? <Link to={link}>{quantity}</Link> : !suppressQuantity(d) ? quantity : ''}</td>
        <td>{formatter(d.unitPrice)}</td>
        <td>{formatter(d.amount)}</td>
      </tr>
    )
  })
