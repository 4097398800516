import { iam } from '@myap/ui-library/esm/profile'
import { finishCloseModal, startCloseModal } from '../../actions/app'
import { Loader } from '.'

const ModalHeader = ({ title, showCloseButton }) => (
  <div className="modal-header">
    {showCloseButton ? (
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    ) : null}
    {title ? <h4 className="modal-title">{title}</h4> : null}
  </div>
)

// actions = { isDismissable, onClick, isPrimary, buttonLabel, isDisabled }
const ModalFooter = ({ actions, unsetModalIsCancelled }) => (
  <div className="modal-footer">
    {actions.map((a, i) => {
      const dismissable = a.isDismissable ? { 'data-dismiss': 'modal' } : {}
      return (
        <button
          type="button"
          key={i}
          onClick={e => {
            if (!a.onClick) return
            unsetModalIsCancelled()
            a.onClick(e)
          }}
          className={`btn btn-sm ${a.className || ''} ${
            a.isPrimary ? 'btn-primary' : 'btn-secondary'
          }`}
          {...dismissable}
          disabled={a.isDisabled}
          style={{ position: 'relative' }}
        >
          {a.buttonLabel}
          {a.busy && (
            <Loader size="sm" style={{ position: 'absolute', top: '12px', right: '10px' }} />
          )}
        </button>
      )
    })}
  </div>
)

const mapStateToProps = state => ({ modal: state.app.modal, breakpoint: state.app.breakpoint.name })

class Modal extends Component {
  constructor(props) {
    super()
    this.modalName = props.modal.name
    this.modalIsCancelled = true
    this.hideModal = this.triggerModalClose.bind(this)
  }

  componentDidMount() {
    const { finishCloseModal, onCloseAction, onCancelOnlyAction, isDismissable = true } = this.props
    const {
      getEventBus,
      events: { Logout },
      getAuthSession,
    } = iam()
    const { isLoggedIn } = getAuthSession()
    const bus = getEventBus()
    const modalOptions = !isDismissable ? { backdrop: 'static', keyboard: false } : {}

    this.$modal.modal(modalOptions)
    this.$modal.on(
      'hidden.bs.modal',
      function (e) {
        onCloseAction && onCloseAction() // Called every time a modal is dismissed
        // add delay and reset pointer to element in case component where element exists is rerendering
        if (this.props.modalCloseFocusElem)
          setTimeout(() => {
            const refreshedElement =
              document.getElementById(this.props.modalCloseFocusElem?.id) ||
              this.props.modalCloseFocusElem
            refreshedElement.focus()
          }, 250)
        finishCloseModal(this.modalName)
      }.bind(this)
    )

    this.$modal.on(
      'hide.bs.modal',
      function (e) {
        this.modalIsCancelled && onCancelOnlyAction && onCancelOnlyAction() // Called only if onCancelOnlyAction exists and dismissing action does not have an associated click value
      }.bind(this)
    )

    this.IOS11WorkAroundForInputFocusBug(true)

    bus.on(Logout, this.hideModal)
  }

  shouldComponentUpdate(nextProps) {
    return !nextProps.modal.transitioning
  }

  componentDidUpdate() {
    const { startCloseModal, shouldCloseModal, children } = this.props
    if (shouldCloseModal) {
      startCloseModal()
      this.hideModal()
    }
  }

  componentWillUnmount() {
    this.hideModal()
    this.IOS11WorkAroundForInputFocusBug()
  }

  triggerModalClose() {
    this.$modal?.modal('hide')
  }

  IOS11WorkAroundForInputFocusBug(show) {
    const { breakpoint } = this.props
    // https://stackoverflow.com/questions/46339063/ios-11-safari-bootstrap-modal-text-area-outside-of-cursor
    const smallBreakpoint = breakpoint === 'mobile' || breakpoint === 'tablet'
    document.body.style.position = show && smallBreakpoint ? 'fixed' : 'relative'
  }

  unsetModalIsCancelled() {
    this.modalIsCancelled = false
  }

  render() {
    const {
      headerTitle,
      children,
      footerActions,
      noHeader,
      hideCloseButton,
      isVideoModal,
      modalStyles = {},
      modalClass,
    } = this.props
    const bodyClasses = `modal-content${!footerActions ? ' cb-no-footer' : ''}${
      noHeader ? ' cb-no-header' : ''
    }`

    return (
      <div
        className={`modal fade cb-modal-animation ${isVideoModal ? 'cb-video-modal' : ''}`}
        role="dialog"
        aria-modal="true"
        aria-label={headerTitle}
        aria-describedby="modalBody"
        tabIndex="-1"
        ref={modal => {
          this.$modal = $(modal)
        }}
      >
        <div className={`modal-dialog ${modalClass || ''}`} role="document" style={modalStyles}>
          <div className={bodyClasses}>
            {!noHeader ? (
              <ModalHeader title={headerTitle} showCloseButton={!hideCloseButton} />
            ) : null}

            <div className="modal-body" id="modalBody">
              {children}
            </div>

            {footerActions ? (
              <ModalFooter
                actions={footerActions}
                unsetModalIsCancelled={this.unsetModalIsCancelled.bind(this)}
              />
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, { startCloseModal, finishCloseModal })(Modal)
