import { getRoom } from './utils'
import { openModal } from '../../actions/app'
import { unassignStudents } from '../../actions/examRoom'
import { plural } from '../../utils/common'

import styles from '../../assets/style/scss/exam-room.scss'


const mapStateToProps = (state, { roomId }) => {
  const { examRoomStudents, examRoomSchedule, settingsEducationPeriod, user } = state
  const room = getRoom(examRoomSchedule.data, roomId)
  const { [roomId]: students = [], saving, selectedStudents } = examRoomStudents

  return {
    room,
    numOfStudents: students.length,
    saving,
    selectedStudents,
    orgId: user.data.selectedOrgId,
    educationPeriod: parseInt(settingsEducationPeriod.selectedEducationPeriod, 10),
  }
}

const ExamRoomActionBar = props => {
  const addStudentsBtnRef = useRef(null)
  const moveStudentsBtnRef = useRef(null)
  const { openModal, room, unassignStudents, selectedStudents, numOfStudents, saving } = props
  const numOfSelections = selectedStudents.length

  return (
    <div className={`${styles['exam-room-action-bar']} row`}>
      <div className="col-sm-9" aria-live="polite" role="region" id="exam-room-action-bar">
        {numOfSelections ? (
          <>
            <strong>
              {numOfSelections} {plural(numOfSelections, 'Student')} Selected
            </strong>
            <button
              type="button"
              className="btn btn-link cb-small-font-size"
              disabled={saving}
              onClick={() => unassignStudents(props)}
            >
              <i className="cb-glyph cb-minus" aria-hidden={true} /> Remove{' '}
              {plural(numOfSelections, 'Student')}
            </button>
            <button
              type="button"
              className="btn btn-link cb-small-font-size"
              disabled={saving}
              aria-haspopup="dialog"
              onClick={() =>
                openModal('ExamRoomMoveModal', {
                  room,
                  modalCloseFocusElem: moveStudentsBtnRef.current,
                })
              }
            >
              <i className="cb-glyph cb-compose" aria-hidden={true} /> Change Room Assignment
            </button>
          </>
        ) : (
          <>
            <strong>{numOfStudents}</strong> of <strong>{room.totalCapacity}</strong>{' '}
            {plural(room.totalCapacity, 'Seat')} Assigned
          </>
        )}
      </div>
      <div className="col-sm-3">
        <button
          type="button"
          className="btn btn-link cb-small-font-size"
          ref={addStudentsBtnRef}
          disabled={saving || room.totalCapacity === room.assignedCapacity}
          aria-haspopup="dialog"
          onClick={() =>
            openModal('ExamRoomAssignStudentsModal', {
              ...room,
              modalCloseFocusElem: addStudentsBtnRef.current,
            })
          }
        >
          <i className="cb-glyph cb-plus" aria-hidden={true} /> Add Students
        </button>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  { openModal, unassignStudents }
)(ExamRoomActionBar)
