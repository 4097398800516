import { profile, isChromebook, isMobileDevice } from '@myap/ui-library/esm/profile'
import { Modal } from '../../components/common'
import CoordinatorPracticePicker from '../../components/examReadiness/coordinator/CoordinatorPracticePicker'
import { provisionPractice } from '../../actions/examReadiness'

const ProvisionPracticeModal = connect(state => ({
  orgId: state.user.data.selectedOrgId,
  educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
}))(({ modalCloseFocusElem, courseName, testCode, orgId, educationPeriod, defaultStep }) => {
  const [step, setStep] = useState(defaultStep || 1)
  const [selectedCourse, setSelectedCourse] = useState({ testCode, courseName })
  const [provisioning, setProvisioning] = useState(false)
  const [error, setError] = useState(null)
  const hideDownloadAppLink = isChromebook() || isMobileDevice()

  const provision = async () => {
    setProvisioning(true)
    const { error, success } = await provisionPractice(
      selectedCourse.testCode,
      orgId,
      educationPeriod
    )
    if (success) setStep(3)
    else if (error) setError(error)
    setProvisioning(false)
  }
  const getFooterActions = () => {
    const CANCEL = { buttonLabel: 'Cancel', isDismissable: true, isDisabled: provisioning }
    const PRIMARY = {
      isPrimary: true,
      isDisabled: provisioning || !selectedCourse.testCode,
      busy: provisioning,
    }

    switch (step) {
      case 1:
        return [CANCEL, { ...PRIMARY, buttonLabel: 'Next', onClick: () => setStep(2) }]
      case 2:
        return [
          CANCEL,
          {
            ...PRIMARY,
            buttonLabel: 'Take Digital Practice',
            onClick: provision,
          },
        ]
      default:
        return [{ ...PRIMARY, buttonLabel: 'Close', isDismissable: true }]
    }
  }

  const getModalContent = () => {
    switch (step) {
      case 1:
        return (
          <CoordinatorPracticePicker
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
          />
        )
      case 2:
        return (
          <>
            <p>
              You are about to be sent a digital practice exam for{' '}
              <strong>{selectedCourse.courseName}</strong>. If you have a practice in progress, it
              will be reset. Please note that the questions for a given course do not change when
              reset.
            </p>
            <p>Do you want to continue?</p>
          </>
        )
      default:
        return (
          <>
            <p>
              An <strong>{selectedCourse.courseName}</strong> digital practice exam is being sent to
              the app. It may take a few minutes for the digital testing app to update. If you
              already have the app open, close and reopen it.
            </p>
            <p>
              If you have not done so already,{' '}
              {hideDownloadAppLink ? (
                'download the app'
              ) : (
                <a
                  href={profile().studentDigitalAppUrl}
                  target="_blank"
                  rel="noreferrer"
                  title="Download the app (Opens in a new window)"
                >
                  download the app
                </a>
              )}{' '}
              in order to access your digital practice exam.
            </p>
          </>
        )
    }
  }

  return (
    <Modal
      modalCloseFocusElem={modalCloseFocusElem}
      headerTitle="Take Digital Practice"
      footerActions={getFooterActions()}
    >
      <>
        {getModalContent()}
        {error ? (
          <p className="cb-error-msg">
            An error occurred setting up your digital practice. Please try again.
          </p>
        ) : null}
      </>
    </Modal>
  )
})

export default ProvisionPracticeModal
