import { Dropdown } from '../../common'
import TakingExamDecisionMenu from './TakingExamDecisionMenu'
import { openModal } from '../../../actions/app'
import { dropSectionStudent, moveSectionStudent } from '../../../actions/studentsBySection'

import stylesheet from '../../../assets/style/scss/section-details-table.scss'
import { getExamWindowsForCourse } from '../../../selectors/examWindows'
import DisplayExamDateTime from '../../common/DisplayExamDateTime'
import { SEE_SUBMISSION_DEADLINE_MSG } from '../../../constants/StudentConstants'

const mapStateToProps = (state, { student }) => {
  const { sectionId, adminWindow } = student
  const section = state.courses.entities.sections[sectionId]
  const { testCd } = section
  const currentCourseWindow = getExamWindowsForCourse(state, testCd)?.[adminWindow] ?? {}
  return {
    section,
    currentCourseWindow,
    teacherCanManageRoster: section.teacherCanManageRoster,
  }
}
export class StudentEnrollmentRow extends Component {
  constructor(props) {
    super(props)
    this.changeSection = this.changeSection.bind(this)
    this.dropStudent = this.dropStudent.bind(this)
  }

  changeSection(e) {
    const { moveSectionStudent, section } = this.props
    this.openActionModal(e, 'ChangeSectionModal', {
      section: { name: section.sectionName },
      action: moveSectionStudent,
    })
  }

  dropStudent(e) {
    const { dropSectionStudent } = this.props
    this.openActionModal(e, 'DropStudentModal', {
      action: dropSectionStudent,
    })
  }

  openActionModal(e, type, options) {
    const { openModal, student, section } = this.props

    e.preventDefault()
    openModal(type, {
      ...options,
      exam: { ...student, examId: student.studentId },
      courseName: section.courseName,
      studentName: `${student.firstName} ${student.lastName}`,
      storeLocation: 'studentsBySection',
      modalCloseFocusElem: this.modalCloseFocusElem,
    })
  }

  ActionDropdown() {
    const {
      student: { studentId },
    } = this.props
    return {
      label: 'Actions',
      id: studentId,
      menuItems: [
        { label: 'Change Section', clickEvent: this.changeSection },
        { label: 'Drop Student', clickEvent: this.dropStudent },
      ],
    }
  }

  render() {
    const { student, isFullAccessCourse, teacherCanManageRoster, currentCourseWindow } = this.props
    const { lastName, firstName, studentId, emailAddress } = student
    const { noEndOfCourseExam } = currentCourseWindow
    return (
      <tr>
        <th scope="row">
          <div>
            {firstName} {lastName}
          </div>
          <div>
            {emailAddress ? (
              <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            ) : (
              <em>No email address provided</em>
            )}
          </div>
          {studentId ? <div>AP ID: {studentId}</div> : null}
        </th>
        {isFullAccessCourse ? (
          <td className={stylesheet['taking-exam']}>
            <TakingExamDecisionMenu student={student} />
          </td>
        ) : null}
        {isFullAccessCourse ? (
          <td className={stylesheet['exam-date']}>
            {noEndOfCourseExam ? (
              <>{SEE_SUBMISSION_DEADLINE_MSG}</>
            ) : (
              <DisplayExamDateTime
                course={currentCourseWindow}
                iconAdjustment={0}
                displayWithTime={true}
              />
            )}
          </td>
        ) : null}
        {teacherCanManageRoster ? (
          <td
            className={stylesheet.actions}
            ref={node =>
              (this.modalCloseFocusElem = node && node.querySelectorAll('.dropdown-toggle')[0])
            }
          >
            <Dropdown {...this.ActionDropdown()} rightAligned={true} />
          </td>
        ) : null}
      </tr>
    )
  }
}

export default connect(mapStateToProps, { openModal, dropSectionStudent, moveSectionStudent })(
  StudentEnrollmentRow
)
