/*
 * AppConstants
 * These are the variables that determine what our central data store (reducer.js)
 * changes in our state. When you add a new action, you have to add a new constant here
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YOUR_ACTION_CONSTANT';
 */

export const MODAL_OPEN 	= 'MODAL_OPEN'
export const MODAL_START_CLOSE = 'START_MODAL_CLOSE'
export const MODAL_FINISH_CLOSE = 'FINISH_MODAL_CLOSE'

export const GENERIC_APP_ERROR = 'GENERIC_APP_ERROR'

export const BREAKPOINT_CHANGE = 'BREAKPOINT_CHANGE'

export const ADD_FETCH_ACTION = 'ADD_FETCH_ACTION'
export const REMOVE_FETCH_ACTION = 'REMOVE_FETCH_ACTION'

export const SITECODE = 'apfym' //analytics, GH

export const APP_ID = 366 //analytics, GH, Identity

export const LOCAL_NAVIGATION_HEIGHT = 67