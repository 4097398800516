import PhoneNumber from '../common/forms/PhoneNumber'
import {
  Text,
  PhoneValidation,
  EmailValidation,
  LimitedStringValidation,
} from '../common'
import { convertToNumberString } from '../../utils/numbers'
import {
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
} from '../../constants/IncidentReportConstants'
import {
  DOMESTIC_PHONE_LENGTH,
  INTERNATIONAL_PHONE_LENGTH,
} from '../../constants/SettingsConstants'

function IncidentReportCoordinatorContactFields({ countryCode }) {
  const isDomestic = countryCode === 'US'
  return (
    <div className="row">
      <div className="col-sm-6 col-xs-12">
        <h3>AP Coordinator Contact Information</h3>
        <fieldset>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <Text
                name={SHARED_FIELDS.firstName.name}
                isRequired={SHARED_FIELDS.firstName.required}
                label="First Name"
                validate={[LimitedStringValidation]}
                maxlength={20}
              />
            </div>
            <div className="col-sm-6 col-xs-12">
              <Text
                name={SHARED_FIELDS.lastName.name}
                isRequired={SHARED_FIELDS.lastName.required}
                label="Last Name"
                validate={[LimitedStringValidation]}
                maxlength={30}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <PhoneNumber
                name={SHARED_FIELDS.phone.name}
                isRequired={SHARED_FIELDS.phone.required}
                label="Summer Phone Number"
                placeholder={isDomestic ? 'Enter a 10 digit number' : 'Enter telephone number'}
                validate={[PhoneValidation]}
                shouldFormat={true}
                countryCode={countryCode}
                normalize={convertToNumberString}
                maxlength={isDomestic ? DOMESTIC_PHONE_LENGTH : INTERNATIONAL_PHONE_LENGTH}
              />
            </div>
            <div className="col-sm-6 col-xs-12">
              <Text
                name={SHARED_FIELDS.email.name}
                isRequired={SHARED_FIELDS.email.required}
                label="Email Address"
                validate={[EmailValidation]}
              />
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  )
}

export default IncidentReportCoordinatorContactFields