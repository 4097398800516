import { encryptID, fireAnalyticsEvent } from '@myap/ui-library/esm/analytics'
import { UPDATE_TYPE_INTENT, STUDENT_ENROLLMENT_STATUS_YES } from '../constants/StudentConstants'
import { AppcuesPage } from './appcues'

const SET_TESTING = false
const DISABLE_EVENTS = false

const EVENT_EXAM_REG = 'examReg'

const examIntentChangeEvent = ({ exam, getState }) => {
  // Only track when examIntent changes to YES
  if (exam?.examIntent !== STUDENT_ENROLLMENT_STATUS_YES) {
    return
  }
  const {
    user: {
      data: { isCoordinator, isTeacher, selectedRole, roles },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
  } = getState()
  const role = roles[selectedRole]
  fireAnalyticsEvent({
    testing: SET_TESTING,
    disabled: DISABLE_EVENTS,
    eventKey: 'track-event',
    eventData: {
      appUser: {
        role: isCoordinator ? 'coordinator' : isTeacher ? 'teacher' : 'other',
        org: {
          code: role?.orgId,
          name: role?.orgName,
        },
      },
      appPage: {
        domain: 'educator',
        page: '',
        view: '',
        group: '',
      },
      myApDataEvents: [
        {
          events: [EVENT_EXAM_REG],
          studentId: encryptID(exam.studentId),
          courseId: exam.testCd,
          epc: educationPeriodCd,
          courseOrgId: role?.orgId,
        },
      ],
    },
  })
}

export const analyticsExamChangeEvent = ({ exam, type, studentAfterUpdate, getState }) => {
  if (type === UPDATE_TYPE_INTENT) {
    examIntentChangeEvent({ exam, getState })
  }
}

export const analyticsTermsAndConditionsEvent = () => {
  fireAnalyticsEvent({
    testing: SET_TESTING,
    disabled: DISABLE_EVENTS,
    eventData: {
      appUser: {},
      appPage: {
        domain: '',
        page: 'terms',
        view: '',
        group: '',
      },
    },
  })
}

let prevLocation = {}
const startPageLoadListener = (location, store) => {
  const { pathname, search } = location
  const { pathname: prevPath, search: prevSearch } = prevLocation

  prevLocation = location

  // *** Tell Appcues when page change happens
  if (pathname !== prevPath) {
    AppcuesPage()
  }
}

export const startTracking = (history, store) => {
  startPageLoadListener(history.location, store)
}
