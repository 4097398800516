import { isPristine } from 'redux-form'
import {
  WelcomeScreen,
  Participation,
  ExamDecisionIndicator,
  RosterManagement,
} from '../../components/setup'
import { CoordinatorSecondaryNavigation } from '../../components/navigation'
import { Loader } from '../../components/common'
import CoordinatorSetupSchoolInfoForm from '../forms/CoordinatorSetupSchoolInfoForm'
import CoordinatorSetupExamAdminForm from '../forms/CoordinatorSetupExamAdminForm'
import { changeStep } from '../../actions/settingsOrg'
import {
  SCHOOL_INFORMATION_FORM,
  EXAM_ADMINISTRATION_FORM,
  EXAM_DECISION_INDICATOR_FORM,
  ROSTER_MANAGEMENT_FORM,
} from '../../constants/SetupConstants'

const findSetupStep = ({ schoolInformation = {}, examAdministration = {} }, step) => {
  let returnStep = 0
  if (!schoolInformation.complete) {
    if (step) {
      returnStep = 1
    }
  } else if (!examAdministration.complete) {
    returnStep = 2
  } else if (!step) {
    returnStep = 3
  } else if (step >= 3) {
    returnStep = step
  }
  return returnStep
}

const mapStateToProps = (state, ownProps) => {
  const { selectedOrgId, selectedRole, roles, isCoordinator } = state.user.data
  const { selectedEducationPeriod } = state.settingsEducationPeriod
  const isMultiOrg = roles.length > 1
  const { setupStep, [selectedOrgId]: settings = {} } = state.settingsOrg
  const isLevelOne =
    roles[selectedRole].level === 1 || settings?.data?.examAdministration?.orderByAnotherSchool
  const setupFormFlow = isLevelOne
    ? {
        1: SCHOOL_INFORMATION_FORM,
        2: EXAM_ADMINISTRATION_FORM,
        3: ROSTER_MANAGEMENT_FORM,
      }
    : {
        1: SCHOOL_INFORMATION_FORM,
        2: EXAM_ADMINISTRATION_FORM,
        3: EXAM_DECISION_INDICATOR_FORM,
        4: ROSTER_MANAGEMENT_FORM,
      }

  return {
    breakpoint: state.app.breakpoint.name,
    hasFormChanged: !isPristine(setupFormFlow[setupStep])(state),
    selectedOrgId,
    isLevelOne,
    setupFormFlow,
    educationPeriodCd: selectedEducationPeriod,
    isMultiOrg,
    setupStep,
    ...settings,
  }
}

class CoordinatorSetup extends Component {
  componentDidMount() {
    this.findCorrectStep()
  }

  componentDidUpdate(prevProps) {
    const { fetching, fetched, selectedOrgId, educationPeriodCd, fetchOrgSettings, data } =
      this.props

    // if(!fetching
    //    && (prevProps.selectedOrgId !== selectedOrgId
    //      || prevProps.educationPeriodCd !== educationPeriodCd
    //      || !fetched)
    //  ) {
    //  fetchOrgSettings(selectedOrgId, educationPeriodCd)
    // }

    if (prevProps.data !== data) {
      this.findCorrectStep()
    }
  }

  findCorrectStep() {
    const { changeStep, setupStep, data = {}, educationPeriodCd } = this.props
    if (educationPeriodCd === data.educationPeriodCd) {
      const newStep = findSetupStep(data, setupStep)

      if (setupStep === null || newStep !== setupStep) changeStep(newStep)
    }
  }

  renderStep() {
    const { setupStep, fetching, data = {}, selectedOrgId, isMultiOrg, isLevelOne } = this.props

    if (fetching && setupStep) return null

    if (fetching && !isMultiOrg) return <Loader />

    if (isLevelOne) {
      if (setupStep === 4) return <Participation backStep={3} />

      if (setupStep === 3) return <RosterManagement backStep={2} />
    } else {
      if (setupStep === 5) return <Participation backStep={4} />

      if (setupStep === 4) return <RosterManagement backStep={3} />

      if (setupStep === 3) return <ExamDecisionIndicator backStep={2} />
    }
    if (setupStep === 2) return <CoordinatorSetupExamAdminForm backStep={1} />

    if (setupStep === 1) return <CoordinatorSetupSchoolInfoForm backStep={0} />

    if (setupStep === 0) return <WelcomeScreen nextStep={findSetupStep(data, setupStep) || 1} />

    return null
  }

  render() {
    const { breakpoint, setupFormFlow, setupStep, hasFormChanged, changeStep } = this.props
    const style =
      breakpoint === 'mobile' || breakpoint === 'tablet'
        ? { marginTop: '20px' }
        : { marginTop: '40px' }

    return (
      <div>
        <CoordinatorSecondaryNavigation
          unsavedForm={hasFormChanged && setupFormFlow[setupStep]}
          onRoleChange={() => changeStep(null)}
        />
        <div className="container" style={style}>
          {this.renderStep()}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, { changeStep })(CoordinatorSetup)
