export const SUBMIT_INCIDENT_REPORT_PENDING = 'SUBMIT_INCIDENT_REPORT_PENDING'
export const SUBMIT_INCIDENT_REPORT_FULFILLED = 'SUBMIT_INCIDENT_REPORT_FULFILLED'
export const SUBMIT_INCIDENT_REPORT_REJECTED = 'SUBMIT_INCIDENT_REPORT_REJECTED'

export const FETCH_INCIDENT_REPORT_DRAFT_PENDING = 'FETCH_INCIDENT_REPORT_DRAFT_PENDING'
export const FETCH_INCIDENT_REPORT_DRAFT_FULFILLED = 'FETCH_INCIDENT_REPORT_DRAFT_FULFILLED'
export const FETCH_INCIDENT_REPORT_DRAFT_REJECTED = 'FETCH_INCIDENT_REPORT_DRAFT_REJECTED'

export const FETCH_INCIDENT_REPORT_HISTORY_PENDING = 'FETCH_INCIDENT_REPORT_HISTORY_PENDING'
export const FETCH_INCIDENT_REPORT_HISTORY_FULFILLED = 'FETCH_INCIDENT_REPORT_HISTORY_FULFILLED'
export const FETCH_INCIDENT_REPORT_HISTORY_REJECTED = 'FETCH_INCIDENT_REPORT_HISTORY_REJECTED'

export const RESET_INCIDENT_REPORT = 'RESET_INCIDENT_REPORT'

export const RESET_DRAFT_INCIDENT_REPORT = 'RESET_DRAFT_INCIDENT_REPORT'

export const NOT_ALLOWED_TO_SUBMIT = 'Only Coordinators may submit incident report form.'

// Courses for which Exam Form section should be hidden
// - 87 = Chinese Language and Culture
// - 88 = Japanese Language and Culture
// - 3 = Drawing
// - 4 = 2-D Art and Design
// - 5 = 3-D Art and Design
export const COURSES_WITH_HIDDEN_EXAM_FORM_SECTION = [87, 88, 3, 4, 5]

export const INCIDENT_REPORT_FORM_NAME = 'incidentReportForm'

/******************************************************
 * Fields below marked as DEPRECATED are retained 
 * here so previous year forms can still be displayed.
 ******************************************************/

export const INCIDENT_REPORT_SHARED_FIELD_NAMES = {
  firstName: { name: 'firstName', required: true },
  lastName: { name: 'lastName', required: true },
  phone: { name: 'phone', required: true },
  email: { name: 'email', required: true },
  section: { name: 'examSection', required: true, default: '' },
  testCode: { name: 'testCd', required: true, default: null },
  examDate: { name: 'adminWindow', required: true, default: null },
  examForm: { name: 'form', required: true, default: '' },
  formCode: { name: 'formCode', required: true, default: '' },
  interruptionMinutesInd: { name: 'interruptionMinutesInd', required: false, default: false },
  interruptionMinutes: { name: 'interruptionMinutes', required: false, default: null },
  disturbanceMinutesInd: { name: 'disturbanceMinutesInd', required: false, default: false },
  disturbanceMinutes: { name: 'disturbanceMinutes', required: false, default: null },
  misconductInd: { name: 'misconductInd', required: false, default: false },
  studentDismissedInd: { name: 'studentDismissedInd', required: false, default: false },
  usedProhibitedDeviceInd: { name: 'usedProhibitedDeviceInd', required: false, default: false },
  removeExamMaterialsInd: { name: 'removeExamMaterialsInd', required: false, default: false },
  illnessInd: { name: 'illnessInd', required: false, default: false },
  otherInd: { name: 'otherInd', required: false, default: false },
  other: { name: 'other', required: false, default: false }, // DEPRECATED needed for viewing old IRs
  studentFirstName: { name: 'studentFirstName', required: false, default: '' },
  studentLastName: { name: 'studentLastName', required: false, default: '' },
  studentApId: { name: 'apId', required: false, default: '' },
  incidentDetail: { name: 'incidentDetail', default: null },
  apServicesCaseNumber: { name: 'apServicesCaseNumber', required: false, default: null }, // DEPRECATED needed for viewing old IRs
  lateTestingRequiredInd: { name: 'lateTestingRequiredInd', required: false, default: false },
  altExamOrderedInd: { name: 'altExamOrderedInd', required: false, default: false },
  fullTestCenterImpactedInd: { name: 'fullTestCenterImpactedInd', required: false, default: false },
  students: { name: 'students', required: false, default: [] },
}
export const INCIDENT_REPORT_PAPER_FIELD_NAMES = {
  defectiveMatsInd: { name: 'defectiveMaterialsInd', required: false, default: false },
  multipleChoiceInd: { name: 'multipleChoiceInd', required: false, default: false },
  freeResponseInd: { name: 'freeResponseInd', required: false, default: false },
  shortAnswerResponseInd: { name: 'shortAnswerResponseInd', required: false, default: false }, // DEPRECATED needed for viewing old IRs
  orangeBookletInd: { name: 'orangeBookletInd', required: false, default: false },
  masterAudioCDsInd: { name: 'masterAudioCDsInd', required: false, default: false },
  masterAudioCDs: { name: 'masterAudioCDs', required: false, default: null },
  equipProbsInd: { name: 'equipmentProblemsInd', required: false, default: false },
  shortAnsrWrngBookInd: { // DEPRECATED needed for viewing old IRs
    name: 'shortAnsFreeRespWrittenInWrongBookInd',
    required: false,
    default: false,
  },
  earlyOpeningSectionInd: { name: 'earlyOpeningSectionInd', required: false, default: false },
  mixUpFreeResponseBooksInd: { name: 'mixUpFreeResponseBooksInd', required: false, default: false },
  wrngColorInkInd: {
    name: 'usedInappropriateInkColorInFreeResponseBookInd',
    required: false,
    default: false,
  },
  misplacedAnswersInd: { name: 'misplacedAnswersInd', required: false, default: false },
  missingExamMaterialsInd: { name: 'missingExamMaterialsInd', required: false, default: false },
  overtimingMinutesInd: { name: 'overtimingMinutesInd', required: false, default: false },
  overtimingMinutes: { name: 'overtimingMinutes', required: false, default: null },
  undertimingMinutesInd: { name: 'undertimingMinutesInd', required: false, default: false },
  undertimingMinutes: { name: 'undertimingMinutes', required: false, default: null },
  usedInkOnAnswerSheetInd: { name: 'usedInkOnAnswerSheetInd', required: false, default: false },
  usedExtraPaperInd: { name: 'usedExtraPaperInd', required: false, default: false },
  sharedSameAPIDInd: { name: 'sharedSameAPIDInd', required: false, default: false },
  testingOffScheduleInd: { name: 'testingOffScheduleInd', required: false, default: false },
}

export const INCIDENT_REPORT_DIGITAL_FIELD_NAMES = {
  batteryDiedInd: { name: 'batteryDiedInd', required: false, default: false },
  connectionDroppedInd: { name: 'connectionDroppedInd', required: false, default: false },
  deviceFrozeOrCrashInd: { name: 'deviceFrozeOrCrashInd', required: false, default: false },
  deviceDidNotMeetReqInd: { name: 'deviceDidNotMeetReqInd', required: false, default: false },
  loanerDeviceIssueInd: { name: 'loanerDeviceIssueInd', required: false, default: false },
  anotherTestAppInstalledInd: {
    name: 'anotherTestAppInstalledInd',
    required: false,
    default: false,
  },
  blueBookNotInstalledInd: { name: 'blueBookNotInstalledInd', required: false, default: false },
  examSetUpNotCompletedInd: { name: 'examSetUpNotCompletedInd', required: false, default: false },
  noAdmissionTicketInd: { name: 'noAdmissionTicketInd', required: false, default: false },
  studentTechIssueOther: { name: 'studentTechIssueOther', required: false, default: false },
  couldNotOpenInd: { name: 'couldNotOpenInd', required: false, default: false },
  couldNotSignInd: { name: 'couldNotSignInd', required: false, default: false },
  testNotAvailableInd: { name: 'testNotAvailableInd', required: false, default: false },
  couldNotStartAfterTheStartCodeInd: {
    name: 'couldNotStartAfterTheStartCodeInd',
    required: false,
    default: false,
  },
  inAccurateTimingInd: { name: 'inAccurateTimingInd', required: false, default: false },
  contentDisplayIssueInd: { name: 'contentDisplayIssueInd', required: false, default: false },
  calculatorClockOrToolIssueInd: {
    name: 'calculatorClockOrToolIssueInd',
    required: false,
    default: false,
  },
  answerNotSubmittedInd: { name: 'answerNotSubmittedInd', required: false, default: false },
  blueBookProblemOther: { name: 'blueBookProblemOther', required: false, default: false },
  staffTechIssue: { name: 'staffTechIssue', required: false, default: false }, // DEPRECATED
  staffBatteryDiedInd: { name: 'staffBatteryDiedInd', propName: 'batteryDiedInd', required: false, default: false },
  staffConnectionDroppedInd: { name: 'staffConnectionDroppedInd', propName: 'connectionDroppedInd', required: false, default: false },
  staffDeviceFrozeOrCrashInd: { name: 'staffDeviceFrozeOrCrashInd', propName: 'deviceFrozeOrCrashInd', required: false, default: false },
  staffDeviceIssueInd: { name: 'staffDeviceIssueInd', propName: 'staffDeviceIssueInd', required: false, default: false },
  staffTechIssueOther: { name: 'staffTechIssueOther', propName: 'otherInd', required: false, default: false },
  networkOutageInd: { name: 'networkOutageInd', required: false, default: false },
  powerOutageInd: { name: 'powerOutageInd', required: false, default: false },
  facilityTechIssueOther: { name: 'facilityTechIssueOther', required: false, default: false },
  couldNotAccessTDTKInd: { name: 'couldNotAccessTDTKInd', required: false, default: false },
  inAccurateStudentRosterDataInd: {
    name: 'inAccurateStudentRosterDataInd',
    required: false,
    default: false,
  },
  studentCheckInIssueInd: { name: 'studentCheckInIssueInd', required: false, default: false },
  inAccurateStudentStatusInd: {
    name: 'inAccurateStudentStatusInd',
    required: false,
    default: false,
  },
  testDayToolKitProblemOther: {
    name: 'testDayToolKitProblemOther',
    required: false,
    default: false,
  },
}

export const SHARED_FIELDS_LIST = [
  INCIDENT_REPORT_SHARED_FIELD_NAMES.firstName.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.lastName.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.phone.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.email.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.section.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.testCode.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.examDate.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.examForm.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.formCode.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.interruptionMinutesInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.interruptionMinutes.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.disturbanceMinutesInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.disturbanceMinutes.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.misconductInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.studentDismissedInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.usedProhibitedDeviceInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.removeExamMaterialsInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.illnessInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.otherInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.lateTestingRequiredInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.altExamOrderedInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.fullTestCenterImpactedInd.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.studentFirstName.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.studentLastName.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.studentApId.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.students.name,
  INCIDENT_REPORT_SHARED_FIELD_NAMES.incidentDetail.name,
]

export const PAPER_FIELDS_LIST = [
  INCIDENT_REPORT_PAPER_FIELD_NAMES.defectiveMatsInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.multipleChoiceInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.freeResponseInd.name,
  // INCIDENT_REPORT_PAPER_FIELD_NAMES.shortAnswerResponseInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.orangeBookletInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.masterAudioCDsInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.masterAudioCDs.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.equipProbsInd.name,
  // INCIDENT_REPORT_PAPER_FIELD_NAMES.shortAnsrWrngBookInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.earlyOpeningSectionInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.mixUpFreeResponseBooksInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.wrngColorInkInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.misplacedAnswersInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.missingExamMaterialsInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.overtimingMinutesInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.overtimingMinutes.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.undertimingMinutesInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.undertimingMinutes.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.usedInkOnAnswerSheetInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.usedExtraPaperInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.sharedSameAPIDInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.testingOffScheduleInd.name,
]

export const DIGITAL_FIELDS_LIST = [
  // Student Tech Issues
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.batteryDiedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.connectionDroppedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.deviceFrozeOrCrashInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.deviceDidNotMeetReqInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.loanerDeviceIssueInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.anotherTestAppInstalledInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.blueBookNotInstalledInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.examSetUpNotCompletedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.noAdmissionTicketInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.studentTechIssueOther.name,
  // Bluebook Issues
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.couldNotOpenInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.couldNotSignInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.testNotAvailableInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.couldNotStartAfterTheStartCodeInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.inAccurateTimingInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.contentDisplayIssueInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.calculatorClockOrToolIssueInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.answerNotSubmittedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.blueBookProblemOther.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffTechIssue.name,
  // Staff Tech Issues
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffBatteryDiedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffConnectionDroppedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffDeviceFrozeOrCrashInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffDeviceIssueInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffTechIssueOther.name,
  // Facility Tech Issues
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.networkOutageInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.powerOutageInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.facilityTechIssueOther.name,
  // Test Day Toolkit Issues
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.couldNotAccessTDTKInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.inAccurateStudentRosterDataInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.studentCheckInIssueInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.inAccurateStudentStatusInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.testDayToolKitProblemOther.name,
]

export const HYBRID_FIELDS_LIST = [
  // Defective Materials
  INCIDENT_REPORT_PAPER_FIELD_NAMES.defectiveMatsInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.multipleChoiceInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.freeResponseInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.orangeBookletInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.masterAudioCDsInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.masterAudioCDs.name,
  // Other
  INCIDENT_REPORT_PAPER_FIELD_NAMES.earlyOpeningSectionInd.name,
  // Student Tech Issues
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.batteryDiedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.connectionDroppedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.deviceFrozeOrCrashInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.deviceDidNotMeetReqInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.loanerDeviceIssueInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.anotherTestAppInstalledInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.blueBookNotInstalledInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.examSetUpNotCompletedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.noAdmissionTicketInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.studentTechIssueOther.name,
  // Bluebook Issues
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.couldNotOpenInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.couldNotSignInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.testNotAvailableInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.couldNotStartAfterTheStartCodeInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.inAccurateTimingInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.contentDisplayIssueInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.calculatorClockOrToolIssueInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.answerNotSubmittedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.blueBookProblemOther.name,
  // Staff Tech Issues
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffBatteryDiedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffConnectionDroppedInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffDeviceFrozeOrCrashInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffDeviceIssueInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.staffTechIssueOther.name,
  // Test Day Toolkit Issues
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.couldNotAccessTDTKInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.inAccurateStudentRosterDataInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.studentCheckInIssueInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.inAccurateStudentStatusInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.testDayToolKitProblemOther.name,
  // Facility Tech Issues
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.networkOutageInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.powerOutageInd.name,
  INCIDENT_REPORT_DIGITAL_FIELD_NAMES.facilityTechIssueOther.name,
  // Uncategorized
  INCIDENT_REPORT_PAPER_FIELD_NAMES.misplacedAnswersInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.missingExamMaterialsInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.mixUpFreeResponseBooksInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.sharedSameAPIDInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.wrngColorInkInd.name,
  INCIDENT_REPORT_PAPER_FIELD_NAMES.usedExtraPaperInd.name,
]