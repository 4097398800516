import { Modal } from '../../components/common'
import { submit, hasSubmitFailed, hasSubmitSucceeded, isSubmitting, isValid } from 'redux-form'

const mapStateToProps = (state, ownProps) => {
	const { form, canBeSaved = true } = ownProps

	return {
		failed: hasSubmitFailed(form)(state),
		succeeded: hasSubmitSucceeded(form)(state),
		submitting: isSubmitting(form)(state),
		valid: isValid(form)(state),
	}
}

class ConfirmUnsavedChanges extends Component {
	constructor() {
		super()
		this.callSuccessAction = this.callSuccessAction.bind(this)
	}

	footerActions() {
		const {
			callback,
			saveButtonText,
			submitting,
			valid,
			submit,
			form,
			closeOnPrimaryButtonClick = false,
			canBeSaved,
		} = this.props

		return [
			{ buttonLabel: 'Cancel', isDismissable: true },
			{
				buttonLabel: 'Continue Without Saving',
				onClick: () => callback(true),
				isDismissable: true,
			},
			...(canBeSaved
				? [
						{
							buttonLabel: valid ? saveButtonText || 'Save Changes' : 'Continue Editing',
							isDismissable: closeOnPrimaryButtonClick,
							isDisabled: submitting,
							isDismissable: !valid,
							onClick: e => valid && submit(form),
							isPrimary: true,
						},
				  ]
				: []),
		]
	}

	callSuccessAction() {
		const { succeeded, onSuccessAction } = this.props
		succeeded && onSuccessAction && onSuccessAction()
	}

	render() {
		const { form, submit, failed, onSuccessAction } = this.props

		return (
			<Modal
				headerTitle="Confirm Navigation"
				shouldCloseModal={this.props.succeeded || failed}
				onCloseAction={this.callSuccessAction}
				footerActions={this.footerActions()}
				modalClass="modal-condensed-buttons"
			>
				<p>You have unsaved changes that will be lost if you decide to continue.</p>
				<p>Are you sure you want to leave this page?</p>
			</Modal>
		)
	}
}

export default connect(mapStateToProps, { submit })(ConfirmUnsavedChanges)
