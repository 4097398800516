import { saveFeeStatus } from '../../../actions/csr'
import {
  STUDENT_FEE_STATUS,
  STANDARD_FEE_STATUS,
  REDUCED_FEE_STATUS,
} from '../../../constants/StudentConstants'
import { SearchForStudents } from '.'

const mapStateToProps = state => {
  return {
    educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
    orgId: state.user.data.selectedOrgId,
  }
}

const hasItems = (data, fee) => Boolean(data.find(d => d.feesStatus === fee))
const prepItems = (data, fee, update) =>
  data.reduce((acc, d) => (d.feesStatus === fee ? [...acc, { ...d, feesStatus: update }] : acc), [])

const FeeStatus = ({ orgId, educationPeriod }) => {
  const saveSubset = subset => saveFeeStatus(orgId, educationPeriod, subset)

  const getButtons = (selected, students) => {
    const selectedStandard = prepItems(selected, STANDARD_FEE_STATUS, REDUCED_FEE_STATUS)
    const selectedReduced = prepItems(selected, REDUCED_FEE_STATUS, STANDARD_FEE_STATUS)
    const buttons = []

    if (hasItems(students, STANDARD_FEE_STATUS)) {
      buttons.push({
        title: 'Change to Reduced',
        disabled: !selectedStandard.length,
        save: () => saveSubset(selectedStandard),
      })
    }

    if (hasItems(students, REDUCED_FEE_STATUS)) {
      buttons.push({
        title: 'Change to Standard',
        disabled: !selectedReduced.length,
        save: () => saveSubset(selectedReduced),
      })
    }

    return buttons
  }

  return (
    <SearchForStudents
      orgId={orgId}
      educationPeriod={educationPeriod}
      inputLabel="Search for students whose AP Fee Status needs to be changed."
      getButtons={getButtons}
      component={({ student }) => <div>{STUDENT_FEE_STATUS[student.feesStatus]}</div>}
      searchType="FEE_STATUS"
    />
  )
}

export default connect(mapStateToProps)(FeeStatus)
