import { formatDateTime, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { Accordions } from '../common'
import InvoiceTable from './InvoiceTable'
import InvoiceTotals from './InvoiceTotals'
import TransferTable from './TransferTable'
import DownloadInvoiceLink from './DownloadInvoiceLink'

import styles from '../../assets/style/scss/invoices.scss'

const InvoiceHistory = ({ data, data: { history = [] } }) => {
  const getDate = date => formatDateTime(date, DATETIME_FORMATS.longMonthDayYear)
  const historyCount = history.length
  const originalInvoice = history[historyCount - 1] || {}
  const originalInvoiceTitle = `Original Invoice Generated on ${getDate(originalInvoice.invoiceDt)}`

  const DownloadLink = ({ invoiceDt, invoicePDFGenerated }) =>
    invoicePDFGenerated ? (
      <DownloadInvoiceLink invoiceDt={invoiceDt} title="Download Invoice" />
    ) : null

  const InvoiceNumber = ({ invoiceNum }) =>
    invoiceNum ? (
      <>
        <strong>Invoice Number:</strong> {invoiceNum}
      </>
    ) : null

  const OriginalOrder = () => (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={styles['invoice-download-link']}>
          <DownloadLink {...originalInvoice} />
        </div>
        <div>
          <InvoiceNumber {...originalInvoice} />
        </div>
      </div>
      <InvoiceTable title="Fees" data={originalInvoice.fees} />
      <InvoiceTable title="Credits" data={originalInvoice.credits} />
      <InvoiceTotals {...originalInvoice} />
    </>
  )

  const panels = history.slice(0, historyCount - 1).map((d, i) => ({
    panelId: d.invoiceDt,
    openByDefault: i === 0,
    PanelBody: () => (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={styles['invoice-download-link']}>
            <DownloadLink {...d} />
          </div>
          <div>
            <InvoiceNumber {...d} />
          </div>
        </div>
        <TransferTable title="Fees" data={d.fees} />
        <TransferTable title="Credits" data={d.credits} />
      </>
    ),
    title: () => `Updated Invoice Generated on ${getDate(d.invoiceDt)}`,
  }))

  if (historyCount > 1)
    return (
      <Accordions
        id="invoiceAccordions"
        panels={[
          ...panels,
          {
            panelId: 'originalInvoice',
            PanelBody: OriginalOrder,
            title: () => originalInvoiceTitle,
          },
        ]}
      />
    )

  return (
    <>
      <p>
        <strong>{originalInvoiceTitle}</strong>
      </p>
      <OriginalOrder />
    </>
  )
}

export default InvoiceHistory
