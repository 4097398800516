import { useHistory } from 'react-router-dom'
import { Modal } from '../../components/common'
import { hasUnsubmittedChanges } from '../../selectors/order'

const mapStateToProps = (state, ownProps) => {
  const { studentName, orgName } = ownProps
  const { changeOrderStatus } = state.status.data
  return {
    studentName,
    orgName,
    changeOrderStatus,
  }
}

const StudentTransferredOutModal = ({ studentName, orgName, changeOrderStatus }) => {
  const history = useHistory()
  const hasUnsubmittedChanges = hasUnsubmittedChanges(changeOrderStatus)

  const footerActions = () => {
    if (hasUnsubmittedChanges) {
      return [
        { buttonLabel: 'Continue Updating Order', isDismissable: true, isPrimary: false },
        {
          buttonLabel: 'View Order',
          isPrimary: true,
          isDismissable: true,
          onClick: () => history.push(`/orders`),
        },
      ]
    }

    return [{ buttonLabel: 'Close', isDismissable: true, isPrimary: true }]
  }

  return (
    <Modal headerTitle="Transfer Out" footerActions={footerActions()}>
      <p>
        {studentName} has been transferred out of the AP Registration and Ordering system for{' '}
        {orgName}.
        {hasUnsubmittedChanges && (
          <>
            {' '}
            You will need submit your changes on the order screen to remove this student’s exams
            from your order.
          </>
        )}
      </p>
    </Modal>
  )
}

export default connect(mapStateToProps)(StudentTransferredOutModal)
