import { useHistory } from 'react-router-dom'
import { educationPeriodDateSpread, manipulateYear } from '@myap/ui-library/esm/date'
import { changeStep, fetchOrgSettings } from '../../actions/settingsOrg'
import { setSelectedRole } from '../../actions/user'
import { sortColumnByKey, sortColumnByDate } from '../../utils/sort'
import { renderInput as Input } from '../common/forms/Input'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = state => {
  const {
    user: {
      data: { roles, selectedOrgId, selectedRole /* , isLevelOne */ },
    },
    // settingsOrg,
    settingsEducationPeriod: { selectedEducationPeriod, availableEducationPeriods, current },
  } = state
  // const editableOrgs = []
  // const finishedOrgs = []
  // let hasLevelOne = false
  // roles.forEach(role => {
  //   if (role.level === 1) {
  //     hasLevelOne = true
  //   }
  //   if (settingsOrg[role.orgId]?.data?.complete) {
  //     finishedOrgs.push({
  //       orgName: role.orgName,
  //       orgId: role.orgId,
  //     })
  //   } else {
  //     editableOrgs.push({
  //       orgName: role.orgName,
  //       orgId: role.orgId,
  //     })
  //   }
  // })
  const editableOrgs = roles.filter(r => r.needsSetup)
  const finishedOrgs = roles.filter(r => !r.needsSetup)
  const levelOneOrgs = roles.reduce((acc, { level, orgName }) => {
    if (level === 1) {
      acc.push(orgName)
    }
    return acc
  }, [])
  const { academicYrStartDate: startCurrent, academicYrEndDate: endCurrent } =
    availableEducationPeriods[current]

  return {
    editableOrgs: sortColumnByKey(editableOrgs, 'orgName', 'asc'),
    finishedOrgs: sortColumnByDate(
      finishedOrgs,
      ['setupCompletionDate', 'orgName'],
      ['asc', 'asc']
    ),
    // settingsOrg,
    selectedOrgId,
    selectedEducationPeriod,
    currentEducationPeriod: current,
    levelOneOrgNames: levelOneOrgs.length ? levelOneOrgs.join(' or ') : null,
    roles,
    hasLevelOne: roles.some(role => role.level === 1),
    // isLevelOne,
    orgName: roles[selectedRole].orgName,
    currentAcademicYear:
      startCurrent && endCurrent ? educationPeriodDateSpread(startCurrent, endCurrent, '-') : null,
    previousAcademicYear:
      startCurrent && endCurrent
        ? educationPeriodDateSpread(
            manipulateYear(startCurrent, -1),
            manipulateYear(endCurrent, -1),
            '-'
          )
        : null,
  }
}

// const LevelOneIntroText = ({ orgNames }) => (
//   <div>
//     <h1>Welcome to the AP Registration and Ordering System!</h1>
//     <p>This tool will help you to create course sections and enroll students online.</p>
//     <p>To get started, please take a few minutes to configure your settings and share some important information with us.</p>
//     <p>Note: At this time, you will not be able to place exam orders for {orgNames}. If you need to change your school&#39;s authorization level to allow exam ordering and administration, please call 877-274-6474.</p>
//   </div>
// )
//
// const LevelTwoIntroText = () => (
//   <div>
//     <h1>Welcome to AP Registration and Ordering</h1>
//     <p>This system enables AP coordinators to create class sections, facilitate access to teacher and student resources, identify exam takers, manage exam orders and more.</p>
//     <p>To get started, please take a few minutes to configure your settings and share some important information with us.</p>
//   </div>
// )

const SelectOrg = ({ editableOrgs, finishedOrgs, action, selectedOrgId, levelOneOrgNames }) => {
  let label = org =>
    `${org.orgName} ${org.aiCode ? `(${org.aiCode})` : ''} ${org.city ? `${org.city},` : ''} ${
      org.state || ''
    }`
  let spacing = { minHeight: '3em' }

  return (
    <div>
      <p>
        <strong>
          You are an AP Coordinator for multiple schools. Please choose a school to configure
          settings for first.
        </strong>{' '}
        You will have the opportunity to set up your other schools later.
      </p>
      {editableOrgs.map(org => {
        let input = { name: 'setupOrgId', value: org.orgId || '', onChange: action }

        return (
          <div key={org.orgId} style={spacing}>
            <Input
              input={input}
              type="radio"
              label={label(org)}
              checked={org.orgId === selectedOrgId}
            />
          </div>
        )
      })}
      {finishedOrgs.map((org, i) => {
        return (
          <div key={org.orgId} style={spacing}>
            <i
              className="cb-glyph cb-check"
              style={{ color: '#3c763d', marginLeft: '2px', marginRight: '5px' }}
            >
              <span className="sr-only">Already Completed</span>
            </i>{' '}
            {label(org)}
          </div>
        )
      })}
    </div>
  )
}

const Action = ({ action, buttonText, disabled }) => (
  <p className="text-right">
    <button type="button" className="btn-link" onClick={action} disabled={disabled}>
      <strong>
        {buttonText}{' '}
        <i className="cb-glyph cb-east" aria-hidden={true} style={{ fontSize: '.85em' }} />
      </strong>
    </button>
  </p>
)

const WelcomeScreen = props => {
  const {
    roles,
    selectedOrgId,
    editableOrgs,
    levelOneOrgNames,
    hasLevelOne,
    currentAcademicYear,
    previousAcademicYear,
    nextStep,
    setSelectedRole,
    changeStep,
  } = props
  const history = useHistory()
  let action
  let buttonText = ''
  let buttonDisabled = false

  const changeOrg = e => {
    const id = parseInt(e.target.value, 10)
    setSelectedRole(id)
  }

  const goNextStep = () => changeStep(nextStep)

  const goDashboard = () => {
    history.replace(NAV_LINKS.dashboard)
  }

  if (editableOrgs.length) {
    if (!editableOrgs.find(org => org.orgId === selectedOrgId)) {
      buttonDisabled = true
    }
    action = goNextStep
    buttonText = 'Go to setup'
  } else {
    action = goDashboard
    buttonText = 'Go to dashboard'
  }

  return (
    <>
      <h1>Welcome to AP Registration and Ordering for {currentAcademicYear}</h1>
      <p>
        <strong>If you need to access your information from {previousAcademicYear}</strong>, select
        from the drop down next to your school name above.
      </p>
      <p>
        This system enables AP coordinators to create class sections, facilitate access to teacher
        and student resources, identify exam takers, manage exam orders and more.
      </p>
      <p>
        To get started for the {currentAcademicYear} school year, please take a few minutes to
        configure your settings and share some important information with us.
      </p>
      {hasLevelOne ? (
        <p>
          Note: At this time, you will not be able to place exam orders for {levelOneOrgNames}. If
          you need to change your school&#39;s authorization level to allow exam ordering and
          administration, please call 877-274-6474.
        </p>
      ) : null}
      {roles.length > 1 ? <SelectOrg {...props} action={changeOrg} /> : null}
      <Action action={action} buttonText={buttonText} disabled={buttonDisabled} />
    </>
  )
}

export default connect(mapStateToProps, { changeStep, setSelectedRole, fetchOrgSettings })(
  WelcomeScreen
)
