import styles from '../../assets/style/scss/login-unavailable.scss'
import loginBackgroundImage from '../../assets/images/AP-Pilot-Hero-Oversize-circled.png'

export default () => (
  <CSSTransition
    classNames="animate-fade"
    in={true}
    timeout={500}
    appear={true}
    enter={false}
    exit={false}
  >
    <>
      <div
        className={`cb-hero cb-hero-standard hub-hero-sharp cb-hero-left ${styles['login-unavailable-hero']}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-lg-5 col-lg-offset-1">
              <div>
                <img src={loginBackgroundImage} alt="Image of students working" />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 flex-block">
              <div className="content">
                <h2>Looking for 2021 AP Scores?</h2>
                <p>Scores for exams taken in May will be available starting July 21.</p>
                <ul className="cb-hero-cta">
                  <li>
                    <a href="https://apscore.collegeboard.org" className="btn btn-sm">
                      Student Scores
                    </a>
                  </li>
                  <li>
                    <a href="https://scores.collegeboard.org" className="btn btn-sm cb-primary-cta">
                      Educator Score Reports
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  </CSSTransition>
)
