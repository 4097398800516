import { reduxForm, submit } from 'redux-form'
import { Modal, Error } from '../../components/common'
import {
  APCoordinator,
  Principal,
  TechnologyCoordinator,
  AuthorizedStaff,
  StudentPopulation,
  PurchaseOrderNumber,
  StudentFRPL,
} from '../../components/setup'
import BillingContact from '../../components/settings/participationform/BillingContactForm'
import {
  SCHOOL_INFORMATION_FORM,
  SCHOOL_INFORMATION_FIELDS as FIELDS,
} from '../../constants/SetupConstants'
import { updateOrgSettings } from '../../actions/settingsOrg'
import { getNestedAttr } from '../../utils/common'

// add in null values for missing fields
// this will make sure redux form correctly calculates if a form has changed.
// without this, if you add content to a missing field and then remove it,
// redux-form will still think the form has changed because the attribute was missing from the initial values
// and now is present but with a value of null.
// since the overall values object has changed, redux-form considers the form "dirty"
const setDefaultInitialValue = (fieldPrefix, obj = {}) => {
  const fields = Object.keys(FIELDS).filter(key => key.startsWith(fieldPrefix))
  const updated = fields.reduce((acc, key) => {
    return { ...acc, ...getNestedAttr(obj, FIELDS[key].name, true) }
  }, {})
  return updated
}

const mapStateToProps = state => {
  const { selectedOrgId } = state.user.data
  const orgSettingsData = state.settingsOrg[selectedOrgId].data || {}
  const {
    schoolInformation: {
      coordinator,
      principal,
      technologyCoordinator,
      additionalStaff,
      billing,
      purchaseOrderNumber = '',
      address: { countryCode },
    },
  } = orgSettingsData || {}

  return {
    [`minimum${FIELDS.POP9.name}`]: 0,
    [`minimum${FIELDS.POP10.name}`]: 0,
    [`minimum${FIELDS.POP11.name}`]: 0,
    [`minimum${FIELDS.POP12.name}`]: 0,
    [`minimum${FIELDS.REDUCED_FEE_PCT.name}`]: 0,
    [`maximum${FIELDS.REDUCED_FEE_PCT.name}`]: 100,
    countryCode,
    initialValues: {
      ...orgSettingsData,
      confirm: {
        schoolInformation: {
          coordinator: { contactInformation: { email: coordinator?.contactInformation?.email } },
          principal: { email: principal?.email },
          technologyCoordinator: { email: technologyCoordinator?.email },
          additionalStaff: { email: additionalStaff?.email },
          billing: { contactInformation: { email: billing?.contactInformation?.email } },
        },
      },
      schoolInformation: {
        ...orgSettingsData.schoolInformation,
        coordinator: {
          ...coordinator,
          contactInformation: setDefaultInitialValue('COORD', orgSettingsData),
        },
        principal: setDefaultInitialValue('PRIN', orgSettingsData),
        technologyCoordinator: setDefaultInitialValue('TECH', orgSettingsData),
        additionalStaff: setDefaultInitialValue('AUTH', orgSettingsData),
        billing: {
          contactInformation: {
            ...setDefaultInitialValue('BILL', orgSettingsData),
            address: setDefaultInitialValue('BADDR', orgSettingsData),
          },
        },
        purchaseOrderNumber,
      },
      examAdministration: {
        ...orgSettingsData.examAdministration,
      },
    },
  }
}

const UpdateOrgSettingsModal = ({
  title,
  modalCloseFocusElem,
  type,
  description,
  formattedDescription,
  handleSubmit,
  submitSucceeded,
  submitFailed,
  error,
  initialValues,
  countryCode,
  submitting,
  invalid,
  pristine,
  submit,
}) => {
  const {
    coordinator,
    principal,
    technologyCoordinator,
    additionalStaff,
    studentPopulation,
    billing,
    purchaseOrderNumber,
    schoolInfo,
  } = initialValues?.schoolInformation ?? {}
  const { confirm } = initialValues

  const footerActions = () => {
    return [
      { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
      {
        buttonLabel: 'Save',
        isPrimary: true,
        isDismissable: false,
        isDisabled: pristine || submitting || invalid,
        onClick: () => submit(SCHOOL_INFORMATION_FORM),
      },
    ]
  }

  return (
    <Modal
      headerTitle={title}
      modalCloseFocusElem={modalCloseFocusElem}
      shouldCloseModal={submitSucceeded}
      footerActions={footerActions()}
    >
      {description ? <p>{description}</p> : null}
      {formattedDescription ? <>{formattedDescription}</> : null}
      <form onSubmit={handleSubmit}>
        {submitFailed ? <Error message={error} /> : null}
        {type === 'C' ? (
          <APCoordinator
            {...coordinator}
            countryCode={countryCode}
            confirm={confirm}
            hideTitle={true}
          />
        ) : null}
        {type === 'P' ? (
          <Principal {...principal} countryCode={countryCode} confirm={confirm} hideTitle={true} />
        ) : null}
        {type === 'T' ? (
          <TechnologyCoordinator
            {...technologyCoordinator}
            countryCode={countryCode}
            confirm={confirm}
            hideTitle={true}
          />
        ) : null}
        {type === 'A' ? (
          <AuthorizedStaff {...additionalStaff} confirm={confirm} hideTitle={true} />
        ) : null}
        {type === 'S' ? <StudentPopulation {...studentPopulation} hideTitle={true} /> : null}
        {type === 'B' ? <BillingContact {...billing.contactInformation} confirm={confirm} /> : null}
        {type === 'PO' ? <PurchaseOrderNumber purchaseOrderNumber={purchaseOrderNumber} /> : null}
        {type === 'FRPL' ? <StudentFRPL {...schoolInfo} hideTitle={true} /> : null}
      </form>
    </Modal>
  )
}

export default connect(mapStateToProps, { submit, updateOrgSettings })(
  reduxForm({
    form: SCHOOL_INFORMATION_FORM,
    onSubmit: (data, dispatch, props) =>
      props.updateOrgSettings({ ...props.initialValues, ...data }),
  })(UpdateOrgSettingsModal)
)
