import { AccordionTableRows, TableRowSelection, SortableTable } from '../../components/common'
import { formatSSDMaterials } from '../../components/examRoom/utils'
import { sortColumnByKey } from '../../utils/sort'
import { plural } from '../../utils/common'

const sortFunction = (data, key, sorting) => {
  return data.map(d => ({
    ...d,
    students: sortColumnByKey(d.students, key, sorting),
  }))
}

const NAME_COLUMN = 'assignableStudentNames'
const SSD_COLUMN = 'assignableSSDMaterials'
const SECTION_COLUMN = 'assignableSectionName'
const STUDENT_ROW = 'assignableStudentName'

const headerColumns = [
  {
    headerText: { title: 'Select Student', srOnly: true },
  },
  {
    headerText: { title: 'Student Name' },
    headerAttributes: { id: NAME_COLUMN },
    key: ['lastName', 'firstName'],
    sorting: 'asc',
    defaultSort: true,
    sortFunction,
  },
  {
    headerText: { title: 'SSD Materials' },
    headerAttributes: { id: SSD_COLUMN },
    key: ['ssdMaterials', 'lastName', 'firstName'],
    sorting: 'asc',
    sortFunction,
  },
  {
    headerText: { title: 'Expand/Collapse Sections', srOnly: true },
  },
]

const ExamRoomAssignableStudentsTable = props => {
  const {
    assignableSections = [],
    visibleSections = [],
    name,
    setSelectedStudents,
    selectedStudents,
    roomId,
  } = props
  const hasVisibleSections = visibleSections.length

  function getSectionStudents(id) {
    const { students } = assignableSections.find(s => s.sectionId === id) || {}
    return students
  }

  function updateSelectedStudent(update, addition) {
    const updated = addition
      ? [...selectedStudents, update]
      : selectedStudents.filter(selected => update.id !== selected.id)

    setSelectedStudents(updated)
  }

  function updateSelectedSection(sectionId, addition) {
    const students = getSectionStudents(sectionId)

    const updated = addition
      ? [
          ...selectedStudents,
          ...students.filter(s => !selectedStudents.find(selected => selected.id === s.id)),
        ]
      : selectedStudents.filter(
          selected => !students.find(s => s.id === selected.id && s.roomAssignment?.id !== roomId)
        )
    setSelectedStudents(updated)
  }

  function isSectionChecked(sectionId) {
    const students = getSectionStudents(sectionId)
    return students.every(s => selectedStudents.find(selected => selected.id === s.id))
  }

  return (
    <>
      <SortableTable
        tableAttributes={{
          id: 'assignableStudentRoster',
          summary:
            'Students are listed in ascending order by name. Additional sorting options are available by clicking on the column headers.',
          className: 'table cb-no-table-border',
        }}
        data={visibleSections.map(s => ({
          ...s,
          students: s.students.map(student => ({
            ...student,
            ssdMaterials: formatSSDMaterials(student),
          })),
        }))}
        rowBuilder={sections =>
          sortColumnByKey(sections, 'name', 'asc').map((section, i) => {
            const unassignedCount = section.students.filter(s => !s.roomAssignment).length
            const sectionHeaderId = `${SECTION_COLUMN}-${i}`
            const allStudentsSelected = isSectionChecked(section.sectionId)
            return (
              <AccordionTableRows
                key={i}
                defaultOpen={allStudentsSelected}
                rowIds={section.students.map((s, i) => `assignable-${s.id}-${i}`).join(' ')}
              >
                <TableRowSelection
                  rowId={i}
                  label={`Select all students in ${section.name}`}
                  isChecked={allStudentsSelected}
                  disabled={section.students.every(s => s.roomAssignment?.id === roomId)}
                  onSelection={({ checked }) => updateSelectedSection(section.sectionId, checked)}
                >
                  <th scope="row" id={sectionHeaderId}>
                    <strong>{section.name}</strong>
                    <div>
                      {unassignedCount} of {section.students.length}{' '}
                      {plural(unassignedCount, 'Student')} Unassigned
                    </div>
                  </th>
                  <td>
                    {section.teacherNames?.length ? (
                      `Teacher: ${section.teacherNames.join(', ')}`
                    ) : (
                      <em>Exam Only</em>
                    )}
                  </td>
                </TableRowSelection>
                {section.students.map(student => {
                  const studentHeaderId = `${STUDENT_ROW}-${student.id}`
                  return (
                    <TableRowSelection
                      key={student.id}
                      rowId={`assignable-${student.id}-${i}`}
                      label={`Select ${student.firstName} ${student.lastName}`}
                      isChecked={Boolean(selectedStudents.find(s => s.id === student.id))}
                      onSelection={({ checked }) => updateSelectedStudent(student, checked)}
                      disabled={student.roomAssignment?.id === roomId}
                    >
                      <th
                        scope="row"
                        id={studentHeaderId}
                        headers={`${NAME_COLUMN} ${sectionHeaderId}`}
                      >
                        {student.lastName}, {student.firstName}
                        {student.roomAssignment ? (
                          <div>Assigned to {student.roomAssignment.name}</div>
                        ) : null}
                      </th>
                      <td headers={`${SSD_COLUMN} ${studentHeaderId}`}>{student.ssdMaterials}</td>
                    </TableRowSelection>
                  )
                })}
              </AccordionTableRows>
            )
          })
        }
        columns={headerColumns}
        tableCaption={{
          caption: `A view of all students who can be assigned to exam room: ${name}`,
          srOnly: true,
        }}
        tbody={false}
      />
      {!hasVisibleSections ? <p>No students available.</p> : null}
    </>
  )
}

export default ExamRoomAssignableStudentsTable
