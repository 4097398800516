import { setNavLink, unsetNavLink } from '../../actions/nav'
import { fetchExamOrderSummary, fetchUnsubmittedOrderChanges } from '../../actions/orders'
import { getHoldNotification } from '../../actions/notifications'
import { Loader, Error } from '../../components/common'
import {
  ExamAdjustmentsTable,
  MaterialsAdjustmentsTable,
  SubmitButton,
} from '../../components/orders/coordinator'
import { NAV_LINKS } from '../../constants/NavConstants'
import { DEFAULT_REQUEST_ERROR } from '../../constants/MessageConstants'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    ordersUnsubmittedChanges: { unsubmittedExamChanges = {}, unsubmittedMaterialChanges = [] },
  } = state
  return {
    orgId: selectedOrgId,
    educationPeriodCd: selectedEducationPeriod,
    unsubmittedExamChanges,
    unsubmittedMaterialChanges,
    changesToSubmit:
      unsubmittedExamChanges?.examSummaries?.length > 0 || unsubmittedMaterialChanges?.length > 0,
  }
}

const UnsubmittedOrderSubmissionButton = ({ changesToSubmit }) => {
  if (changesToSubmit) {
    return (
      <div className="col-md-2 col-xs-4 text-right">
        <SubmitButton redirectOnSuccess={true} />
      </div>
    )
  }

  return null
}

const UnsubmittedChangesPage = ({
  orgId,
  educationPeriodCd,
  fetching,
  error,
  unsubmittedExamChanges,
  unsubmittedMaterialChanges,
  changesToSubmit,
  setNavLink,
  unsetNavLink,
  fetchExamOrderSummary,
  fetchUnsubmittedOrderChanges,
  getHoldNotification,
}) => {
  useEffect(() => {
    setNavLink(NAV_LINKS.orders, 'Back to order summary')
    return () => unsetNavLink()
  }, [])

  useEffect(() => {
    if (orgId && educationPeriodCd) {
      fetchExamOrderSummary(orgId, educationPeriodCd)
    }
  }, [orgId, educationPeriodCd])

  useEffect(() => {
    if (orgId && educationPeriodCd) {
      fetchUnsubmittedOrderChanges(orgId, educationPeriodCd)
    }
  }, [orgId, educationPeriodCd])

  useEffect(() => {
    if (orgId) {
      getHoldNotification({ orgId })
    }
  }, [ orgId ])

  if (fetching) return <Loader />

  if (error)
    return (
      <div className="container">
        <Error title="Error Loading Unsubmitted Changes" message={DEFAULT_REQUEST_ERROR} />
      </div>
    )

  return (
    <div className="container">
      <div className="row">
        <h2 className="col-md-10 col-xs-8" style={{ lineHeight: '48px' }}>
          Unsubmitted Changes
        </h2>
        <UnsubmittedOrderSubmissionButton changesToSubmit={changesToSubmit} />
      </div>
      <ExamAdjustmentsTable
        data={unsubmittedExamChanges?.examSummaries}
        aggregateRow={unsubmittedExamChanges?.allExamsSummary}
        id="unsubmittedExamAdjustments"
        isUnsubmitted={true}
        emptyText="There are no unsubmitted exam adjustments."
        headerStyle={{ marginTop: '30px', marginBottom: '5px' }}
      />
      <MaterialsAdjustmentsTable
        data={unsubmittedMaterialChanges}
        id="unsubmittedMaterialAdjustments"
        emptyText="There are no unsubmitted material adjustments."
      />
    </div>
  )
}

UnsubmittedChangesPage.displayName = 'UnsubmittedChangesPage'

export default connect(mapStateToProps, {
  setNavLink,
  unsetNavLink,
  fetchExamOrderSummary,
  fetchUnsubmittedOrderChanges,
  getHoldNotification,
})(UnsubmittedChangesPage)
