import { iam } from '@myap/ui-library/esm/profile'
import axios, { getServerErrorMessage } from '../utils/axios'
import * as c from '../constants/InvoiceConstants'

const { getJWTToken, getAuthSession } = iam()

export const fetchInvoiceDownloadUrl =
  ({ orgId, educationPeriodCd, invoiceDt = 'latest' }) =>
  async dispatch => {
    const params = invoiceDt !== 'latest' ? { params: { invoiceDt } } : {}

    dispatch({ type: c.FETCH_INVOICE_DOWNLOAD_URL_PENDING, payload: { orgId, invoiceDt } })
    const config = {
      headers: {
        'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
        'X-CB-Catapult-Authorization-Token': getJWTToken(),
      },
    }
    try {
      const {
        data: { invoiceDownloadUrl },
      } = await axios.get(
        `${Config.API_GATE_URL}/invoices/organizations/${orgId}/education-periods/${educationPeriodCd}/download`,
        { ...params, ...config }
      )
      dispatch({
        type: c.FETCH_INVOICE_DOWNLOAD_URL_FULFILLED,
        payload: invoiceDownloadUrl,
      })
    } catch (err) {
      dispatch({
        type: c.FETCH_INVOICE_DOWNLOAD_URL_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const fetchInvoices = (orgId, educationPeriodCd) => async (dispatch, getState) => {
  const {
    settingsDeadlines: { data },
  } = getState()
  const { invoiceAvailableDateIsPast } = data?.[educationPeriodCd] ?? {}

  // No need to make any requests to this service before invoices are available
  if (invoiceAvailableDateIsPast) {
    dispatch({ type: c.FETCH_INVOICES_PENDING })
    const config = {
      headers: {
        'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
        'X-CB-Catapult-Authorization-Token': getJWTToken(),
      },
    }
    try {
      const { data } = await axios.get(
        `${Config.API_GATE_URL}/invoices/organizations/${orgId}/education-periods/${educationPeriodCd}`,
        { ...config }
      )
      dispatch({
        type: c.FETCH_INVOICES_FULFILLED,
        payload: { data },
      })
    } catch (err) {
      dispatch({ type: c.FETCH_INVOICES_REJECTED, payload: getServerErrorMessage(err) })
    }
  }
}

export const fetchInvoiceHistory =
  ({ orgId, educationPeriodCd }) =>
  async dispatch => {
    dispatch({ type: c.FETCH_INVOICE_HISTORY_PENDING })
    const config = {
      headers: {
        'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
        'X-CB-Catapult-Authorization-Token': getJWTToken(),
      },
    }

    try {
      const { data } = await axios.get(
        `${Config.API_GATE_URL}/invoices/organizations/${orgId}/education-periods/${educationPeriodCd}/history`,
        { ...config }
      )
      dispatch({ type: c.FETCH_INVOICE_HISTORY_FULFILLED, payload: { data } })
    } catch (err) {
      dispatch({ type: c.FETCH_INVOICE_HISTORY_REJECTED, payload: getServerErrorMessage(err) })
    }
  }

export const flushInvoiceDownloadUrl = () => dispatch =>
  dispatch({ type: c.FLUSH_INVOICE_DOWNLOAD_URL })
