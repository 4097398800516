import { Error } from '@myap/ui-library/esm/components/apricot'
import { Modal } from '../../components/common'

const GenerateNARErrorModal = ({ modalCloseFocusElem, error }) => {
  return (
    <Modal
      modalCloseFocusElem={modalCloseFocusElem}
      headerTitle="Error Generating NAR Form"
      footerActions={[{ buttonLabel: 'Close', isDismissable: true, isPrimary: false }]}
    >
      {error ? <Error message={error} /> : null}
    </Modal>
  )
}

export default GenerateNARErrorModal
