import { removeFilter } from '../../../actions/filters'
import styles from '../../../assets/style/scss/filters.scss'

export default ({ filter, label, value }) => {
  //useEffect(() => {
  //  if (!label) removeFilter(filter, value)
  //}, [])

  return label ? (
    <span className={styles['filter-tag']}>
      {label}
      <button
        type="button"
        onClick={() => removeFilter(filter, value)}
        aria-label={`Remove Filter: ${label}`}
      >
        <span className="sr-only">remove filter</span>
        <span className={styles['filter-x-circle']} aria-hidden={true}>
          <span className="cb-glyph cb-x-mark" />
        </span>
      </button>
    </span>
  ) : null
}
