import { getFormValues, change } from 'redux-form'
import get from 'lodash-es/get'
import { formatDate } from '@myap/ui-library/esm/date'
import { Input, Date as DateSelector } from '../../common'
import {
  SCHOOL_INFORMATION_FORM,
  SCHOOL_INFORMATION_FIELDS as FIELDS,
} from '../../../constants/SetupConstants'

const mapStateToProps = state => {
  return {
    currentFormValues: getFormValues(SCHOOL_INFORMATION_FORM)(state) || {},
  }
}

const RequireStudentsPayAPFeesBy = ({ currentFormValues, change }) => {
  return (
    <fieldset role="radiogroup" aria-labelledby="pay-date-selection-group" aria-required="true">
      <legend className="cb-required" id="pay-date-selection-group" role="presentation">
        By what date do you require students to pay their AP Exam fees?
      </legend>
      <div>
        <div className="row">
          <div className="col-xs-6">
            <Input
              type="radio"
              name={FIELDS.REDUCED_FEE_COLLECTION.name}
              isRequired={FIELDS.REDUCED_FEE_COLLECTION.required}
              label={FIELDS.REDUCED_PAY_DATE.label}
              srOnlyLabel={true}
              value={false}
              normalize={val => val === 'true'}
              style={{ lineHeight: '104px', display: 'inline', position: 'absolute' }}
            />
            <DateSelector
              name={FIELDS.REDUCED_PAY_DATE.name}
              form={SCHOOL_INFORMATION_FORM}
              label={
                <>
                  Students who <strong>are</strong> eligible for the College Board fee reduction:
                </>
              }
              disabled={get(currentFormValues, FIELDS.REDUCED_FEE_COLLECTION.name)}
              isRequired={!get(currentFormValues, FIELDS.REDUCED_FEE_COLLECTION.name)}
              openToDate={
                get(currentFormValues, FIELDS.REDUCED_PAY_DATE.name) || formatDate(new Date())
              }
              onChangeAction={date => {
                change(SCHOOL_INFORMATION_FORM, FIELDS.REDUCED_FEE_COLLECTION.name, false)
              }}
              wrapperStyle={{
                display: 'inline-block',
                marginLeft: '30px',
                width: 'calc(100% - 30px)',
                minWidth: '140px',
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12" style={{ marginBottom: '48px' }}>
            <Input
              type="radio"
              name={FIELDS.REDUCED_FEE_COLLECTION.name}
              isRequired={FIELDS.REDUCED_FEE_COLLECTION.required}
              label={FIELDS.REDUCED_FEE_COLLECTION.label}
              value={true}
              normalize={val => val === 'true'}
              onChange={val => {
                if (val) {
                  change(SCHOOL_INFORMATION_FORM, FIELDS.REDUCED_PAY_DATE.name, '')
                }
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <Input
              type="radio"
              name={FIELDS.STANDARD_FEE_COLLECTION.name}
              isRequired={FIELDS.STANDARD_FEE_COLLECTION.required}
              label={FIELDS.STANDARD_PAY_DATE.label}
              srOnlyLabel={true}
              value={false}
              normalize={val => val === 'true'}
              style={{ lineHeight: '104px', display: 'inline', position: 'absolute' }}
            />
            <DateSelector
              name={FIELDS.STANDARD_PAY_DATE.name}
              form={SCHOOL_INFORMATION_FORM}
              label={
                <>
                  Students who <strong>are not</strong> eligible for the College Board fee
                  reduction:
                </>
              }
              disabled={get(currentFormValues, FIELDS.STANDARD_FEE_COLLECTION.name)}
              isRequired={!get(currentFormValues, FIELDS.STANDARD_FEE_COLLECTION.name)}
              openToDate={
                get(currentFormValues, FIELDS.STANDARD_PAY_DATE.name) || formatDate(new Date())
              }
              onChangeAction={date => {
                change(SCHOOL_INFORMATION_FORM, FIELDS.STANDARD_FEE_COLLECTION.name, false)
              }}
              wrapperStyle={{
                display: 'inline-block',
                marginLeft: '30px',
                width: 'calc(100% - 30px)',
                minWidth: '140px',
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Input
              type="radio"
              name={FIELDS.STANDARD_FEE_COLLECTION.name}
              isRequired={FIELDS.STANDARD_FEE_COLLECTION.required}
              label={FIELDS.STANDARD_FEE_COLLECTION.label}
              value={true}
              normalize={val => val === 'true'}
              onChange={val => {
                if (val) {
                  change(SCHOOL_INFORMATION_FORM, FIELDS.STANDARD_PAY_DATE.name, '')
                }
              }}
            />
          </div>
        </div>
      </div>
    </fieldset>
  )
}

export default connect(mapStateToProps, { change })(RequireStudentsPayAPFeesBy)
