import { StreetAddress, Loader } from '.'
import { fetchShippingAddress } from '../../actions/shipments'

const mapStateToProps = state => {
  const {
    shipmentsAddress,
    user: {
      data: { selectedRole, roles },
    },
  } = state
  const { aiCode } = roles[selectedRole]

  return {
    ...shipmentsAddress,
    address: shipmentsAddress[aiCode],
    fetching: shipmentsAddress.fetching,
    error: shipmentsAddress.error,
    aiCode,
  }
}

const ShippingAddress = ({ fetching, error, address, aiCode, fetchShippingAddress }) => {
  useEffect(() => {
    if (!address && !fetching) {
      fetchShippingAddress(aiCode)
    }
  }, [])

  if (error || !address)
    return (
      <div>
        <em>Not Available</em>
      </div>
    )

  if (fetching) return <Loader size="sm" />

  if (address)
    return (
      <>
        <div>
          <strong>{address.schoolName}</strong>
        </div>
        <StreetAddress {...address} />
      </>
    )

  return null
}

export default connect(mapStateToProps, { fetchShippingAddress })(ShippingAddress)
