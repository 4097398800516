import * as c from '../constants/SettingsConstants'

const initialState = {
  fetching: false,
  fetched: false,
  fetchingAllowedToGrant: false,
  saving: false,
  saved: false,
  error: null,
  saveError: null,
  allowedToGrantAccess: false,
  aproGrantedCourses: [],
  apcaGrantedCourses: [],
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case c.TEACHERS_GRANTED_ACCESS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }

    case c.TEACHERS_GRANTED_ACCESS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        aproGrantedCourses: payload.aproGrantedCourses,
        apcaGrantedCourses: payload.apcaGrantedCourses,
      }

    case c.TEACHERS_GRANTED_ACCESS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    case c.REMOVE_TEACHER_GRANTED_ACCESS_FULFILLED:
      return {
        ...state,
        aproGrantedCourses: state.aproGrantedCourses.filter(
          grant => grant.proId !== payload.proId || grant.testCd !== payload.testCd
        ),
      }

    case c.GRANT_TEACHER_ACCESS_TO_COURSE_FULFILLED: {
      // search over list
      const index = state.aproGrantedCourses.findIndex(grant => {
        return grant.proId === payload.proId && grant.testCd === payload.testCd
      })
      //   if no match found
      if (index === -1) {
        // add this new grant to the data set
        return {
          ...state,
          aproGrantedCourses: [...state.aproGrantedCourses, { ...payload, canRevoke: true }],
        }
      }
      return state
    }

    case c.FETCH_ALLOWED_TO_GRANT_ACCESS_PENDING:
      return { ...state, fetchingAllowedToGrant: true }

    case c.FETCH_ALLOWED_TO_GRANT_ACCESS_FULFILLED:
      return { ...state, allowedToGrantAccess: payload, fetchingAllowedToGrant: false }

    case c.FETCH_ALLOWED_TO_GRANT_ACCESS_REJECTED:
      return { ...state, allowedToGrantAccess: false, fetchingAllowedToGrant: false }

    case c.TOGGLE_AUTHORIZED_TEACHERS_ACCESS_PENDING:
      return { ...state, saving: true, saved: false, saveError: null }

    case c.TOGGLE_AUTHORIZED_TEACHERS_ACCESS_FULFILLED: {
      const { proId, testCd, certOverrideInd } = payload
      const newAuthorizationsData = state.apcaGrantedCourses.slice()
      const authIndex = newAuthorizationsData.findIndex(
        auth => auth.proId === proId && auth.testCd === testCd
      )
      newAuthorizationsData[authIndex] = { ...newAuthorizationsData[authIndex], certOverrideInd }
      return { ...state, saving: false, saved: true, apcaGrantedCourses: newAuthorizationsData }
    }

    case c.TOGGLE_AUTHORIZED_TEACHERS_ACCESS_REJECTED:
      return { ...state, saving: false, saved: false, saveError: payload }

    case c.RESET_ENABLE_DISABLE_TEACHER_AUTHORIZATION:
      return { ...state, saveError: null }

    default:
      return state
  }
}
