import { change } from 'redux-form'
import {
  Text,
  Input,
  LimitedStringValidation,
  ApIdValidation,
} from '../common'
import {
  INCIDENT_REPORT_FORM_NAME,
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
} from '../../constants/IncidentReportConstants'
import { isEmpty } from '../../utils/common'

function IncidentReportStudentsInvolvedFields({ currentValues, addStudent, removeStudent, change }) {
  const fullTestCenterImpactedIndChecked =
    currentValues[SHARED_FIELDS.fullTestCenterImpactedInd.name]

  const studentHeaderStyles = {
    fontWeight: 'bold',
    opacity: `${currentValues[SHARED_FIELDS.fullTestCenterImpactedInd.name] ? '.67' : '1'}`,
  }

  useEffect(() => {
    if (fullTestCenterImpactedIndChecked) {
      // Empty any students listed
      change(INCIDENT_REPORT_FORM_NAME, SHARED_FIELDS.students.name, [])
      change(INCIDENT_REPORT_FORM_NAME, SHARED_FIELDS.studentFirstName.name, '')
      change(INCIDENT_REPORT_FORM_NAME, SHARED_FIELDS.studentLastName.name, '')
      change(INCIDENT_REPORT_FORM_NAME, SHARED_FIELDS.studentApId.name, '')
    }
  }, [fullTestCenterImpactedIndChecked])

  return (
    <div className="row">
      <div className="col-xs-12">
        <h3 className="cb-required">Students Involved</h3>
        <fieldset>
          <div className="row">
            <div className="col-xs-12">
              <Input
                type="checkbox"
                label="Select if all students taking the exam were impacted."
                name={SHARED_FIELDS.fullTestCenterImpactedInd.name}
                isRequired={SHARED_FIELDS.fullTestCenterImpactedInd.required}
                style={{ marginBottom: '24px' }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <div id="firstNameHeader" style={studentHeaderStyles}>
                    First Name
                  </div>
                </div>
                <div className="col-md-3 col-xs-12" style={studentHeaderStyles}>
                  <div id="lastNameHeader">Last Name</div>
                </div>
                <div className="col-md-3 col-xs-12" style={studentHeaderStyles}>
                  <div id="apIdHeader">AP ID</div>
                </div>
              </div>
              {currentValues?.[SHARED_FIELDS.students.name]?.map(student => (
                <div className="row" key={student[SHARED_FIELDS.studentApId.name]}>
                  <div className="col-md-3 col-xs-12">
                    <div aria-describedby="firstNameHeader">
                      {student[SHARED_FIELDS.firstName.name]}
                    </div>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <div aria-describedby="lastNameHeader">
                      {student[SHARED_FIELDS.lastName.name]}
                    </div>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <div aria-describedby="apIdHeader">
                      {student[SHARED_FIELDS.studentApId.name]}
                    </div>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <button
                      type="button"
                      className="btn-link"
                      id={`apId_${student[SHARED_FIELDS.studentApId.name]}`}
                      onClick={removeStudent}
                      style={{ color: '#f00' }}
                    >
                      <span className="glyphicon glyphicon-minus" /> Remove Student
                    </button>
                  </div>
                </div>
              ))}
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <Text
                    name={SHARED_FIELDS.studentFirstName.name}
                    isRequired={SHARED_FIELDS.studentFirstName.required}
                    placeholder="Enter First Name"
                    ariaLabel="First Name"
                    validate={[LimitedStringValidation]}
                    maxlength={20}
                    disabled={currentValues[SHARED_FIELDS.fullTestCenterImpactedInd.name]}
                  />
                </div>
                <div className="col-md-3 col-xs-12">
                  <Text
                    name={SHARED_FIELDS.studentLastName.name}
                    isRequired={SHARED_FIELDS.studentLastName.required}
                    placeholder="Enter Last Name"
                    ariaLabel="Last Name"
                    validate={[LimitedStringValidation]}
                    maxlength={30}
                    disabled={currentValues[SHARED_FIELDS.fullTestCenterImpactedInd.name]}
                  />
                </div>
                <div className="col-md-3 col-xs-12">
                  <Text
                    name={SHARED_FIELDS.studentApId.name}
                    isRequired={SHARED_FIELDS.studentApId.required}
                    placeholder="Enter AP ID"
                    ariaLabel="AP ID"
                    normalize={val => val.toUpperCase()}
                    validate={[ApIdValidation]}
                    maxlength={8}
                    disabled={currentValues[SHARED_FIELDS.fullTestCenterImpactedInd.name]}
                  />
                </div>
                <div className="col-md-3 col-xs-12">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    disabled={
                      isEmpty(currentValues[SHARED_FIELDS.studentFirstName.name]) ||
                      isEmpty(currentValues[SHARED_FIELDS.studentLastName.name]) ||
                      isEmpty(currentValues[SHARED_FIELDS.studentApId.name]) ||
                      ApIdValidation(currentValues[SHARED_FIELDS.studentApId.name])
                    }
                    onClick={addStudent}
                    style={{ marginTop: 0 }}
                  >
                    <span className="glyphicon glyphicon-plus" /> Add Another Student
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  )
}

export default connect(state => state, { change })(IncidentReportStudentsInvolvedFields)
