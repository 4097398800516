import * as c from '../../constants/ShipmentConstants'

const mapStateToProps = ({
  user: {
    data: { isCSR, isSchoolAdmin },
  },
}) => {
  return {
    readAccess: false, // All user roles can access
  }
}

const organizeTracking = trackingInfo => {
  return trackingInfo.reduce((acc, info) => {
    if (!acc[info.carrier]) acc[info.carrier] = [info.trackingNum]
    else acc[info.carrier].push(info.trackingNum)
    return acc
  }, {})
}

const generateLinks = ({ updatedTrackingInfo, readAccess, id }) => {
  return c.VENDOR_LIST.reduce((acc, vendor) => {
    if (updatedTrackingInfo[vendor]) {
      switch (vendor) {
        case c.CARRIER_PRIORITY_SOLUTIONS:
        case c.CARRIER_UPS_FREIGHT:
        case c.CARRIER_UPS:
          acc.push(
            <CarrierHomeLink
              vendor={vendor}
              trackingInfo={updatedTrackingInfo}
              key={`Track_${vendor}_${id}`}
            />
          )
          break
        case c.CARRIER_NASSAU:
          acc.push(<Nassau key={`Track_${vendor}_${id}`} />)
          break
        case c.CARRIER_EXPRESS:
        case c.CARRIER_DHL:
        case c.CARRIER_USPS:
          acc.push(
            <CarrierTrackingLink
              vendor={vendor}
              trackingInfo={updatedTrackingInfo}
              readAccess={readAccess}
              key={`Track_${vendor}_${id}`}
            />
          )
          break
        default:
          break
      }
    }
    return acc
  }, [])
}

const CarrierHomeLink = ({ vendor, trackingInfo }) => (
  <>
    <strong className="cb-small-font-size">
      Tracking with{' '}
      <a
        href={c.trackingLink(vendor)}
        sr-only="This will open a new window"
        className="btn-link cb-small-font-size"
        target="_blank"
        rel="noopener noreferrer"
      >
        {`${c.VENDOR_DISPLAY[vendor]}:`}
      </a>
    </strong>{' '}
    {trackingInfo[vendor].map(trackNum => (
      <div key={`track_${trackNum}`} className="cb-small-font-size">
        {trackNum}
      </div>
    ))}
  </>
)

const getTrackingLink = ({ readAccess, vendor, trackNum }) => {
  if (readAccess) {
    return (
      <span href={c.trackingLink(vendor, trackNum)} className="btn-link cb-small-font-size">
        {trackNum}
      </span>
    )
  }
  return (
    <a
      href={c.trackingLink(vendor, trackNum)}
      sr-only="This will open a new window"
      className="btn-link cb-small-font-size"
      target="_blank"
      rel="noopener noreferrer"
    >
      {trackNum}
    </a>
  )
}

const CarrierTrackingLink = ({ vendor, trackingInfo, readAccess }) => (
  <>
    <strong className="cb-small-font-size">{`Tracking with ${c.VENDOR_DISPLAY[vendor]}:`}</strong>{' '}
    {trackingInfo[vendor].map(trackNum => (
      <div key={`track_${trackNum}`}>{getTrackingLink({ readAccess, vendor, trackNum })}</div>
    ))}
  </>
)

const Nassau = () => {
  return (
    <>
      <strong className="cb-small-font-size">Shipping with Nassau Courier:</strong>
      <div className="cb-small-font-size">{c.noNumber}</div>
    </>
  )
}

const Tracking = ({ trackingInfo, status, readAccess, id }) => {
  const updatedTrackingInfo = organizeTracking(trackingInfo)

  if (status === c.STATUS_PROCESSING || status === c.STATUS_SUBMITTED)
    return <p className="cb-small-font-size">{c.notify}</p>
  return Object.keys(updatedTrackingInfo).length ? (
    generateLinks({ updatedTrackingInfo, readAccess, id })
  ) : (
    <p className="cb-small-font-size">{c.noInfo}</p>
  )
}

export default connect(mapStateToProps)(Tracking)
