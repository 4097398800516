import memoize from 'memoizee'

export const getSelectedEducationPeriod = memoize(state => {
  const { selectedEducationPeriod } = state.settingsEducationPeriod
  return selectedEducationPeriod
})

export const getSelectedOrgId = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
  } = state
  return selectedOrgId
}

export const isAdministeringDigitalExams = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
  } = state
  return state.settingsOrg[selectedOrgId]?.administeringDigitalExams ?? false
}

export const getSchoolName = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
  } = state
  return state.settingsOrg[selectedOrgId]?.data?.schoolInformation?.name
}

export const getSchoolAICode = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
  } = state
  return state.settingsOrg[selectedOrgId]?.data?.schoolInformation?.aiCode
}

export const getSchoolNameAndAddress = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
  } = state
  const schoolName = getSchoolName(state)
  const schoolAddress = state.settingsOrg[selectedOrgId]?.data?.schoolInformation?.address
  return { schoolName, ...schoolAddress }
}

export const getCoordinatorContactInfo = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
  } = state
  return state.settingsOrg[selectedOrgId]?.data?.schoolInformation?.coordinator?.contactInformation
}

export const getDeadlineIsPast = (state, deadlineName) => {
  const educationPeriodCd = getSelectedEducationPeriod(state)
  const {
    settingsDeadlines: { data: deadlines },
  } = state
  return deadlines?.[educationPeriodCd]?.[`${deadlineName}IsPast`] ?? false
}
