import { openModal } from '../../actions/app'
import { sortColumnByKey } from '../../utils/sort'
import {
  EXAM_MATERIALS,
  OTHER_MATERIALS,
  PACKING_LIST_COMPONENT_MAPPING,
  PACKING_LIST_LABEL_MAPPING,
  CHANGE_PACKING_LIST_ITEMS_MODAL,
  EXAM_MATERIAL,
  SUBMITTED,
  ANSWER_SHEETS_RETURNED,
} from '../../constants/PackingListConstants'

const mapStateToProps = state => {
  const {
    packingListDetails: { packingListItems = [], status },
  } = state
  return {
    items: sortColumnByKey(packingListItems, ['courseName'], ['asc']),
    readOnly: status === SUBMITTED,
  }
}

const MaterialRow = ({
  row,
  row: { key, courseName, material, expected, actual, materialName, unused },
  readOnly,
}) => {
  const editButtonRef = useRef()
  const expectedQuantity = () => {
    if (materialName === ANSWER_SHEETS_RETURNED) {
      return expected - unused
    }
    return expected
  }

  return (
    <tr key={key}>
      <th scope="row">{courseName}</th>
      <td>{material}</td>
      <td>{expectedQuantity()}</td>
      <td>{actual}</td>
      {!readOnly ? (
        <td style={{ textAlign: 'center' }}>
          <button
            style={{ padding: '0' }}
            type="button"
            className="btn-link"
            ref={editButtonRef}
            onClick={() => row.handleClick(editButtonRef.current, row)}
          >
            Edit
          </button>
        </td>
      ) : null}
    </tr>
  )
}

export default connect(
  mapStateToProps,
  { openModal }
)(({ items, readOnly, openModal }) => {
  const rows = []
  const len = items.length
  for (let i = 0; i < len; i = i + 1) {
    const item = items[i]
    const { examsQuantity: originalQty, courseName, testCd } = item
    const addDiscrepancy = mat => {
      rows.push({
        courseName,
        material: PACKING_LIST_COMPONENT_MAPPING[mat],
        expected: originalQty,
        actual: item[EXAM_MATERIAL][mat],
        key: `${mat}-${testCd}`,
        materials: item[EXAM_MATERIAL],
        packingListItemId: item.packingListItemId,
        materialName: mat,
        unused: item.unused,
        headerTitle: `${courseName}: Edit Materials Quantities`,
        handleClick: (ref, row) => {
          const { materials } = row
          const filterMaterials = materials =>
            Object.keys(materials).filter(key => EXAM_MATERIALS.includes(key))

          const formattedMaterials = filterMaterials(materials).map(key => ({
            label: PACKING_LIST_COMPONENT_MAPPING[key],
            name: key,
            quantity: materials[key],
            returnedReason: materials[`${key}Reason`] || '',
          }))

          openModal(CHANGE_PACKING_LIST_ITEMS_MODAL, {
            materials: formattedMaterials,
            shippedQuantity: row.expected,
            packingListItemId: row.packingListItemId,
            headerTitle: row.headerTitle,
            modalCloseFocusElem: ref,
            unused: item.unused,
          })
        },
      })
    }
    EXAM_MATERIALS.forEach(mat => {
      if (mat === ANSWER_SHEETS_RETURNED) {
        // special case for answer sheets since the returned quantity must factor in unused quantity
        if (
          item.examMaterial &&
          item.examMaterial[mat] !== undefined &&
          item.examMaterial[mat] !== originalQty - item.unused
        ) {
          addDiscrepancy(mat)
        }
      } else if (
        item.examMaterial &&
        item.examMaterial[mat] !== undefined &&
        item.examMaterial[mat] !== originalQty
      ) {
        addDiscrepancy(mat)
      }
    })
    OTHER_MATERIALS.forEach(mat => {
      if (item[mat] !== undefined && item[mat].returned !== item[mat].quantity) {
        rows.push({
          courseName,
          material: PACKING_LIST_LABEL_MAPPING[mat],
          expected: item[mat].quantity,
          actual: item[mat].returned,
          key: `${mat}-${testCd}`,
          materials: item[mat],
          packingListItemId: item.packingListItemId,
          headerTitle: `${courseName}: Edit Quantities`,
          handleClick: (ref, row) => {
            const { materials } = row
            const formattedMaterials = [
              {
                label: PACKING_LIST_LABEL_MAPPING[mat],
                name: mat,
                quantity: materials.returned,
                returnedReason: materials.returnedReason || '',
              },
            ]

            openModal(CHANGE_PACKING_LIST_ITEMS_MODAL, {
              materials: formattedMaterials,
              shippedQuantity: materials.quantity,
              packingListItemId: row.packingListItemId,
              headerTitle: row.headerTitle,
              modalCloseFocusElem: ref,
            })
          },
        })
      }
    })
  }

  if (rows.length)
    return (
      <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '24px' }}>
        <h3>Material Discrepancies</h3>
        <table className="table cb-no-table-border">
          <thead>
            <tr>
              <th scope="col">Exam</th>
              <th scope="col">Material</th>
              <th scope="col">Expected</th>
              <th scope="col">Actual</th>
              {!readOnly ? (
                <th scope="col">
                  <span className="sr-only">Action</span>
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <MaterialRow key={row.key} row={row} readOnly={readOnly} />
            ))}
          </tbody>
        </table>
      </div>
    )
  return null
})
