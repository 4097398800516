export const FETCH_EXAM_ORDER_SUMMARY_PENDING = 'FETCH_EXAM_ORDER_SUMMARY_PENDING'
export const FETCH_EXAM_ORDER_SUMMARY_FULFILLED = 'FETCH_EXAM_ORDER_SUMMARY_FULFILLED'
export const FETCH_EXAM_ORDER_SUMMARY_REJECTED = 'FETCH_EXAM_ORDER_SUMMARY_REJECTED'

export const FETCH_APPROVAL_REQUEST_COUNTS_PENDING = 'FETCH_APPROVAL_REQUEST_COUNTS_PENDING'
export const FETCH_APPROVAL_REQUEST_COUNTS_FULFILLED = 'FETCH_APPROVAL_REQUEST_COUNTS_FULFILLED'
export const FETCH_APPROVAL_REQUEST_COUNTS_REJECTED = 'FETCH_APPROVAL_REQUEST_COUNTS_REJECTED'

export const FETCH_ORDER_UNSUBMITTED_CHANGES_PENDING = 'FETCH_ORDER_UNSUBMITTED_CHANGES_PENDING'
export const FETCH_ORDER_UNSUBMITTED_CHANGES_FULFILLED = 'FETCH_ORDER_UNSUBMITTED_CHANGES_FULFILLED'
export const FETCH_ORDER_UNSUBMITTED_CHANGES_REJECTED = 'FETCH_ORDER_UNSUBMITTED_CHANGES_REJECTED'

export const FETCH_ORDER_HISTORY_PENDING = 'FETCH_ORDER_HISTORY_PENDING'
export const FETCH_ORDER_HISTORY_FULFILLED = 'FETCH_ORDER_HISTORY_FULFILLED'
export const FETCH_ORDER_HISTORY_REJECTED = 'FETCH_ORDER_HISTORY_REJECTED'

export const FETCH_SPECIAL_EXAM_REQUESTS_PENDING = 'FETCH_SPECIAL_EXAM_REQUESTS_PENDING'
export const FETCH_SPECIAL_EXAM_REQUESTS_FULFILLED = 'FETCH_SPECIAL_EXAM_REQUESTS_FULFILLED'
export const FETCH_SPECIAL_EXAM_REQUESTS_REJECTED = 'FETCH_SPECIAL_EXAM_REQUESTS_REJECTED'

export const FETCH_SECURE_MATERIALS_REQUESTS_PENDING = 'FETCH_SECURE_MATERIALS_REQUESTS_PENDING'
export const FETCH_SECURE_MATERIALS_REQUESTS_FULFILLED = 'FETCH_SECURE_MATERIALS_REQUESTS_FULFILLED'
export const FETCH_SECURE_MATERIALS_REQUESTS_REJECTED = 'FETCH_SECURE_MATERIALS_REQUESTS_REJECTED'

export const FETCH_NON_SECURE_MATERIALS_REQUESTS_PENDING =
  'FETCH_NON_SECURE_MATERIALS_REQUESTS_PENDING'
export const FETCH_NON_SECURE_MATERIALS_REQUESTS_FULFILLED =
  'FETCH_NON_SECURE_MATERIALS_REQUESTS_FULFILLED'
export const FETCH_NON_SECURE_MATERIALS_REQUESTS_REJECTED =
  'FETCH_NON_SECURE_MATERIALS_REQUESTS_REJECTED'

export const FETCH_EXCEPTION_TESTING_EXAM_REQUESTS_PENDING =
  'FETCH_EXCEPTION_TESTING_EXAM_REQUESTS_PENDING'
export const FETCH_EXCEPTION_TESTING_EXAM_REQUESTS_FULFILLED =
  'FETCH_EXCEPTION_TESTING_EXAM_REQUESTS_FULFILLED'
export const FETCH_EXCEPTION_TESTING_EXAM_REQUESTS_REJECTED =
  'FETCH_EXCEPTION_TESTING_EXAM_REQUESTS_REJECTED'

export const FETCH_ORDERS_AFTER_DEADLINE_REQUESTS_PENDING =
  'FETCH_ORDERS_AFTER_DEADLINE_REQUESTS_PENDING'
export const FETCH_ORDERS_AFTER_DEADLINE_REQUESTS_FULFILLED =
  'FETCH_ORDERS_AFTER_DEADLINE_REQUESTS_FULFILLED'
export const FETCH_ORDERS_AFTER_DEADLINE_REQUESTS_REJECTED =
  'FETCH_ORDERS_AFTER_DEADLINE_REQUESTS_REJECTED'

export const FETCH_OFF_CYCLE_TESTING_REQUESTS_PENDING = 'FETCH_OFF_CYCLE_TESTING_REQUESTS_PENDING'
export const FETCH_OFF_CYCLE_TESTING_REQUESTS_FULFILLED =
  'FETCH_OFF_CYCLE_TESTING_REQUESTS_FULFILLED'
export const FETCH_OFF_CYCLE_TESTING_REQUESTS_REJECTED = 'FETCH_OFF_CYCLE_TESTING_REQUESTS_REJECTED'

export const FETCH_STUDENT_LABEL_SORT_ORDER_PENDING = 'FETCH_STUDENT_LABEL_SORT_ORDER_PENDING'
export const FETCH_STUDENT_LABEL_SORT_ORDER_FULFILLED = 'FETCH_STUDENT_LABEL_SORT_ORDER_FULFILLED'
export const FETCH_STUDENT_LABEL_SORT_ORDER_REJECTED = 'FETCH_STUDENT_LABEL_SORT_ORDER_REJECTED'

export const FETCH_PROGRAM_STATS_PENDING = 'FETCH_PROGRAM_STATS_PENDING'
export const FETCH_PROGRAM_STATS_FULFILLED = 'FETCH_PROGRAM_STATS_FULFILLED'
export const FETCH_PROGRAM_STATS_REJECTED = 'FETCH_PROGRAM_STATS_REJECTED'

// export const FETCH_UPDATED_ORDER_COUNTS_PENDING   = 'FETCH_UPDATED_ORDER_COUNTS_PENDING'
// export const FETCH_UPDATED_ORDER_COUNTS_FULFILLED = 'FETCH_UPDATED_ORDER_COUNTS_FULFILLED'
// export const FETCH_UPDATED_ORDER_COUNTS_REJECTED  = 'FETCH_UPDATED_ORDER_COUNTS_REJECTED'

export const SUBMIT_STUDENT_LABEL_SORT_ORDER_PENDING = 'SUBMIT_STUDENT_LABEL_SORT_ORDER_PENDING'
export const SUBMIT_STUDENT_LABEL_SORT_ORDER_FULFILLED = 'SUBMIT_STUDENT_LABEL_SORT_ORDER_FULFILLED'

export const SUBMIT_EXAM_ORDERS_PENDING = 'SUBMIT_EXAM_ORDERS_PENDING'
export const SUBMIT_EXAM_ORDERS_FULFILLED = 'SUBMIT_EXAM_ORDERS_FULFILLED'
export const SUBMIT_EXAM_ORDERS_REJECTED = 'SUBMIT_EXAM_ORDERS_REJECTED'

export const CONFIRM_EXAM_ORDERS_SUBMITTED = 'CONFIRM_EXAM_ORDERS_SUBMITTED'
export const CONFIRM_EXAM_ORDERS_CANCELED = 'CONFIRM_EXAM_ORDERS_CANCELED'
export const CONFIRM_EXAM_ORDERS_PENDING = 'CONFIRM_EXAM_ORDERS_PENDING'

export const FETCH_EXAMS_WITH_SECURE_MATERIALS_PENDING = 'FETCH_EXAMS_WITH_SECURE_MATERIALS_PENDING'
export const FETCH_EXAMS_WITH_SECURE_MATERIALS_FULFILLED =
  'FETCH_EXAMS_WITH_SECURE_MATERIALS_FULFILLED'
export const FETCH_EXAMS_WITH_SECURE_MATERIALS_REJECTED =
  'FETCH_EXAMS_WITH_SECURE_MATERIALS_REJECTED'

export const FETCH_MAX_ALLOWED_SECURE_MATERIALS_PENDING =
  'FETCH_MAX_ALLOWED_SECURE_MATERIALS_PENDING'
export const FETCH_MAX_ALLOWED_SECURE_MATERIALS_FULFILLED =
  'FETCH_MAX_ALLOWED_SECURE_MATERIALS_FULFILLED'
export const FETCH_MAX_ALLOWED_SECURE_MATERIALS_REJECTED =
  'FETCH_MAX_ALLOWED_SECURE_MATERIALS_REJECTED'

export const FETCH_SELECTED_SECURE_MATERIALS_PENDING = 'FETCH_SELECTED_SECURE_MATERIALS_PENDING'
export const FETCH_SELECTED_SECURE_MATERIALS_FULFILLED = 'FETCH_SELECTED_SECURE_MATERIALS_FULFILLED'
export const FETCH_SELECTED_SECURE_MATERIALS_REJECTED = 'FETCH_SELECTED_SECURE_MATERIALS_REJECTED'

export const UPDATE_SELECTED_SECURE_MATERIALS_PENDING = 'UPDATE_SELECTED_SECURE_MATERIALS_PENDING'
export const UPDATE_SELECTED_SECURE_MATERIALS_FULFILLED =
  'UPDATE_SELECTED_SECURE_MATERIALS_FULFILLED'
export const UPDATE_SELECTED_SECURE_MATERIALS_REJECTED = 'UPDATE_SELECTED_SECURE_MATERIALS_REJECTED'

export const STUDENT_LABEL_SORT_ORDER_FORM = 'ordersStudentLabelSortOrderForm'
export const STUDENT_LABEL_SORT_ORDER_SELECT = 'studentLabelSortOrderSelect'
export const STUDENT_LABEL_SORT_ORDER_SORT_BYNAME = 'N'
export const STUDENT_LABEL_SORT_ORDER_SORT_BYFIRSTEXAM = 'E'
export const STUDENT_LABEL_SORT_ORDER_SORT_BYGRADE = 'G'
export const STUDENT_LABEL_SORT_ORDER_SORT_OPTIONS = [
  { label: 'Student Name', value: STUDENT_LABEL_SORT_ORDER_SORT_BYNAME },
  { label: 'Grade Level', value: STUDENT_LABEL_SORT_ORDER_SORT_BYGRADE },
  { label: 'Student First Exam', value: STUDENT_LABEL_SORT_ORDER_SORT_BYFIRSTEXAM },
]
export const STUDENT_LABEL_SORT_ORDER_MAP = {
  [STUDENT_LABEL_SORT_ORDER_SORT_BYNAME]: 'studentName',
  [STUDENT_LABEL_SORT_ORDER_SORT_BYFIRSTEXAM]: ['examDateTime', 'studentName'],
  [STUDENT_LABEL_SORT_ORDER_SORT_BYGRADE]: ['grade', 'studentName'],
}

export const PENDING_ORDERS_TABLE = 'PENDING_ORDERS_TABLE'
export const SUBMITTED_ORDERS_TABLE = 'SUBMITTED_ORDERS_TABLE'

export const ORDER_TAB_EXAMS = 'examsPanel'
export const ORDER_TAB_LABELS = 'studentLabelPanel'
export const ORDER_TAB_MATERIALS = 'otherMaterialsPanel'
export const ORDER_TAB_SERVICES = 'scoreReportingServicesPanel'
export const ORDER_TAB_REBATE = 'volumeRebates'

export const ORDER_NOT_SUBMITTED = 'NOT_SUBMITTED'
export const ORDER_SUBMITTED = 'SUBMITTED'
export const ORDER_PENDING = 'PENDING_RESUBMIT'
export const ORDER_CREATED = 'CREATED'

export const APPROVAL_NOT_SUBMITTED = 'APPROVAL_NOT_SUBMITTED'
export const APPROVAL_PENDING = 'APPROVAL_PENDING'
export const APPROVAL_APPROVED = 'APPROVED'
export const APPROVAL_REJECTED = 'REJECTED'
export const APPROVAL_REQUIRES_INFO = 'REQUIRES_INFO'
export const APPROVAL_CANCELLED = 'CANCELLED'
export const APPROVAL_STATUS = {
  [APPROVAL_NOT_SUBMITTED]: 'Not Submitted',
  [APPROVAL_PENDING]: 'Pending Approval',
  [APPROVAL_APPROVED]: 'Approved',
  [APPROVAL_REJECTED]: 'Denied',
  [APPROVAL_REQUIRES_INFO]: 'Needs Info',
  [APPROVAL_CANCELLED]: 'Cancelled',
}
export const APPROVAL_STATUS_LABEL = {
  [APPROVAL_NOT_SUBMITTED]: 'not-submitted',
  [APPROVAL_PENDING]: 'new',
  [APPROVAL_APPROVED]: 'approved',
  [APPROVAL_REQUIRES_INFO]: 'needs-info',
  [APPROVAL_REJECTED]: 'denied',
}

export const APPROVAL_STATUS_SORT_ORDER = {
  [APPROVAL_PENDING]: 1,
  [APPROVAL_REQUIRES_INFO]: 2,
  [APPROVAL_REJECTED]: 3,
  [APPROVAL_APPROVED]: 4,
}

export const SECURE_MATERIAL_APPROVAL_NOT_REQUIRED = 'APPROVAL_NOT_REQUIRED'
export const SECURE_MATERIAL_PENDING_SUBMISSION = 'PENDING_SUBMISSION'
export const SECURE_MATERIAL_PENDING_RESUBMISSION = 'PENDING_RESUBMISSION'
export const SECURE_MATERIAL_APPROVAL_PENDING = 'APPROVAL_PENDING'
export const SECURE_MATERIAL_APPROVED = 'APPROVED'
export const SECURE_MATERIAL_REJECTED = 'REJECTED'
export const SECURE_MATERIAL_REQUIRES_INFO = 'REQUIRES_INFO'
export const SECURE_MATERIAL_CANCELLED = 'CANCELLED'
export const SECURE_MATERIAL_STATUS = {
  [SECURE_MATERIAL_APPROVAL_NOT_REQUIRED]: 'Approval Not Required',
  [SECURE_MATERIAL_PENDING_SUBMISSION]: 'Pending Submission',
  [SECURE_MATERIAL_PENDING_RESUBMISSION]: 'Not Submitted',
  [SECURE_MATERIAL_APPROVAL_PENDING]: 'Pending Approval',
  [SECURE_MATERIAL_APPROVED]: 'Approved',
  [SECURE_MATERIAL_REJECTED]: 'Denied',
  [SECURE_MATERIAL_REQUIRES_INFO]: 'Needs Info',
  [SECURE_MATERIAL_CANCELLED]: 'Cancelled',
}

export const ALL_APPROVAL_REQUESTS = 'all'

export const APPROVAL_REQUEST_FILTERS = {
  all: {
    value: ALL_APPROVAL_REQUESTS,
    label: 'All',
  },
  new: {
    value: APPROVAL_PENDING,
    label: APPROVAL_STATUS[APPROVAL_PENDING],
  },
  needsInfo: {
    value: APPROVAL_REQUIRES_INFO,
    label: APPROVAL_STATUS[APPROVAL_REQUIRES_INFO],
  },
  denied: {
    value: APPROVAL_REJECTED,
    label: APPROVAL_STATUS[APPROVAL_REJECTED],
  },
  approved: {
    value: APPROVAL_APPROVED,
    label: APPROVAL_STATUS[APPROVAL_APPROVED],
  },
}

export const SET_APPROVAL_REQUEST_FILTER = 'SET_APPROVAL_REQUEST_FILTER'

export const SELECT_SECURE_MATERIALS_FORM = 'selectSecureMaterialsForm'
export const REASON_FOR_SECURE_MATERIALS_FIELD = 'reasonForOrder'

export const SCORE_REPORTING_SERVICES_BOOKLET_FIELD = 'freeResponseBooklets'
export const SCORE_REPORTING_SERVICES_LABEL_FIELD = 'scoreLabelsQuantity'
export const SCORE_REPORTING_SERVICES_LABELS = {
  [SCORE_REPORTING_SERVICES_BOOKLET_FIELD]: 'Free-Response Booklets',
  [SCORE_REPORTING_SERVICES_LABEL_FIELD]: 'AP Score Labels',
}
export const FETCH_SCORE_REPORTING_SERVICES_PENDING = 'FETCH_SCORE_REPORTING_SERVICES_PENDING'
export const FETCH_SCORE_REPORTING_SERVICES_FULFILLED = 'FETCH_SCORE_REPORTING_SERVICES_FULFILLED'
export const FETCH_SCORE_REPORTING_SERVICES_REJECTED = 'FETCH_SCORE_REPORTING_SERVICES_REJECTED'
export const SAVE_SCORE_REPORTING_SERVICES_PENDING = 'SAVE_SCORE_REPORTING_SERVICES_PENDING'
export const SAVE_SCORE_REPORTING_SERVICES_FULFILLED = 'SAVE_SCORE_REPORTING_SERVICES_FULFILLED'
export const SAVE_SCORE_REPORTING_SERVICES_REJECTED = 'SAVE_SCORE_REPORTING_SERVICES_REJECTED'

export const FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_PENDING =
  'FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_PENDING'
export const FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_FULFILLED =
  'FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_FULFILLED'
export const FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_REJECTED =
  'FETCH_COORD_NON_SECURE_ANCILLARY_MATERIALS_REJECTED'
export const SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_PENDING =
  'SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_PENDING'
export const SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_FULFILLED =
  'SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_FULFILLED'
export const SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_REJECTED =
  'SAVE_COORD_NON_SECURE_ANCILLARY_MATERIALS_REJECTED'

export const OTHER_MATERIALS_FORM_NAME = 'otherMaterialsForm'

export const SETUP_NON_SECURE_ANCILLARY_MATERIALS_FORM_DATA =
  'SETUP_NON_SECURE_ANCILLARY_MATERIALS_FORM_DATA'
export const CLEAR_NON_SECURE_ANCILLARY_MATERIALS_FORM_DATA =
  'CLEAR_NON_SECURE_ANCILLARY_MATERIALS_FORM_DATA'

export const CHANGE_NON_SECURE_MATERIAL_SELECTED_QUANTITY =
  'CHANGE_NON_SECURE_MATERIAL_SELECTED_QUANTITY'
export const CHANGE_NON_SECURE_MATERIAL_PROVIDED_REASON =
  'CHANGE_NON_SECURE_MATERIAL_PROVIDED_REASON'
export const SET_NON_SECURE_MATERIAL_REASON_VALIDATION_ERROR =
  'SET_NON_SECURE_MATERIAL_REASON_VALIDATION_ERROR'

export const FETCH_VOLUME_REBATE_PENDING = 'FETCH_VOLUME_REBATE_PENDING'
export const FETCH_VOLUME_REBATE_REJECTED = 'FETCH_VOLUME_REBATE_REJECTED'
export const FETCH_VOLUME_REBATE_FULFILLED = 'FETCH_VOLUME_REBATE_FULFILLED'
export const SAVE_VOLUME_REBATE_PENDING = 'SAVE_VOLUME_REBATE_PENDING'
export const SAVE_VOLUME_REBATE_REJECTED = 'SAVE_VOLUME_REBATE_REJECTED'
export const SAVE_VOLUME_REBATE_FULFILLED = 'SAVE_VOLUME_REBATE_FULFILLED'
export const RESET_VOLUME_REBATE = 'RESET_VOLUME_REBATE'

export const NON_SECURE_MATERIALS_TEXT_INPUTS = ['cd_3873', 'cd_2041', 'cd_2064']

export const FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_PENDING =
  'FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_PENDING'
export const FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_FULFILLED =
  'FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_FULFILLED'
export const FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_REJECTED =
  'FETCH_FREE_RESPONSE_BOOKLETS_FOR_DOWNLOAD_REJECTED'

export const FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_PENDING =
  'FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_PENDING'
export const FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_FULFILLED =
  'FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_FULFILLED'
export const FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_REJECTED =
  'FETCH_FREE_RESPONSE_BOOKLET_DOWNLOAD_URL_REJECTED'

export const FETCH_EXCEPTION_TESTING_AR_DETAILS_PENDING =
  'FETCH_EXCEPTION_TESTING_AR_DETAILS_PENDING'
export const FETCH_EXCEPTION_TESTING_AR_DETAILS_FULFILLED =
  'FETCH_EXCEPTION_TESTING_AR_DETAILS_FULFILLED'
export const FETCH_EXCEPTION_TESTING_AR_DETAILS_REJECTED =
  'FETCH_EXCEPTION_TESTING_AR_DETAILS_REJECTED'

export const FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_PENDING =
  'FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_PENDING'
export const FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_FULFILLED =
  'FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_FULFILLED'
export const FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_REJECTED =
  'FETCH_ORDERS_AFTER_DEADLINE_AR_DETAILS_REJECTED'

export const RESET_EXCEPTION_TESTING_AR_DETAILS = 'RESET_EXCEPTION_TESTING_AR_DETAILS'
export const RESET_ORDERS_AFTER_DEADLINE_AR_DETAILS = 'RESET_ORDERS_AFTER_DEADLINE_AR_DETAILS'
