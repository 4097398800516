export const CHANGE_STEP = 'CHANGE_STEP'
export const FINISH_COORDINATOR_SETUP = 'FINISH_COORDINATOR_SETUP'
export const SET_PARTFORM_TO_SIGNED = 'SET_PARTFORM_TO_SIGNED'

export const SCHOOL_INFORMATION_FORM = 'schoolInformationForm'
export const SCHOOL_INFORMATION_FIELDS = {
  COORD_FNAME: {
    name: 'schoolInformation.coordinator.contactInformation.firstName',
    required: true,
  },
  COORD_MI: { name: 'schoolInformation.coordinator.contactInformation.middleInitial' },
  COORD_LNAME: {
    name: 'schoolInformation.coordinator.contactInformation.lastName',
    required: true,
  },
  COORD_PHONE: {
    name: 'schoolInformation.coordinator.contactInformation.telephone',
    required: true,
  },
  COORD_FAX: { name: 'schoolInformation.coordinator.contactInformation.fax' },
  COORD_EMAIL: { name: 'schoolInformation.coordinator.contactInformation.email', required: true },
  TECH_COORD_FNAME: {
    name: 'schoolInformation.technologyCoordinator.firstName',
    required: true,
  },
  TECH_COORD_MI: {
    name: 'schoolInformation.technologyCoordinator.middleInitial',
  },
  TECH_COORD_LNAME: {
    name: 'schoolInformation.technologyCoordinator.lastName',
    required: true,
  },
  TECH_COORD_PHONE: {
    name: 'schoolInformation.technologyCoordinator.telephone',
    required: false,
  },
  TECH_COORD_EMAIL: {
    name: 'schoolInformation.technologyCoordinator.email',
    required: true,
  },
  PROFDEV: { name: 'schoolInformation.coordinator.allowShareProfessionalDevelopment' },
  HOMESCH: { name: 'schoolInformation.coordinator.allowShareHomeSchool' },
  PRIN_FNAME: { name: 'schoolInformation.principal.firstName', required: true },
  PRIN_MI: { name: 'schoolInformation.principal.middleInitial' },
  PRIN_LNAME: { name: 'schoolInformation.principal.lastName', required: true },
  PRIN_PHONE: { name: 'schoolInformation.principal.telephone' },
  PRIN_EMAIL: { name: 'schoolInformation.principal.email', required: true },
  AUTH_FNAME: { name: 'schoolInformation.additionalStaff.firstName' },
  AUTH_MI: { name: 'schoolInformation.additionalStaff.middleInitial' },
  AUTH_LNAME: { name: 'schoolInformation.additionalStaff.lastName' },
  AUTH_EMAIL: { name: 'schoolInformation.additionalStaff.email' },
  POP9: { name: 'schoolInformation.studentPopulation.grade9' },
  POP10: { name: 'schoolInformation.studentPopulation.grade10' },
  POP11: { name: 'schoolInformation.studentPopulation.grade11' },
  POP12: { name: 'schoolInformation.studentPopulation.grade12' },
  REDUCED_FEE_CEP_IND: {
    name: 'schoolInformation.schoolInfo.reducedFeeCEPInd',
    label:
      'My school has adopted the Community Eligibility Provision (CEP) for the current academic year.',
  },
  REDUCED_FEE_PCT: {
    name: 'schoolInformation.schoolInfo.reducedFeeStudentPercentage',
    label: 'Percent of qualifying students',
  },
  CONFIRM: { name: 'schoolInformation.complete', required: true },
  BILL_FNAME: { name: 'schoolInformation.billing.contactInformation.firstName', required: true },
  BILL_MI: { name: 'schoolInformation.billing.contactInformation.middleInitial' },
  BILL_LNAME: { name: 'schoolInformation.billing.contactInformation.lastName', required: true },
  BILL_EMAIL: { name: 'schoolInformation.billing.contactInformation.email', required: true },
  BADDR_STR1: {
    name: 'schoolInformation.billing.contactInformation.address.street1',
    required: true,
  },
  BADDR_STR2: { name: 'schoolInformation.billing.contactInformation.address.street2' },
  BADDR_STR3: { name: 'schoolInformation.billing.contactInformation.address.street3' },
  BADDR_CITY: { name: 'schoolInformation.billing.contactInformation.address.city', required: true },
  BADDR_STATE: { name: 'schoolInformation.billing.contactInformation.address.state' },
  BADDR_PROVINCE: { name: 'schoolInformation.billing.contactInformation.address.province' },
  BADDR_CODE: {
    name: 'schoolInformation.billing.contactInformation.address.postalCode',
    required: true,
  },
  BADDR_COUNTRY: {
    name: 'schoolInformation.billing.contactInformation.address.country',
    required: true,
  },
  BADDR_COUNTRYCODE: {
    name: 'schoolInformation.billing.contactInformation.address.countryCode',
    required: true,
  },
  PO_NUMBER: { name: 'schoolInformation.purchaseOrderNumber', required: true },
  START_DATE: {
    name: 'schoolInformation.schoolInfo.startDate',
    required: true,
    label: 'First day of class:',
  },
  END_DATE: {
    name: 'schoolInformation.schoolInfo.endDate',
    required: true,
    label: 'Last day of class:',
  },
  //REDUCED_PAY_DATE: {
  //  name: 'schoolInformation.schoolInfo.reducedFeePayDate',
  //  required: false,
  //  label: 'Students who are eligible for the College Board fee reduction:',
  //},
  //REDUCED_FEE_COLLECTION: {
  //  name: 'schoolInformation.schoolInfo.reducedFeeNotApplicable',
  //  required: true,
  //  label:
  //    "We don't collect fees from students who are eligible for the College Board fee reduction.",
  //},
  //STANDARD_PAY_DATE: {
  //  name: 'schoolInformation.schoolInfo.standardFeePayDate',
  //  required: false,
  //  label: 'Students who are not eligible for the College Board fee reduction:',
  //},
  //STANDARD_FEE_COLLECTION: {
  //  name: 'schoolInformation.schoolInfo.standardFeeNotApplicable',
  //  required: true,
  //  label:
  //    "We don't collect fees from students who aren't eligible for the College Board fee reduction.",
  //},
  REDUCED_FEE_AVG_COST: {
    name: 'schoolInformation.schoolInfo.reducedFeeAverageExamCost',
    // label: '',
  },
  STANDARD_FEE_AVG_COST: {
    name: 'schoolInformation.schoolInfo.standardFeeAverageExamCost',
    // required: true,
  },
  STUDENTS_USING_PROVIDED_DEVICES: {
    name: 'schoolInformation.schoolInfo.subsidizedDeviceStudentPercentage',
    required: true,
  },
  DEVICE_AVAILABILITY: {
    name: 'schoolInformation.schoolInfo.deviceAvailability',
    required: true,
  },
  GPA_WEIGHTAGE_POLICY: {
    name: 'schoolInformation.schoolInfo.gpaWeightage',
    required: true,
  },
}

export const PROFDEV_TEXT =
  'Organizations that offer professional development or other AP-related resources.'
export const HOMESCHOOL_TEXT =
  'Persons trying to locate a school that will administer exams to home-schooled students or students whose schools do not offer AP.'
export const SCHOOLADDRESS_INSTRUCTIONS =
  'If there is an error in the name or address please contact TDB.'

export const EXAM_ADMINISTRATION_FORM = 'examAdministrationForm'
export const EXAM_ADMINISTRATION_FIELDS_TWO = {
  ORDER_LOCATION: { name: 'orderByAnotherSchool', required: true },
  ORDER_SCHOOL: { name: 'schoolInfo', required: false },
  ORDER_SCHOOL_DISPLAY: { name: 'schoolInfoDisplay', required: false },
  BULLETIN_COPIES: { name: 'numberOfBulletinCopy', required: true },
  EXAM_POLICY: { name: 'bestDescriptionOfExamPolicy', required: true },
}
export const EXAM_ADMINISTRATION_FIELDS_ONE = {
  ORDER_LOCATION: { name: 'orderByAnotherSchool', required: true, disabled: true },
  ORDER_SCHOOL: { name: 'schoolInfo', required: false },
  ORDER_SCHOOL_DISPLAY: { name: 'schoolInfoDisplay', required: false },
  BULLETIN_COPIES: { name: 'numberOfBulletinCopy' },
  EXAM_POLICY: { name: 'bestDescriptionOfExamPolicy', required: true },
}

export const EXAM_DECISION_INDICATOR_FORM = 'examDecisionIndicatorForm'
export const EXAM_DECISION_INDICATOR_FIELDS = {
  DECISION_INDICATOR: { name: 'decisionIndicator', required: true },
}

export const ROSTER_MANAGEMENT_FORM = 'rosterManagementForm'
export const ROSTER_MANAGEMENT_FIELDS = {
  TEACHER_MANAGEMENT: { name: 'canTeacherManage', required: true },
}

export const PARTICIPATION_FORM = 'participationForm'
export const PARTICIPATION_FIELDS = {
  COMPLETE: { name: 'complete' },
}

export const DEVICE_CHROMEBOOKS = 'CHROME_BOOK'
export const DEVICE_IPAD = 'IPAD'
export const DEVICE_MAC = 'MAC_LAPTOPS'
export const DEVICE_WINDOWS = 'WINDOWS_LAPTOPS'
export const DEVICE_NONE = 'NONE'
export const DEVICE_TYPES = {
  [DEVICE_IPAD]: 'iPads',
  [DEVICE_CHROMEBOOKS]: 'Chromebooks',
  [DEVICE_MAC]: 'Mac laptops',
  [DEVICE_WINDOWS]: 'Windows laptops',
  [DEVICE_NONE]: 'None (Exclusive)',
}

export const DISTRIBUTED_IND = 'distributedToStudentInd'
export const AVAILABLE_IND = 'availableInLabOrCartInd'

export const GPA_WEIGHTAGE_EQUAL = 'EQUAL_WEIGHTAGE'
export const GPA_WEIGHTAGE_MORE_WITH_AP_EXAM_TAKEN = 'MORE_WEIGHTAGE_WITH_AP_EXAM_TAKEN'
export const GPA_WEIGHTAGE_MORE = 'MORE_WEIGHTAGE'
