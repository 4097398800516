import { useHistory, useLocation } from 'react-router-dom'
import { Error } from '@myap/ui-library/esm/components'
import { IncidentReportHistory } from '../../components/incidentReports'
import { getIncidentReportAvailability } from '../../selectors/incidentReports'
import { suppressNav, unSuppressNav } from '../../actions/nav'
import { queryParamsToObj } from '../../utils/common'
import { setSelectedEducationPeriod } from '../../actions/settingsGlobal'

const mapStateToProps = state => {
  const {
    settingsEducationPeriod: { selectedIsCurrent, availableEducationPeriods },
  } = state
  const { active } = getIncidentReportAvailability(state)
  return { active, selectedIsCurrent, validEpcs: availableEducationPeriods }
}

const PrintIncidentReportPage = ({
  active,
  selectedIsCurrent,
  suppressNav,
  unSuppressNav,
  validEpcs,
  setSelectedEducationPeriod,
}) => {
  const { search } = useLocation()
  const {
    coursename: courseName = null,
    reportid: reportId = null,
    epc,
  } = queryParamsToObj(search) ?? {}

  const isEPCValid = Object.keys(validEpcs).some(key => key === epc)

  useEffect(() => {
    if (isEPCValid) {
      setSelectedEducationPeriod(epc)
    }
  }, [epc, isEPCValid])

  useEffect(() => {
    suppressNav()

    return () => unSuppressNav()
  }, [])

  if (selectedIsCurrent && !active) {
    return (
      <div className="container" style={{ marginTop: '5.0em' }}>
        <div className="row">
          <div className="col-xs-12">
            <p>Incident Reports are not available at this time.</p>
          </div>
        </div>
      </div>
    )
  }

  if (!courseName || !reportId || !epc) {
    return (
      <div className="container" style={{ marginTop: '5.0em' }}>
        <Error title="Error Retrieving Incident Report" message="Invalid incident report" />
      </div>
    )
  }

  return (
    <div className="container" style={{ marginTop: '5.0em' }}>
      <IncidentReportHistory
        print={true}
        courseName={decodeURIComponent(courseName)}
        educationPeriodCd={decodeURIComponent(epc)}
        reportId={reportId}
      />
    </div>
  )
}

export default connect(mapStateToProps, {
  suppressNav,
  unSuppressNav,
  setSelectedEducationPeriod,
})(PrintIncidentReportPage)
