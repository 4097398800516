import { useLocation } from 'react-router-dom'
import { BasicInput } from '../../common'
import { addFilter, getFilters, removeFilter } from '../../../actions/filters'
import styles from '../../../assets/style/scss/filters.scss'
import { FILTER_YES } from '../../../constants/FilterConstants'

const FilterCheckbox = ({ breakpoint, label, category, id }) => {
  const { search } = useLocation()
  const activeFilters = getFilters(search)[category] || []
  const shouldBeChecked = activeFilters.find(f => f === FILTER_YES)

  return (
    <div className={`${styles['filter-checkbox']} ${styles[breakpoint]}`}>
      <BasicInput
        type="checkbox"
        input={{
          name: id,
          value: category,
          checked: Boolean(shouldBeChecked),
          onChange: e => {
            if (e.target.checked) addFilter(category, FILTER_YES)
            else removeFilter(category, FILTER_YES)
          },
        }}
        label={label}
      />
    </div>
  )
}

export default connect(state => ({ breakpoint: state.app.breakpoint.name }))(FilterCheckbox)
