import uniqBy from 'lodash-es/uniqBy'
import { DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import FilterDropdown from '../common/FilterDropdown'
import {
  EXAM_DATE_FILTER,
  LOGIN_STATUS_FILTER,
  FILTER_DEFAULT_VALUES,
  SETUP_STATUS_FILTER,
  EXAM_STATUS_FILTER,
  CHECKIN_STATUS_FILTER,
  MAKEUP_STATUS_FILTER,
  PRACTICE_STATUS_FILTER,
  SECTION_FILTER,
} from '../../../constants/FilterConstants'
import { DIGITAL_EXAM } from '../../../constants/SettingsConstants'
import { sortColumnByKey } from '../../../utils/sort'
import {
  CHECKIN_STATUSES,
  EXAM_STATUSES,
  MAKEUP_STATUSES,
  PRACTICE_STATUSES,
  SETUP_STATUSES,
  STATUS_NOT_APPLICABLE,
} from '../../../constants/ExamReadinessConstants'
import { formatExamWindow } from '../../../selectors/examWindows'

export const ExamDateFilterDropdown = ({ windows = [], label = EXAM_DATE_FILTER.label }) => {
  const dates = uniqBy(
    windows.reduce((arr, w) => [...arr, ...Object.values(w.examMap)], []),
    'examDateTime'
  )
  return dates.length ? (
    <FilterDropdown
      label={label}
      id="examdates"
      categories={[
        {
          key: EXAM_DATE_FILTER.filter,
          options: sortColumnByKey(dates, 'examDateTime', 'asc').map(d => ({
            label: `${formatExamWindow(
              d.examDateTime,
              `${DATETIME_FORMATS.shortMonthDay} ${DATETIME_FORMATS.hour}`,
              d.examFormat,
              true
            )}`,
            value: d.examDateTime,
          })),
        },
      ]}
    />
  ) : null
}

export const LoginFilterDropdown = ({ label = LOGIN_STATUS_FILTER.label }) => (
  <FilterDropdown
    label={label}
    id="loginstatus"
    categories={[
      {
        key: LOGIN_STATUS_FILTER.filter,
        options: Object.keys(FILTER_DEFAULT_VALUES).map(key => ({
          label: FILTER_DEFAULT_VALUES[key],
          value: key,
        })),
      },
    ]}
  />
)

export const PracticeStatusFilterDropdown = ({ label = PRACTICE_STATUS_FILTER.label }) => (
  <FilterDropdown
    label={label}
    id="practicestatus"
    categories={[
      {
        key: PRACTICE_STATUS_FILTER.filter,
        options: Object.keys(PRACTICE_STATUSES).map(key => ({
          label: PRACTICE_STATUSES[key],
          value: key,
        })),
      },
    ]}
  />
)

export const SetupStatusFilterDropdown = ({ label = SETUP_STATUS_FILTER.label }) => (
  <FilterDropdown
    label={label}
    id="setupstatus"
    categories={[
      {
        key: SETUP_STATUS_FILTER.filter,
        options: Object.keys(SETUP_STATUSES).map(key => ({
          label: SETUP_STATUSES[key],
          value: key,
        })),
      },
    ]}
  />
)

export const CheckinStatusFilterDropdown = ({ label = CHECKIN_STATUS_FILTER.label }) => (
  <FilterDropdown
    label={label}
    id="checkinstatus"
    categories={[
      {
        key: CHECKIN_STATUS_FILTER.filter,
        options: Object.keys(CHECKIN_STATUSES).map(key => ({
          label: CHECKIN_STATUSES[key],
          value: key,
        })),
      },
    ]}
  />
)

export const ExamStatusFilterDropdown = ({ label = EXAM_STATUS_FILTER.label, combineStatuses }) => {
  const { [STATUS_NOT_APPLICABLE]: na, ...statuses } = EXAM_STATUSES
  const examStatuses = combineStatuses ? statuses : EXAM_STATUSES
  const mappedExamStatus = Object.keys(examStatuses).map(key => ({
    label: EXAM_STATUSES[key],
    value: `${EXAM_STATUS_FILTER.filter}${key}`,
  }))
  const mappedCheckinStatus = combineStatuses
    ? Object.keys(CHECKIN_STATUSES).map(key => ({
        label: CHECKIN_STATUSES[key],
        value: `${CHECKIN_STATUS_FILTER.filter}${key}`,
      }))
    : []
  return (
    <FilterDropdown
      label={label}
      id="examstatus"
      categories={[
        {
          key: EXAM_STATUS_FILTER.filter,
          options: [...mappedCheckinStatus, ...mappedExamStatus],
        },
      ]}
    />
  )
}

export const MakeupStatusFilterDropdown = ({ label = MAKEUP_STATUS_FILTER.label }) => (
  <FilterDropdown
    label={label}
    id="makeupstatus"
    categories={[
      {
        key: MAKEUP_STATUS_FILTER.filter,
        options: Object.keys(MAKEUP_STATUSES).map(key => ({
          label: MAKEUP_STATUSES[key],
          value: key,
        })),
      },
    ]}
  />
)

export const SectionFilterDropdown = ({ sections, label = SECTION_FILTER.label }) =>
  sections.length ? (
    <FilterDropdown
      label={`${label}s`}
      id="sections"
      categories={[
        {
          key: SECTION_FILTER.filter,
          options: sections.map(s => ({ label: s.name, value: s.sectionId })),
        },
      ]}
    />
  ) : (
    []
  )
