export const initialState = { fetching: false, fetched: false, error: null }
export const saveInitialState = { saving: false, saveError: null, saved: false }

export const pending = state => ({ ...state, fetching: true, fetched: false, error: null })
export const rejected = (state, payload) => ({
  ...state,
  fetching: false,
  fetched: true,
  error: payload,
})
export const fulfilled = (state, payload) => ({
  ...state,
  fetching: false,
  fetched: true,
  ...payload,
})

export const save = (state = saveInitialState, { type, payload }, constants, constantType) => {
  switch (type) {
    case constants[`SAVE_${constantType}_PENDING`]:
      return { ...state, ...saveInitialState, saving: true }
    case constants[`SAVE_${constantType}_REJECTED`]:
      return { ...state, ...saveInitialState, saveError: payload }
    case constants[`SAVE_${constantType}_FULFILLED`]:
      return { ...state, ...saveInitialState, ...payload, saved: true }
    default:
      return state
  }
}

export default (
  state = initialState,
  { type, payload },
  constants,
  constantType,
  usePrefix = true
) => {
  const constant = usePrefix ? `FETCH_${constantType}` : constantType
  switch (type) {
    case constants[`${constant}_PENDING`]:
      return pending(state)
    case constants[`${constant}_FULFILLED`]:
      return fulfilled(state, payload)
    case constants[`${constant}_REJECTED`]:
      return rejected(state, payload)
    default:
      return state
  }
}
