export const DefaultSettingHeader = <strong>Default Setting</strong>
export const DefaultSettingIntro = ({ classes }) => (
  <p className={classes}>
    By default, every student who enrolls in a class section will be included in the exam roster
    with an order exam status of <strong>Yes</strong>. Before submitting the AP Exam order, AP
    coordinators only need to review the exam roster and change the exam registration for any
    students who are <em style={{ fontStyle: 'normal', textDecoration: 'underline' }}>not</em>{' '}
    taking an exam.
  </p>
)

export const AdvancedSettingHeader = <strong>Advanced Setting</strong>
export const AdvancedSettingIntro = ({ classes }) => (
  <div className={classes}>
    <p>
      With the advanced setting, students need to indicate their exam registration for each class
      section in which they enroll. Students will have an order exam status of{' '}
      <strong>Undecided</strong> until they indicate their exam registration. AP coordinators are
      able to adjust students&#39; exam registrations if necessary before submitting the AP Exam
      order.
    </p>
    <p>
      AP coordinators need to set a <strong>student decision deadline</strong>. This is the date by
      which students need to indicate their exam registration. To allow time to verify exam
      registrations and finalize the exam order, set the student decision deadline to a date{' '}
      <em style={{ fontStyle: 'normal', textDecoration: 'underline' }}>before</em> the exam ordering
      deadline. Set student decision deadlines for full-year/first semester courses and for courses
      that begin after the Final Ordering Deadline.
    </p>
  </div>
)
