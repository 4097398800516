/*
 props:
  - renderSuggestion
  - suggestions
  - total (optional)
  - validate (optional)
  - name
  - isRequired (optional)
  - disabled (optional)
  - label (optional)
  - placeholder (optional)
  - suggestionsFooterAction (optional)
*/

import { Field } from 'redux-form'
import { AutosuggestProxy } from './AutosuggestProxy'
import { AddRequiredValidation } from '..'

export default props => {
  const { isRequired, ...remainingProps } = props
  const passingProps = { ...remainingProps, required: isRequired }
  return (
    <Field
      {...passingProps}
      component={AutosuggestProxy}
      validate={AddRequiredValidation(passingProps.required, passingProps.validate)}
    />
  )
}
