import memoize from 'memoizee'
import { areDatesEqual, isDateBefore, isDateAfter } from '@myap/ui-library/esm/date'

const _parseFloatable = value => {
  return typeof value === 'string' &&
    (/^\d+$/.test(value) || /^\d+$/.test(value.replace(/[,.%$]/g, '')))
    ? true
    : false
}

const _parseIfFloat = value => {
  return parseFloat(value.replace(/,/g, ''))
}

const _cleanDataType = value => {
  return _parseFloatable(value)
    ? _parseIfFloat(value)
    : typeof value === 'string'
    ? value.toLowerCase()
    : value
}

const _sortDataByKeyProps = prop => {
  return keyValues => {
    let a = _cleanDataType(keyValues[0][prop])
    let b = _cleanDataType(keyValues[1][prop])

    return a > b ? 1 : a < b ? -1 : 0
  }
}

const _sortPositionOfData = (data, dirs) => {
  let props = data
  return (a, b) => {
    let keyArrAsc = [a, b]
    let keyArrDesc = [b, a]

    let i = 0,
      result = 0
    while (result === 0 && i < props.length) {
      let dir = dirs[i] || dirs[0]
      let keyValues = dir.toLowerCase() == 'desc' ? keyArrDesc : keyArrAsc
      result = a.defaultToTop ? 1 : b.defaultToTop ? 1 : _sortDataByKeyProps(props[i])(keyValues)
      i++
    }
    return result
  }
}

const _sortDataByKey = (data, key, fn) => {
  const clone = Array.apply(null, data)
  return clone.sort((a, b) => {
    return fn(a[key], b[key])
  })
}

export const sortColumnByKey = memoize((data, key, dir) => {
  const clone = Array.apply(null, data)
  let keys = Array.isArray(key) ? key : [key]
  let dirs = Array.isArray(dir) ? dir : [dir]
  return clone.sort(_sortPositionOfData(keys, dirs))
})

export const sortColumnByDate = memoize((data, key, dir) => {
  // const clone = Array.apply(null, data)
  // const keys = Array.isArray(key) ? key : [key]
  // const dirs = Array.isArray(dir) ? dir : [dir]
  // return clone.sort(_sortPositionOfData(keys, dirs))
  return _sortDataByKey(data, key, (a, b) => {
    if (!a) return 1
    if (!b) return -1
    if (areDatesEqual(a, b)) return 0
    if (dir === 'desc') return isDateBefore(a, b) ? 1 : -1
    else return isDateAfter(a, b) ? 1 : -1
  })
})

export const checkForSameness = memoize((data, key) => {
  let keys = Array.isArray(key) ? key : [key]
  return keys.every(k => data.every((d, i, arr) => d[k] && d[k] === arr[0][k]))
})

export const sortBySubAttribute = attr => (data, key, dir) => {
  let reformattedData = data
    .map(d => [...d[attr].map(s => ({ ...d, [attr]: [s], [key]: s[key] }))])
    .reduce((acc, arr) => [...acc, ...arr])

  return sortColumnByKey(reformattedData, key, dir)
}

export const mapToLabelValueObjectArrayAndSort = memoize(
  (data, labelKey, valueKey, sort, sortDir = 'asc') => {
    const sortedData = sortColumnByKey(data, sort || labelKey, sortDir)
    return sortedData.map(d => ({
      label: typeof labelKey === 'function' ? labelKey(d) : d[labelKey],
      value: typeof valueKey === 'function' ? valueKey(d) : d[valueKey],
    }))
  }
)
