import { change } from 'redux-form'
import { Select, Error, normalizeToNumber } from '../../common'
import { CREATE_FORM_NAME, CREATE_FORM_FIELDS } from '../../../constants/SectionConstants'
import { mapToLabelValueObjectArrayAndSort } from '../../../utils/sort'

const mapStateToProps = (state, { orgId }) => {
  const {
    orgsByCourse: { data = [], fetching, fetched },
  } = state
  return {
    orgId,
    data,
    fetching,
    fetched,
    availableOrgs: mapToLabelValueObjectArrayAndSort(data, 'orgName', 'orgId'),
  }
}

export default connect(
  mapStateToProps,
  { change }
)(({ orgId, data, availableOrgs, fetching, change }) => {
  if (!fetching && !orgId) {
    if (data.length > 1)
      return (
        <Select
          name={CREATE_FORM_FIELDS.orgId}
          label="School Name"
          options={availableOrgs}
          normalize={normalizeToNumber}
          isRequired={true}
        />
      )
    if (data.length === 1) change(CREATE_FORM_NAME, CREATE_FORM_FIELDS.orgId, data[0].orgId)
    else
      return (
        <Error
          title="Cannot Create Section"
          message="Error: You are not authorized to create a section for this course."
        />
      )
  }

  return null
})
