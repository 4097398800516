import { Table, Column, WindowScroller } from 'react-virtualized'
import 'react-virtualized/styles.css'
import style from '../../../assets/style/scss/infinite-scroll-table.scss'

const DEFAULT_ROW_HEIGHT = 48
const SORT_ASC = 'ASC'
const SORT_DESC = 'DESC'

const mapStateToProps = state => ({ breakpoint: state.app.breakpoint.name })

const InfiniteScrollTable = ({ breakpoint, rowRenderer, columns, data, defaultSort, sortFn }) => {
  const containerRef = useRef(null)
  const [sticky, setSticky] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [sortBy, setSortBy] = useState(defaultSort)
  const [sortDirection, setSortDirection] = useState(SORT_ASC)
  const count = data.length
  const tableWidth = breakpoint === 'desktop' ? 930 : 1146
  const sorted = sortFn(data, sortBy, sortDirection)

  const getOffets = () => {
    const { top, bottom } =
      (containerRef.current && containerRef.current.getBoundingClientRect()) || {}
    const stickyHeaderVisible = document.getElementsByClassName('cb-adjust-local-nav').length
    const { height: localNavHeight = 0 } =
      document.getElementsByClassName('cb-local-navigation')[0]?.getBoundingClientRect() || {}

    return { top, bottom, localNavOffset: stickyHeaderVisible ? localNavHeight : 0 }
  }

  const SortableHeader = ({ label, sortBy, dataKey, sortDirection }) => {
    return (
      <div className="sortable-header">
        <span>{label}</span>
        <i
          className={`cb-glyph cb-sort${
            sortBy === dataKey ? `-${sortDirection.toLowerCase()}` : ''
          }`}
          aria-hidden="true"
        />
      </div>
    )
  }

  const StickyHeaderRowRenderer = ({ className, columns, style }) => {
    const { localNavOffset } = getOffets()
    const stickyStyle = sticky ? { position: 'fixed', top: localNavOffset } : {}

    return (
      <div className={className} role="row" style={{ ...style, ...stickyStyle }}>
        {columns}
      </div>
    )
  }

  return count ? (
    <>
      <div className="tabbable-content-only">
        <button
          type="button"
          className="btn btn-sm btn-link"
          title={`${showAll ? 'Not ' : ''}Recommended for Screen Readers`}
          onClick={() => setShowAll(!showAll)}
        >
          View data in a {showAll ? 'scrolling' : 'single'} window
        </button>
      </div>
      <div
        className={`${style['infinite-scroll-table-responsive']} ${style[breakpoint]}`}
        ref={containerRef}
      >
        <WindowScroller
          onScroll={() => {
            const { top, bottom, localNavOffset } = getOffets()
            const isPastTable = bottom - localNavOffset - DEFAULT_ROW_HEIGHT < 0
            const shouldBeSticky = localNavOffset && top < localNavOffset
            setSticky(
              shouldBeSticky && !isPastTable && breakpoint !== 'tablet' && breakpoint !== 'mobile'
            )
          }}
          containerRole="presentation"
          scrollingResetTimeInterval={1}
        >
          {({ height, isScrolling, onChildScroll, scrollTop }) => (
            <Table
              autoHeight
              height={showAll ? DEFAULT_ROW_HEIGHT * (count + 1) : height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              scrollTop={scrollTop}
              width={tableWidth}
              headerHeight={DEFAULT_ROW_HEIGHT}
              rowHeight={DEFAULT_ROW_HEIGHT}
              rowCount={count}
              rowGetter={({ index }) => sorted[index]}
              rowStyle={() => ({ zIndex: 1000 })}
              sort={({ sortBy: sort }) => {
                const isNewColumn = sort !== sortBy
                const dir = !isNewColumn && sortDirection === SORT_ASC ? SORT_DESC : SORT_ASC
                setSortBy(sort)
                setSortDirection(dir)
              }}
              sortBy={sortBy}
              sortDirection={sortDirection}
              aria-readonly={false}
              tabIndex={null}
              containerRole="presentation"
              rowRenderer={rowRenderer}
              headerRowRenderer={StickyHeaderRowRenderer}
              gridStyle={{ paddingTop: sticky ? DEFAULT_ROW_HEIGHT : 0 }}
            >
              {Object.keys(columns).map(key => {
                const props = columns[key]
                const { cellRenderer: Cell, headerRenderer: Header, sortable, ...c } = props || {}
                return props ? (
                  <Column
                    key={key}
                    {...c}
                    disableSort={!sortable}
                    headerRenderer={
                      sortable
                        ? SortableHeader
                        : Header
                        ? props => <Header {...props} />
                        : undefined
                    }
                    cellRenderer={props => (
                      <Cell {...props} sortSelected={sortBy === c.dataKey} data={sorted} />
                    )}
                  />
                ) : null
              })}
            </Table>
          )}
        </WindowScroller>
      </div>
    </>
  ) : null
}

export default connect(mapStateToProps)(InfiniteScrollTable)
