import * as c from '../constants/SettingsConstants'

const initialState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  errored: null,
  error: null
}

export default (state={}, { type, payload }) => {
  switch(type) {
    case c.FETCH_EXAM_DECISIONS_PENDING:
      return {...state, [payload]: { ...state[payload], ...initialState, fetching: true, fetched: false, error: null }}
    case c.UPDATE_EXAM_DECISIONS_PENDING:
      return {...state, [payload]: { ...state[payload], updating: true, updated: false, error: null }}


    case c.FETCH_EXAM_DECISIONS_FULFILLED:
      return {...state, [payload.orgId]: { ...initialState, data: payload.data, fetched: true, fetching: false }}
    case c.UPDATE_EXAM_DECISIONS_FULFILLED:
      return {...state, [payload.orgId]: { ...state[payload.orgId], data: payload.data, updated: true, errored: null, updating: false }}

    case c.FETCH_EXAM_DECISIONS_REJECTED:
      return {...state, [payload.orgId]: { ...state[payload.orgId], fetching: false, error: payload.error }}
    case c.UPDATE_EXAM_DECISIONS_REJECTED:
      return {...state, [payload.orgId]: { ...state[payload.orgId], updating: false, errored: payload.error }}

    default:
      return state
  }
}