import {
  ExamsTab,
  StudentLabelsTab,
  OtherMaterialsTab,
  ScoreReportingServicesTab,
  VolumeRebateTab,
} from './'
import {
  ORDER_TAB_EXAMS,
  ORDER_TAB_LABELS,
  ORDER_TAB_MATERIALS,
  ORDER_TAB_SERVICES,
  ORDER_TAB_REBATE,
} from '../../../constants/OrderConstants'
import Tabs from '../../common/Tabs'

export const AllAccessOrderTabs = () => (
  <div style={{ marginTop: 20 }}>
    <Tabs
      page="orders"
      tabs={[
        { label: 'All Exams', Component: ExamsTab, compId: ORDER_TAB_EXAMS, isDefault: true },
        { label: 'Student Labels', Component: StudentLabelsTab, compId: ORDER_TAB_LABELS },
        { label: 'Other Materials', Component: OtherMaterialsTab, compId: ORDER_TAB_MATERIALS },
        {
          label: 'Score Reporting Services',
          Component: ScoreReportingServicesTab,
          compId: ORDER_TAB_SERVICES,
        },
        { label: 'Large Volume Rebates', Component: VolumeRebateTab, compId: ORDER_TAB_REBATE },
      ]}
    />
  </div>
)

export const RestrictedOrderTabs = () => {
  return null
}
