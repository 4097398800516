import { Link } from 'react-router-dom'
import { NAV_LINKS } from '../../constants/NavConstants'
import { getIncidentReportAvailability } from '../../selectors/incidentReports'

const mapStateToProps = state => {
  const {
    settingsEducationPeriod: { selectedIsCurrent },
  } = state
  const { active, visible } = getIncidentReportAvailability(state)
  return { active, visible, selectedIsCurrent }
}

const IncidentReportSubmission = ({ active, visible, selectedIsCurrent }) => {
  if (visible && active && selectedIsCurrent) {
    return (
      <>
        <h2 className="roboto" style={{ marginBottom: 0, marginTop: 30 }}>
          <Link to={NAV_LINKS.createIncidentReport}>Incident Report Submission</Link>
        </h2>
        <p>Access the online Incident Report (IR) form.</p>
      </>
    )
  }
  return null
}

export default connect(mapStateToProps)(IncidentReportSubmission)
