import { useHistory } from 'react-router-dom'
import { Modal } from '../../components/common'
import { hasUnsubmittedChanges } from '../../selectors/order'
import { isTakingExam } from '../../selectors/section'
import {
  UPDATE_TYPE_SSD,
  UPDATE_TYPE_DIGITAL_ACCOMMODATIONS,
  UPDATE_TYPE_INTENT,
  UPDATE_TYPE_DATE,
} from '../../constants/StudentConstants'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = state => ({
  changeOrderExists: hasUnsubmittedChanges(state.status.data.changeOrderStatus),
  isSubmitted: state.status.data.isSubmitted,
})

const Text = ({ type, exam, studentName, courseName, isSubmitted, swapCourseName }) => {
  if (type === UPDATE_TYPE_SSD)
    return (
      <p>
        {studentName}&#39;s SSD Materials for {courseName} exam have been updated. You will need to
        submit your changes on the order screen before they apply.
      </p>
    )

  if (type === UPDATE_TYPE_DIGITAL_ACCOMMODATIONS)
    return (
      <p>
        {studentName}&#39;s Digital Accommodations for {courseName} exam have been updated. You will
        need to submit your changes on the order screen before they apply.
      </p>
    )

  if (type === UPDATE_TYPE_DATE)
    return (
      <p>
        {studentName}&#39;s {courseName} exam date has been {isSubmitted ? 'changed' : 'updated'}.
        You will need to submit your changes on the order screen before they apply.
      </p>
    )

  if (type === UPDATE_TYPE_INTENT) {
    if (swapCourseName)
      return (
        <p>
          {studentName}&#39;s has been switched to {swapCourseName} from {courseName}. There is no
          impact to the cost. You will need to submit your changes on the order screen before they
          apply.
        </p>
      )

    return (
      <p>
        {studentName}&#39;s {courseName} exam has been {isTakingExam(exam) ? 'added' : 'removed'}.
        You will need to submit your changes on the order screen before they apply.
      </p>
    )
  }

  return null
}

const UpdateStudentExamSuccessModal = props => {
  const history = useHistory()
  const { modalCloseFocusElem, changeOrderExists } = props

  const footer = () => [
    { buttonLabel: 'Continue Updating Order', isDismissable: true, isPrimary: false },
    {
      buttonLabel: 'View Order',
      isPrimary: true,
      isDismissable: true,
      onClick: () => history.push(NAV_LINKS.orders),
    },
  ]

  return (
    <Modal
      headerTitle={changeOrderExists ? 'Change Order Updated' : 'Change Order Created'}
      modalCloseFocusElem={modalCloseFocusElem}
      footerActions={footer()}
    >
      <Text {...props} />
    </Modal>
  )
}

export default connect(mapStateToProps)(UpdateStudentExamSuccessModal)
