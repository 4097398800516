import { BasicSelect } from '../../common'
import { fetchNotTakingExamReasons } from '../../../actions/studentsCommon'

const SELECT_NOT_TAKING_EXAM_REASON_FIELD_NAME = 'notTakingExamReasonCd'

export default class SelectNotTakingExamReason extends Component {
  state = { selectedNotTakingExamReason: '', options: [] }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount() {
    const data = await fetchNotTakingExamReasons()
    this.setState(data)
  }

  handleChange(e) {
    const value = e.target.value !== '' ? parseInt(e.target.value, 10) : ''
    const { onChangeAction } = this.props
    
    this.setState({ selectedNotTakingExamReason: value })
    onChangeAction({ [SELECT_NOT_TAKING_EXAM_REASON_FIELD_NAME]: value })
  }

  render() {
    const { selectedNotTakingExamReason, options } = this.state


    return options.length
      ? <BasicSelect
          label="Why isn&#39;t this student taking this exam?"
          options={options}
          input={{
            name: SELECT_NOT_TAKING_EXAM_REASON_FIELD_NAME,
            value: selectedNotTakingExamReason,
            onChange: this.handleChange,
          }}
      /> : null
  }
}
