import {reduxForm} from 'redux-form'
import {PARTICIPATION_FORM, PARTICIPATION_FORM_FIELDS as FIELDS} from '../../../constants/SetupConstants'
import {StepTracker, StepButtons} from '..'
import {finishCoordinatorSetup} from '../../../actions/user'
import {updateOrgSettings, fetchOrgSettings} from '../../../actions/settingsOrg'

const mapStateToProps = state => {
  const { selectedOrgId } = state.user.data
  const orgSettings = state.settingsOrg[selectedOrgId].data
  const { selectedEducationPeriod, availableEducationPeriods } = state.settingsEducationPeriod
  return {
    orgSettings,
    selectedOrgId,
    selectedEducationPeriod,
    educationPeriod: availableEducationPeriods[selectedEducationPeriod]?.descr,
  }
}

const ParticipationForm = ({backStep, participationForm={}, schoolInformation, educationPeriod }) => (
  <div>
    <StepTracker />
    <h3 className="h1" style={{marginTop: 40}}>Participation Form</h3>
    <p>Using the information you provided, we have generated the <em>{educationPeriod} AP Participation Form</em>. This form will require your electronic signature before you can submit your school&#39;s exam orders. We have emailed a signature request to the AP Coordinator listed in the School Information section of this setup. Please note that it may take a few minutes for you to receive this email. You can also access and sign the Participation Form from the School Information and  Participation Contacts section on the Settings page.</p>
    <p>Click on the <strong>Continue to Dashboard</strong> button to go to the AP Registration and Ordering.</p>

    <div style={{marginTop: 50}}>
      <StepButtons backStep={backStep} form={PARTICIPATION_FORM} buttonText="Continue to Dashboard" />
    </div>

  </div>
)

export default connect(mapStateToProps, {updateOrgSettings, fetchOrgSettings, finishCoordinatorSetup})(reduxForm({
	form: PARTICIPATION_FORM,
	onSubmit: (data, dispatch, props) => props.updateOrgSettings({
		...props.orgSettings,
		participationForm: {...props.orgSettings.participationForm, complete: true}
	}),
	onSubmitSuccess: (data, dispatch, props) => {
    props.finishCoordinatorSetup(props.selectedOrgId, props.orgSettings.examAdministration.orderByAnotherSchool)
    // Fetch the org settings after saving because backend copies coordinator's
    // address into billing address and we want this to display on Settings Page.
    props.fetchOrgSettings(props.selectedOrgId, props.selectedEducationPeriod)
  },
})(ParticipationForm))
