import { Modal, Error } from '../../components/common'
import {
  removeTeacherWithGrantedAccess,
  resetGrantRevokeTeacherAccess,
} from '../../actions/settingsTeacherAccess'
import { getSelectedOrgId } from '../../selectors/settings'

const mapStateToProps = state => {
  const {
    teacherRevokeAccess: { error, fetching, fetched },
    settingsEducationPeriod: { selectedEducationPeriod, availableEducationPeriods },
  } = state
  const { descr } = availableEducationPeriods[selectedEducationPeriod]

  return {
    orgId: getSelectedOrgId(state),
    error,
    fetching,
    fetched,
    academicYear: descr,
  }
}

const RevokeTeacherAccessModal = ({
  grant: { courseName, firstName, lastName, proId, testCd, educationPeriodCd, onlineInd },
  orgId,
  error,
  fetching,
  fetched,
  academicYear,
  modalCloseFocusElem,
  removeTeacherWithGrantedAccess,
  resetGrantRevokeTeacherAccess,
}) => {
  useEffect(() => {
    return () => resetGrantRevokeTeacherAccess()
  }, [resetGrantRevokeTeacherAccess])

  return (
    <Modal
      shouldCloseModal={fetched}
      modalCloseFocusElem={modalCloseFocusElem}
      headerTitle="Remove Teacher Access"
      footerActions={[
        { buttonLabel: 'No', isDismissable: true },
        {
          buttonLabel: 'Yes',
          isPrimary: true,
          onClick: () =>
            removeTeacherWithGrantedAccess({
              proId,
              orgId,
              educationPeriodCd,
              testCd,
              onlineInd,
            }),
          isDisabled: fetching,
          busy: fetching,
        },
      ]}
    >
      <p>
        Are you sure you want to remove {courseName} access for {firstName} {lastName} for{' '}
        {academicYear}? <strong>Note:</strong> The teacher&#39;s access to AP Classroom will also be
        disabled.
      </p>
      {error ? <Error message={error} /> : null}
    </Modal>
  )
}

export default connect(mapStateToProps, {
  removeTeacherWithGrantedAccess,
  resetGrantRevokeTeacherAccess,
})(RevokeTeacherAccessModal)
